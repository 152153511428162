<template>
  <div class="w-100">
    <v-app id="my_vue_app">
        <v-row class="tabs-body-color">
            <v-col cols="12">
                <v-btn class="my-2" style="float: right" @click="exportWarehousePreparationLists()">Export</v-btn>
            </v-col>
        </v-row>
        <v-card v-show="loaded">
            <v-container fluid>
                <v-row class="mx-auto my-2">
                    <v-col sm="12" md="3">
                        <v-autocomplete
                            v-model="customer_id"
                            :items="GET_CUSTOMERS_LIST_SELECTION"
                            id="customer_id"
                            label="Select Customer"
                            dense
                            outlined
                            hide-details
                            auto-select-first
                            clearable
                        ></v-autocomplete>
                    </v-col>
                    <v-col sm="12" md="2">
                        <v-autocomplete
                            v-model="user_id"
                            :items="GET_ALL_USERS_SELECT"
                            id="user_id"
                            item-value="value"
                            item-text="name"
                            label="Select User"
                            dense
                            outlined
                            hide-details
                            auto-select-first
                            clearable
                        ></v-autocomplete>
                    </v-col>
                    <v-col sm="12" md="2" class="d-flex align-items-end mb-1">
                        <v-btn @click="getAllWarehousePreparationLists()">Filter</v-btn>
                    </v-col>
                    <v-spacer></v-spacer>
                    <v-col sm="12" md="5" class="text-right" v-if="tab_name == 'is_all'">
                        <a href="#" class="badge badge-all ml-1" @click="status_id = -1; getAllWarehousePreparationLists();">all</a>
                        <a href="#" class="badge badge-preparing ml-1" @click="status_id = 0; getAllWarehousePreparationLists();">preparing</a>
                        <a href="#" style="color: white" class="badge badge-prepared ml-1" @click="status_id = 1; getAllWarehousePreparationLists();">prepared</a>
                        <!-- <a href="#" class="badge badge-warning" @click="status_id=2; getAllWarehousePreparationLists()">rejected</a> -->
                        <!-- <a href="#" class="badge badge-danger" @click="status_id=3; getAllWarehousePreparationLists()">canceled</a> -->
                        <!-- <a href="#" class="badge badge-dark" @click="status_id=4; getAllWarehousePreparationLists()">deleted</a> -->
                        <!-- <a href="#" class="badge badge-success" @click="status_id=5; getAllWarehousePreparationLists()">completed</a> -->
                    </v-col>
                </v-row>
                <v-row class="mx-auto my-2">
                    <v-col sm="6" md="2">
                        <v-menu
                            v-model="menu2"
                            :close-on-content-click="false"
                            :nudge-right="40"
                            transition="scale-transition"
                            min-width="290px"
                        >
                            <template v-slot:activator="{ on }">
                                <v-text-field
                                    v-model="warehouse_preparation_list_dates[0]"
                                    v-on="on"
                                    label="Date From"
                                    append-icon="mdi-calendar-clock"
                                    dense
                                    outlined
                                    hide-details
                                    readonly
                                ></v-text-field>
                            </template>
                            <v-date-picker v-model="warehouse_preparation_list_dates[0]" @input="menu2 = false"></v-date-picker>
                        </v-menu>
                    </v-col>
                    <v-col sm="6" md="2">
                        <v-menu
                            v-model="menu3"
                            :close-on-content-click="false"
                            :nudge-right="40"
                            transition="scale-transition"
                            min-width="290px"
                        >
                            <template v-slot:activator="{ on }">
                                <v-text-field
                                    v-model="warehouse_preparation_list_dates[1]"
                                    v-on="on"
                                    label="Date To"
                                    append-icon="mdi-calendar-clock"
                                    dense
                                    outlined
                                    hide-details
                                    readonly
                                ></v-text-field>
                            </template>
                            <v-date-picker v-model="warehouse_preparation_list_dates[1]" @input="menu3 = false"></v-date-picker>
                        </v-menu>
                    </v-col>
                    <v-col sm="12" md="2">
                        <v-autocomplete
                            v-model="type_selection"
                            :items="item_type_selection"
                            dense
                            outlined
                            hide-details
                            auto-select-first
                            label="Select Item Type"
                            clearable
                        ></v-autocomplete>
                    </v-col>
                    <v-col sm="1">
                        <v-autocomplete
                            v-model="trade.base"
                            :items="trade.items"
                            label="Trade"
                            auto-select-first
                            hide-details
                            clearable
                            dense
                            outlined
                        ></v-autocomplete>
                    </v-col>
                    <v-spacer></v-spacer>
                    <v-col sm="12" md="12" lg="2" xl="2" class="mr-2 align-items-end">
                        <v-text-field
                            v-model="search"
                            label="Search WPF# / SO# / SQ# / Remarks"
                            single-line
                            clearable
                            dense
                            hide-details
                            @keydown.enter="searchInput()"
                            @click:clear="search='';searchInput()"
                        ></v-text-field>
                    </v-col>
                    <v-col sm="12" md="12" lg="2" xl="2" class="mr-3 align-items-end">
                        <v-text-field
                            v-model="searchItem"
                            label="Search Item Model / Name"
                            append-outer-icon="mdi-magnify"
                            single-line
                            clearable
                            dense
                            hide-details
                            @keydown.enter="searchInput()"
                            @click:append-outer="searchInput()"
                            @click:clear="searchItem='';searchInput()"
                        ></v-text-field>
                    </v-col>
                </v-row>
            </v-container>
            <v-container fluid>
                <v-data-table
                    v-model="dataTable"
                    :headers="headers"
                    :items="warehousePreparationLists"
                    :search="search"
                    :options.sync="options"
                    :items-per-page="10"
                    :server-items-length="totalWarehousePreparationLists"
                    :footer-props="{
                        itemsPerPageOptions: itemsPerPageOptions,
                    }"
                    :show-select="showCheckbox"
                    class="w-100"
                    @input="getSelectedID"
                >
                    <template v-slot:[`item.warehouse_name`]="{ item }">
                        <span style="font-size: 11px !important">{{ item.warehouse_name }}</span>
                    </template>

                    <template v-slot:[`item.customer_name`]="{ item }">
                        <span style="font-size: 10px !important">{{ item.customer_name }}</span>
                    </template>

                    <template v-slot:[`item.total_amount`]="{ item }">
                        <span class="text-nowrap">&#x20b1;{{ item.total_amount | currency }}</span>
                    </template>

                    <template v-slot:[`item.created_at`]="{ item }">
                        <span class="text-nowrap">{{ item.created_at | formatDateTime }}</span>
                    </template>

                    <template v-slot:[`item.picking_correction`]="{ item }">
                        <span class="badge badge-primary" v-if="item.picking_correction == 1">Yes</span>
                        <span class="badge badge-danger" v-else-if="item.picking_correction == 0">No</span>
                        <span class="badge badge-danger" v-else></span>
                    </template>

                    <template v-slot:[`item.printed`]="{ item }">
                        <span class="badge badge-primary" v-if="item.printed == 1">Yes</span>
                        <span class="badge badge-danger" v-else>No</span>
                    </template>

                    <template v-slot:[`item.checker_form_status`]="{ item }">
                        <span class="badge badge-primary" v-if="item.checker_form_status != -1">Yes</span>
                        <span class="badge badge-danger" v-else>No</span>
                    </template>

                    <template v-slot:[`item.trade`]="{ item }">
                        <span class="badge badge-primary" v-if="item.trade != -1">Yes</span>
                        <span class="badge badge-danger" v-else>No</span>
                    </template>

                    <template v-slot:[`item.status_text`]="{ item }">
                        <span class="badge badge-preparing" v-if="item.status_text == 'preparing'">{{ item.status_text }}</span>
                        <span class="badge badge-prepared" v-else-if="item.status_text == 'prepared'">{{ item.status_text }}</span>
                        <span class="badge badge-warning" v-else-if="item.status_text == 'rejected'">{{ item.status_text }}</span>
                        <span class="badge badge-danger" v-else-if="item.status_text == 'canceled'">{{ item.status_text }}</span>
                        <span class="badge badge-dark" v-else-if="item.status_text == 'deleted'">{{ item.status_text }}</span>
                        <span class="badge badge-success" v-else-if="item.status_text == 'completed'">{{ item.status_text }}</span>
                        <span class="badge badge-pending" v-else-if="item.status_text == 'partially prepared'">{{ item.status_text }}</span>
                    </template>

                    <template v-slot:[`item.action`]="{ item }">
                        <span class="text-nowrap" v-if="userAccess.view">
                            <v-btn text icon color="orange">
                                <v-icon class="btn-action" small @click="viewWarehousePreparationList(item.id)">mdi-eye</v-icon>
                            </v-btn>
                            <v-btn text icon color="blue" v-if="userAccess.edit && item.status == 0 || userAccess.edit && item.status == 6">
                                <v-icon class="btn-action" small @click="editWarehousePreparationList(item.id)">mdi-pencil</v-icon>
                            </v-btn>
                            <v-btn text icon color="red" v-if="userAccess.delete">
                                <v-icon class="btn-action" small @click=" currentWarehousePreparationListId = item.id; override.approve_override_delete_dialog = true;">mdi-delete</v-icon>
                            </v-btn>
                        </span>
                    </template>
                </v-data-table>
                <div v-if="convertBtn" class="table-footer-prepend d-flex pl-2 align-center" style="margin-top: -52px; height: 58px">
                    <v-btn @click="convertMultipleWPF()">CONVERT TO CF</v-btn>
                </div>
            </v-container>
            <!-- printing -->
            <v-container fluid style="display: none">
                <div id="div_logo_conversion">
                    <div style="font-size: 12px; line-height: 1.9">
                        <p><span style="font-size: 18px; font-weight: bold">WADFOW Tools Philippines Corp.</span></p>
                    </div>
                </div>
                <div id="div_wp_list_num_conversion" style="font-size: 12px; line-height: 1.9">
                    <div style="border-top: 1px dashed #cfcfcf; padding: 2px; padding-bottom: -4px; text-align: center; border-bottom: none;">&nbsp;</div>
                    <p>
                        <span style="font-size: 18px; font-weight: bold">Warehouse Preparation Form</span>
                        <br />
                        <b>WPF# {{ print.wp_id }}</b>
                        <br />
                        <b>SO# {{ print.so_num }}</b>
                        <br />
                        <b>Terms: {{ print.so_terms == 0 ? "CASH" : print.so_terms + " days" }}</b>
                    </p>
                </div>
                <div>
                    <table id="customer_details_conversion">
                        <tbody>
                            <tr>
                                <td>Customer:</td>
                                <td>{{ print.so.customer_name | uppercase }}</td>
                                <td>Date:</td>
                                <td>{{ print.created | formatDate }}</td>
                            </tr>
                            <tr>
                                <td>SO Remarks:</td>
                                <td>{{ print.so_remarks }}</td>
                                <td></td>
                                <td></td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div>
                    <table id="items_details_without_price_conversion">
                        <thead>
                            <tr>
                                <th>#</th>
                                <th>PREP</th>
                                <th>QTY</th>
                                <th>STOCK</th>
                                <th>UNITS</th>
                                <th>MODEL</th>
                                <th>DESCRIPTION</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(item, index) in print.item" :key="index">
                                <td>{{ item.non_default_warehouse_stocks + item.default_warehouse_stocks == 0 ? `(${index + 1})` : index + 1 }}</td>
                                <td><!-- {{ item.quantity_served }} --></td>
                                <td>{{ item.quantity }}</td>
                                <td>{{ item.stocks }}</td>
                                <td>{{ item.item_uom_text }}</td>
                                <td>{{ item.model }}</td>
                                <td>{{ item.name }}</td>
                            </tr>
                            <tr>
                                <td colspan="6">***** NOTHING FOLLOWS *****</td>
                            </tr>
                        </tbody>
                    </table>
                </div>

                <div>
                    <table id="num_of_items_conversion">
                        <tbody>
                            <tr>
                                <td><span>No. of Items: {{ print.no_of_items }}</span></td>
                                <td></td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div>
                    <table id="footer_conversion">
                        <tbody>
                            <tr>
                                <td>Prepared By</td>
                            </tr>
                            <tr>
                                <td><br /><br /><br />{{ print.preparer_name }}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </v-container>
        </v-card>
    </v-app>

    <v-app id="load_bar" v-if="!loaded">
        <v-text-field color="info" loading disabled></v-text-field>
    </v-app>

    <ConfirmOverrideComponentVue
        :approve_override_dialog="override.approve_override_delete_dialog"
        :departments_allowed="override.override_departments_allowed"
        :heading="override.override_heading"
        @closeConfirmOverride="closeConfirmOverride"
    ></ConfirmOverrideComponentVue>

    <!-- VIEW MODAL-->
    <WarehousePreparationViewComponentVue
        :viewWarehousePreparationListId="viewWarehousePreparationListId"
        @closeView="closeView"
        @getAllWarehousePreparationLists="getAllWarehousePreparationLists"
    ></WarehousePreparationViewComponentVue>
  </div>
</template>

<script>
import ConfirmOverrideComponentVue from "@/views/main/Utils/ConfirmOverrideComponent.vue";
import WarehousePreparationViewComponentVue from "./WarehousePreparationViewComponent.vue";
import SharedFunctionsComponent from "@/views/main/Shared/SharedFunctionsComponent.vue";
import swal from "sweetalert2";
import { mapGetters } from "vuex";

export default {
    mixins: [
        SharedFunctionsComponent,
    ],
    components: {
        ConfirmOverrideComponentVue,
        WarehousePreparationViewComponentVue,
    },
    props: [
        'warehousePreparationListsActionResult',
        'tab_name',
        'print',
        'user_access',
    ],
    data() {
        return {
            dataTable: [],
            test: true,
            convertBtn: false,
            override: {
                approve_override_delete_dialog: false,
                override_departments_allowed: ['OP', 'EX', 'IT'],
                override_heading: 'Delete Warehouse Prep Form',
                override_authenticated: false,
                errors: [],
            },
            actionResult: {
                model: 'WarehousePreparationLists',
                action: '',
            },
            loaded: false,
            totalWarehousePreparationLists: 0,
            options: {},
            warehousePreparationLists: [],
            itemsPerPageOptions: [5, 10, 20, 50, 100],
            itemsPerPage: 10,
            search: '',
            searchItem: '',
            awaitingSearch: false,
            headers: [
                { text: 'WPF#', align: 'left', width: '6%', value: 'id' },
                { text: 'Warehouse', align: 'left', value: 'warehouse_name' },
                { text: 'SO#', align: 'left', width: '4%', value: 'sales_order_num' },
                { text: 'SQ#', align: 'left', width: '4%', value: 'sales_quotation_num' },
                { text: 'Customer', align: 'left', value: 'customer_name' },
                { text: 'Status', align: 'left', width: '5%', sortable: false, value: 'status_text' },
                { text: 'Date Issued', align: 'left', width: '8%', value: 'created_at' },
                { text: 'Preparer', align: 'left', value: 'preparer_name' },
                { text: 'Date Completed', align: 'left', value: 'complete_date' },
                { text: 'Completed By', align: 'left', value: 'completed_by_name' },
                { text: 'Item Type', align: 'left', value: 'item_type' },
                { text: 'Crate Number', align: 'left', value: 'crate_number' },
                { text: 'Correct Picking', align: 'left', width: '3%', sortable: false, value: 'picking_correction' },
                { text: 'Printed', align: 'left', width: '3%', sortable: false, value: 'printed' },
                { text: 'Converted', align: 'left', width: '3%', sortable: false, value: 'checker_form_status' },
                { text: 'Trade', align: 'left', width: '3%', sortable: false, value: 'trade' },
                { text: 'Actions', align: 'center', width: '6%', sortable: false, value: 'action' },
            ],

            viewWarehousePreparationListId: '',
            currentWarehousePreparationListId: '',

            customers_list: [],
            customer_id: '',
            users_list: [],
            user_id: '',

            warehouse_preparation_list_dates: [
                this.$dayjs().startOf("year").format("YYYY-MM-DD"),
                this.$dayjs().endOf("month").format("YYYY-MM-DD"),
            ],

            menu2: false,
            menu3: false,

            userAccess: {
                view: false,
                edit: false,
                delete: false,
            },

            selected_id: '',
            status: [],
            selected_preparer: [],

            print_no_price: true,

            status_id: -1,
            showCheckbox: false,

            loading: {
                customer_selection: true,
                // salesman_selection  : true,
                user_selection: true,
            },
            item_type_selection: [],
            type_selection: [],
            trade: {
                items: [
                    { value: 1, text: 'Yes' },
                    { value: 0, text: 'No' },
                ],
                base: '',
            },
        }
    },
    methods: {
        showMassConversion() {
            if (
                this.userAccess.view == 1 &&
                (this.status_id == 0 || this.status_id == -1) &&
                (this.tab_name == "is_preparing" ||
                this.tab_name == "is_for_print" ||
                this.tab_name == "is_all")
            ) {
                this.showCheckbox = true;
                return false;
            } else if (this.userAccess.view == 1 && this.status_id == 1) {
                this.showCheckbox = false;
                return false;
            }
        },
        async convertMultipleWPF() {
            let data = [];
            await swal.fire({
                title: "",
                text: "Are you sure you want to Convert selected WPF to CF?",
                icon: 'question',
                showConfirmButton: true,
                showCancelButton: true,
                reverseButtons: true,
                allowOutsideClick: false,
                confirmButtonColor: "#397373",
                cancelButtonColor: "grey",
                confirmButtonText: "Confirm",
                showCloseButton: true,
            }).then(async (result) => {
                if (result.isConfirmed) {
                    let payload = {
                        selected_id: this.selected_id,
                        url: "getWPFDetails",
                    }

                    await this.$store.dispatch("getWPF", payload).then((response) => {
                        data = response.data;
                    })

                    if (data != "") {
                        for (const index in data.data) {
                            await new Promise((resolve) => {
                                this.print.wp_id = data.data[index].id;
                                this.print.so_num = data.data[index].sales_order_num;
                                this.print.so_terms = data.data[index].so_terms;
                                this.print.created = data.data[index].created_at;
                                this.print.so_remarks = data.data[index].sales_order.remarks;
                                this.print.preparer_name = data.data[index].preparer_name;
                                this.print.item = data.data[index].wp_items;
                                this.print.so = data.data[index].sales_order;
                                this.print.item.forEach((e) => {
                                    this.print.no_of_items += e.quantity;
                                });

                                let payload = {
                                    url: "warehousePreparationLists/print/" + this.print.wp_id,
                                }

                                this.$store.dispatch("postWPF", payload).then((response) => {
                                    if (response) {
                                        let update_payload = {
                                            url: "updateWPListStatus/" + this.print.wp_id,
                                            status: 1,
                                        }

                                        this.$store.dispatch("postWPF", update_payload).then((response) => {
                                            let data = response.data;
                                            if (data.error) {
                                                swal.fire("", data.error, "warning");
                                                return false;
                                            }

                                            resolve();
                                        });
                                    }
                                });
                            });

                            this.renderPDF("print", "_conversion");
                            this.print.no_of_items = 0;
                        }

                        swal.fire("", "Warehouse Preparation Form Convert Success", "success")
                        this.getAllWarehousePreparationLists();
                    } else {
                        swal.fire("", "NO ITEMS FOUND", "warning");
                    }
                }
            })
        },
        getSelectedID(val) {
            this.status = val.map(function (e) {
                return e.status;
            })
            this.selected_id = val.map(function (e) {
                return e.id;
            })
            this.selected_preparer = val.map(function (e) {
                return e.preparer_name;
            })

            if (this.selected_id != "") {
                this.status.filter((i) => {
                    if (i == 1) {
                        swal.fire("Status must be preparing", "", "warning");
                        const index = this.dataTable.indexOf(this.dataTable[i]);
                        this.dataTable.splice(index, 1);
                        return false;
                    } else {
                        this.convertBtn = true;
                    }
                })

                this.selected_preparer.filter((i) => {
                    if (i == "") {
                        swal.fire("Preparer cannot be blank", "", "warning");
                        const index = this.dataTable.indexOf(this.dataTable[i]);
                        this.dataTable.splice(index, 1);
                        return false;
                    } else {
                        this.convertBtn = true;
                    }
                })
            } else {
                this.convertBtn = false;
            }
        },
        renderPDF(pdfAction, type) {
            var doc = new this.$jspdf("p", "mm", "letter")

            doc.setFont(undefined, "bold").setFontSize(14).text(7, 4, "WADFOW Tools Philippines Corp.").setFont(undefined, "normal")
            doc.setFont(undefined, "bold").setFontSize(14).text(142, 4, "Warehouse Preparation Form")
            doc.setFont(undefined, "bold").setFontSize(12).text(142, 7, "WPF# " + this.print.wp_id + "\nSO# " + this.print.so_num + "\nTerms: " + this.print.so_terms == 0 ? "CASH" : this.print.so_terms + " days")

            doc.autoTable({
                html: "#customer_details" + type,
                theme: "plain",
                margin: { top: 40, left: 6, right: 6 },
                columnStyles: {
                0: { cellWidth: 30 },
                1: { cellWidth: 124 },
                2: { cellWidth: 16 },
                3: { cellWidth: 25 },
                },
            })

            if (this.print_no_price == false) {
                doc.autoTable({
                    html: "#items_details" + type,
                    startY: doc.autoTable.previous.finalY + 3,
                    margin: { left: 6, right: 6 },
                    theme: "grid",
                    headStyles: {
                        halign: "center",
                        fillColor: "#ffffff",
                        textColor: "#000000",
                        lineWidth: 0.2,
                        lineColor: "#000000",
                    },
                    columnStyles: {
                        0: { cellWidth: 9, halign: "center", textColor: "#000000" },
                        1: { cellWidth: 13, halign: "center", textColor: "#000000" },
                        2: { cellWidth: 14, halign: "center", textColor: "#000000" },
                        3: { cellWidth: 14, halign: "center", textColor: "#000000" },
                        4: { cellWidth: 29, halign: "center", textColor: "#000000" },
                        5: { halign: "left", textColor: "#000000" },
                        6: { cellWidth: 25, halign: "right", textColor: "#000000" },
                        7: { cellWidth: 25, halign: "right", textColor: "#000000" },
                    },
                    bodyStyles: {
                        lineColor: "#000000",
                    },
                })
            } else {
                doc.autoTable({
                    html: "#items_details_without_price" + type,
                    startY: doc.autoTable.previous.finalY + 3,
                    margin: { left: 6, right: 6 },
                    theme: "grid",
                    headStyles: {
                        halign: "center",
                        fillColor: "#ffffff",
                        textColor: "#000000",
                        lineWidth: 0.2,
                        lineColor: "#000000",
                    },
                    columnStyles: {
                        0: { cellWidth: 8, halign: "center", textColor: "#000000" },
                        1: { cellWidth: 14, halign: "center", textColor: "#000000" },
                        2: { cellWidth: 14, halign: "center", textColor: "#000000" },
                        3: { cellWidth: 16, halign: "center", textColor: "#000000" },
                        4: { cellWidth: 15, halign: "center", textColor: "#000000" },
                        5: { cellWidth: 27, halign: "center", textColor: "#000000" },
                        6: { halign: "left", textColor: "#000000" },
                    },
                    bodyStyles: {
                        lineColor: "#000000",
                    },
                })
            }

            if (this.print_no_price == false) {
                doc.autoTable({
                    html: "#total_details" + type,
                    theme: "plain",
                    startY: 207,
                    margin: { top: 37, left: 6, right: 6 },
                    columnStyles: {
                        0: { cellWidth: 115, textColor: "#000000" },
                        1: { cellWidth: 35, halign: "right", textColor: "#000000" },
                        2: { cellWidth: 12, textColor: "#000000" },
                        3: { cellWidth: 35, halign: "right", textColor: "#000000" },
                    },
                });
            }

            doc.autoTable({
                html: "#num_of_items" + type,
                theme: "plain",
                tableWidth: 116,
                startY: 225,
                margin: { top: 37, left: 6, right: 6 },
                columnStyles: {
                0: { cellWidth: 38, textColor: "#000000" },
                1: { cellWidth: 38, textColor: "#000000" },
                2: { cellWidth: 38, textColor: "#000000" },
                },
            })

            var footer_y = 235;

            doc.autoTable({
                html: "#footer" + type,
                theme: "grid",
                tableWidth: 106,
                startY: footer_y,
                margin: { left: 6, right: 6 },
                columnStyles: {
                    0: { halign: "center", valign: "bottom", textColor: "#000000" },
                },
                bodyStyles: {
                    lineColor: "#000000",
                },
            })

            let finalY = doc.lastAutoTable.finalY; // The y position on the page
            doc.setFontSize(7);
            doc.setTextColor("#000000");
            doc.text(190, finalY, "Page 1 of 1");
            if (pdfAction == "download") {
                doc.save("Warehouse_Preparation_Form " + this.wp_list.id + ".pdf");
            } else if (pdfAction == "print") {
                doc.autoPrint();
                window.open(doc.output("bloburl"), "_blank");
            }
        },
        getAllWarehousePreparationLists() {
            this.loaded = false;
            let payload = {
                page: this.options.page,
                itemsPerPage: this.options.itemsPerPage,
                sortBy: this.options.sortBy,
                sortDesc: this.options.sortDesc,
                search: this.search,
                searchItem: this.searchItem,
                customer_id: this.customer_id,
                user_id: this.user_id,
                warehouse_preparation_list_dates: this.warehouse_preparation_list_dates,
                status_filter: this.status_id,
                tab_name: this.tab_name,
                url: "warehousePreparationLists/getWarehousePreparationLists",
                type_selection: this.type_selection,
                trade: this.trade.base,
            }

            this.$store.dispatch("getWPF", payload).then((response) => {
                this.warehousePreparationLists = response.data.data;
                this.loaded = true;
                this.totalWarehousePreparationLists = response.data.total;
                this.dataTable = [];
                this.showMassConversion();
                this.checkAccess();
            })
        },
        async exportWarehousePreparationLists() {
            this.route_name = "exportWarehousePreparationLists";
            let that = this;

            const workbook = new this.$exceljs.Workbook();

            let payload = {
                url: 'exportWarehousePreparationLists',
                customer_id:that.customer_id,
                user_id:that.user_id,
                warehouse_preparation_list_dates:that.warehouse_preparation_list_dates,
                status_id:that.status_id,
                tab_name:that.tab_name,
                type_selection: that.type_selection,
            }

            that.loaded = false

            await this.$store.dispatch('getWPF', payload).then((response) => {
                let summary = response.data.summary
                let detailed = response.data.detailed

                const summarySheet = workbook.addWorksheet('Summary');

                summarySheet.columns = [
                    { header: 'WPF#', key: 'id' },
                    { header: 'Warehouse', key: 'warehouse_code' },
                    { header: 'SO#', key: 'order_num', style: { numFmt: '@' } },
                    { header: 'SQ#', key: 'quote_num', style: { numFmt: '@' } },
                    { header: 'Customer', key: 'company_name' },
                    { header: 'Status', key: 'wpf_status' },
                    { header: 'Date Issued', key: 'created_at', style: { numFmt: 'mm/dd/yyyy' } },
                    { header: 'Issuer', key: 'issuer' },
                    { header: 'Preparer', key: 'preparer_name' },
                    { header: 'Complete Date', key: 'complete_date', style: { numFmt: 'mm/dd/yyyy' } },
                    { header: 'Complete Month', key: 'complete_month', style: { numFmt: 'yyyy-mm' } },
                    { header: 'Completed By', key: 'completer' },
                    { header: 'Correct Picking', key: 'picking_correction' },
                    { header: 'Picking Reason', key: 'correct_picking_reason' },
                    { header: 'Start Date', key: 'start_date', style: { numFmt: 'mm/dd/yyyy hh:mm' } },
                    { header: 'End Date', key: 'end_date', style: { numFmt: 'mm/dd/yyyy hh:mm' } },
                    { header: 'Durations', key: 'durations' },
                    { header: 'Printed', key: 'printed' },
                    { header: 'Converted', key: 'converted' },
                    { header: 'Crate Number', key: 'crate_number' },
                ]

                summary.forEach((e) => {
                    e.created_at = this.formatDate(e.created_at)
                    e.complete_date = [null, '0000-00-00'].includes(e.complete_date) ? '' : this.formatDate(e.complete_date)
                    e.complete_month = [null, '0000-00-00'].includes(e.complete_month) ? '' : this.formatDate(e.complete_month)
                    e.start_date = isNaN(new Date(e.start_date)) ? '' : this.formatDate(e.start_date)
                    e.end_date = isNaN(new Date(e.end_date)) ? '' : this.formatDate(e.end_date)

                    summarySheet.addRow(e)
                })

                const detailedSheet = workbook.addWorksheet("Detailed");

                detailedSheet.columns = [
                    { header: 'WPF#', key: 'id' },
                    { header: 'Warehouse', key: 'warehouse_code' },
                    { header: 'SO#', key: 'order_num', style: { numFmt: '@' } },
                    { header: 'SQ#', key: 'quote_num', style: { numFmt: '@' } },
                    { header: 'Customer', key: 'company_name' },
                    { header: 'Status', key: 'wpf_status' },
                    { header: 'Date Issued', key: 'created_at', style: { numFmt: 'mm/dd/yyyy' } },
                    { header: 'Issuer', key: 'issuer' },
                    { header: 'Preparer', key: 'preparer_name' },
                    { header: 'Complete Date', key: 'complete_date', style: { numFmt: 'mm/dd/yyyy' } },
                    { header: 'Complete Month', key: 'complete_month', style: { numFmt: 'yyyy-mm' } },
                    { header: 'Completed By', key: 'completer' },
                    { header: 'Correct Picking', key: 'picking_correction' },
                    { header: 'Picking Reason', key: 'correct_picking_reason' },
                    { header: 'Start Date', key: 'start_date', style: { numFmt: 'mm/dd/yyyy hh:mm' } },
                    { header: 'End Date', key: 'end_date', style: { numFmt: 'mm/dd/yyyy hh:mm' } },
                    { header: 'Durations', key: 'durations' },
                    { header: 'Printed', key: 'printed' },
                    { header: 'Converted', key: 'converted' },
                    { header: 'Model', key: 'model' },
                    { header: 'Name', key: 'name' },
                    { header: 'UOM', key: 'uom' },
                    { header: 'Type', key: 'type' },
                    { header: 'Category', key: 'category' },
                    { header: 'Quantity', key: 'quantity', style: { numFmt: '#,##0' } },
                    { header: 'Quantity To Ship', key: 'quantity_served', style: { numFmt: '#,##0' } },
                    { header: 'Stocks', key: 'warehouse_stocks', style: { numFmt: '#,##0' } },
                ]

                detailed.forEach((e) => {
                    e.created_at = this.formatDate(e.created_at)
                    e.complete_date = [null, '0000-00-00'].includes(e.complete_date) ? '' : this.formatDate(e.complete_date)
                    e.complete_month = [null, '0000-00-00'].includes(e.complete_month) ? '' : this.formatDate(e.complete_month)
                    e.start_date = isNaN(new Date(e.start_date)) ? '' : this.formatDate(e.start_date)
                    e.end_date = isNaN(new Date(e.end_date)) ? '' : this.formatDate(e.end_date)
                    e.warehouse_stocks = parseFloat(e.warehouse_stocks)

                    detailedSheet.addRow(e);
                })
            }).catch((e) => {
                swal.fire("Error !", "", "error");
                that.loaded = true;
            })

            that.loaded = true;
            workbook.xlsx.writeBuffer().then((data) => {
                const blob = new Blob([data], {
                    type: "applicationi/xlxs",
                })
                this.$filesaver.saveAs(blob, `Warehouse Preparation Forms - All.xlsx`);
            })
        },
        viewWarehousePreparationList(id) {
            this.viewWarehousePreparationListId = id;
        },
        editWarehousePreparationList(id) {
            this.$emit("showEditModal", id);
        },
        deleteWarehousePreparationList(id) {
            if (this.override.override_authenticated) {
                swal.fire({
                    title: "",
                    text: "Are you sure you want to Delete?",
                    icon: 'question',
                    showConfirmButton: true,
                    showCancelButton: true,
                    reverseButtons: true,
                    allowOutsideClick: false,
                    confirmButtonColor: "#397373",
                    cancelButtonColor: "grey",
                    confirmButtonText: "Confirm",
                    showCloseButton: true,
                }).then((result) => {
                    if (result.isConfirmed) {
                        let payload = {
                            url: "warehousePreparationLists/" + id,
                        }

                        this.$store.dispatch("deleteWPF", payload).then((response) => {
                            if (response.data.status == "success") {
                                this.getAllWarehousePreparationLists()
                            }

                            swal.fire(response.data.msg, { icon: response.data.status })
                        }).catch((error) => {
                            swal.fire("An Error Occured", error, "error")
                        })
                    }
                })
            }
        },
        closeView(action = false) {
            this.viewWarehousePreparationListId = 0
            if (action == "convertSuccess" || action == "updateStatusSuccess" || action == "printSuccess") {
                this.getAllWarehousePreparationLists()
            }
        },
        closeConfirmOverride(user_id, authenticated = false) {
            this.override.approve_override_delete_dialog = false
            if (authenticated) {
                this.override.override_authenticated = true
                this.deleteWarehousePreparationList(this.currentWarehousePreparationListId)
            }
        },
        searchInput() {
            this.getAllWarehousePreparationLists({ page:this.options.page = 1, query: this.search })
        },
        getCustomers() {
            // this.$store.dispatch('getAllCustomers').then(response => {
            //     this.customers_list = response.data
            // });
        },
        getUsers() {
            // this.$store.dispatch('getAllSelectUsers').then(response => {
            //     this.users_list = response
            // });
        },
        addKeyListener() {
            window.addEventListener("keyup", function (e) {
                if (e.keyCode == 13) {
                    this.searchInput()
                    $("input").blur()
                }
            }.bind(this))
        },
        checkAccess() {
            let val = this.user_access
            val.forEach((e) => {
                if (e.actions_code == "edit") {
                    this.userAccess.edit = true
                } else if (e.actions_code == "delete") {
                    this.userAccess.delete = true
                } else if (e.actions_code == "view") {
                    this.userAccess.view = true
                }
            })
        },
        getSearchFromUrl() {
            if (Object.keys(this.$route.query).length != 0) {
                Object.keys(this.$route.query).forEach(e => {
                    this.warehouse_preparation_list_dates[0] = atob(this.$route.query.currentYear);
                })
                this.getAllWarehousePreparationLists();
            }
        },
    },
    mounted() {
        // this.addKeyListener()
        this.getCustomers()
        this.checkAccess()
        this.getUsers()
        this.getSearchFromUrl()
        this.$store.dispatch('getItemTypes').then((response)=>{
          this.item_type_selection = response.data
        })
    },
    watch: {
        warehousePreparationListsActionResult: function (val) {
            if (this.warehousePreparationListsActionResult == "success") {
                this.getAllWarehousePreparationLists();
            }
        },
        options: {
            handler() {
                this.getAllWarehousePreparationLists();
            },
            deep: true,
        },
        GET_CUSTOMERS_LIST_SELECTION: {
            handler(val) {
                this.loading.customer_selection = false;
            },
        },
        GET_ALL_USERS_SELECT: {
            handler(val) {
                this.loading.user_selection = false
            },
        },
    },
    computed: {
        ...mapGetters([
            "GET_CUSTOMERS_LIST_SELECTION", "GET_ALL_USERS_SELECT", 'GET_ITEM_TYPES',
        ]),
        date_range_filter_text() {
            return this.date_range_filter.values.join("  to  ")
        },
    },
};
</script>
