<template>
    <v-dialog v-model="repairFormDetailsDialog" persistent max-width="98%" scrollable>
        <v-card class="pb-1">
            <v-row class="m-0">
                <v-col>
                    <v-card-title>
                        <span class="headline">Repair Form Details</span>
                    </v-card-title>
                </v-col>
                <v-col cols="pull-right-10 p-2">
                    <v-btn
                        text
                        icon
                        color="gray"
                        class="float-right"
                        @click="closeRepairFormDetailsDialog()"
                    >
                        <v-icon>mdi-close-circle</v-icon>
                    </v-btn>
                </v-col>
            </v-row>
            <v-divider class="mt-0" />
            <v-container fluid style="background-color: white; overflow-y: scroll">
                <v-col>
                    <v-card-text>
                        <div class="ma-4">
                            <v-layout row class="px-4">
                                <v-flex lg4>
                                    <div>
                                        RF#: <span>{{ rfNumber }}</span>
                                    </div>
                                    <div>
                                        RD#: <span>{{ rrNumber }}</span>
                                    </div>
                                    <div>
                                        Date: <span>{{ rrDate }}</span>
                                    </div>
                                    <div>
                                        Status: <span>{{ rfStatus }}</span>
                                    </div>
                                    <div v-if="itemWarrantyType == 'In-Warranty'">
                                        Purchase Date:
                                        <span>{{ itemPurchaseDate }}</span>
                                    </div>
                                    <div>
                                        Warranty Type:
                                        <v-chip :style="checkWarrantyType()" small>{{ itemWarrantyType }}</v-chip>
                                    </div>
                                </v-flex>
                                <v-flex lg4>
                                    <div>
                                        Repair Status:
                                        <span>
                                            <v-chip
                                                :color="repairStatusColor()"
                                                text-color="white"
                                                small
                                            >
                                                {{ itemRepairStatus }}
                                            </v-chip>
                                        </span>
                                    </div>
                                    <div v-if="rfStatus == 'REP' || rfStatus == 'RFD' || rfStatus == 'DEL'">
                                        Processed By: <span>{{ preparedBy }}</span>
                                    </div>
                                    <!-- <div v-if="rfStatus == 'REP' || rfStatus == 'RFD' || rfStatus == 'DEL'">
                                        Parts Personnel: <span>{{  }}</span>
                                    </div> -->
                                    <div>
                                        Technician: <span>{{ assignedTechnician }}</span>
                                    </div>

                                    <div v-if="!!stockTransferNum">
                                        Stock Transfer#: <span>{{ stockTransferNum }}</span>
                                    </div>

                                    <div v-if="!!stockRequestNum">
                                        Stock Request#: <span>{{ stockRequestNum }}</span>
                                    </div>
                                </v-flex>
                                <v-flex lg4>
                                    <div v-if="rfStatus == 'RFD'">
                                        Preparation:
                                        <span>
                                            <v-chip
                                                :color="rfPrepared ? 'blue' : 'orange'"
                                                text-color="white"
                                                small
                                            >
                                                {{ rfPrepared ? 'Prepared' : 'Preparing' }}
                                            </v-chip>

                                            <v-chip
                                                v-if="readyForDelivery"
                                                color="green"
                                                text-color="white"
                                                small
                                            >
                                                Ready for delivery
                                            </v-chip>
                                        </span>
                                    </div>
                                    <div v-if="rfStatus == 'RFD' && !!salesOrderNum || rfStatus == 'DEL' && !!salesOrderNum || rfStatus == 'COL' && !!salesOrderNum">
                                        SO#: <span>{{ salesOrderNum }}</span>
                                        <span style="color:blue;font-size:14px">{{ ' ' +  salesOrderTextStatus() }}</span>
                                    </div>
                                    <div v-if="rfStatus == 'RFD' && !!packingListNum || rfStatus == 'DEL' && !!packingListNum || rfStatus == 'COL' && !!packingListNum">
                                        PL#: <span>{{ packingListNum }}</span>
                                    </div>
                                    <div v-if="rfStatus == 'PIN'">
                                        Start Repair: <span>{{ repairStartedAt }}</span>
                                    </div>
                                    <div v-if="rfStatus == 'REP'">
                                        Complete Repair: <span>{{ repairCompletedAt }}</span>
                                    </div>
                                </v-flex>
                            </v-layout>

                            <v-divider />
                        </div>

                        <div class="mx-4">
                            <div class="pb-2">
                                <v-layout>
                                    <v-flex lg6>
                                        <!-- <v-btn @click="showCustomerInfoDialog()" class="mr-4" :loading="loading" small>Customer Information</v-btn> -->
                                        <v-tooltip bottom>
                                            <template v-slot:activator="{ on, attrs }">
                                                <v-btn @click="showCustomerInfoDialog()" class="mr-4"
                                                    fab
                                                    text
                                                    small
                                                    color="blue"
                                                    v-bind="attrs"
                                                    v-on="on"
                                                >
                                                <v-icon>mdi-account-question</v-icon>
                                                </v-btn>
                                            </template>
                                            <span>Customer Information</span>
                                        </v-tooltip>

                                        <!-- <v-btn @click="showItemInfoDialog()" class="mr-4" :loading="loading" small>Item Information</v-btn> -->
                                        <v-tooltip bottom>
                                            <template v-slot:activator="{ on, attrs }">
                                                <v-btn @click="showItemInfoDialog()" class="mr-4"
                                                    fab
                                                    text
                                                    small
                                                    color="blue"
                                                    v-bind="attrs"
                                                    v-on="on"
                                                >
                                                <v-icon>mdi-wrench</v-icon>
                                                </v-btn>
                                            </template>
                                            <span>Item Information</span>
                                        </v-tooltip>

                                        <v-btn
                                            v-if="['PIN','DEF'].includes(rfStatus)"
                                            class="mr-4"
                                            :loading="loading"
                                            outlined
                                            text
                                            icon
                                            @click="showUploadWarranty()"
                                        >
                                            <v-icon>mdi-attachment</v-icon>
                                        </v-btn>
                                        <!-- <span v-if="rfStatus == 'REP'">
                                            <v-tooltip bottom>
                                                <template v-slot:activator="{ on, attrs }">
                                                    <v-badge
                                                            :content="GET_APPROVE_ORDERS.item_rejected_counter"
                                                            color="red"
                                                            offset-x="15"
                                                            offset-y="15"
                                                            bordered
                                                            overlap
                                                            :loading="loading"
                                                            :value="GET_APPROVE_ORDERS.item_rejected_counter ? true : false"
                                                        >
                                                        <v-btn
                                                            @click="showRejectedItemDialog()"
                                                            style="opacity:1;"
                                                            v-bind="attrs"
                                                            v-on="on"
                                                            text
                                                            icon
                                                            outlined
                                                            :loading="loading"
                                                            color="red"
                                                        ><v-icon>mdi-close-thick</v-icon>
                                                        </v-btn>
                                                    </v-badge>
                                                </template>
                                                <span>Rejected Item Check List</span>
                                            </v-tooltip>
                                        </span> -->
                                    </v-flex>

                                    <v-spacer />

                                    <v-flex lg1>
                                        <service-files-viewer
                                            v-if="showViewFiles()"
                                            :rfId="rfId"
                                            :rfNumber="rfNumber"
                                        />
                                    </v-flex>
                                    <v-flex class="text-right" v-if="['DEF'].includes(rfStatus)" lg1>
                                        <BaseFilesViewerVue :delete="showDelete()" :rfId="this.rfId" />
                                    </v-flex>

                                    <v-flex lg1 v-if="rfStatus == 'RFD' && !this.rfPrepared && itemRepairStatus == 'Cancelled'">
                                        <div style="text-align:end">
                                            <v-checkbox label="Re Estimate" v-model="backToForCsApproval"></v-checkbox>
                                        </div>
                                    </v-flex>
                                </v-layout>
                            </div>

                            <v-card
                                v-if="showTableWithParts()"
                                    class="pa-4"
                                >
                                <v-layout row class="px-4">
                                    <v-flex lg6>
                                        <div>Requested <span>{{ itemRepairStatus == 'For-Replacement' ? 'Item' : 'Spare Parts' }}</span></div>
                                        <div class="pb-2">Requested Date: {{ partsOrderDate }}</div>
                                        <div v-if="rfStatus == 'WFP' && !!partsReceived || rfStatus == 'PIN' && !!partsReceived || rfStatus == 'REP'">
                                            Received Spare Parts: <span>{{ partsReceived }}</span>
                                        </div>
                                    </v-flex>
                                    <v-flex lg6>
                                        <v-btn @click="refreshPartsOrder()">Refresh</v-btn>
                                    </v-flex>
                                </v-layout>
                                <v-data-table
                                    :headers="rfWithPartsHeaders"
                                    :items="rfPartsOrderItems"
                                    :loading="loading"
                                >

                                    <template v-slot:item.status="{ item }">
                                        <td class="px-0">
                                            <v-chip
                                                :color="requestedStatusColor(item)"
                                                text-color="white"
                                                small
                                            >
                                                {{ item.status }}
                                            </v-chip>
                                        </td>
                                    </template>
                                </v-data-table>
                            </v-card>

                            <v-divider />

                            <div v-if="rfStatus == 'REP'" style="text-align:center" class="py-4">
                                <v-layout row>
                                    <!-- <v-flex lg12>
                                        <div style="text-align:center">
                                            <v-btn @click="showQaRemarks('Approve')" :disabled="loading">QA Tester</v-btn>
                                        </div>
                                    </v-flex> -->

                                    <!-- <v-flex lg6>
                                        <div style="text-align:center">
                                            <v-btn @click="showQaRemarks('Reject')" color="red" :disabled="loading">QA Reject</v-btn>
                                        </div>
                                    </v-flex> -->
                                </v-layout>
                            </div>
                            <div style="text-align:center" v-if="rfStatus == 'REC'">
                                <v-tooltip bottom color="#f69f1a">
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-btn @click="forRecovered()"
                                            fab
                                            text
                                            small
                                            color="#f69f1a"
                                            v-bind="attrs"
                                            v-on="on"
                                            :loading="loading"
                                            :disabled="checkingTransfer()"
                                        >
                                            <v-icon>mdi-cog-outline</v-icon>
                                        </v-btn>

                                    </template>
                                    <span>Recovered</span>
                                </v-tooltip>
                            </div>
                            <!-- <div style="text-align:center" v-if="rfStatus == 'DEF'">
                                <v-tooltip bottom color="#f69f1a">
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-btn @click="forRecovery()"
                                            fab
                                            text
                                            small
                                            color="#f69f1a"
                                            v-bind="attrs"
                                            v-on="on"
                                            :loading="loading"
                                        >
                                            <v-icon>mdi-progress-wrench</v-icon>
                                        </v-btn>

                                    </template>
                                    <span>For Recovery</span>
                                </v-tooltip>

                                <v-tooltip bottom color="#f69f1a">
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-btn @click="forCannibalization()"
                                            fab
                                            text
                                            small
                                            color="#f69f1a"
                                            v-bind="attrs"
                                            v-on="on"
                                            :loading="loading"
                                        >
                                            <v-icon>mdi-tools</v-icon>
                                        </v-btn>

                                    </template>
                                    <span>For Cannibalization</span>
                                </v-tooltip>

                                <v-tooltip bottom color="#f69f1a">
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-btn @click="forDispose()"
                                            fab
                                            text
                                            small
                                            color="#f69f1a"
                                            v-bind="attrs"
                                            v-on="on"
                                            :loading="loading"
                                        >
                                            <v-icon>mdi-delete-outline</v-icon>
                                        </v-btn>

                                    </template>
                                    <span>Dispose</span>
                                </v-tooltip>
                            </div> -->

                            <div v-if="rfStatus == 'RFD'" class="py-4">
                                <div v-if="backToForCsApproval" style="text-align:center">
                                    <v-btn @click="confirmReEstimation()" :disabled="loading">Confirm Re Estimation</v-btn>
                                </div>

                                <div v-else>
                                    <v-layout v-if="!!plDate && !packingListNum && !rfCancelled" class="pb-2">
                                        <v-flex lg6>
                                            <div style="text-align:center">Selected PL Date: {{ plDate }}</div>
                                        </v-flex>
                                    </v-layout>

                                    <v-layout >
                                    <v-flex lg3>
                                        <div
                                            v-if="showIfRepair()"
                                            style="text-align:center"
                                            class="d-flex justify-content-around"
                                        >
                                            <v-btn :disabled="!!salesOrderNum" :loading="loading" @click="generateSo()">Generate SO</v-btn>
                                            <v-btn v-if="userAccess.generatePl" :disabled="showPl()" :loading="generatePlOnFlight" @click="showPlDateDialog()">Generate PL</v-btn>
                                            <v-btn v-if="userAccess.printPl" :disabled="!packingListNum && !packingListId" :loading="printPlOnFlight" @click="printPL()">Print PL</v-btn>
                                        </div>
                                        <div v-else-if="showIfNoRepair()" style="text-align:center">
                                            <v-btn
                                                :disabled="!!rfPrepared"
                                                @click="confirmRfPreparation()"
                                            >
                                                Confirm Preparation
                                            </v-btn>
                                        </div>
                                    </v-flex>
                                    <v-spacer>

                                    </v-spacer>
                                    <v-flex lg6>
                                        <div class="d-flex justify-content-around">
                                            <!-- <v-btn @click="cancelSo()" color="red" :disabled="!!GET_APPROVE_ORDERS.packing_list_num" v-if="!!GET_APPROVE_ORDERS.sales_order_num" :loading="loading"> Cancel SO and PL</v-btn> -->
                                            <v-btn @click="cancelSo()" color="red" v-if="!!GET_APPROVE_ORDERS.sales_order_num" :loading="loading"> Cancel SO </v-btn>
                                            <v-btn :disabled="checkDelivery()" @click="markAsReadyForDelivery()" class="mr-5" :loading="loading">Mark as ready for delivery</v-btn>
                                            <v-btn v-if="showWarrantyReject()" @click="warrantyChange()" color="red" class="mr-5" :disabled="loading">Change Warranty</v-btn>
                                            <v-btn v-if="!salesOrderNum && itemRepairStatus == 'Repaired'" @click="diagnosisReject()" color="red" :disabled="loading">Diagnosis Reject</v-btn>
                                            <v-btn v-if="[
                                                'No-Problem-Found',
                                                'Repair-w/o-Parts',
                                                'Return-w/o-Repair'
                                                ].includes(itemRepairStatus)" color="red" @click="updateRf()"  :loading="loading">Re Estiamte</v-btn>

                                        </div>
                                    </v-flex>
                                </v-layout>
                                </div>
                            </div>

                            <div v-if="rfStatus == 'DEL'" style="text-align:center" class="py-4">
                                <v-btn @click="confirmSuccessDelivery()" :disabled="loading">Confirm Success Delivery</v-btn>
                            </div>
                        </div>
                    </v-card-text>
                </v-col>
            </v-container>
        </v-card>

        <!-- Item Information Dialog -->
        <v-dialog v-model="itemInfoDialog" persistent max-width="50%">
            <v-card class="pa-4">
                <v-row class="m-0">
                    <v-col>
                        <v-card-title>
                            <span class="headline">Item Information</span>
                        </v-card-title>
                    </v-col>
                    <v-col cols="pull-right-10 p-2">
                        <v-btn
                        text
                        icon
                        color="gray"
                        class="float-right"
                        @click="closeItemInfoDialog()"
                        >
                        <v-icon>mdi-close-circle</v-icon>
                        </v-btn>
                    </v-col>
                </v-row>

                <v-divider class="mt-0" />

                <v-layout row class="mx-4">
                    <v-flex lg4>
                        <label style="color: gray">Warranty Type:</label>
                    </v-flex>
                    <v-flex lg8>
                        {{ itemWarrantyType }}
                    </v-flex>
                </v-layout>

                <v-layout row class="mx-4">
                    <v-flex lg4>
                        <label style="color: gray">Purchase Date:</label>
                    </v-flex>
                    <v-flex lg8>
                        {{ itemPurchaseDate }}
                    </v-flex>
                </v-layout>

                <v-layout row class="mx-4">
                    <v-flex lg4>
                        <label style="color: gray">Item Model:</label>
                    </v-flex>
                    <v-flex lg8>
                        {{ itemModel }}
                    </v-flex>
                </v-layout>

                <v-layout row class="mx-4">
                    <v-flex lg4>
                        <label style="color: gray">Item Name:</label>
                    </v-flex>
                    <v-flex lg8>
                        {{ itemProductName }}
                    </v-flex>
                </v-layout>

                <v-layout row class="mx-4">
                    <v-flex lg4>
                        <label style="color: gray">Serial No:</label>
                    </v-flex>
                    <v-flex lg8>
                        {{ itemSerial }}
                    </v-flex>
                </v-layout>

                <v-layout row class="mx-4">
                    <v-flex lg4>
                        <label style="color: gray">Accessories:</label>
                    </v-flex>
                    <v-flex lg8>
                        {{ itemAccessories }}
                    </v-flex>
                </v-layout>

                <v-layout row class="mx-4">
                    <v-flex lg4>
                        <label style="color: gray">Other Accessory:</label>
                    </v-flex>
                    <v-flex lg8>
                        {{ itemOtherAccessory }}
                    </v-flex>
                </v-layout>

                <v-layout row class="mx-4">
                    <v-flex lg4>
                        <label style="color: gray">Other Unit Condition:</label>
                    </v-flex>
                    <v-flex lg8>
                        {{ itemOtherUnitCondition }}
                    </v-flex>
                </v-layout>

                <v-layout row class="mx-4">
                    <v-flex lg4>
                        <label style="color: gray">Complaint / Symptom:</label>
                    </v-flex>
                    <v-flex lg8>
                        {{ itemDefectDescription }}
                    </v-flex>
                </v-layout>

                <v-layout row class="mx-4">
                    <v-flex lg4>
                        <label style="color: gray">Remarks:</label>
                    </v-flex>
                    <v-flex lg8>
                        {{ itemDefectRemarks }}
                    </v-flex>
                </v-layout>

                <v-layout row class="mx-4">
                    <v-flex lg4>
                        <label style="color: gray">Assigned Technician:</label>
                    </v-flex>
                    <v-flex lg8>
                        {{ assignedTechnician }}
                    </v-flex>
                </v-layout>

                <v-layout row class="mx-4">
                    <v-flex lg4>
                        <label style="color: gray">Symptom Upon Tested:</label>
                    </v-flex>
                    <v-flex lg8>
                        {{ itemSymptomUponTested }}
                    </v-flex>
                </v-layout>

                <v-layout row class="mx-4">
                    <v-flex lg4>
                        <label style="color: gray">Job Done:</label>
                    </v-flex>
                    <v-flex lg8>
                        {{ itemJobDone }}
                    </v-flex>
                </v-layout>

                <v-layout row class="mx-4">
                    <v-flex lg4>
                        <label style="color: gray">QA Remarks:</label>
                    </v-flex>
                    <v-flex lg8>
                        {{ qaRemarks }}
                    </v-flex>
                </v-layout>
            </v-card>
        </v-dialog>

        <!-- Customer Information Dialog -->
        <v-dialog v-model="customerInfoDialog" persistent max-width="50%">
            <v-card class="pa-4">
                <v-row class="m-0">
                <v-col>
                    <v-card-title>
                        <span class="headline" :loading="loading">Customer Information</span>
                    </v-card-title>
                </v-col>
                <v-col cols="pull-right-10 p-2">
                    <v-btn
                    text
                    icon
                    color="gray"
                    class="float-right"
                    @click="closeCustomerInfoDialog()"
                    >
                    <v-icon>mdi-close-circle</v-icon>
                    </v-btn>
                </v-col>
                </v-row>
                <v-divider class="mt-0" />
                <v-layout row class="mx-4">
                <v-flex lg4>
                    <label style="color: gray">Company Name:</label>
                </v-flex>
                <v-flex lg8>
                    {{ companyName }}
                </v-flex>
                </v-layout>
                <v-layout row class="mx-4">
                <v-flex lg4>
                    <label style="color: gray">Company Number:</label>
                </v-flex>
                <v-flex lg8>
                    {{ companyContactNumber }}
                </v-flex>
                </v-layout>
                <v-layout row class="mx-4">
                <v-flex lg4>
                    <label style="color: gray">Customer Number:</label>
                </v-flex>
                <v-flex lg8>
                    {{ contactNumber }}
                </v-flex>
                </v-layout>
                <v-layout row class="mx-4">
                <v-flex lg4>
                    <label style="color: gray">Customer Name:</label>
                </v-flex>
                <v-flex lg8>
                    {{ customerName }}
                </v-flex>
                </v-layout>
                <v-layout row class="mx-4">
                <v-flex lg4>
                    <label style="color: gray">Address:</label>
                </v-flex>
                <v-flex lg8>
                    {{ address }}
                </v-flex>
                </v-layout>
                <v-layout row class="mx-4">
                <v-flex lg4>
                    <label style="color: gray">Email:</label>
                </v-flex>
                <v-flex lg8>
                    {{ email }}
                </v-flex>
                </v-layout>
            </v-card>
        </v-dialog>

        <!-- Deliver Receipt -->
        <v-container fluid style="display:none">
            <div id="company_details" >
                <div style="font-size: 12px; line-height: 1.9">
                    <p>
                        <br>Address: 2 D Arellano St. Bagong Barrio, Caloocan City
                        <br>Mobile No: 0917 625 2286
                        <!-- <br>Tel. No.: (02) 8556-9129, (02) 7717-4059 -->
                        <br>
                    </p>
                </div>
            </div>
            <div id="customers_copy" style="font-size:14px;font-weight:bold">
                Delivery Receipt
            </div>
            <div id="document_number" >
                <div style="font-size: 12px; line-height: 1.9">
                    <p>
                        <br>RF#: <span>{{ rfNumber }}</span>
                        <br>Date: <span>{{ dateToday() }}</span>
                        <br>PL#: <span>{{ packingListNum }}</span>
                        <br>
                    </p>
                </div>
            </div>
            <div id="document_details" >
                <div style="font-size: 12px; line-height: 1.9">
                    <p>
                        <br>Item Name: <span>{{ itemProductName }}</span>
                        <br>Item Model: <span>{{ itemModel }}</span>
                        <br>Included Items: <span>{{ itemAccessories }}</span>
                        <br>
                    </p>
                </div>
            </div>
            <div id="document_status" >
                <div style="font-size: 12px; line-height: 1.9">
                    <p>
                        <br>Status: <span>{{ itemRepairStatus }}</span>
                        <br>Serial#: <span>{{ itemSerial }}</span>
                        <br>
                        <br>
                    </p>
                </div>
            </div>
            <div>
                <table id="process_details">
                    <tbody>
                        <tr>
                            <td>Processed by:</td>
                            <td>{{ preparedBy }}</td>
                            <td>Received by:</td>
                            <td></td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </v-container>

        <ConfirmOverrideComponentVue
            :approve_override_dialog="override.override_dialog"
            :departments_allowed="override.override_departments_allowed"
            @closeConfirmOverride="closeConfirmOverride"
        ></ConfirmOverrideComponentVue>

        <!-- Warranty Reject Reason Dialog -->
        <v-dialog v-model="showWarrantyReason" persistent max-width="40%">
            <v-card class="pa-4">
                <v-row class="m-0">
                    <v-col>
                        <v-card-title>
                            <span class="headline">Change Warranty Reason</span>
                        </v-card-title>
                    </v-col>
                    <v-col cols="pull-right-10 p-2">
                        <v-btn
                            text
                            icon
                            color="gray"
                            class="float-right"
                            @click="closeWarrantyReason()"
                        >
                            <v-icon>mdi-close-circle</v-icon>
                        </v-btn>
                    </v-col>
                </v-row>

                <v-divider />

                <v-textarea v-model="warrantyRejectReason" dense outlined></v-textarea>

                <v-divider />

                <div style="text-align:center">
                    <v-btn @click="showOverride()">Submit</v-btn>
                </div>
            </v-card>
        </v-dialog>

        <v-dialog v-model="UploadWarranty" persistent max-width="20%">
            <v-card class="pa-4">
                <v-btn
                text
                icon
                color="gray"
                class="float-right"
                @click="closeCameraCapture()"
                >
                <v-icon>mdi-close-circle</v-icon>
                </v-btn>
                <div class="py-2">
                    <!-- <v-checkbox v-model="attach" label="Attach Documents" class="ma-0 pa-0 pt-2" /> -->
                    <span>Attach Documents via Camera or Upload</span><span v-if="(this.images == null && this.uploadedFiles == null) && ((this.itemWarrantyType == 'Dealer-Stock' || this.itemWarrantyType == 'In-Warranty') && (this.itemRepairStatus == 'For-Repair' || this.itemRepairStatus == 'For-Replacement'))" style="color:red">  *</span>
                    <v-divider/>
                </div>

                <div class="py-2 mt-2">
                    <v-layout row class="mx-0">
                        <v-flex lg2 class="mr-2">
                            <BaseCameraCaptureVue ref="cameraCapture" @capturedImages="getImages" />
                        </v-flex>
                        <v-flex v-if="!!images && images.length > 0" lg3 class="mr-2 mt-1 ">
                            <span>Images Count: {{ images.length }}</span>
                        </v-flex>
                        <v-flex v-if="!!images && images.length > 0">
                            <v-btn @click="clearCameraCapture()">Clear</v-btn>
                        </v-flex>
                    </v-layout>
                </div>

                <div class="py-2 mt-3">
                    <v-layout row class="mx-0">
                        <v-flex :class="{'mr-2': true, 'lg3': !!uploadedFiles && uploadedFiles.attachments.length > 1, 'lg6': !!uploadedFiles && uploadedFiles.attachments.length == 1}">
                            <FileUploadComponentVue ref="fileUpload" @uploadedData="uploadedData" @reset="clearFileUpload"/>
                        </v-flex>
                        <v-flex v-if="!!uploadedFiles && uploadedFiles.attachments.length > 0" >
                            <span>Upload Count: {{ uploadedFiles.attachments.length }}</span>
                        </v-flex>
                        <!-- <v-flex v-if="!!uploadedFiles && uploadedFiles.attachments.length > 0">
                            <v-btn @click="clearFileUpload()" class="mt-1">Clear</v-btn>
                        </v-flex> -->
                    </v-layout>
                </div>
                <div class="py-2 mt-3">
                    <v-card-actions class="d-flex justify-content-end">
                        <v-btn  @click="submitWarranty()">Submit</v-btn>
                    </v-card-actions>
                </div>
                <!-- <div class="ma-4">
                            <v-layout class="mx-0">
                                <v-flex>
                                    <div style="text-align:center">
                                        <v-btn :disabled="showPrintEstimation()" :loading="loading" @click="submit()">Submit</v-btn>
                                    </div>
                                </v-flex>
                                <v-flex lg6>
                                    <div class="pt-2" style="text-align:center">
                                        <v-btn :loading="loading" @click="submit">Submit</v-btn>
                                    </div>
                                </v-flex>
                            </v-layout>
                        </div> -->
            </v-card>
        </v-dialog>

        <!-- Print Packing List -->
        <v-container fluid style="display:none;">
            <div id="service_logo" >
                <div style="font-size: 12px; line-height: 1.9">
                    <p>
                        <span style="font-size: 18px; font-weight: bold;">
                            WADFOW Tools Philippines Corp.
                        </span>
                        <br>2 D Arellano St. Bagong Barrio, Caloocan City
                        <br>Cell No.:
                        <br>Tel. No.:
                        <br>Service Mobile / Viber No.: +63 917 625 2286
                        <br>Spare Parts Mobile / Viber No.:
                    </p>
                </div>
            </div>
            <div id="service_packing_list_num" style="font-size: 12px; line-height: 1.9">
                <div style="border-top: 1px dashed #CFCFCF; padding: 2px; padding-bottom: -4px; text-align: center; border-bottom: none;">&nbsp;</div>
                <p>
                    <span style="font-size: 18px; font-weight: bold;">Packing List</span>
                    <br><b>PL# {{ packing_list.packing_list_num }}</b>
                    <br><b>SO# {{ packing_list.order_num }}</b>
                </p>
            </div>

            <div>
                <table id="service_customer_details">
                    <tbody>
                    <tr>
                        <td>Customer:</td>
                        <td>{{ packing_list.customer_name | uppercase }} </td>
                        <td>Date:</td>
                        <td>{{ packing_list.packing_list_date | formatDate }} </td>
                    </tr>
                    <tr>
                        <td>Address:</td>
                        <td>{{ packing_list.shipping_address_text | uppercase }}</td>
                        <td>Terms:</td>
                        <td v-if="packing_list.promo_id != '' && packing_list.terms == 0">Cash</td>
                        <td v-else>{{ packing_list.terms }} days</td>
                    </tr>
                    <tr>
                        <td>Remarks:</td>
                        <td>{{ packing_list.remarks }}</td>
                        <td>PO:</td>
                        <td>{{ packing_list.customer_po_num }}</td>
                    </tr>
                    </tbody>
                </table>
            </div>

            <div>
                <table id="service_items_details">
                    <thead>
                        <tr>
                            <th>#</th>
                            <th>QTY</th>
                            <th>UNITS</th>
                            <th>MODEL</th>
                            <th>DESCRIPTION</th>
                            <th>UNIT PRICE</th>
                            <th>TOTAL</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(item, index ) in packingListItems" :key="index">
                            <td>{{ index + 1 }}</td>
                            <td>{{ item.quantity }}</td>
                            <td>{{ item.item.item_uom_text }}</td>
                            <td>{{ item.item.model }}</td>
                            <td>{{ item.item.name }}</td>
                            <td v-if="item.total_amount>0">{{ item.display_srp | currency }}</td><td v-else>FOC</td>
                            <td v-if="item.total_amount>0">{{ item.quantity * item.display_srp | currency }}</td><td v-else>FOC</td>
                        </tr>
                        <tr>
                            <td colspan="7">***** NOTHING FOLLOWS *****</td>
                        </tr>
                    </tbody>
                </table>
            </div>

            <div>
                <table id="service_items_details_without_price">
                    <thead>
                        <tr>
                            <th>#</th>
                            <th>QTY</th>
                            <th>UNITS</th>
                            <th>MODEL</th>
                            <th>DESCRIPTION</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(item, index ) in packingListItems" :key="index">
                            <td>{{ index + 1 }}</td>
                            <td>{{ item.quantity }}</td>
                            <td>{{ item.item.item_uom_text }}</td>
                            <td>{{ item.item.model }}</td>
                            <td>{{ item.item.name }}</td>
                        </tr>
                        <tr>
                            <td colspan="5">***** NOTHING FOLLOWS *****</td>
                        </tr>
                    </tbody>
                </table>
            </div>

            <div>
                <table id="service_total_details">
                    <tbody>
                        <tr>
                            <td>
                                <p>{{ (itemTypeDiscounts[0] != null)?itemTypeDiscounts[0]: '' }}</p>
                            </td>
                            <td>
                                <p>GROSS SALES:</p>
                            </td>
                            <td>
                                <p>PHP</p>
                            </td>
                            <td>
                                <p>{{ packing_list.gross_amount | currency }}</p>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <p>{{ (itemTypeDiscounts[1] != null)?itemTypeDiscounts[1]: '' }}</p>
                            </td>
                            <td>
                                <p>Less - Discount:</p>
                            </td>
                            <td>
                                <p></p>
                            </td>
                            <td>
                                <p>{{ packing_list.discount_amount | currency }}</p>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <p>{{ (itemTypeDiscounts[2] != null)?itemTypeDiscounts[2]: '' }}</p>
                            </td>
                            <td>
                                <p>TOTAL SALES:</p>
                            </td>
                            <td>
                                <p>PHP</p>
                            </td>
                            <td>
                                <p>{{ packing_list.display_total_amount | currency }}</p>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div>
                <table id="service_num_of_items">
                    <tbody>
                        <tr>
                            <td><span>Note: Invoice to follow</span></td>
                            <td><span>No. of Items: {{ this.no_of_items }}</span></td>
                            <td><span>No. of Warranty: {{ this.no_of_warranties }}</span></td>
                            <td></td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div id="service_received_note"  style="font-size: 10px; line-height: 1.0">
                <p style="font-size: 12px;">Received the above goods and<br>services in good order & condition</p>
                <p style="font-size: 10px;"></p>
                <p style="font-size: 22px;">_____________</p>
                <p style="font-size: 10px;"><i>Signature over printed name / date</i></p>
            </div>
            <div>
                <table id="service_footer">
                    <tbody>
                        <tr>
                            <td>Issued By</td>
                            <td colspan="2">Noted by</td>
                        </tr>
                        <tr>
                            <td><br><br>{{ this.packing_list.prepared_by }}</td>
                            <td><br><br></td>
                            <td><br><br></td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </v-container>

        <v-dialog v-model="plDateDialog" persistent max-width="20%">
            <v-card class="pa-4">
                <v-row class="m-0">
                    <v-col>
                        <v-card-title>
                            <span class="headline">Packing List Date</span>
                        </v-card-title>
                    </v-col>

                    <v-col cols="pull-right-10 p-2">
                        <v-btn
                        text
                        icon
                        color="gray"
                        class="float-right"
                        @click="closePlDateDialog()"
                        >
                        <v-icon>mdi-close-circle</v-icon>
                        </v-btn>
                    </v-col>
                </v-row>

                <v-divider class="mt-0" />

                <v-card-text>
                    <v-dialog
                        ref="dialog"
                        v-model="dateDialog"
                        :return-value.sync="plDate"
                        persistent
                        width="290px"
                    >
                        <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                                v-model="plDate"
                                :label="'Pick Date'"
                                prepend-icon="mdi-calendar"
                                readonly
                                v-bind="attrs"
                                v-on="on"
                                outlined
                                dense
                                clearable
                            />
                        </template>
                        <v-date-picker
                            v-model="plDate"
                            scrollable
                            :min="dateToday()"
                            :max="dateTodayPlusDays()"
                        >
                        <v-spacer />
                        <v-btn
                            text
                            color="primary"
                            @click="$refs.dialog.save(plDate)"
                        >
                            OK
                        </v-btn>
                        </v-date-picker>
                    </v-dialog>
                </v-card-text>

                <v-divider class="mt-0" />

                <div style="text-align:center">
                    <v-btn @click="generatePl()">Confirm</v-btn>
                </div>
            </v-card>
        </v-dialog>

        <!-- QA Remarks -->
        <v-dialog v-model="qaRemarksDialog" persistent max-width="30%">
            <v-card class="pa-4" height="700">
                <v-row class="m-0">
                    <v-col class="d-flex justify-space-between">
                        <v-card-title class="pr-0 pt-0 pl-3 pb-0">
                            <span class="headline">{{ `QA ${!!qaRemarksAction ? qaRemarksAction + '' : ''} Remarks` }}</span>
                        </v-card-title>

                        <v-btn
                            text
                            icon
                            color="gray"
                            class="float-right"
                            @click="closeQaRemarksDialog()"
                        >
                            <v-icon>mdi-close-circle</v-icon>
                        </v-btn>
                    </v-col>
                </v-row>

                <v-card class="w-100" height="420" style="overflow-y: scroll;">
                    <v-card-text>
                        <v-row class="m-0">
                            <v-col class="d-flex justify-space-between" sm="12">
                                    <span style="font-size: 140%">Check Lists</span>
                                    <v-checkbox
                                        v-model="checklist.all"
                                        true-value="1"
                                        false-value="0"
                                        label="All"
                                    ></v-checkbox>
                            </v-col>
                        </v-row>
                        <v-row class="mx-0 p-0">
                            <v-col v-for="(checklist, index) in GET_CHECK_LISTS_SELECTION" :key="index" lg="12" >
                                <span class="d-flex align-items-center">
                                    <v-tooltip top>
                                        <template v-slot:activator="{ on, attrs }">
                                            <span v-bind="attrs" v-on="on">
                                                <v-checkbox
                                                    v-model="checklist.selected_checklist"
                                                    true-value="1"
                                                    false-value="0"
                                                    :label="checklist.name"
                                                    >
                                                </v-checkbox>
                                            </span>
                                        </template>
                                        <span>{{ checklist.code }}</span>
                                    </v-tooltip>
                                </span>
                            </v-col>

                        </v-row>
                    </v-card-text>

                </v-card>
                <v-divider/>
                <v-textarea v-model="qaRemarks" height="100" label="Remarks" dense outlined></v-textarea>

                <v-divider />
                <v-row>
                    <v-col class="d-flex justify-center">
                        <v-card-actions>
                            <div style="text-align:center">
                                <v-btn @click="submit()">Submit</v-btn>
                            </div>
                        </v-card-actions>
                    </v-col>
                </v-row>
            </v-card>
        </v-dialog>

        <!-- Diagnosis Reject Reason Dialog -->
        <v-dialog v-model="diagnosisReasonDialog" persistent max-width="40%">
            <v-card class="pa-4">
                <v-row class="m-0">
                    <v-col>
                        <v-card-title>
                            <span class="headline">Diagnosis Reject Reason</span>
                        </v-card-title>
                    </v-col>
                    <v-col cols="pull-right-10 p-2">
                        <v-btn
                            text
                            icon
                            color="gray"
                            class="float-right"
                            @click="closeDiagnosisRejectReasonDialog()"
                        >
                            <v-icon>mdi-close-circle</v-icon>
                        </v-btn>
                    </v-col>
                </v-row>

                <v-divider />

                <v-textarea v-model="diagnosisRejectReason" dense outlined></v-textarea>

                <v-divider />

                <div style="text-align:center">
                    <v-btn @click="partsReject()">Submit</v-btn>
                </div>
            </v-card>
        </v-dialog>

            <!-- Rejected Counter Dialog -->
        <v-dialog v-model="rejectedCounterDialog" persistent max-width="40%">
            <v-card class="pa-4">
                <v-row>
                    <v-col>
                        <v-card-title>
                            <span class="headline"> Rejected Item Check List </span>
                        </v-card-title>
                    </v-col>

                    <v-col cols="pull-right-10 p-2">
                        <v-btn
                            text
                            icon
                            color="gray"
                            class="float-right"
                            @click="closeRejectedCounterDialog()"
                        >
                            <v-icon>mdi-close-circle</v-icon>
                        </v-btn>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col>
                        <v-card-text>
                            <v-simple-table height="300px">
                                    <thead>
                                        <tr>
                                            <th>Name</th>
                                            <th>Code</th>
                                            <th>Description</th>
                                            <th>Rejected Count</th>
                                        </tr>
                                    </thead>

                                    <tbody>
                                        <tr
                                            v-for="(check_list, index) in GET_CHECK_LISTS_SELECTION"
                                            :key="index"
                                        >
                                            <td>
                                                <span>
                                                {{ check_list.name }}
                                                </span>
                                            </td>

                                            <td>
                                                <span>
                                                {{ check_list.code }}
                                                </span>
                                            </td>

                                            <td>
                                                <span>
                                                {{ check_list.description }}
                                                </span>
                                            </td>

                                            <td>
                                                <span>
                                                {{ check_list.rejected_counter }}
                                                </span>
                                            </td>
                                        </tr>
                                    </tbody>
                                </v-simple-table>
                        </v-card-text>
                    </v-col>
                </v-row>
            </v-card>
        </v-dialog>

        <!-- <ConfirmOverrideComponentVue
            :approve_override_dialog="overridePlDate.override_dialog"
            :departments_allowed="overridePlDate.override_departments_allowed"
            :heading="'Packing List Date'"
            @closeConfirmOverride="closeConfirmOverridePlDate"
        ></ConfirmOverrideComponentVue> -->

        <ConfirmOverrideComponentVue
            :approve_override_dialog="overrideWarrantyChange.override_dialog"
            :departments_allowed="overrideWarrantyChange.override_departments_allowed"
            :heading="'Change Warranty'"
            @closeConfirmOverride="closeConfirmOverrideWarrantyChange"
        ></ConfirmOverrideComponentVue>

        <ConfirmOverrideComponentVue
            :approve_override_dialog="overrideDiagnosisReject.override_dialog"
            :departments_allowed="overrideDiagnosisReject.override_departments_allowed"
            :heading="'Diagnosis Reject'"
            @closeConfirmOverride="closeConfirmOverrideDiagnosisReject"
        ></ConfirmOverrideComponentVue>
    </v-dialog>

</template>

<script>
import BaseFilesViewerVue from "@/views/main/modules/Services/Base/BaseFilesViewer.vue";
import ConfirmOverrideComponentVue from '@/views/main/Utils/ConfirmOverrideComponent.vue';
import SharedFunctionsComponentVue from '@/views/main/Shared/SharedFunctionsComponent.vue';
import ServiceFunctionsComponentVue from '@/views/main/Shared/ServiceFunctionsComponent.vue';
import BaseDatePickerVue from '../../Base/BaseDatePicker.vue';
import { mapGetters } from 'vuex';
import swal from 'sweetalert2';
import BaseCameraCaptureVue from '../../Base/BaseCameraCapture.vue';
import FileUploadComponentVue from '@/views/main/Shared/FileUploadComponent.vue';
import PusherFunctionComponent from '@/views/main/Shared/PusherFunctionComponent2.vue';
export default {
    mixins: [SharedFunctionsComponentVue,ServiceFunctionsComponentVue,PusherFunctionComponent],
    data() {
        return {
            customerInfoDialog: false,
            itemInfoDialog: false,
            rfStatus: "",
            rfDate: "",
            rrDate: "",
            rfNumber: "",
            rrNumber: "",
            pulloutNumber: "",
            companyName: "",
            companyContactNumber: "",
            customerName: "",
            address: "",
            contactNumber: "",
            email: "",
            itemModel: "",
            itemProductName: "",
            itemSerial: "",
            itemCategory: "",
            itemDefectDescription: "",
            itemDefectRemarks: "",
            itemAccessories: "",
            itemOtherAccessory: "",
            itemWarrantyType: "",
            itemWarrantyDocument: "",
            itemUnitCondition: "",
            itemOtherUnitCondition: "",
            itemStatus: "",
            itemPurchaseDate: "",
            deliveredBy: "",
            receivedBy: "",
            warehouse: "",
            assignedTechnician: "",
            rfDetails: null,
            rfEstimation: null,
            rfEstItems: [],
            rfWithPartsHeaders: [
                { text: "Request Qty", value: "requested_qty" },
                { text: "Serve Qty", value: "serve_qty" },
                { text: "Transfer Qty", value: "transfer_qty" },
                { text: "Item Model", value: "item.model" },
                { text: "Item Name", value: "item.name" },
                { text: "Category", value: "category" },
                { text: "Status", value: "status" },
            ],
            releaseItems: [],
            rfProcessedBy: "",
            rfEstItemsDate: "",
            itemRepairStatus: "",
            itemId: "",
            estimateItemTotalPrice: 0,
            loading: false,
            customerDissapprovedReason: '',
            totalAmount: 0,
            sparePartLists: [],
            warehousesCode: [],
            completeDetails: false,
            selectedWarehouseValue: null,
            selectedItemId: '',
            selectedModelValue: null,
            readOnly: false,
            selectedItemForSearch: null,
            customerId: '',
            customerDetails: null,
            discountPrice: 0,
            customerDiscounts: [],
            customerDiscount: "",
            hasChanges: false,
            salesQuotationNumber: "",
            itemsFound: [],
            rfOrderParts: [],
            partsOrderDate: '',
            rfId: '',
            rfPartsOrderItems: [],
            rfPrepared: false,
            showOrderPartsTable: true,
            rfCancelled: false,
            rfCompleted: false,
            rfPartsOrder: null,
            repairStartedAt: '',
            repairCompletedAt: '',
            partsReceived: '',
            salesOrderId: '',
            salesOrderNum: '',
            packingListNum: '',
            preparedBy: '',
            readyForDelivery: false,
            backToForCsApproval: false,
            salesOrderStatus: '',
            itemSymptomUponTested: "",
            itemJobDone: "",
            warrantyRejectReason: '',
            showWarrantyReason: false,
            override: {
                override_dialog: false,
                errors: [],
                override_departments_allowed: ['AC', 'SM', 'EX', 'IT'],
                override_authenticated: false,
            },
            packingListId: '',
            packing_list: '',
            packingListItems: [],
            print_no_price: false,
            discounts: [],
            itemTypeDiscounts: [],
            itemTypeDiscountsPDF: [],
            type_list: [],
            item_type: '',
            pdfAction: 'print',
            generatePlOnFlight: false,
            printedPl: false,
            printPlOnFlight: false,
            userAccess: {
                generatePl: false,
                printPl: false,
            },
            generateSoOnFlight: false,
            plDateDialog: false,
            dateDialog: false,
            plDate: '',
            overridePlDate: {
                override_dialog: false,
                errors: [],
                override_departments_allowed: ['AC', 'SM', 'EX', 'IT'],
                override_authenticated: false,
            },
            overrideWarrantyChange: {
                override_dialog: false,
                errors: [],
                override_departments_allowed: ['SR', 'SM', 'EX', 'IT'],
                override_authenticated: false,
            },
            overrideDiagnosisReject: {
                override_dialog: false,
                errors: [],
                override_departments_allowed: ['SR', 'SM', 'EX', 'IT'],
                override_authenticated: false,
            },
            overrideUserPassword: '',
            qaRemarksDialog: false,
            qaRemarks: '',
            qaRemarksAction: '',
            diagnosisReasonDialog: false,
            diagnosisRejectReason: '',
            selectedPlDate: '',
            repair_form:null,
            UploadWarranty: false,
            images: null,
            uploadedFiles: null,
            selectedAttach: 'Camera',
            attach: false,
            checklist: {
                selected_checklist: null,
                all: "0",
            },
            check_list: [],
            rejectedCounterDialog: false,
            rfForRecovery: '',
            warehouses: [],
            rfPartsOrderId: '',
            itemRepairStatus: '',
            stockTransferNum: '',
            stockRequestNum: '',
        }
    },
    mounted: function () {
        // this.checkAccess('SR', 'packing_list', 'generatePl');
        // this.checkAccess('SR', 'packing_list', 'printPl');
        this.$store.dispatch('checkAccessUser',this.$router.currentRoute.params.id);
        this.$store.dispatch('getLists')
        this.$store.dispatch('getItemChecked')
        this.callPusher();
        this.getWarehouses()
    },
    components: {
        ConfirmOverrideComponentVue,
        BaseDatePickerVue,
        BaseCameraCaptureVue,
        FileUploadComponentVue,
        BaseFilesViewerVue,
    },
    props: ['rf'],
    fromView:{
                default:()=>false,
                type:Boolean
            },

    watch: {
        'GET_CHECK_LISTS_SELECTION':{
            handler(val){
                val = val.map(e=>{
                    e.selected_checklist = '0'
                    return e
                })
            }
        },
        "checklist.all": {
        handler(val) {
          if (val == 0) {
            this.resetCheckboxes();
          } else {
            this.checklist.selected_checklist = this.GET_CHECK_LISTS_SELECTION.map((e) => {
                e.selected_checklist = "1";
                return e;
            });
            this.checklist.selected_checklist = this.checklist.selected_checklist.sort((a, b) => {
                return a.id - b.id;
            });
          }
        },
      },
        USER_ACCESS: {
            handler(val) {
               if(val != "fail"){
                val.forEach((e) => {
                    if (e.actions_code == "GEN_PL"){
                        this.userAccess.generatePl = true;
                    }
                    if (e.actions_code == "PRNT_PL"){
                        this.userAccess.printPl = true;
                    }
                });
               }
            },

        },
        rf() {
            if (!!this.rf) {
                this.rfDetails = this.rf;
                this.rfId = this.rf.id;
                this.getRfPartsOrder();
                let rr = this.rfDetails.sc_receiving_report;
                this.rfNumber = this.rfDetails.rf_number;
                this.rfForRecovery = this.rfDetails.for_recovery;
                this.rrNumber = rr.rr_number;
                this.rfStatus = this.rfDetails.status;
                this.customerId = rr.customer_id;
                this.customerDetails = rr.customer;
                this.rfPrepared = this.rf.prepared;
                this.showOrderPartsTable = this.rf.order_parts;
                this.rfCancelled = this.rf.cancelled;
                this.rfCompleted = this.rf.completed;
                this.salesOrderNum = this.rf.sales_order_num;
                this.packingListId = this.rf.packing_list_id;
                this.packingListNum = this.rf.packing_list_num;
                this.printedPl = !!this.rf.printed_pl;
                this.readyForDelivery = this.rf.ready_for_delivery;

                if(!!this.rf.prepared_by) {
                    this.preparedBy = this.rf.prepared_by;
                }

                if (!!rr.pullout_number) {
                this.pulloutNumber = rr.pullout_number;
                }
                this.companyName = rr.customer.company_name;
                this.rrDate = this.$dayjs(rr.created_at).format("YYYY-MM-DD, hh:mm:ss a");
                this.customerId = rr.customer_id;
                this.customerDiscounts = JSON.parse(rr.customer.discounts);

                if (!!this.rfDetails.assigned_technician) {
                    this.assignedTechnician = this.rfDetails.assigned_technician;
                }

                let contact = [];
                let fullName = [];

                if (!!this.rfDetails.sc_repair_form_item) {
                    if(!!this.rfDetails.sc_repair_form_item.tech_received_items_at) {
                        this.partsReceived = this.$dayjs(this.rfDetails.sc_repair_form_item.tech_received_items_at).format('YYYY-MM-DD, hh:mm:ss a');
                    } else {
                        this.partsReceived = "";
                    }
                    this.repairStartedAt = this.$dayjs(this.rfDetails.sc_repair_form_item.repair_started_at).format('YYYY-MM-DD, hh:mm:ss a');
                    this.repairCompletedAt = this.$dayjs(this.rfDetails.sc_repair_form_item.repair_completed_at).format('YYYY-MM-DD, hh:mm:ss a');

                    this.itemId = this.rfDetails.sc_repair_form_item.item_id;
                    this.itemModel = this.rfDetails.sc_repair_form_item.model;
                    this.itemProductName =
                        this.rfDetails.sc_repair_form_item.product_name;
                    this.itemCategory = this.rfDetails.sc_repair_form_item.category;
                    this.itemDefectDescription =
                        this.rfDetails.sc_repair_form_item.defect_description;
                    this.itemDefectRemarks =
                        this.rfDetails.sc_repair_form_item.defect_remarks;

                    if(!!this.rfDetails.sc_repair_form_item.replacement_serial_number) {
                        this.itemSerial = this.rfDetails.sc_repair_form_item.replacement_serial_number;
                    } else {
                        this.itemSerial = this.rfDetails.sc_repair_form_item.serial;
                    }

                    this.itemAccessories = this.rfDetails.sc_repair_form_item.accessories;
                    this.itemOtherAccessory =
                        this.rfDetails.sc_repair_form_item.other_accessory;
                    this.itemWarrantyType =
                        this.rfDetails.sc_repair_form_item.warranty_type;
                    this.itemWarranty = this.rfDetails.sc_repair_form_item.warranty;
                    this.itemWarrantyDocument =
                        this.rfDetails.sc_repair_form_item.warranty;
                    this.itemUnitCondition =
                        this.rfDetails.sc_repair_form_item.unit_condition;
                    this.itemOtherUnitCondition =
                        this.rfDetails.sc_repair_form_item.other_unit_condition;
                    this.itemStatus = this.rfDetails.sc_repair_form_item.status;
                    this.itemPurchaseDate = this.$dayjs(this.rfDetails.sc_repair_form_item.purchase_date).format('YYYY-MM-DD');
                    this.itemRepairStatus =
                        this.rfDetails.sc_repair_form_item.repair_status;
                    this.itemSymptomUponTested = this.rfDetails.sc_repair_form_item.symptom_upon_tested;
                    this.itemJobDone = this.rfDetails.sc_repair_form_item.job_done;

                    this.qaRemarks = this.rfDetails.sc_repair_form_item.qa_remarks;
                }

                if (rr.customer_id == 275) {
                if(!!rr.sc_customer) {
                    if(!!rr.sc_customer.first_name) {
                        fullName.push(rr.sc_customer.first_name);
                    } if(!!rr.sc_customer.middle_name) {
                        fullName.push(rr.sc_customer.middle_name);
                    } if(!!rr.sc_customer.last_name) {
                        fullName.push(rr.sc_customer.last_name);
                    }

                    this.contactNumber = rr.sc_customer.contact_number;
                    this.address = rr.sc_customer.address;
                    this.email = rr.sc_customer.email;
                }
                this.customerName = fullName.toString().replaceAll(",", " ");

                } else {
                    this.companyContactNumber = contact.toString().replaceAll(",", " ");
                    this.address = JSON.parse(rr.customer.shipping_addresses)[0].address;
                if (!!rr.customer.mobile_1) {
                    contact.push(rr.customer.mobile_1);
                }
                if (!!rr.customer.mobile_2) {
                    contact.push(rr.customer.mobile_2);
                }
                if (!!rr.customer.mobile_3) {
                    contact.push(rr.customer.mobile_3);
                }
                if (!!rr.customer.tel_1) {
                    contact.push(rr.customer.tel_1);
                }
                if (!!rr.customer.tel_2) {
                    contact.push(rr.customer.tel_2);
                }
                if (!!rr.customer.tel_3) {
                    contact.push(rr.customer.tel_3);
                }
                // if (!!rr.customer.first_name) {
                //     fullName.push(rr.customer.first_name);
                // }
                // if (!!rr.customer.middle_name) {
                //     fullName.push(rr.customer.middle_name);
                // }
                // if (!!rr.customer.last_name) {
                //     fullName.push(rr.customer.last_name);
                // }
                // this.customerName = fullName.toString().replaceAll(",", " ");
                this.companyContactNumber = contact.toString().replaceAll(",", " / ");
                }
            }
        },
        rfPartsOrder() {
            if(!!this.rfPartsOrder) {
                this.partsOrderDate = this.$dayjs(this.rfPartsOrder.created_at).format('YYYY-MM-DD, hh:mm:ss a');
                this.rfPartsOrderItems = this.rfPartsOrder.sc_parts_order_items;
                this.rfPartsOrderId = this.rfPartsOrder.id;

                let data = this.rfPartsOrderItems.find(e=> e.sc_parts_order_id == this.rfPartsOrderId)
                this.stockTransferNum = data.stock_transfer_num;
                this.stockRequestNum = data.stock_request_num;
            }
        },
        salesOrderId() {
            if(!!this.salesOrderId) {
                this.getSalesOrderStatus()
            }
        },
        // Print Packing List function Start
    },
    computed: {
        ...mapGetters([
            'USER_ACCESS',
            'GET_APPROVE_ORDERS',
            'GET_SERVICE_SALES_ORDER_STATUS',
            'GET_VIEW_PACKING_LIST',
            'GET_ITEM_TYPES',
            'GET_UPDATE_PL_PRINTED',
            'GET_CONFIRM_OVERRIDE_PRINT_PL',
            'GET_CHECK_LISTS_SELECTION',
            'GET_ALL_PROCESS_RFS',
            'GET_ITEM_QA_CHECKED',
            'USERACCOUNT_LOAD',
        ]),
        repairFormDetailsDialog() {
            return this.$store.state.service.repairFormDetailsDialog;
        },
        // Print Packing List function Start
        no_of_items:function(){
            if(this.packingListItems != null && this.packingListItems.length > 0){
                var count = 0;
                this.packingListItems.forEach((item)=>{
                    count += item.quantity
                })
                return this.thousandSeprator(count)
            }else{
                return 0
            }
        },
        no_of_warranties:function(){
            if(this.packingListItems != null && this.packingListItems.length > 0){
                var count = 0;
                this.packingListItems.forEach((item)=>{
                    if(item.view_item.type_id==1){
                        count += item.quantity
                    }
                })
                return this.thousandSeprator(count)
            }else{
                return 0
            }
        },

        sortedChecklistName() {
        return this.GET_CHECK_LISTS_SELECTION.sort((a, b) =>
          a.name.localeCompare(b.name)
            );
        },
        // Print Packing List function End
    },
    methods: {
        clearFields() {
            this.packingListNum = "";
            this.salesOrderNum = "";
            this.salesOrderStatus = "";
            this.qaRemarksDialog = false;
            this.qaRemarksAction = "";
            this.qaRemarks = "";
            this.warrantyRejectReason = "";
        },
        closeRepairFormDetailsDialog() {
            this.rfPartsOrderItems = [];
            this.adminPassword = '';
            this.$store.commit('closeRepairFormDetailsDialog');
            this.$store.commit('APPROVE_ORDERS',[])
            this.backToForCsApproval = false;
            this.qaRemarks = '';
            this.stockRequestNum = '';
            this.stockTransferNum = '';
            this.resetCheckboxes()
            this.clearFields()
        },
        checkWarrantyType() {
            if (this.itemWarrantyType == "Dealer-Stock") {
                return "background-color:green;border-radius:6px;padding:6px;color:white";
            }

            if (this.itemWarrantyType == "In-Warranty") {
                return "background-color:violet;border-radius:6px;padding:6px;color:white";
            }

            if (this.itemWarrantyType == "No-Warranty-Type") {
                return "background-color:red;border-radius:6px;padding:6px;color:white";
            }

            if (this.itemWarrantyType == "Out-Warranty") {
                return "background-color:orange;border-radius:6px;padding:6px;color:white";
            }
            return "";
        },
        showCustomerInfoDialog() {
            this.itemInfoDialog = false;
            this.customerInfoDialog = true;
        },
        showItemInfoDialog() {
            this.customerInfoDialog = false;
            this.itemInfoDialog = true;
        },
        closeCustomerInfoDialog() {
            this.customerInfoDialog = false;
        },
        closeItemInfoDialog() {
            this.itemInfoDialog = false;
        },
        getRfPartsOrder() {
            this.loading = true
            this.$store.dispatch('getApproveOrders',this.rfId).then((response) => {
                if (!!this.GET_APPROVE_ORDERS) {
                    this.rfPrepared = this.GET_APPROVE_ORDERS.prepared;
                    if (!_.isEmpty(this.GET_APPROVE_ORDERS.sales_orders)) {
                        let salesOrders = this.GET_APPROVE_ORDERS.sales_orders.slice();
                        let salesOrder = salesOrders.pop();
                        this.salesOrderId = salesOrder.id;
                        // this.salesOrderNum = salesOrder.order_num;
                    }
                    // this.salesOrderId = this.GET_APPROVE_ORDERS.sales_order_id;
                    // this.salesOrderNum = this.GET_APPROVE_ORDERS.sales_order_num;
                    if(!!this.salesOrderNum) {
                        this.getSalesOrderStatus();
                    }
                    this.packingListNum = this.GET_APPROVE_ORDERS.packing_list_num;
                    this.packingListId = this.GET_APPROVE_ORDERS.packing_list_id;
                    this.printedPl = this.GET_APPROVE_ORDERS.printed_pl;
                    this.readyForDelivery = this.GET_APPROVE_ORDERS.ready_for_delivery;
                    this.rfPartsOrder = this.GET_APPROVE_ORDERS.sc_parts_orders[this.GET_APPROVE_ORDERS.sc_parts_orders.length - 1];
                }
                this.loading = false
            });
        },
        requestedStatusColor(item) {
            if(item.status == 'pending') {
                return 'red';
            }

            if(item.status == 'for-approval') {
                return 'orange';
            }

            if(item.status == 'partial') {
                return 'blue';
            }

            if(item.status == 'for-confirmation') {
                return '#6f42c1';
            }

            if(item.status == 'completed' || item.status == 'replaced') {
                return 'green';
            }

            return '';
        },
        refreshPartsOrder() {
            this.rfPartsOrderItems = [];
            this.getRfPartsOrder();
        },
        showQaRemarks(action) {
            this.qaRemarksAction = action;
            this.qaRemarksDialog = true;
        },
        confirmRepairedItem() {
            let selectedChecklist = this.GET_CHECK_LISTS_SELECTION.filter(
            (e) => e.selected_checklist == 1
            );

            if(!this.qaRemarks) {
                swal.fire('','Please input remarks','warning');
            } else {
                swal.fire({
                    title: 'Are you sure you want to Approve?',
                    text: 'This Repair Form will move to RFD',
                    icon: 'question',
                    showConfirmButton:true,
                    showCancelButton  :  true,
                    reverseButtons:true,
                    allowOutsideClick :  false,
                    confirmButtonColor: '#397373',
                    cancelButtonColor : 'grey',
                    confirmButtonText:'Confirm',
                    showCloseButton:true,
                }).then(result=>{
                    if(result.isConfirmed) {
                        let payload = {
                            rfId: this.rf.id,
                            qaRemarks: this.qaRemarks,
                            warranty: this.itemWarrantyType,
                            repair_status:this.itemRepairStatus,
                            check_list: selectedChecklist.map((e) => e.id),
                            qaRemarksAction: this.qaRemarksAction,
                        }
                        this.$store.dispatch('repairedRfConfirm',payload).then(response=>{
                            if(response.data.msg) {
                                this.$emit('successConfirm', true);
                                swal.fire("", response.data.msg, "success");

                                let payload = {
                                    rfId: this.rf.id
                                }
                                this.$store.dispatch('serviceGenerateSo',payload).then(response=>{
                                    if (response.data.msg){
                                        this.getRfPartsOrder();
                                        this.$emit('successConfirm', true);
                                        swal.fire("", response.data.msg, "success");
                                    } else {
                                        swal.fire("", response.data.error, "error");
                                    }
                                })

                                this.closeRepairFormDetailsDialog();
                            }
                            else{
                                // this.closeRepairFormDetailsDialog();
                                // this.$emit('successConfirm', true);
                                swal.fire("", response.data.error, "error");
                            }
                        });
                    }
                });
            }
        },
        confirmRfPreparation() {
            swal.fire({
                title: 'Preparation Done?',
                text: 'Click ok to continue',
                icon: 'question',
                showConfirmButton:true,
                confirmButtonText:'Confirm',
                reverseButtons:true,
                showCancelButton  :  true,
                allowOutsideClick :  false,
                confirmButtonColor: '#397373',
                cancelButtonColor : 'grey',
            }).then(confirm=>{
                if(confirm.isConfirmed) {
                    let payload = {
                        rfId: this.rf.id
                    }
                    this.$store.dispatch('confirmRfPreparation',payload).then(response=>{
                        if(response.data.msg) {
                            this.getRfPartsOrder();
                            this.$emit('successConfirm', true);
                            swal.fire("", response.data.msg, "success");
                        }
                    });
                }
            });
        },
        deliverItem() {
            swal.fire({
                title: 'Are you sure you want to deliver now?',
                text: 'Click ok to continue',
                icon: 'question',
                dangerMode: true,
                buttons: true
            }).then(confirm=>{
                if(confirm) {
                    let payload = {
                        rfId: this.rf.id
                    }
                    this.$store.dispatch('deliverItem',payload).then(response=>{
                        if(response.data.msg) {
                            this.closeRepairFormDetailsDialog();
                            this.$emit('successConfirm', true);
                            swal.fire("", response.data.msg, "success");
                        }
                    });
                }
            });
        },
        confirmSuccessDelivery() {
            swal.fire({
                title: 'Success Deliver?',
                text: 'Click ok to continue',
                icon: 'question',
                dangerMode: true,
                buttons: true
            }).then(confirm=>{
                if(confirm) {
                    let payload = {
                        rfId: this.rf.id
                    }
                    this.$store.dispatch('confirmSuccessDelivery',payload).then(response=>{
                        if(response.data.msg) {
                            this.closeRepairFormDetailsDialog();
                            this.$emit('successConfirm', true);
                            swal.fire("", response.data.msg, "success");
                        }
                    });
                }
            });
        },
        showTableWithParts() {
            if (this.itemRepairStatus == 'Repair-w/o-Parts') {
                return  false;
            } else if (this.itemRepairStatus == 'Return-w/o-Repair') {
                return  false;
            } else if (this.itemRepairStatus == 'No-Problem-Found') {
                return  false;
            } else if (this.itemRepairStatus == 'Credit-Memo') {
                return  false;
            } else if (this.itemRepairStatus == 'Replaced') {
                return  true;
            } else if(this.rfCancelled) {
                return false;
            } else {
               return true;
            }
        },
        repairStatusColor() {
            if(this.itemRepairStatus == 'Repaired' || this.itemRepairStatus == 'Credit-Memo' || this.itemRepairStatus == 'Replaced') {
                return 'green';
            } else if(this.itemRepairStatus == 'No-Problem-Found' || this.itemRepairStatus == 'Repair-w/o-Parts' || this.itemRepairStatus == 'Return-w/o-Repair') {
                return 'blue';
            } else {
                return 'red';
            }
        },
        generateSo() {
            this.generateSoOnFlight = true;
            swal.fire({
                title: "",
                text: "Are you sure you want to Generate SO?",
                icon: 'question',
                showCancelButton:true,
                reverseButtons:true
            }).then((confirm) => {
                if (confirm.isConfirmed) {
                    let payload = {
                        rfId: this.rf.id,
                        customer_id: this.customerId,
                    }
                    this.loading = true
                    this.$store.dispatch('serviceGenerateSo',payload).then(response=>{
                        if(response.data.msg) {
                            this.getRfPartsOrder();
                            this.$emit('successConfirm', true);
                            this.generateSoOnFlight = false;
                            swal.fire("", response.data.msg, "success");
                        }

                        window.Echo.channel('smod-channel').listen('ConvertToSOMessage',(e)=>{
                            let data = e.data.find(el => el.userID == this.USERACCOUNT_LOAD.id);
                            let check_unique_id = (e.data[0].rfId.toString().replace(/"/g,'') == this.rf.id)
                            if(check_unique_id && data && (data.message.msg || data.message.error) ){
                                if(data.message.error){
                                    swal.fire("", data.message.error, "warning");
                                    this.loading = false
                                    return false;
                                }
                                this.closeRepairFormDetailsDialog()
                                this.sales_quotation_items_dialog = false;
                                swal.fire("", data.message.msg, "success");
                                this.$emit('closeView');
                                this.loading = false
                            }
                        });
                    });
                }
                this.generateSoOnFlight = false;
            });
        },
        checkRequiredDocs() {
            if(this.itemWarrantyType != 'Dealer-Stock') {
                if(this.rfPrepared && !!this.salesOrderNum && !!this.packingListNum) {
                    return false;
                }
            }

            if(this.itemWarrantyType == 'Dealer-Stock') {
                if(!!this.rfPrepared) {
                    return false;
                }

                if(!!this.salesOrderNum && !!this.packingListNum) {
                    return false;
                }

                if(this.itemRepairStatus == 'Credit-Memo') {
                    return false;
                }
            }

            if(this.itemRepairStatus == 'Cancelled' && !!this.rfPrepared) {
                return false;
            }

            if(this.itemRepairStatus == 'No-Problem-Found' && !!this.rfPrepared) {
                return false;
            }

            if(this.itemRepairStatus == 'Repair-w/o-Parts' && !!this.rfPrepared) {
                return false;
            }

            if(this.itemRepairStatus == 'Return-w/o-Repair' && !!this.rfPrepared) {
                return false;
            }

            return true;
        },
        showIfRepair() {
            if(this.itemRepairStatus == 'Repaired' || this.itemRepairStatus == 'Replaced') {
                return true
            }

            return false;
        },
        showIfNoRepair() {
            if(this.itemRepairStatus == 'Cancelled' || this.itemRepairStatus == 'No-Problem-Found' || this.itemRepairStatus == 'Repair-w/o-Parts'  || this.itemRepairStatus == 'Return-w/o-Repair' || this.itemRepairStatus == 'Credit-Memo' || this.itemRepairStatus == 'For-Replacement') {
                return true
            }

            return false;
        },
        generatePl() {
            this.generatePlOnFlight = true;
            swal.fire({
                title: `PL Date ${this.plDate}`,
                text: "Are you sure you want to Generate PL?",
                icon: 'question',
                buttons: true,
                dangerMode: true,
                showCancelButton:true
            }).then((confirm) => {
                this.loading = true
                if (confirm.isConfirmed) {
                    let payload = {
                        rfId: this.rf.id,
                        plDate: this.plDate
                    }
                    this.$store.dispatch('serviceGeneratePackingList',payload).then(response=>{
                        if(response.data.msg) {
                            this.plDateDialog = false;
                            this.generatePlOnFlight = false;
                            this.getRfPartsOrder();
                            this.$emit('successConfirm', true);
                            swal.fire("", response.data.msg, "success");
                            this.loading = false
                        }
                    });
                }
            });
        },
        printDr() {
            let doc = new this.$jspdf('p', 'mm', 'letter');
            let ingcoPhLogo = new Image();
            ingcoPhLogo.src = '/images/ingcoph-logo.png';
            doc.addImage(ingcoPhLogo, 'png',7,6,60,15);

            doc.autoTable({
                html: '#process_details',
                theme: 'grid',
                startY: 65,
                margin: {top: 37, left: 7, right: 6},
                columnStyles: {
                    0: {cellWidth: 25, halign:'left', textColor: '#000000', fontSize: 8},
                    1: {cellWidth: 73, halign:'left', textColor: '#000000', fontSize: 8},
                    2: {cellWidth: 25, halign:'left', textColor: '#000000', fontSize: 8},
                    3: {cellWidth: 73, halign:'left', textColor: '#000000', fontSize: 8},
                }
            });

            doc.autoPrint();
            window.open(doc.output('bloburl'), '_blank');
        },
        dateToday() {
            return this.$dayjs().format('YYYY-MM-DD, hh:mm:ss a');
        },
        dateTodayPlusDays() {
            return this.$dayjs().add(3, 'day').format('YYYY-MM-DD, hh:mm:ss a');
        },
        markAsReadyForDelivery() {
            swal.fire({
                title: "Repair Form Ready For Delivery?",
                text: "Click ok to proceed",
                icon: 'question',
                buttons: true,
                dangerMode: true,
                showCancelButton:true,
                reverseButtons:true,
            }).then(confirm=>{
                if(confirm.isConfirmed) {
                    let payload = {
                        rfId: this.rf.id
                    }

                    this.$store.dispatch('markRfReadyForDelivery',payload).then(response=>{
                        if(response.data.msg) {
                            this.getRfPartsOrder();
                            this.$emit('successConfirm', true);
                            swal.fire("", response.data.msg, "success");
                        }
                    })
                }
            });
        },
        checkDelivery() {
            if(this.itemRepairStatus == 'Cancelled') {
                if(!!this.rfPrepared) {
                    if(!!this.readyForDelivery) {
                        return true;
                    } else {
                        return false
                    }
                }
            } else if(this.itemRepairStatus == 'Repaired' || this.itemRepairStatus == 'Replaced') {
                if(!!this.packingListNum && !!this.rfPrepared) {
                    if(!!this.readyForDelivery) {
                        return true;
                    } else {
                        return false;
                    }
                }
            } else if(this.itemRepairStatus == 'No-Problem-Found' || this.itemRepairStatus == 'Repair-w/o-Parts' || this.itemRepairStatus == 'Return-w/o-Repair') {
                if(!!this.rfPrepared) {
                    if(!!this.readyForDelivery) {
                        return true;
                    } else {
                        return false;
                    }
                }
            }

            return true;
        },
        showViewFiles() {
            if(
                this.rfStatus == 'QUO' ||
                this.rfStatus == 'FCA' ||
                this.rfStatus == 'FTA' ||
                this.rfStatus == 'WCM'
            ) {
                return true;
            }

            return false;
        },
        confirmReEstimation() {
            swal.fire({
                title: 'Are You Sure You Want To Re Estimate?',
                icon: 'question',
                dangerMode: true,
                buttons: true
            }).then((confirm)=> {
                if(confirm) {
                    let payload = {
                        rfId: this.rf.id,
                    }

                    this.$store.dispatch('rfReEstimate',payload).then(response => {
                        if(response.data.error) {
                            swal.fire("", response.data.error, "error");
                        } else {
                            this.closeRepairFormDetailsDialog();
                            this.$emit('successConfirm', true);
                            swal.fire("", response.data.msg, "success");
                        }
                    });
                }
            });
        },
        qaDisapproved() {
            let selectedChecklist = this.GET_CHECK_LISTS_SELECTION.filter(
            (e) => e.selected_checklist == 1
            );

            swal.fire({
                title: 'Are You Sure You Want To Reject?',
                icon: 'question',
                buttons: true,
                dangerMode: true,
                showCancelButton:true,
                reverseButtons:true
            }).then((confirm)=> {
                if(confirm.isConfirmed) {
                    let payload = {
                        rfId: this.rf.id,
                        qaRemarks: this.qaRemarks,
                        check_list: selectedChecklist.map((e) => e.id),
                    }
                    this.$store.dispatch('qaDisapproved',payload).then(response => {
                        if(response.data.error) {
                            swal.fire("", response.data.error, "error");
                        } else {
                            this.$emit('successConfirm', true);
                            swal.fire("", response.data.msg, "success");
                            this.closeRepairFormDetailsDialog();
                        }
                    });
                }
            });
        },
        getSalesOrderStatus() {

            this.$store.dispatch('serviceGetSalesOrderStatus',this.salesOrderId).then(response=>{
                this.salesOrderStatus = this.GET_SERVICE_SALES_ORDER_STATUS;
            })
        },
        salesOrderTextStatus() {
            if(!!this.salesOrderStatus) {
                if(this.salesOrderStatus == 1) {
                    return "for-approval";
                } else if(this.salesOrderStatus == 2) {
                    return "for-accounting";
                } else if(this.salesOrderStatus == 3) {
                    return "approved";
                }
            }

            return "";
        },
        showPl() {
            if(this.salesOrderStatus == 3) {
                if(!!this.salesOrderNum && !this.packingListNum) {
                    return false;
                }

                return true;
            }

            return true;
        },
        warrantyRejectReasonDialog() {
            this.showWarrantyReason = true;
        },
        closeWarrantyReason() {
            this.warrantyRejectReason = "";
            this.showWarrantyReason = false;
        },
        showOverride() {
            this.showWarrantyReason = false;
            this.override.override_dialog = true;
        },
        cancelSo(){
            swal.fire({
                showCancelButton:true,
                reverseButtons:true,
                title:'Warning!',
                text:`- Are you sure you want to Cancel SO?`,
                icon: 'question',
            }).then(action=>{
                if(action.isConfirmed){
                    this.loading = true
                    this.$store.dispatch('serviceGet',{
                        url:'cancel-so',
                        rf_id:this.rf.id,
                        so_id:this.GET_APPROVE_ORDERS.sales_order_id,
                        pl_id: this.packingListId,
                    }).then(response=>{
                        swal.fire('Success!','','success')
                        this.loading = false
                        this.closeRepairFormDetailsDialog()
                    }).catch(err=>{
                        console.log(err)
                        swal.fire('Error!','','error')
                        this.loading = false
                    })
                }
            })
        },
        rfdWarrantyReject() {
            swal.fire({
                title: 'Are You Sure You Want To Reject?',
                icon: 'question',
                dangerMode: true,
                buttons: true
            }).then((confirm)=> {
                if(confirm) {
                    let payload = {
                        rfId: this.rf.id,
                        warrantyRejectReason: this.warrantyRejectReason
                    }
                    this.$store.dispatch('rfdWarrantyReject',payload).then(response => {
                        if(response.data.error) {
                            swal.fire("", response.data.error, "error");
                        } else {
                            this.closeWarrantyReason();
                            this.closeRepairFormDetailsDialog();
                            this.$emit('successConfirm', true);
                            swal.fire("", response.data.msg, "success");
                        }
                    });
                }

                this.warrantyRejectReason = "";
            });
        },
        closeConfirmOverride(user_id, authenticated = false) {
            if (authenticated) {
                this.override.override_dialog = false;
                this.rfdWarrantyReject();
            } else {
                this.override.override_dialog = false;
                this.showWarrantyReason = true;
            }
        },
        partsReject() {
            if(!!this.diagnosisRejectReason) {
                swal.fire({
                    title: 'Are You Sure You Want To Reject Parts?',
                    icon: 'question',
                    dangerMode: true,
                    showConfirmButton:true,
                    showCancelButton  :  true,
                    reverseButtons:true,
                }).then((confirm)=> {
                    if(confirm.isConfirmed) {
                        let payload = {
                            rfId: this.rf.id,
                        }
                        this.$store.dispatch('rfdPartsReject',payload).then(response => {
                            if(response.data.error) {
                                swal.fire("", response.data.error, "error");
                            } else {
                                this.closeRepairFormDetailsDialog();
                                this.$emit('successConfirm', true);
                                swal.fire("", response.data.msg, "success");
                            }
                        });
                    }
                });
            } else {
                swal.fire("", 'Please input diagnosis reason', "warning");
            }

        },
        showWarrantyReject() {
            if(this.itemRepairStatus == 'Repaired') {
                if(!!this.salesOrderNum) {
                    return false;
                }

                return true;
            }

            return false;
        },
        // Print Packing List function Start
        printPL() {
            if(this.printedPl) {
                this.override.override_dialog = true;
            } else {
                this.printPlOnFlight = true;
                this.renderPDF(this.packingListId);
            }
        },
        renderPDF(id) {
            this.$store.dispatch('viewPackingList',id).then(async response => {
                let data = this.GET_VIEW_PACKING_LIST

                if(data.error){
                    swal.fire("", data.error, "warning").then(confirm=>{
                        this.printPlOnFlight = false;
                    });

                    return false
                }

                this.packing_list = data.packing_list
                this.packingListItems = data.packing_list.packing_list_items

                if(data.packing_list.discounts != ''){
                    this.discounts = JSON.parse(data.packing_list.discounts)
                    if(this.discounts != null){
                        this.discounts = this.discounts[0].discounts
                    }
                }

                await this.getItemTypes()

            }).catch(error => {
                if(error.response.status == 422){
                    this.errors = error.response.data.errors
                }
            });
        },
        getItemTypes(){

            this.$store.dispatch('getItemTypes').then(async response => {
                this.type_list = this.GET_ITEM_TYPES
                await this.getItemTypeDiscounts();
                await this.getItemTypeDiscountsPDF();
                await this.printNow();
            });
        },
        getItemTypeDiscounts(){
            this.itemTypeDiscounts = []
            this.item_type = this.packingListItems[0].item.item_type_text
            if(this.type_list.length > 0){
                this.type_list.forEach((type)=>{
                    var name = type.name
                    var str = type.name + ': '
                    var isEmpty = true;
                    if(this.discounts.length > 0){
                        this.discounts.forEach((discount, index)=>{
                            if(discount[name.toLowerCase()] != null && discount[name.toLowerCase()] != '' && discount[name.toLowerCase()] > 0){
                                if(index > 0){
                                    str = str + ' / '
                                }
                                str = str + discount[name.toLowerCase()].toString()
                                isEmpty = false
                            }
                        })
                        if(isEmpty){
                            str = ''
                        }else{
                            if(this.packingListItems[0].item.item_type_text == type.name){
                                this.itemTypeDiscounts.push(str)
                            }
                        }
                    }
                })
            }
        },
        getItemTypeDiscountsPDF(){
            this.itemTypeDiscountsPDF = []
            if(this.type_list.length > 0){
                this.type_list.forEach((type)=>{
                    var name = type.name
                    var str = '('+type.name+') LESS: '
                    var isEmpty = true;
                    if(this.discounts.length > 0){
                        this.discounts.forEach((discount, index)=>{
                            if(discount[name.toLowerCase()] != null && discount[name.toLowerCase()] != '' && discount[name.toLowerCase()] > 0){
                                if(index > 0){
                                    str = str + ' / '
                                }
                                str = str + discount[name.toLowerCase()].toString() + '%'
                                isEmpty = false
                            }
                        })
                        if(isEmpty){
                            str = ''
                        }else{
                            if(this.packingListItems[0].item.item_type_text == type.name){
                                this.itemTypeDiscountsPDF.push(str)
                            }
                        }
                    }
                })
            }
        },
        printNow() {
            var doc = new this.$jspdf('p', 'mm', 'letter');
            var margin_left = 7;
            var margin_right = 9;

            // doc.fromHTML($("#service_logo").get(0), margin_left,-4,{'width':600});
            // doc.fromHTML($("#service_packing_list_num").get(0), 162,4,{'width':400});
            // doc.fromHTML($("#service_received_note").get(0), 132,237,{'width':400, halign:'center'});

            doc.autoTable({
                html: '#service_customer_details',
                theme: 'plain',
                margin: {top: 50, left: margin_left, right: margin_right},
                columnStyles: {
                    0: {cellWidth: 20},
                    1: {cellWidth: 133},
                    2: {cellWidth: 14},
                    3: {cellWidth: 23},
                }
            });

            if(this.print_no_price == false){
                doc.autoTable({
                    html: '#service_items_details',
                    startY: doc.autoTable.previous.finalY + 3,
                    margin: {left: margin_left, right: margin_right},
                    theme: 'grid',
                    headStyles: {
                        halign:'center',
                        fillColor: '#ffffff',
                        textColor: '#000000',
                        lineWidth: .2,
                        lineColor: '#000000',
                        fontSize: 9,
                    },
                    columnStyles: {
                        0: {cellWidth: 7, halign:'center',  textColor: '#000000', fontSize: 8},
                        1: {cellWidth: 12, halign:'center', textColor: '#000000', fontSize: 8},
                        2: {cellWidth: 14, halign:'center', textColor: '#000000', fontSize: 8},
                        3: {cellWidth: 31, halign:'center', textColor: '#000000', fontSize: 8},
                        4: {cellWidth: 82, halign:'left', textColor: '#000000', fontSize: 8},
                        5: {cellWidth: 23, halign:'right', textColor: '#000000', fontSize: 8},
                        6: {cellWidth: 25, halign:'right', textColor: '#000000', fontSize: 8}
                    },
                    bodyStyles: {
                        lineColor: '#000000',
                    }
                });
            }else{
                doc.autoTable({
                    html: '#service_items_details_without_price',
                    startY: doc.autoTable.previous.finalY + 3,
                    margin: {left: margin_left, right: margin_right},
                    theme: 'grid',
                    headStyles: {
                        halign:'center',
                        fillColor: '#ffffff',
                        textColor: '#000000',
                        lineWidth: .2,
                        lineColor: '#000000',
                        fontSize: 9,
                    },
                    columnStyles: {
                        0: {cellWidth: 11, halign:'center', textColor: '#000000', fontSize: 8},
                        1: {cellWidth: 17, halign:'center', textColor: '#000000', fontSize: 8},
                        2: {cellWidth: 17, halign:'center', textColor: '#000000', fontSize: 8},
                        3: {cellWidth: 27, halign:'center', textColor: '#000000', fontSize: 8},
                        4: {cellWidth: 118, halign:'left', textColor: '#000000', fontSize: 8},
                    },
                    bodyStyles: {
                        lineColor: '#000000',
                    }
                });
            }

            if(this.print_no_price == false){
                doc.autoTable({
                    html: '#service_total_details',
                    theme: 'plain',
                    startY: 207,
                    margin: {top: 37, left: margin_left, right: margin_right},
                    columnStyles: {
                        0: {cellWidth: 115, textColor: '#000000'},
                        1: {cellWidth: 32, halign:'right', textColor: '#000000'},
                        2: {cellWidth: 11, textColor: '#000000'},
                        3: {cellWidth: 35, halign:'right', textColor: '#000000'},
                    }
                });
            }

            doc.autoTable({
                html: '#service_num_of_items',
                theme: 'plain',
                tableWidth: 116,
                startY: 230,
                margin: {top: 37, left: margin_left, right: margin_right},
                columnStyles: {
                    0: {cellWidth: 38, textColor: '#000000'},
                    1: {cellWidth: 34, textColor: '#000000'},
                    2: {cellWidth: 38, textColor: '#000000'},
                }
            });

            var footer_y = 240

            doc.autoTable({
                html: '#service_footer',
                theme: 'grid',
                tableWidth: 116,
                startY: footer_y,
                margin: {left: margin_left, right: margin_right},
                columnStyles: {
                    0: {cellWidth: 38, halign:'center', textColor: '#000000'},
                    1: {cellWidth: 38, halign:'center', textColor: '#000000'},
                    2: {cellWidth: 38, halign:'center', textColor: '#000000'},
                },
                bodyStyles: {
                    lineColor: '#000000',
                }
            });

            let finalY = doc.lastAutoTable.finalY; // The y position on the page
            doc.setFontSize(7);
            doc.setTextColor('#000000');
            doc.text(181, finalY, "Page 1 of 1")

            let total_print_count = this.packing_list.print_with_price_counter + this.packing_list.print_without_price_counter
            if (this.packing_list.printed == 1 && total_print_count > 0) {
                doc.setFontSize(6);
                doc.text(197, finalY, "R-" + total_print_count)
            }

            if(this.pdfAction == 'download'){
                doc.save('PackingList ' + this.packing_list.packing_list_num + '.pdf');
            }else if(this.pdfAction == 'print'){
                if (this.packing_list.terms == 0) {
                    swal.fire({
                        title: "",
                        text: "PL terms is CASH. Please make sure payment is made before releasing the items.",
                        icon: "warning",
                        dangerMode: true,
                    }).then(willPrint => {
                        this.triggerPrintedPL();
                        window.open(doc.output('bloburl'), '_blank');
                    })
                } else {
                    this.triggerPrintedPL();
                    window.open(doc.output('bloburl'), '_blank');
                }
            }
        },
        triggerPrintedPL(doc){
            let payload = {
                id: this.packingListId,
                params:{
                    service: true,
                    rfId: this.rf.id
                }
            }
            this.$store.dispatch('updatePLPrinted',payload).then(response => {
                let data = this.GET_UPDATE_PL_PRINTED

                if(data.error){
                    swal.fire("", data.error, "warning");
                    return false
                } else {
                    this.getRfPartsOrder();
                    this.$emit('successConfirm', true);
                    swal.fire('',response.data.msg,'success');
                    this.printPlOnFlight = false;
                }
            });
        },
        showPlDateDialog() {
            this.plDateDialog = true;
            this.plDate = this.$dayjs().format('YYYY-MM-DD');
        },
        closePlDateDialog() {
            this.plDateDialog = false;
            this.plDate = "";
        },
        //confirmPlDate() {
        //     let today = this.$dayjs().format('YYYY-MM-DD');
        //     if(this.plDate > today) {
        //         this.$store.commit('closeRepairFormDetailsDialog');
        //         this.overridePlDate.override_dialog = true;
        //     } else {
        //         this.generatePl();
        //     }
        // },
        warrantyChange() {
            this.overrideWarrantyChange.override_dialog = true;
            this.overrideDiagnosisReject.override_dialog = false;
        },
        diagnosisReject() {
            this.overrideDiagnosisReject.override_dialog = true;
            this.overrideWarrantyChange.override_dialog = false;
        },
        confirmedOverridePrint() {
            this.showOverridePrint = true;
            if(!!this.overrideUserPassword) {
                let payload = {
                    departments_allowed: ['SR', 'IT' , 'EX'],
                    override_user_password: this.overrideUserPassword
                }
                this.$store.dispatch('confirmOverridePrintPL',payload).then((response)=> {
                    if(this.GET_CONFIRM_OVERRIDE_PRINT_PL.error) {
                        swal.fire("", this.GET_CONFIRM_OVERRIDE_PRINT_PL.error, "warning").then(()=>{this.confirmOverride = false;})
                        return false;
                    }
                    this.overrideUserPassword = "";
                });
            }
        },
        closeConfirmOverride(user_id, authenticated = false) {
            if (authenticated) {
                this.override.override_dialog = false;
                this.printPlOnFlight = true;
                this.renderPDF(this.packingListId);
            } else {
                this.override.override_dialog = false;
            }
        },

        // Iterate through the checklist items and set selected_checklist to false
        resetCheckboxes() {
            let checklist = [];
            checklist =  this.GET_CHECK_LISTS_SELECTION.map((e) => {
                e.selected_checklist = "0";
            return e;
            });
            this.$store.commit('CHECK_LISTS',checklist)
            this.checklist = {
                selected_checklist: null,
                all: "0",
            };

        },

        // Print Packing List function End
        closeQaRemarksDialog() {
            this.qaRemarksDialog = false;
            this.qaRemarks = "";
            this.qaRemarksAction = "";
            this.resetCheckboxes();
        },

        submit() {

           let checker = this.GET_CHECK_LISTS_SELECTION.filter(e=>{
                if(e.selected_checklist == '0'){
                    return e;
                }
           })

           if(checker.length > 0 ) {
                this.qaDisapproved();

            } else {
                this.confirmRepairedItem();
            }
        },
        showDiagnosisRejectReasonDialog() {
            this.diagnosisReasonDialog = true;
        },
        showRejectedItemDialog(){
            this.rejectedCounterDialog = true;
        },
        closeRejectedCounterDialog(){
            this.rejectedCounterDialog = false;
        },
        closeDiagnosisRejectReasonDialog() {
            this.diagnosisRejectReason = "";
            this.diagnosisReasonDialog = false;
        },
        // closeConfirmOverridePlDate(user_id, authenticated = false) {
        //     if (authenticated) {
        //         this.overridePlDate.override_dialog = false;
        //         this.$store.commit('showRepairFormDetailsDialog');
        //         this.generatePl();
        //     } else {
        //         this.overridePlDate.override_dialog = false;
        //         this.$store.commit('showRepairFormDetailsDialog');
        //     }
        // },
        closeConfirmOverrideWarrantyChange(user_id, authenticated = false) {
            if (authenticated) {
                this.overrideWarrantyChange.override_dialog = false;
                this.warrantyRejectReasonDialog();
            } else {
                this.overrideWarrantyChange.override_dialog = false;
            }
        },
        closeConfirmOverrideDiagnosisReject(user_id, authenticated = false) {
            if (authenticated) {
                this.overrideDiagnosisReject.override_dialog = false;
                this.showDiagnosisRejectReasonDialog();
            } else {
                this.overrideDiagnosisReject.override_dialog = false;
            }
        },
        async updateRf(){
            swal.fire({
                title:'Warning',
                text:'Are you sure you want to udpate',
                showCancelButton:true,
                reverseButtons:true,
                icon:'warning'
            }).then(async action=>{
                if(action.isConfirmed){
                    const response = await this.updateStatus('UAS',this.rf.id)
                    swal.fire('Success!',`- ${response.rf_number} updated`,'success')
                    this.closeRepairFormDetailsDialog()
                }
            })

        },
        showUploadWarranty() {
            this.UploadWarranty = true;
            this.plDateDialog = false;
        },
        getImages(images) {
            this.images = images;
        },
        clearCameraCapture(){
            this.$refs.cameraCapture.reset();
            this.images = null;
        },
        clearFileUpload(confirm) {
            if(confirm) {
                this.uploadedFiles = null;
            }
            // this.$refs.fileUpload.reset();
        },
        closeCameraCapture(){
            this.UploadWarranty = false;
            this.clearCameraCapture()
            this.clearFileUpload(confirm)
            this.$refs.cameraCapture.reset();
            this.$refs.fileUpload.reset();
            this.attach = false;
        },
        uploadedData(data) {
            if(!data) {
                this.uploadedFiles = null;
            }

            this.uploadedFiles = data;
        },
        selectedAttach() {
            if(this.selectedAttach == 'Camera') {
                this.uploadedFiles = null;
            } else if(this.selectedAttach == 'Upload') {
                this.images = null;
            }
        },
        submitWarranty(filePaths){
            const data = new FormData();

            // this.images == null && this.uploadedFiles == null
            const config = {
                headers: {
                    'content-type': 'multipart/form-data'
                }
            }
            if(!!this.uploadedFiles && this.uploadedFiles.attachments.length > 0) {
                let files = this.uploadedFiles.attachments;

                for(let i=0;i < _.compact(files).length;i++) {
                    data.append('files[]', files[i]);
                }
            }

            if(!!this.images && this.images.length > 0) {
                for(let i=0;i < _.compact(this.images).length;i++) {
                    data.append('images[]', _.compact(this.images)[i]);
                }
            }
            data.append('rfId', this.rfId);
            swal.fire({
                    title: 'Are you sure you want to Capture or Upload Image?',
                    icon: 'question',
                    showConfirmButton:true,
                    showCancelButton  :  true,
                    reverseButtons:true,
                    allowOutsideClick :  false,
                    confirmButtonColor: '#397373',
                    cancelButtonColor : 'grey',
                    confirmButtonText:'Confirm',
                    showCloseButton:true,
                }).then(confirm=>{
                    if(confirm.isConfirmed){
                     let payload = {
                        rfId: this.rf.id,
                        params: data,
                        config: config,
                    }
                    this.$store.dispatch('uploadWarranty', payload).then(response=>{
                    this.closeCameraCapture();
                    this.$emit('successConfirm', true);
                    swal.fire("Success", '', "success");
                    this.closeRepairFormDetailsDialog()
                    }).catch(err=>{
                        console.log(err)
                        swal.fire('Failed!','','error')
                        this.loading = false
                         })
                    }
                });
            // swal.fire({
            //         title: 'Are you sure you want to Capture or Upload Image?',
            //         icon: 'warning',
            //         allowOutsideClick :  false,


            //     }).then(confirm=>{
            //         if(confirm.isConfirmed){
            //          let payload = {
            //             rfId: this.rf.id,
            //             images: this.images,
            //             files: this.files,
            //         }
            //         this.$store.dispatch('uploadWarranty', payload).then(response=>{
            //                         if(response.data.msg) {
            //                             this.closeCameraCapture();
            //                             this.$emit('successConfirm', true);
            //                             swal.fire("Success", response.data.msg, "success");
            //                         }
            //                         else{
            //                             swal.fire("Error", response.data.error, "error");
            //                         }
            //                     });
            //                 }
            //         })
            // data.append('rfId', this.rfId);
            // data.append('rfItemId', this.rfItemId);
            // data.append('item_id', this.itemId);
            // if(!!this.images && this.images.length > 0) {
            //     for(let i=0;i < _.compact(this.images).length;i++) {
            //         data.append('images[]', _.compact(this.images)[i]);
            //     }
            // }


            //     swal.fire({
            //         title: 'Please Capture or Upload Image',
            //         icon: 'warning',
            //         dangerMode: true,
            //     }).then(confirm=>{
            //         let payload = {
            //             images: this.images,
            //             files: this.uploadedFiles
            //         }
            //         this.$store.dispatch('uploadWarranty', payload);
            //         this.images == null && this.uploadedFiles == null
            //         this.itemWarrantyType == 'Dealer-Stock' || this.itemWarrantyType == 'In-Warranty'
            //         this.itemRepairStatus == 'For-Repair' || this.itemRepairStatus == 'For-Replacement'
            //     })
        },

        forRecovered() {
            swal.fire({
                title: 'Are you sure you want to confirm?',
                text: '- This will be considered as recovered',
                icon: 'question',
                showConfirmButton:true,
                showCancelButton  :  true,
                reverseButtons:true,
                allowOutsideClick :  false,
                confirmButtonColor: '#397373',
                cancelButtonColor : 'grey',
                confirmButtonText:'Confirm',
                showCloseButton:true,
            }).then(confirm=>{
                if(confirm.isConfirmed){
                    this.$store.dispatch('servicePost', {
                        rfId: this.rf.id,
                        url: 'baklasRecovered',
                    }).then((response) => {
                        swal.fire('Success!','','success')

                        let desti_warehouse = this.warehousesCode.filter(x=>x.code == 'C8')[0];
                        let source_warehouse = this.warehousesCode.filter(x=>x.code == 'DM-D')[0];
                        let payload = {
                            warehouse_id: desti_warehouse.id,
                            initial_serving_warehouse_id: source_warehouse.id,
                            remarks: `For Service And Repair, RF#: ${this.rfNumber}, Defective Item (CM) Serial# ${this.itemSerial} `,
                            service: 1,
                            rfId: this.rf.id,
                            delivery_module: true,
                            baklas: 1,
                            allow_butal:1
                        }
                        this.$store.dispatch('requestCreate',payload).then(response=>{
                            swal.fire("", `Stock Request ${response.data.stock_request_form_number},
                                Stock Transfer ${response.data.stock_transfer_form_number} Create Successfully!`, "success").then(confirm=>{
                                    this.closeRepairFormDetailsDialog()
                                    this.$emit('successConfirm', true);
                            });
                        });
                    }).catch(err=>{
                        swal.fire('Error!','','error')
                        console.log(err)
                    })
                }
            })
        },

        forCannibalization() {
            swal.fire({
                title: 'Are you sure you want to confirm?',
                text: '- This will be considered as new',
                icon: 'question',
                showConfirmButton:true,
                showCancelButton  :  true,
                reverseButtons:true,
                allowOutsideClick :  false,
                confirmButtonColor: '#397373',
                cancelButtonColor : 'grey',
                confirmButtonText:'Confirm',
                showCloseButton:true,
            }).then(confirm=>{
                if(confirm.isConfirmed){
                    this.$store.dispatch('servicePost', {
                        rfId: this.rf.id,
                        url: 'baklasCannibalization',
                    }).then(response => {
                        swal.fire('Success!', 'Your Baklas# ' + response.data.message,'success')
                        this.closeRepairFormDetailsDialog()
                        this.$emit('successConfirm', true);
                    }).catch(err=>{
                        swal.fire('Error!','','error')
                        console.log(err)
                    })
                }
            })
        },

        forDispose() {
            swal.fire({
                title: 'Are you sure you want to confirm?',
                text: '- This will be considered as disposed',
                icon: 'question',
                showConfirmButton:true,
                showCancelButton  :  true,
                reverseButtons:true,
                allowOutsideClick :  false,
                confirmButtonColor: '#397373',
                cancelButtonColor : 'grey',
                confirmButtonText:'Confirm',
                showCloseButton:true,
            }).then(confirm=>{
                if(confirm.isConfirmed){
                    this.$store.dispatch('servicePost', {
                        rfId: this.rf.id,
                        url: 'baklasDispose',
                    }).then((response) => {
                        swal.fire('Success!','Your Baklas# ' + response.data.message,'success')
                        this.closeRepairFormDetailsDialog()
                        this.$emit('successConfirm', true);
                    }).catch(err=>{
                        swal.fire('Error!','','error')
                        console.log(err)
                    })
                }
            })
        },

        forRecovery() {
            if(this.rfForRecovery == 0) {
                swal.fire('Info!','- Please Upload Image or Files','info')
            } else {
                swal.fire({
                    title: 'Are you sure you want to confirm?',
                    text: '- This will be considered as for recovery',
                    icon: 'question',
                    showConfirmButton:true,
                    showCancelButton  :  true,
                    reverseButtons:true,
                    allowOutsideClick :  false,
                    confirmButtonColor: '#397373',
                    cancelButtonColor : 'grey',
                    confirmButtonText:'Confirm',
                    showCloseButton:true,
                }).then(confirm=>{
                    if(confirm.isConfirmed){
                        this.$store.dispatch('servicePost', {
                            rfId: this.rf.id,
                            url: 'baklasForRecovery',
                        }).then((response) => {
                            swal.fire('Success!','','success')
                            this.closeRepairFormDetailsDialog()
                            this.$emit('successConfirm', true);
                        }).catch(err=>{
                            swal.fire('Error!','','error')
                            console.log(err)
                        })
                    }
                })
            }
        },

        showDelete() {
            if(this.rfStatus == 'DEF') {
                return true;
            }
            return false;
        },

        async getWarehouses() {
            await this.$store.dispatch('serviceGetWarehouses').then(response=>{
                this.warehousesCode = response.data.data
                let data = response.data.data
                this.warehouses = data.filter(x=>{
                    return x.code == 'DM-B' || x.code == 'DM'
                });
            })
        },

        checkingTransfer() {
            if(!!this.stockTransferNum && !!this.stockRequestNum) {
                return true;
            } else {
                return false;
            }
        },
    }
}
</script>

<style scoped>
.v-input--selection-controls {
    margin-top: 0px;
    padding-top: 0px;
}

</style>
