<template>
<div>
    <v-app id="load_bar" v-if="!loaded">
        <v-text-field color="info" loading disabled></v-text-field>
    </v-app>
    <v-app id="view_client_modal">
        <v-dialog v-model="wp_list_items_dialog" persistent max-width="98%" scrollable>
            <v-card>
                <v-row class="m-0">
                    <v-card-title>
                        <span class="headline">Warehouse Preparation Form Details</span>
                    </v-card-title>
                    <v-col cols="pull-right-10 p-2">
                        <v-btn text icon color="gray" class="float-right" @click="clearWPListItems(); $emit('closeView');">
                            <v-icon>mdi-close-circle</v-icon>
                        </v-btn>
                    </v-col>
                </v-row>
                <v-card-text>
                    <v-row class="m-0">
                        <v-container  class="d-flex justify-space-between" fluid>
                            <v-row>
                                <v-col cols="12" md="4" class="text-left text-dark">
                                    <div class="row ml-2">
                                        <div class="col-sm-12 col-md-8 p-0">WPF#: {{ wp_list.id }}</div>
                                    </div>
                                    <div class="row ml-2">
                                        <div class="col-sm-12 col-md-8 p-0">SO#: {{ wp_list.sales_order_num }}</div>
                                    </div>
                                    <div class="row ml-2">
                                        <div class="col-sm-12 col-md-8 p-0">SQ#: {{ wp_list.sales_quotation_num }}</div>
                                    </div>
                                    <div class="row ml-2">
                                        <div class="col-sm-12 col-md-8 p-0">Warehouse: {{ wp_list.warehouse_name }}</div>
                                    </div>
                                    <div class="row ml-2">
                                        <div class="col-sm-12 col-md-8 p-0">SO Remarks: {{ wp_list.sales_order_remarks }}</div>
                                    </div>
                                    <div class="row ml-2">
                                        <div class="col-sm-12 col-md-8 p-0">Notes: {{ wp_list.notes }}</div>
                                    </div>
                                </v-col>
                                <v-col cols="12" md="4" class="text-left text-dark">
                                    <div class="row ml-2">
                                        <div class="col-sm-12 col-md-8 p-0">WPF Remarks: {{ wp_list.remarks }}</div>
                                    </div>
                                    <div class="row ml-2">
                                        <div class="col-sm-12 col-md-8 p-0">Preparer: {{ wp_list.preparer_name }}</div>
                                    </div>
                                    <div class="row ml-2" v-if="stock_card">
                                        <div class="col-sm-12 col-md-8 p-0"><b>Stock Card</b></div>
                                    </div>
                                    <div class="row ml-2">
                                        <div class="col-sm-12 col-md-8 p-0">Start Date: {{ wp_list.start_date | formatDateTime24hours }}</div>
                                    </div>
                                    <div class="row ml-2">
                                        <div class="col-sm-12 col-md-8 p-0">End Date: {{ wp_list.end_date | formatDateTime24hours }}</div>
                                    </div>
                                    <div class="row ml-2">
                                        <div class="col-sm-12 col-md-8 p-0">Durations:{{ wp_list.durations }}</div>
                                    </div>
                                    <div class="row ml-2">
                                        <div class="col-sm-12 col-md-8 p-0">Correct Picking Reason: {{ wp_list.correct_picking_reason }}</div>
                                    </div>
                                </v-col>
                                <v-col cols="12" md="4" class="mt-2">
                                    <v-row class="d-flex flex-column align-end">
                                        <v-col md="6" class="d-flex justify-end">
                                            <v-btn class="mr-2" @click="imageUpload = true">
                                                Attach File
                                            </v-btn>
                                            <BaseFileViewer  :wpfId="wp_list.id" :delete="true"/>
                                        </v-col>
                                        <v-col v-if="wp_list.item_type_id == 3 && [0, 6].includes(wp_list.status)" md="4" class="mt-1 mr-1">
                                            <v-text-field
                                                v-model="crate_number.parts_crate_num"
                                                append-icon="mdi-content-save"
                                                label="Parts Crate Number"
                                                outlined
                                                dense
                                                @click:append="savePartsCrateNumber()"
                                                > 
                                            </v-text-field>
                                        </v-col>
                                    </v-row>
                                </v-col>
                            </v-row>
                        </v-container>
                    </v-row>

                    <v-data-table
                        :headers="selectedHeaders"
                        :items="wpListItems"
                        :items-per-page="500"
                        hide-default-footer
                        class="w-100"
                    >
                    </v-data-table >

                    <v-row class="m-0">
                        <v-container fluid>
                            <v-row>
                                <div class="col-sm-12 col-md-8 text-left">

                                    <div class="card-body py-4 d-flex">
                                        <!-- <span class="m-1"><v-btn @click="convertToCheckerForm()" :disabled="wp_list.status != 1 || wp_list.checker_form_status != -1">Convert to CF</v-btn></span> -->
                                        <span class="m-1">
                                            <v-btn :loading="!loaded" v-show="wp_list.status == 0 || wp_list.status == 6 && !forPartial.length > 0" @click="print_no_price = true; renderPDF('print','_preparer');" >Print Preparer</v-btn>
                                        </span>
                                    </div>
                                </div>
                            </v-row>
                        </v-container>
                    </v-row>
                    <div class="border-top">
                        <div class="card-body py-4 d-flex justify-content-center" v-if="wp_list.status == 0 || wp_list.status == 6">
                            <span class="m-1" v-if="wp_list.printed == 1"><v-btn @click="updateWPListStatus(1)" :loading="loading.convertToCf">Convert To CF</v-btn></span>
                            <span class="m-1" v-if="wp_list.status == 6 && wp_list.printed != 1"><v-btn :loading="!loaded" @click="revertPartial()">Revert</v-btn></span>
                            <div class="m-1" v-if="wp_list.status == 0">
                                <span class="m-1"><v-btn :loading="!loaded" @click="markAsUnserved()">Mark as Unserved</v-btn></span>
                                <span class="m-1" v-if="forPartial.length > 0"><v-btn :loading="!loaded" @click="markAsPartial()">Mark as Partial</v-btn></span>
                            </div>
                        </div>
                    </div>

                </v-card-text>

                <!-- PREPARER -->
                <v-container fluid style="display:none;">
                    <div id="div_logo_preparer">
                        <div style="font-size: 12px; line-height: 1.9">
                            <p><span style="font-size: 18px; font-weight: bold;">WADFOW Tools Philippines Corp.</span>
                            </p>
                        </div>
                    </div>
                    <div id="div_wp_list_num_preparer" style="font-size: 12px; line-height: 1.9">
                        <div style="border-top: 1px dashed #CFCFCF; padding: 2px; padding-bottom: -4px; text-align: center; border-bottom: none;">&nbsp;</div>
                        <p>
                            <span style="font-size: 18px; font-weight: bold;">Warehouse Preparation Form</span>
                            <br>
                            <b>WPF# {{ wp_list.id }}</b>
                            <br>
                            <b>SO# {{ wp_list.sales_order_num }}</b>
                            <br>
                            <b>Terms: {{ sales_order.terms == 0 ? 'CASH' : sales_order.terms + ' days' }}</b>
                        </p>
                    </div>

                    <div>
                        <table id="customer_details_preparer">
                            <tbody>
                            <tr>
                                <td>Customer:</td>
                                <td>{{ wp_list.customer_name | uppercase }} </td>
                                <!-- <td>Date:</td>
                                <td>{{ wp_list.created_at | formatDate }} </td> -->
                            </tr>
                            <tr>
                                <td>SO Remarks:</td>
                                <td>{{ wp_list.sales_order_remarks }} </td>
                                <td></td>
                                <td></td>
                            </tr>
                            <tr>
                                <td>Notes:</td>
                                <td>{{ wp_list.notes }} </td>
                                <td></td>
                                <td></td>
                            </tr>
                            </tbody>
                        </table>
                    </div>

                    <div v-for="(items, i ) in print.wpListItems" :key="i">
                        <table :id="'items_details_without_price_preparer' + i">
                            <thead>
                                <tr>
                                    <th>#</th>
                                    <th>PREP</th>
                                    <th>QTY</th>
                                    <th>STOCK</th>
                                    <th>UNITS</th>
                                    <th>MODEL</th>
                                    <th>DESCRIPTION</th>
                                    <th>LOCATION</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(item, index ) in items" :key="index">
                                    <td>{{ item.item.non_default_warehouse_stocks + item.item.default_warehouse_stocks == 0 ? `${index+1}`:index + 1 }}</td>
                                    <!-- <td>{{ item.quantity_served }}</td> -->
                                    <td></td>
                                    <td>{{ item.quantity }}</td>
                                    <td>{{ item.warehouse_stocks }}</td>
                                    <td>{{ item.item_uom_text }}</td>
                                    <td>{{ item.model }}</td>
                                    <td>{{ item.name }}</td>
                                    <td>{{ item.item_location }}</td>
                                </tr>
                                <tr v-if="print.wpListItems.length == (i + 1)">
                                    <td colspan="7">***** NOTHING FOLLOWS *****</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>

                    <div>
                        <table id="num_of_items_preparer">
                            <tbody>
                                <tr>
                                    <td><span>No. of Items: {{ this.no_of_items }}</span></td>
                                    <td></td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div>
                        <table id="footer_preparer">
                            <tbody>
                                <tr>
                                    <td>Prepared By</td>
                                </tr>
                                <tr>
                                    <td><br><br><br>{{ this.wp_list.preparer_name }}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </v-container>
                <!-- FILE UPLOAD DIALOG -->
                <v-dialog v-model="imageUpload" persistent max-width="20%">
                    <v-card class="pa-4">
                        <v-btn
                            text
                            icon
                            color="gray"
                            class="float-right"
                            @click="closeImageUploadDialog()"
                        >
                            <v-icon>mdi-close-circle</v-icon>
                        </v-btn>
                        <div class="py-2">
                            <span>Attach Image File</span><span style="color:red">  *</span>
                            <v-divider/>
                        </div>
                        <div class="py-2 mt-2">
                            <v-layout row class="mx-0">
                                <v-flex lg2 class="mr-2">
                                    <BaseCameraCaptureVue ref="cameraCapture" @capturedImages="getImages" />
                                </v-flex>
                                <div class="mt-2">
                                    <v-chip
                                        v-if="!!images && images.length > 0"
                                        color="#f69f1a"
                                        outlined
                                        close
                                        @click:close="clearCameraCapture()"
                                    >
                                        <span class="mr-2">Images Count: {{ images.length }}</span>
                                    </v-chip>
                                </div>
                            </v-layout>
                        </div>

                        <div class="py-2 mt-3">
                            <v-layout row class="mx-0">
                                <v-flex :class="{'mr-2': true, 'lg3': !!uploadedImageFile && uploadedImageFile.attachments.length > 1, 'lg6': !!uploadedImageFile && uploadedImageFile.attachments.length == 1}">
                                    <FileUploadComponentVue ref="fileUpload" @uploadedData="uploadedData" @reset="clearImageUpload"/>
                                </v-flex>
                                <v-flex v-if="!!uploadedImageFile && uploadedImageFile.attachments.length > 0" class="ml-2" >
                                    <div class="mt-2">
                                        <v-chip
                                            v-if="!!uploadedImageFile && uploadedImageFile.attachments.length > 0"
                                            color="#f69f1a"
                                            outlined
                                            close
                                            @click:close="clearImageUpload()"
                                        >
                                            <span class="mr-2">Upload Count: {{ uploadedImageFile.attachments.length }}</span>
                                        </v-chip>
                                    </div>
                                </v-flex>
                            </v-layout>
                        </div>
                        <div class="py-2 mt-3">
                            <v-card-actions class="d-flex justify-content-end">
                                <v-btn  @click="storeImageUpload(wp_list.id)">Submit</v-btn>
                            </v-card-actions>
                        </div>
                    </v-card>
                </v-dialog>
            </v-card>
        </v-dialog>
    </v-app>
</div>
</template>
<script>
    // import SharedFunctionsComponent from '../Shared/SharedFunctionsComponent.vue'
    import SharedFunctionsComponentVue from '@/views/main/Shared/SharedFunctionsComponent.vue';
    import OperationsFunctionComponentVue from '@/views/main/Shared/OperationsFunctionComponent.vue';
    import swal from 'sweetalert2';
    import FileUploadComponentVue from '@/views/main/Shared/FileUploadComponent.vue';
    import BaseCameraCaptureVue from '@/views/main/modules/Services/Base/BaseCameraCapture.vue';
    import BaseFileViewer from '@/views/main/modules/Operations/WarehouseReturn/BaseFileViewr.vue';
    import { mapGetters } from 'vuex';
    export default {
        mixins: [SharedFunctionsComponentVue,OperationsFunctionComponentVue],
        components: {
            FileUploadComponentVue,
            BaseCameraCaptureVue,
            BaseFileViewer
        },
        props: [
            'viewWarehousePreparationListId',
        ],
        mounted(){
            this.getSystemPlatform();
        },
        data() {
            return {
                stock_card: false,
                wpListItems: [],
                selectedHeaders: [],
                wpListItemsHeaders: [
                    { text: 'Model', align: 'left', value: 'model', width: '15%', sortable: false},
                    { text: 'Name', align: 'left', value: 'name', width: '35%', sortable: false},
                    { text: 'Type', align: 'left', value: 'item_type_text', sortable: false},
                    { text: 'UOM', align: 'left', value: 'item_uom_text', sortable: false},
                    { text: 'Location', align: 'left', value: 'item_location', sortable: false},
                    { text: 'Stocks', align: 'center', value: 'warehouse_stocks', sortable: false},
                    { text: 'Qty', align: 'center', value: 'quantity', sortable: false},
                    { text: 'Qty to Ship', align: 'center', value: 'quantity_served', sortable: false},
                ],
                wpListItemsHeaders_forPartial: [
                    { text: 'Model', align: 'left', value: 'model', width: '15%', sortable: false},
                    { text: 'Name', align: 'left', value: 'name', width: '35%', sortable: false},
                    { text: 'Type', align: 'left', value: 'item_type_text', sortable: false},
                    { text: 'UOM', align: 'left', value: 'item_uom_text', sortable: false},
                    { text: 'Location', align: 'left', value: 'item_location', sortable: false},
                    { text: 'Stocks', align: 'center', value: 'warehouse_stocks', sortable: false},
                    { text: 'Qty', align: 'center', value: 'quantity', sortable: false},
                    { text: 'Qty to Ship', align: 'center', value: 'quantity_served', sortable: false},
                    { text: 'Partially Reserve', align: 'center', value: 'partially_reserve', sortable: false},
                ],
                stockCardwpListItemsHeaders: [
                    { text: 'Model', align: 'left', value: 'model', width: '15%', sortable: false},
                    { text: 'Name', align: 'left', value: 'name', width: '35%', sortable: false},
                    { text: 'Type', align: 'left', value: 'item_type_text', sortable: false},
                    { text: 'UOM', align: 'left', value: 'item_uom_text', sortable: false },
                    { text: 'Location', align: 'left', value: 'item_location', sortable: false},
                    { text: 'Stocks', align: 'center', value: 'warehouse_stocks', sortable: false },
                    { text: 'SO Qty', align: 'center', value: 'quantity', sortable: false},
                    { text: 'SC Qty', align: 'center', value: 'sc_quantity', sortable: false},
                    { text: 'Qty to Ship', align: 'center', value: 'sc_quantity', sortable: false},
                ],

                loaded: true,
                wp_list_items_dialog: false,
                wp_list: '',
                sales_order: '',
                discounts: [],
                itemTypeDiscounts: [],
                itemTypeDiscountsPDF: [],
                type_list: [],
                errors: [],
                loading:{
                    convertToCf:false
                },
                warehouseId: '',
                forPartial: [],
                forRevertChecking: [],
                imageUpload: false,
                images: false,
                uploadedImageFile: null,
                companyDetails:{},
                print: {
                    wpListItems: [],
                },
                crate_number: {
                    parts_crate_num:'',
                    loading: false,
                }
            }
        },
        methods: {
            convertToCheckerForm(){
                if(this.wp_list.status == 1){
                    this.errors = []
                    swal.fire({
                        title: "",
                        text: "Are you sure you want to Convert this to CF?",
                        icon: 'question',
                        showConfirmButton:true,
                        showCancelButton  :  true,
                        reverseButtons:true,
                        allowOutsideClick :  false,
                        confirmButtonColor: '#397373',
                        cancelButtonColor : 'grey',
                        confirmButtonText:'Confirm',
                        showCloseButton:true,
                    })
                    .then((result) => {
                        if (result) {

                            let payload = {
                                url: 'convertToCheckerForm/'+this.viewWarehousePreparationListId
                            }
                            this.$store.dispatch('postWPF',payload).then(response => {
                                let data = response.data

                                if(data.error){
                                    swal.fire("", data.error, "warning");
                                    return false
                                }
                                this.resetFields()

                                swal.fire("", data.msg, "success");

                                this.wp_list_items_dialog = false

                                this.$emit('closeView', 'convertSuccess')
                            });
                        }
                    });
                }
            },
            markAsUnserved() {
                this.loaded = false

                swal.fire({
                    title: "",
                    text: "Are you sure you want to mark this as unserved?",
                    icon: 'question',
                    showConfirmButton:true,
                    showCancelButton  :  true,
                    reverseButtons:true,
                    allowOutsideClick :  false,
                    confirmButtonColor: '#397373',
                    cancelButtonColor : 'grey',
                    confirmButtonText: 'Yes',
                    showCloseButton:true,
                }).then((result) => {
                    if (result.isConfirmed) {
                        let payload = {
                            url: 'markAsUnserved/'+this.viewWarehousePreparationListId
                        }
                        this.$store.dispatch('postWPF',payload).then(response => {
                            let data = response.data

                            if(data.error){
                                swal.fire("", data.error, "warning");
                                return false
                            }
                            this.resetFields()

                            swal.fire("", data.msg, "success");

                            this.wp_list_items_dialog = false
                            this.$emit('closeView', 'updateStatusSuccess')
                            this.loaded = true
                        });
                    }
                    else{
                        this.loaded = true
                    }
                });
            },
            updateWPListStatus(newStatus){
                this.errors = []
                if (!this.wp_list.preparer_name) {
                    swal.fire("", "Preparer cannot be blank!", "warning")
                    return false
                }
                this.loading.convertToCf = true
                swal.fire({
                    title: "",
                    text: "Are you sure you want to update Warehouse Preparation Form?",
                    icon: 'question',
                    showConfirmButton:true,
                    showCancelButton  :  true,
                    reverseButtons:true,
                    allowOutsideClick :  false,
                    confirmButtonColor: '#397373',
                    cancelButtonColor : 'grey',
                    confirmButtonText:'Confirm',
                    showCloseButton:true,
                })
                .then((result) => {
                    if (!this.stock_card) {
                        const totalQuantityToShip = this.wpListItems.reduce((total, item) => total + item.quantity_served, 0);

                        if (result.isConfirmed && totalQuantityToShip <= 0) {
                            swal.fire(
                                'Error!',
                                'WPF must have atleast 1 of its items that has greater than 0 Qty to Ship.',
                                'error',
                            );
                            this.loading.convertToCf = false;
                            return;
                        }
                    }

                    if (result.isConfirmed) {
                        let payload = {
                            status: newStatus,
                            url: 'updateWPListStatus/'+this.viewWarehousePreparationListId
                        }
                        this.$store.dispatch('postWPF',payload).then(response => {
                            let data = response.data

                                if(data.error){
                                swal.fire("", data.error, "warning");
                                return false
                            }
                            this.resetFields()

                            swal.fire("", data.msg, "success");

                            this.wp_list_items_dialog = false
                            this.$emit('closeView', 'updateStatusSuccess')
                            this.loading.convertToCf = false
                        });
                    }
                    else{
                        this.loading.convertToCf = false
                    }
                });
            },
            clearWPListItems(){
                this.resetFields()
                this.wp_list_items_dialog = false
            },
            resetFields(){
                this.wpListItems = []
                this.wp_list = ''
                this.discounts = []
                this.itemTypeDiscounts = []
                this.itemTypeDiscountsPDF = []
                this.printed = false
                this.override_print_dialog = false
                this.stock_card = false
                this.forPartial = []
                this.crate_number.parts_crate_num = ''
            },
            getItemTypes(){
                this.$store.dispatch('getItemTypes').then(response => {
                    this.type_list = response.data
                });
            },
            renderPDF(pdfAction,type){
                var doc = new this.$jspdf('p', 'mm', 'letter');
                var margin_left = 9;
                var margin_right = 9;
                let date = this.$dayjs(this.wp_list.created_at).format('MM/DD/YYYY');
                let so = this.sales_order.terms == 0 ? 'CASH' : this.sales_order.terms + ' days'
                let customer_details_top = 30
                this.print.wpListItems.forEach((e, i) => {
                    doc.setFont('courier', 'bold').setFontSize(13).text(6,13,`${this.companyDetails.company_name}`)
                    doc.setFont('courier', 'bold').setFontSize(13).text(142,13,"Warehouse Preparation Form")
                    doc.setFont('courier', 'bold').setFontSize(9).text(142,18,"WPF# "+this.wp_list.id)
                    doc.setFont('courier', 'bold').setFontSize(9).text(142,23,"SO# "+this.wp_list.sales_order_num)
                    doc.setFont('courier', 'bold').setFontSize(9).text(142,28,"Terms: "+so)
                    doc.setFont('courier', 'bold').setFontSize(9).text(142,33,"Date: "+date)

                    doc.autoTable({
                        html: '#customer_details'+type,
                        theme: 'plain',
                        margin: { top: customer_details_top, left: margin_left, right: margin_right },
                        styles: {
                            font: 'courier',
                            cellPadding: 0,
                        },
                        columnStyles: {
                            0: { cellWidth: 23 },
                            1: { cellWidth: 130 },
                            2: { cellWidth: 16 },
                            3: { cellWidth: 25 },
                        }
                    });

                    if(this.print_no_price == false){
                        doc.autoTable({
                            html: '#items_details'+type+`${i}`,
                            startY: doc.autoTable.previous.finalY + 3,
                            margin: { left: margin_left, right: margin_right },
                            theme: 'grid',
                            headStyles: {
                                halign: 'center',
                                fillColor: '#ffffff',
                                textColor: '#000000',
                                lineWidth: .2,
                                lineColor: '#000000',
                                fontStyle: 'bold',
                                fontSize: 9,
                                font: 'courier',
                            },
                            columnStyles: {
                                0: {cellWidth: 9, halign:'center',  textColor: '#000000'},
                                1: {cellWidth: 13, halign:'center', textColor: '#000000'},
                                2: {cellWidth: 14, halign:'center', textColor: '#000000'},
                                3: {cellWidth: 14, halign:'center', textColor: '#000000'},
                                4: {cellWidth: 29, halign:'center', textColor: '#000000'},
                                5: {halign:'left', textColor: '#000000'},
                                6: {cellWidth: 25, halign:'right', textColor: '#000000'},
                                7: {cellWidth: 25, halign:'right', textColor: '#000000'}
                            },
                            bodyStyles: {
                                lineColor: '#000000',
                                font: 'courier',
                                cellPadding: 1.60,
                            }
                        });
                    }else{
                        doc.autoTable({
                            html: '#items_details_without_price'+type+`${i}`,
                            startY: doc.autoTable.previous.finalY + 1,
                            margin: { left: margin_left, right: margin_right },
                            theme: 'grid',
                            headStyles: {
                                halign: 'center',
                                fillColor: '#ffffff',
                                textColor: '#000000',
                                lineWidth: .2,
                                lineColor: '#000000',
                                fontStyle: 'bold',
                                fontSize: 9,
                                font: 'courier',
                            },
                            columnStyles: {
                                0: {cellWidth: 8, halign:'center', textColor: '#000000'},
                                1: {cellWidth: 14, halign:'center', textColor: '#000000'},
                                2: {cellWidth: 14, halign:'center', textColor: '#000000'},
                                3: {cellWidth: 16, halign:'center', textColor: '#000000'},
                                4: {cellWidth: 15, halign:'center', textColor: '#000000'},
                                5: {cellWidth: 27, halign:'center', textColor: '#000000'},
                                6: {halign:'left', textColor: '#000000'},
                                5: {cellWidth: 27, halign:'center', textColor: '#000000'},
                            },
                            bodyStyles: {
                                lineColor: '#000000',
                                font: 'courier'
                            }
                        });
                    }

                    if(this.print_no_price == false){
                        doc.autoTable({
                            html: '#total_details'+type,
                            theme: 'plain',
                            startY: doc.autoTable.previous.finalY + 3,
                            margin: {top: 37, left: 6, right: 6},
                            columnStyles: {
                                0: {cellWidth: 115, textColor: '#000000'},
                                1: {cellWidth: 35, halign:'right', textColor: '#000000'},
                                2: {cellWidth: 12, textColor: '#000000'},
                                3: {cellWidth: 35, halign:'right', textColor: '#000000'},
                            }
                        });
                    }

                    let itemFinalY = doc.lastAutoTable.finalY;
                    doc.autoTable({
                        html: '#num_of_items'+type,
                        theme: 'plain',
                        tableWidth: 100,
                        startY:  e.length == 24 ? itemFinalY + 3 : 225,
                        styles:{
                            font: 'courier',
                            fontSize: 7,
                        },
                        margin: {top: 37, left: margin_left, right: margin_right},
                        columnStyles: {
                            0: {cellWidth: 42, textColor: '#000000', cellPadding: 2},
                            1: {cellWidth: 36, textColor: '#000000'},
                            2: {cellWidth: 42, textColor: '#000000'},
                        },
                        didParseCell: function (data) {
                            // Set a fixed row height
                            let fixedRowHeight = 7; // Adjust this value as needed
                            data.cell.styles.cellPadding = { top: (fixedRowHeight - data.cell.styles.fontSize) / 2, bottom: (fixedRowHeight - data.cell.styles.fontSize) / 2 };
                        }
                    });

                    // var footer_y = doc.autoTable.previous.finalY + 1
                    // if(this.print_no_price == true){
                    //     footer_y = 235
                    // }

                    var footer_y = 235;

                    let noOfItemFinaly = doc.lastAutoTable.finalY;
                    doc.autoTable({
                        html: '#footer'+type,
                        theme: 'grid',
                        tableWidth: 106,
                        startY: e.length == 24 ? noOfItemFinaly + 2 : 235,
                        styles: {
                            font: 'courier',
                            fontSize: this.wp_list.preparer_name != null ? (Math.ceil(this.wp_list.preparer_name.length / 20) > 1 ? 6 : 7) : 7
                        },
                        margin: { left: margin_left, right: margin_right },
                        columnStyles: {
                            0: {cellWidth: 38, halign:'center', textColor: '#000000'},
                            1: {cellWidth: 38, halign:'center', textColor: '#000000'},
                        },
                        bodyStyles: {
                            lineColor: '#000000',
                        }
                    });

                    let finalY = doc.lastAutoTable.finalY; // The y position on the page
                    doc.setFont('courier', 'bold').setFontSize(7).text(190, finalY + 7, `Page ${i + 1} of ${this.print.wpListItems.length}`)
                    if(this.print.wpListItems.length != (i + 1) ){
                        doc.addPage(1)
                    }
                })

                let payload = {
                    url: 'warehousePreparationLists/print/'+this.wp_list.id
                }
                this.$store.dispatch('postWPF',payload).then(response => {
                    if (response) {
                        this.resetFields()
                        this.wp_list_items_dialog = false
                        this.$emit('closeView', 'printSuccess')
                    }
                })

                if(pdfAction == 'download'){
                    doc.save('Warehouse_Preparation_Form ' + this.wp_list.id + '.pdf');
                }else if(pdfAction == 'print'){
                    doc.autoPrint();
                    window.open(doc.output('bloburl'), '_blank');
                }
            },

            viewItems(val){
                this.loaded = false
                if(this.viewWarehousePreparationListId != null && this.viewWarehousePreparationListId > 0){
                    this.wpListItems = []
                    let payload = {
                        url: 'viewWarehousePreparationList/'+this.viewWarehousePreparationListId
                    }
                    this.$store.dispatch('getWPF').then(async response => {
                        let data = response.data

                        if(data.error){
                            swal.fire("", data.error, "warning");
                            return false
                        }

                        this.wp_list            = data.wp_list
                        this.sales_order        = data.sales_order
                        this.stock_card = data.stock_card

                        if(this.stock_card){
                            this.selectedHeaders = this.stockCardwpListItemsHeaders
                        }else{
                            this.selectedHeaders = this.wpListItemsHeaders_ops
                        }

                        this.wp_list_items_dialog = true
                        if(val != ''){
                            this.wpListItems = this.wpListItems.filter(e=>{
                                if(e.stock_level == val){
                                    return e
                                }
                            })
                        }

                        this.loaded = true
                    }).catch(error => {

                    });
                }
            },

            markAsPartial() {
                this.loaded = false
                let items = this.wpListItems.filter((e) => {
                    return e.quantity_served != 0;
                })
                swal.fire({
                    title: "",
                    text: "Are you sure you want to mark this as partial?",
                    icon: 'question',
                    showConfirmButton:true,
                    showCancelButton  :  true,
                    reverseButtons:true,
                    allowOutsideClick :  false,
                    confirmButtonColor: '#397373',
                    cancelButtonColor : 'grey',
                    confirmButtonText: 'Yes',
                    showCloseButton:true,
                }).then((result) => {
                    if (result.isConfirmed) {
                        let payload = {
                            url: 'markAsPartial/'+this.viewWarehousePreparationListId,
                            wpListItems: items,
                            warehouseId: this.warehouseId,
                        }
                        this.$store.dispatch('postWPF',payload).then(response => {
                            let data = response.data

                            if(data.error){
                                swal.fire("", data.error, "warning");
                                return false
                            }
                            this.resetFields()

                            swal.fire("", data.msg, "success");

                            this.wp_list_items_dialog = false
                            this.$emit('closeView', 'updateStatusSuccess')
                            this.loaded = true
                        });
                    }
                    else{
                        this.loaded = true
                    }
                });
            },

            revertPartial() {
                this.loaded = false
                let items = this.wpListItems.filter((e) => {
                    return e.quantity_served != 0;
                })
                if(this.forRevertChecking.length > 0) {
                    swal.fire('You cannot use revert','- Partial reservation and quantity to ship are not the same.','warning').then(result => {
                        if(result.isConfirmed) {
                            this.loaded = true
                        }
                    })
                    return false;
                }
                swal.fire({
                    title: "",
                    text: "Are you sure you want to revert this?",
                    icon: 'question',
                    showConfirmButton:true,
                    showCancelButton  :  true,
                    reverseButtons:true,
                    allowOutsideClick :  false,
                    confirmButtonColor: '#397373',
                    cancelButtonColor : 'grey',
                    confirmButtonText: 'Yes',
                    showCloseButton:true,
                }).then((result) => {
                    if (result.isConfirmed) {
                        let payload = {
                            url: 'markAsPartial/'+this.viewWarehousePreparationListId,
                            wpListItems: items,
                            warehouseId: this.warehouseId,
                        }
                        this.$store.dispatch('postWPF',payload).then(response => {
                            let data = response.data

                            if(data.error){
                                swal.fire("", data.error, "warning");
                                return false
                            }
                            this.resetFields()

                            swal.fire("", data.msg, "success");

                            this.wp_list_items_dialog = false
                            this.$emit('closeView', 'updateStatusSuccess')
                            this.loaded = true
                        });
                    }
                    else{
                        this.loaded = true
                    }
                });
            },

            closeImageUploadDialog() {
                this.imageUpload = false;
            },

            getImages(images) {
                this.images = images;
            },

            uploadedData(data) {
                if(!data) {
                    this.uploadedImageFile = null;
                }

                this.uploadedImageFile = data;
            },

            clearImageUpload() {
                this.uploadedImageFile = null;
                this.$refs.fileUpload.reset();
            },

            clearCameraCapture() {
                this.$refs.cameraCapture.reset();
                this.images = null;
            },

            storeImageUpload(wpfId) {
                if (!!wpfId) {
                    const data = new FormData();
                    const config = {
                        headers: {
                            'content-type': 'multipart/form-data'
                        }
                    }
                    if(!!this.uploadedImageFile && this.uploadedImageFile.attachments.length > 0) {
                        let files = this.uploadedImageFile.attachments;

                        for(let i=0;i < _.compact(files).length;i++) {
                            data.append('files[]', files[i]);
                        }
                    }

                    if(!!this.images && this.images.length > 0) {
                        for(let i=0;i < _.compact(this.images).length;i++) {
                            data.append('images[]', _.compact(this.images)[i]);
                        }
                    }
                    data.append('wpfId', wpfId);

                    this.$store.dispatch('storeWarehousePreparationListFile', {params:data, config: config}).then(response=>{
                        swal.fire("", response.data.message, response.data.message.toLowerCase());
                        this.$emit('successConfirm', true);
                        this.closeImageUploadDialog();
                        this.clearCameraCapture();
                        this.clearImageUpload();
                    })
                } else {
                    console.error("Invalid Warehouse Preparation List ID");
                }
            },

            fixItemInPdf(array, linesPerPage) {
                let clean_array = [];
                let temp = [];
                let linesRemaining = linesPerPage;
                let totalLines = 0;
                array.forEach((e, i) => {
                    e.index = i;
                    const nameLines = Math.ceil(e.item.name.length / 47);
                    totalLines += nameLines;
                    if (totalLines <= linesRemaining) {
                        temp.push(e);
                    } else {
                        clean_array.push(temp);
                        temp = [e];
                        totalLines = nameLines;
                    }
                    if (totalLines === linesPerPage || array.length === (i + 1)) {
                        clean_array.push(temp);
                        temp = [];
                        linesRemaining = linesPerPage;
                        totalLines = 0;
                    }
                });
                return clean_array;
            },

            savePartsCrateNumber() {
                if (!this.crate_number.parts_crate_num.trim()) {
                    swal.fire("","-Type crate number first", "warning");
                    return;
                }
                swal.fire({
                    title: `Are you sure to save crate number?`,
                    text: '-Confirm to proceed',
                    icon: 'question',
                    showConfirmButton:true,
                    showCancelButton  :  true,
                    reverseButtons:true,
                    allowOutsideClick :  false,
                    confirmButtonColor: '#397373',
                    cancelButtonColor : 'grey',
                    confirmButtonText:'Confirm',
                }).then( ans => {
                    if (ans.isConfirmed) {
                        this.crate_number.loading = true;
                        let payload = {
                            module:'WPF',
                            wpfId:this.wp_list.id,
                            crate_number: this.crate_number.parts_crate_num,
                            url:'parts-crate-number'
                        }
                        this.$store.dispatch('servicePost', payload).then( response => {
                            swal.fire(response.data.message);
                            this.crate_number.parts_crate_num = response.data.crate_number
                            this.crate_number.loading = false;
                        }).catch( e => {
                            console.log(e);
                        })
                    }
                })
            }
        },
        watch: {
            viewWarehousePreparationListId: function(val){
                if(val != -1){
                    if(this.viewWarehousePreparationListId != null && this.viewWarehousePreparationListId > 0){
                        let payload = {
                            url:    'viewWarehousePreparationList/'+this.viewWarehousePreparationListId
                        }

                        this.$store.dispatch('getWPF',payload).then( async response => {
                            let data = response.data
                            if(data.error){
                                swal.fire("", data.error, "warning");
                                return false
                            }
                            this.wp_list            = data.wp_list
                            this.sales_order        = data.sales_order
                            this.stock_card         = data.stock_card
                            this.wp_list.durations  = this.formatDateTimeDuration(this.wp_list.start_date, this.wp_list.end_date)

                            if(this.stock_card){
                                this.selectedHeaders = this.stockCardwpListItemsHeaders
                            }else{
                                if(this.wp_list.status == 6) {
                                    this.selectedHeaders = this.wpListItemsHeaders_forPartial
                                } else {
                                    this.selectedHeaders = this.wpListItemsHeaders
                                }
                            }

                            this.wpListItems        = data.wp_list.warehouse_preparation_list_items
                            this.warehouseId        = data.wp_list.warehouse_id
                            this.print.wpListItems  = this.fixItemInPdf(this.wpListItems, 24);
                            this.forPartial         = data.wp_list.warehouse_preparation_list_items.filter((e) => {
                                return e.quantity_served !== e.quantity;
                            });
                            this.forRevertChecking  = data.wp_list.warehouse_preparation_list_items.filter((e) => {
                                return e.partially_reserve != e.quantity_served
                            });
                            this.getItemTypes()
                            let user_warehosues = await this.checkWarehouseUser()

                            this.$store.dispatch('inventoryGet',{
                                url:'items-stocks-per-warehouses',
                                warehouses:user_warehosues.warehouses.map(e=>e.id),
                                items:this.wpListItems.map(e=>e.item_id)
                            }).then(response=>{
                                this.wpListItems.forEach(el=>{
                                    response.data.forEach(e=>{
                                        if(el.item_id == e.item_id){
                                            Object.assign(el,{[e.code]:e.quantity})
                                        }

                                        if( !this.selectedHeaders.find(ell=> ell.value == e.code)){
                                            this.selectedHeaders.push({text:e.code,value:e.code})
                                        }
                                    })
                                })
                            })
                            this.wp_list_items_dialog = true
                        }).catch(error => {
                            console.log(error)
                        });
                    }
                }
            },
            GET_SYSTEM: {
                handler(val){
                    if(Object.keys(val).length > 0) {
                        switch (val.code) {
                            case 'ING':
                                this.getCompanyDetails(['Sales'], 'ING').then( result => {
                                    this.companyDetails = result[0];
                                });
                                break;
                            case 'WAD':
                                this.getCompanyDetails(['Sales'], 'WAD').then( result => {
                                    this.companyDetails = result[0];
                                });
                                break;
                        }
                    }
                }
            }

        },
        computed: {
            no_of_items:function(){
                if(this.wpListItems != null && this.wpListItems.length > 0){
                    var count = 0;
                    this.wpListItems.forEach((item)=>{
                        count += this.stock_card ? item.sc_quantity : item.quantity
                    })
                    return this.thousandSeprator(count)
                }else{
                    return 0
                }
            },
            ...mapGetters([
                'GET_SYSTEM'
            ]),
        }

    };

</script>
<style>
    .v-avatar{
        margin: 15px 50px !important;
    }
    #client_modal_card{
        min-height: 160px !important;
    }
</style>
