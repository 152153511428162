<template>
<div>
    <v-app id="load_bar" v-if="!loaded">
        <v-text-field color="info" loading disabled></v-text-field>
    </v-app>
    <v-app id="view_client_modal">
        <v-dialog v-model="dispatch_dialog" persistent max-width="98%" scrollable>
            <v-card>
                <v-row class="m-0">
                    <v-col>
                        <v-card-title>
                            <span class="headline">Dispatch Details</span>
                        </v-card-title>
                    </v-col>

                    <v-col cols="pull-right-10 p-2">
                        <v-btn text icon color="gray" class="float-right" @click="clearDispatchItems(); $emit('closeView');">
                            <v-icon>mdi-close-circle</v-icon>
                        </v-btn>
                    </v-col>
                </v-row>
                <v-card-text>
                    <v-row class="m-0">
                        <v-container fluid>
                            <v-row>
                                <div class="col-sm-12 col-md-12 text-left text-dark">
                                    <v-row>
                                        <v-col sm="12" lg="2">
                                            <p><b>Dispatch ID#:</b> {{ dispatch.id }}</p>
                                            <p><b>Status: </b>{{ dispatch.status_text }}</p>
                                            <p><b>Vehicle:</b> {{ dispatch.vehicle_name }}</p>
                                            <p><b>Driver: </b>{{ dispatch.driver_name }}</p>
                                            <p><b>Dispatcher:</b> {{ user.details.is_service ? dispatch.created_name : dispatch.dispatcher_name }}</p>
                                        </v-col>
                                        <v-col sm="12" lg="2">
                                            <p><b>Forwarder</b>: {{ dispatch.forwarder_name }}</p>
                                            <p><b>Helper:</b> {{ dispatch.helper }}</p>
                                            <p><b>Helper 2: </b>{{ dispatch.helper_2 }}</p>
                                            <p><b>Helper 3:</b> {{ dispatch.helper_3 }}</p>
                                            <p v-if="user.details.is_service"><b>Guard</b> {{ dispatch.guard_name }}</p>
                                        </v-col>
                                        <v-col sm="12" lg="2">
                                            <p><b>Dispatch Date:</b> {{ dispatch.dispatch_date | formatDateTime }}</p>
                                            <p><b>ETA Date:</b> {{ dispatch.estimated_datetime  }}</p>
                                            <p><b>ATA Date:</b> {{ dispatch.actual_datetime  }}</p>
                                        </v-col>
                                        <v-col sm="12" lg="2">
                                            <p><b>Total Items CBM:</b> {{ dispatch.total_items_cbm }}</p>
                                            <p><b>Remaining CBM:</b> {{ dispatch.remaining_cbm }}</p>
                                            <p><b>Vehicle CBM:</b> {{ dispatch.vehicle_cbm }}</p>
                                        </v-col>
                                        <v-col sm="12" lg="2">
                                            <p v-if="this.USERACCOUNT_LOAD.manager == 1 || this.USERACCOUNT_LOAD.super_admin == 1 "><b>Negotiated Price:</b> {{ formatCurrencyPHP(dispatch.negotiated_price) }}</p>
                                            <p v-if="this.dispatch.status == 1 || this.USERACCOUNT_LOAD.manager == 1 && this.USERACCOUNT_LOAD.super_admin == 1"><b>Total Number of Boxes:</b> {{ dispatch.total_boxes }}</p>
                                            <!-- <p v-if="this.dispatch.total_pl_amount != 'no access' && this.dispatch.status >= 1 && this.USERACCOUNT_LOAD.manager == 1 || this.USERACCOUNT_LOAD.super_admin == 1 "><b>Total Amount:</b> {{ dispatch.total_pl_amount }}</p> -->
                                            <!-- <p dispatch.total_pl_amount><b>Total Amount:</b> {{ dispatch.total_pl_amount }}</p> -->
                                        </v-col>
                                        <v-col sm="12" lg="2" class="text-right">
                                            <v-btn @click="renderPDF('download')">Download</v-btn>
                                        </v-col>
                                    </v-row>

                                    <v-row>
                                        <v-col sm="12" lg="5">
                                            <p><b>Remarks:</b> {{ dispatch.remarks }}</p>
                                        </v-col>
                                        <v-col sm="1" lg="1" class="d-flex flex-column align-end justify-start">
                                            <v-btn
                                            text
                                            icon
                                            small
                                            @click="updateAdditionalRemarks()"
                                            >
                                            <v-icon small>mdi-pencil</v-icon>
                                            </v-btn>
                                            <v-btn
                                            v-if="update_additional_remarks"
                                            text
                                            icon
                                            small
                                            @click="saveAdditionalRemarks()"
                                            >
                                            <v-icon small>mdi-content-save</v-icon>
                                            </v-btn>
                                        </v-col>
                                        <v-col sm="12" lg="5">
                                            <div>
                                                <div v-if="update_additional_remarks">
                                                    <v-textarea
                                                    width="100%"
                                                    v-model="updated_additional_remarks"
                                                    outlined
                                                    rows="4"
                                                    label="Additional Remarks:"
                                                    dense
                                                    ></v-textarea>
                                                </div>
                                                <div v-else>
                                                    <p><b>Additional Remarks:</b> {{ dispatch.additional_remarks }}</p>
                                                </div>
                                            </div>
                                        </v-col>
                                    </v-row>

                                </div>

                            </v-row>
                        </v-container>
                    </v-row>
                    <v-row>
                        <v-expansion-panels v-model="panel" multiple>
                            <v-expansion-panel
                              v-for="(item,i) in dispatchItems"
                              :key="i"
                            >
                                <v-expansion-panel-header class="d-flex justify-content-around">
                                    <v-row>
                                        <v-col lg="4">
                                            <h4>{{ i }}</h4>
                                        </v-col>
                                        <v-spacer></v-spacer>
                                    </v-row>
                                </v-expansion-panel-header>
                                <v-expansion-panel-content>
                                    <v-data-table
                                        :headers="dispatchItemsHeaders"
                                        :items="reStructureTableData(item.items)"
                                        hide-default-footer
                                    >
                                        <template v-slot:[`item.sc_nums`]="{ item }">
                                            <span v-if="item.sc_nums">
                                                <v-chip class="mx-1" :color="item.service == 1 ? 'warning': 'primary'">
                                                {{ item.sc_nums }}</v-chip>
                                            </span>
                                        </template>
                                        <template v-slot:[`item.scd_nums`]="{ item }">
                                            <span v-if="item.scd_nums">
                                                <v-chip class="mx-1" :color="item.service == 1 ? 'warning': 'primary'">
                                                {{ item.scd_nums }}</v-chip>
                                            </span>
                                        </template>
                                        <template v-slot:[`item.pl_nums`]="{ item }">
                                            <span v-if="item.pl_nums">
                                                <v-chip class="mx-1" :color="item.service == 1 ? 'warning': 'primary'">
                                                    {{ item.pl_nums }}</v-chip>
                                                </v-chip>
                                            </span>
                                        </template>
                                        <template v-slot:[`item.cf_nums`]="{ item }">
                                            <span v-if="item.cf_nums">
                                                <v-chip class="mx-1" :color="item.service == 1 ? 'warning': 'primary'">
                                                    {{ item.cf_nums }}
                                                </v-chip>
                                            </span>
                                        </template>
                                        <template v-slot:[`item.so_nums`]="{ item }">
                                            <span v-if="item.so_nums">
                                                <v-chip class="mx-1" :color="item.service == 1 ? 'warning': 'primary'">
                                                    {{ item.so_nums }}
                                                </v-chip>
                                            </span>
                                        </template>
                                        <template v-slot:[`item.quantity`]="{ item }">
                                            {{
                                                !item.pl_boxes_per_item
                                                    ? !item.sc_boxes_per_item
                                                        ? !item.sd_boxes_per_item
                                                            ? 'N/A'
                                                            : item.sd_boxes_per_item
                                                        : item.sc_boxes_per_item
                                                    : item.pl_boxes_per_item
                                            }}
                                        </template>
                                    </v-data-table>
                                </v-expansion-panel-content>
                            </v-expansion-panel>
                          </v-expansion-panels>
                    </v-row>

                    <v-row class="m-0">
                        <v-container fluid>
                            <v-row>
                                <div class="col-sm-12 col-md-8 text-left">

                                    <div class="card-body py-4 d-flex">
                                        <span class="m-1"><v-btn @click="renderPDF('print'); ">Print</v-btn></span>
                                    </div>
                                </div>
                            </v-row>
                        </v-container>
                    </v-row>

                    <div class="border-top">
                        <div class="card-body py-4 d-flex justify-content-center">
                            <span class="m-1"><v-btn @click="updateDispatchStatus(1)" v-if="userAccess.confirm && tab_name != 0 && dispatch.status_text=='dispatched'" :loading="loading.button">Confirm</v-btn></span>
                            <span class="m-1"><v-btn color="orange" @click="updateDispatchStatus(2)" v-if="userAccess.confirm && tab_name == 0 " :loading="loading.button">Dispatch</v-btn></span>
                            <!-- <span class="m-1"><v-btn @click="updateDispatchStatus(1)" v-if="dispatch.status_text=='saved'">Confirm</v-btn></span> -->
                            <!--<span class="m-1"><v-btn @click="rejectDispatch()" v-if="dispatch.status_text=='pending'" color="error">Reject</v-btn></span>-->
                        </div>
                    </div>
                    <v-container fluid style="display:none;" >
                        <!-- <v-container fluid > -->
                            <div id="div_logo" >
                                <div style="font-size: 12px; line-height: 1.9">
                                    <p><span style="font-size: 18px; font-weight: bold;">WADFOW Tools Philippines Corp.</span>
                                    </p>
                                </div>
                            </div>
                            <div id="div_dispatch_report" style="font-size: 12px; line-height: 1.9">
                                <div style="border-top: 1px dashed #CFCFCF; padding: 2px; padding-bottom: -4px; text-align: center; border-bottom: none;">&nbsp;</div>
                                <p><span style="font-size: 18px; font-weight: bold;"> DISPATCH REPORT</span></p>
                            </div>
                            <div>
                                <table id="dispatch_details">
                                    <tbody>
                                        <tr>
                                            <td v-if="!user.details.is_service">Vehicle:</td>
                                            <td v-if="!user.details.is_service">{{ dispatch.vehicle_name }}</td>
                                            <td>Dispatch ID#</td>
                                            <td>{{ dispatch.id }}</td>
                                        </tr>
                                        <tr>
                                            <td>Remarks:</td>
                                            <td>{{ dispatch.remarks }}</td>
                                            <td>Dispatch Date:</td>
                                            <td>{{ dispatch.dispatch_date | formatDate }}</td>
                                        </tr>
                                        <tr v-if="dispatch.vehicle_tpl == 1">
                                            <td>Forwarder:</td>
                                            <td>{{ dispatch.forwarder_name }}</td>

                                            <td>ETA Date:</td>
                                            <td>{{ dispatch.estimated_datetime | formatDate }}</td>
                                        </tr>
                                        <tr v-if="dispatch.vehicle_tpl == 1">
                                            <td>ATA Date:</td>
                                            <td>{{ dispatch.actual_datetime | formatDate }}</td>
                                            <td>Negotiated Price::</td>
                                            <td>{{ formatCurrencyPHP(dispatch.negotiated_price) }}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div  v-for="(key, index ) in  Object.keys(this.dispatchItems)" :key="index">
                                <table :id="`items_details${index}`">
                                    <thead>
                                        <tr>
                                            <th v-if="user.details.is_service">Spare Parts Packing Lists ({{getDocCount(dispatchItems[key].items,key,'pl_nums')}})</th>
                                            <th v-else>Packing Lists ({{getDocCount(dispatchItems[key].items,key,'pl_nums')}})</th>
                                            <th v-if="!user.details.is_service">Checker Form ({{getDocCount(dispatchItems[key].items,key,'cf_nums')}})</th>
                                            <th v-if="!user.details.is_service">Stock Cards ({{getDocCount(dispatchItems[key].items,key,'sc_nums')}})</th>
                                            <th v-if="!user.details.is_service">Sales Order ({{getDocCount(dispatchItems[key].items,key,'so_nums')}})</th>
                                            <th> {{user.details.is_service ? 'Service Delivery Receipts ' : 'DR#'}}({{getDocCount(dispatchItems[key].items,key,'scd_nums')}})</th>
                                            <th> No. of Boxes</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <!-- <tr>
                                            <td>{{combineObjects(dispatchItems[key].items).pl_nums ? combineObjects(dispatchItems[key].items).pl_nums.replace(']','').replace('[','') : ''}}</td>
                                            <td v-if="!user.details.is_service">{{combineObjects(dispatchItems[key].items).sc_nums ? combineObjects(dispatchItems[key].items).sc_nums.replace(']','').replace('[','') : ''}}</td>
                                            <td >{{combineObjects(dispatchItems[key].items).scd_nums ? combineObjects(dispatchItems[key].items).scd_nums.replace(']','').replace('[','') : ''}}</td>
                                            <td>{{dispatchItems[key]}}</td>
                                        </tr> -->
                                        <tr v-for="(item,index) in reStructureTableData(dispatchItems[key].items)" :key="index">
                                            <td>{{item.pl_nums ? item.pl_nums : ''}}<span>({{ item.item_type ?? '' }})</span></td>
                                            <td v-if="!user.details.is_service">{{item.cf_nums ? item.cf_nums : ''}}</td>
                                            <td v-if="!user.details.is_service">{{item.sc_nums ? item.sc_nums : ''}}</td>
                                            <td v-if="!user.details.is_service">{{item.so_nums ? item.so_nums : ''}}</td>
                                            <td>{{item.scd_nums ? item.scd_nums : ''}}</td>
                                            <td>
                                                {{
                                                    !item.pl_boxes_per_item
                                                        ? !item.sc_boxes_per_item
                                                            ? !item.sd_boxes_per_item
                                                                ? 'N/A'
                                                                : item.sd_boxes_per_item
                                                            : item.sc_boxes_per_item
                                                        : item.pl_boxes_per_item
                                                }}
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>

                            <div id="div_total_boxes" >
                                <p>Total Boxes: {{ this.dispatch.total_boxes }}</p>
                                <p>Total PLs: {{ this.dispatch.total_pls }}</p>
                            </div>
                            <div>
                                <table id="footer">
                                    <tbody>
                                        <tr>
                                            <td>Dispatched By</td>
                                            <td>Driver</td>
                                            <td>Noted By</td>
                                        </tr>
                                        <tr>
                                            <td><br><br>{{ this.dispatch.dispatcher_name }}</td>
                                            <td><br><br>{{ this.dispatch.driver_name }}</td>
                                            <td><br><br>Logistics Supervisor</td>
                                        </tr>
                                        <tr>
                                            <td colspan="3">Helpers</td>
                                        </tr>
                                        <tr>
                                            <td><br><br>{{ this.dispatch.helper }}</td>
                                            <td><br><br>{{ this.dispatch.helper_2 }}</td>
                                            <td><br><br>{{ this.dispatch.helper_3 }}</td>
                                        </tr>
                                    </tbody>
                                </table>
                                <table id="dr_footer">
                                    <tbody>
                                        <tr>
                                            <td>Vehicle:</td>
                                            <td>{{ dispatch.vehicle_name }}</td>
                                            <td>Guard:</td>
                                            <td>{{ dispatch.guard_name }}</td>
                                        </tr>
                                        <tr>
                                            <td>Driver Name:</td>
                                            <td>{{ dispatch.driver_name }}</td>
                                            <td>Dispatch By:</td>
                                            <td>{{ dispatch.created_name }}</td>
                                        </tr>
                                        <tr>
                                            <td>Helper Name:</td>
                                            <td>{{ dispatch.helper }} / {{dispatch.helper_2}} / {{dispatch.helper_3}}</td>
                                            <td>Received by:</td>
                                            <td></td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </v-container>
                </v-card-text>

            </v-card>
        </v-dialog>


    </v-app>
</div>
</template>
<script>
import { mapGetters } from 'vuex';
import swal from 'sweetalert2';
import SharedFunctionsComponentVue from '@/views/main/Shared/SharedFunctionsComponent.vue'
    export default {
        mixins: [ SharedFunctionsComponentVue ],
        props: [
            'viewDispatchId',
            'tab_name'
        ],
        mounted(){
            // this.checkAccess('OP', 'dispatches', 'confirm')
            this.checkAccess();
            this.getCurrentUser();
        },
        data() {
            return {
                dispatchItems: [],
                dispatchItemsHeaders: [
                    { text: 'PL#',width:'22%', align: 'left', value: 'pl_nums', },
                    { text: 'SC#',width:'22%', align: 'left', value: 'sc_nums',},
                    { text: 'CF#',width:'22%', align: 'left', value: 'cf_nums',},
                    { text: 'SO#',width:'22%', align: 'left', value: 'so_nums',},
                    { text: 'DR#',width:'22%', align: 'left', value: 'scd_nums', },
                    { text: 'No. Of Boxes',width:'5%', align: 'left', value: 'quantity', },
                ],
                panel: [],
                loaded: true,
                dispatch_dialog: false,
                dispatch: {
                    id: '',
                    vehicle_name: '',
                    driver_name: '',
                    helper: '',
                    helper_2: '',
                    helper_3: '',
                    dispatcher_name: '',
                    dispatch_date: '',
                    confirm_date: '',
                    status_text: '',
                    guard_name:''
                },
                dispatchItems: [],

                errors: [],
                userAccess: {
                    confirm: false,
                },
                update_additional_remarks: false,
                updated_additional_remarks: '',
                total_amount_visible: false,
                loading:{
                    button:false
                },
                exports:{
                    dispatch:{},
                    dispatch_items:[],
                    keys:[]
                },
                user:{
                    details:{
                        is_service:false
                    }
                }
            }
        },
        computed:{
            ...mapGetters([
                'GET_DISPATCHES',
                'USERACCOUNT_LOAD'
            ])
        },
        methods: {
            reStructureTableData(items) {
                return items.flatMap((item, i) => {
                    const pl_nums = JSON.parse(item.pl_nums) || [];
                    const sc_nums = JSON.parse(item.sc_nums) || [];
                    const cf_nums = JSON.parse(item.cf_nums) || [];
                    const so_nums = JSON.parse(item.so_nums) || [];
                    const scd_nums = JSON.parse(item.scd_nums) || [];
                    const item_type = item.item_type ?? '';

                    const pl_boxes_per_item = JSON.parse(item.pl_boxes_per_item) || [];
                    const sc_boxes_per_item = JSON.parse(item.sc_boxes_per_item) || [];
                    const sd_boxes_per_item = JSON.parse(item.sd_boxes_per_item) || [];

                    const maxLength = Math.max(
                        pl_nums.length,
                        sc_nums.length,
                        cf_nums.length,
                        so_nums.length,
                        scd_nums.length,
                    );

                    const result = [];

                    for (let index = 0; index < maxLength; index++) {
                        result.push({
                            pl_nums: pl_nums[index] || null,
                            sc_nums: sc_nums[index] || null,
                            cf_nums: cf_nums[index] || null,
                            so_nums: so_nums[index] || null,
                            scd_nums: scd_nums[index] || null,
                            item_type: item_type ?? '',
                            pl_boxes_per_item: pl_boxes_per_item[index] || null,
                            sc_boxes_per_item: sc_boxes_per_item[index] || null,
                            sd_boxes_per_item: sd_boxes_per_item[index] || null,
                            quantity: item.quantity,
                            company_name: item.company_name,
                            service: item.service,
                            item_id: item.id,
                            id: index + (maxLength * i),
                        });
                    }

                    return result;
                });
            },
            checkAccess(){
                this.$store.dispatch('checkAccessUser', this.$router.currentRoute.params.id).then(response=>{
                    let val = response;
                    this.userAccess.confirm = false;
                    if(val != "fail"){
                    val.forEach((e) => {
                        if (e.actions_code == "confirm"){
                            this.userAccess.confirm = true;
                        }
                    });
                    }
                })
            },
            checkNumOfBoxes(){
                var no_empty_box = true
                let keys = Object.keys(this.dispatchItems);
                keys.forEach(e=>{
                    this.dispatchItems[e].items.forEach(el=>{
                        if([null,'',0].includes(el)){
                            no_empty_box = false
                        }
                    })
                })

                if(!no_empty_box){
                    swal.fire("", 'No. of Box cannot be empty', "warning");
                }

                return no_empty_box
            },
            updateDispatchStatus(newStatus){
                if(this.checkNumOfBoxes()){
                    this.loading.button = true
                    this.errors = []
                    swal.fire({
                        text: 'Are you sure you want to update Dispatch?',
                        icon: 'question',
                        showConfirmButton:true,
                        showCancelButton  :  true,
                        reverseButtons:true,
                        allowOutsideClick :  false,
                        confirmButtonColor: '#397373',
                        cancelButtonColor : 'grey',
                        confirmButtonText:'Confirm',
                    }).then((result)=>{
                        if(result.isConfirmed) {
                            let keys = Object.keys(this.dispatchItems)
                            let items = []
                            keys.forEach(e=>{
                                items = items.concat(this.dispatchItems[e].items)
                            })

                            let payload = {
                                id: this.viewDispatchId,
                                status: newStatus,
                                items:items
                            }

                            this.$store.dispatch('updateDispatchStatus',payload).then(response => {
                                let data = response.data
                                if(data.error){
                                    swal.fire("", data.error, "warning");
                                    this.loading.button = false
                                    return false
                                }
                                this.resetFields()
                                this.loading.button = false
                                swal.fire("", data.msg, "success");
                                this.dispatch_dialog = false
                                this.$emit('closeView', 'updateStatusSuccess')
                                this.loading.button = false
                            });
                        }else{
                            this.loading.button = false
                        }
                    });
                }else{
                    this.loading.button = false
                    return false
                }
            },
            clearDispatchItems(){
                this.resetFields()
                this.dispatch_dialog = false
                this.update_additional_remarks = false
            },
            // renderPDF(pdfAction,filename = ''){
            //     var doc = new this.$jspdf('p', 'mm', 'letter');

            //     doc.text(6, 10, 'INGCOPH Traders INC').setFontSize(12).setFont(undefined, 'bold');
            //     doc.text(130, 15, 'WAREHOUSE DISPATCH REPORT');

            //     doc.autoTable({
            //         html: '#dispatch_details',
            //         theme: 'plain',
            //         margin: {top: 20, left: 6, right: 6},
            //         columnStyles: {
            //             0: {cellWidth: 20},
            //             1: {cellWidth: 130},
            //             2: {cellWidth: 26},
            //             3: {cellWidth: 26},
            //         },
            //     });

            //     // doc.autoTable({
            //     //     html: '#items_details',
            //     //     startY: doc.autoTablel.previous.finalY + 3,
            //     //     margin: {left: 6, right: 6},
            //     //     theme: 'grid',
            //     //     headStyles: {
            //     //         halign:'center',
            //     //         fillColor: '#ffffff',
            //     //         textColor: '#000000',
            //     //         lineWidth: .2,
            //     //         lineColor: '#000000',
            //     //     },
            //     //     styles: {
            //     //         fontSize: 8
            //     //     },
            //     //     columnStyles: {
            //     //         0: {halign:'center',  textColor: '#000000'},
            //     //         1: {cellWidth: 50, halign:'center', textColor: '#000000'},
            //     //         2: {cellWidth: 20, halign:'center',  textColor: '#000000'},
            //     //     },
            //     //     bodyStyles: {
            //     //         lineColor: '#000000',
            //     //     }
            //     // });

            //     // let footerY = 210;
            //     // let totalPls = 226;
            //     // let totalBoxes = 218;

            //     // if(doc.autoTablel.previous.finalY > 200){
            //     //    footerY = doc.autoTablel.previous.finalY + 50;
            //     //    totalPls = doc.autoTablel.previous.finalY + 20;
            //     //    totalBoxes = doc.autoTablel.previous.finalY + 15;
            //     // }

            //     // doc.text(160,totalBoxes,"Total Boxes: "+this.dispatch.total_boxes);
            //     // doc.text(160,totalPls,"Total PLs: "+this.dispatch.total_pls);

            //     doc.autoTable({
            //         html: '#footer',
            //         theme: 'grid',
            //         tableWidth: 105,
            //         startY: 115,
            //         margin: {left: 6, right: 6},
            //         columnStyles: {
            //             0: {cellWidth: 35, halign:'center', textColor: '#000000'},
            //             1: {cellWidth: 35, halign:'center', textColor: '#000000'},
            //             2: {cellWidth: 35, halign:'center', textColor: '#000000'},
            //         },
            //         bodyStyles: {
            //             lineColor: '#000000',
            //         }
            //     });

            //     if(pdfAction == 'download'){
            //         doc.save(filename +' (Dispatch ' + this.dispatch.id + ').pdf');
            //     }else if(pdfAction == 'print'){
            //         doc.autoPrint();
            //         window.open(doc.output('bloburl'), '_blank');
            //     }
            // },
            resetFields(){
                this.dispatchItems = []
                this.updated_additional_remarks = ''
                this.dispatch    = {
                    srf_num: '',
                    source_id: '',
                    destination_id: '',
                    remarks: '',
                    source_name: '',
                    destination_name: '',
                    user_name: '',
                    created_at: '',
                    status: '',
                    status_text: '',
                }
            },
            updateAdditionalRemarks(){
                this.update_additional_remarks = !this.update_additional_remarks
                this.updated_additional_remarks = this.dispatch.additional_remarks
            },
            saveAdditionalRemarks(){

                swal.fire({
                    title: '',
                    text: 'Are you sure do you want to update Additional Remarks?',
                    icon: 'question',
                    showConfirmButton: true,
                    showCancelButton: true,
                    reverseButtons: true,
                    allowOutsideClick: false,
                    confirmButtonColor: '#397373',
                    cancelButtonColor: 'grey',
                    confirmButtonText: 'Confirm',

                }).then((result) => {
                    if (result.isConfirmed) {
                        let payload = {
                            update_additional_remarks: this.update_additional_remarks,
                            additional_remarks: this.updated_additional_remarks,
                            id: this.viewDispatchId,
                        }
                        this.$store.dispatch('updateDispatchInfo', payload).then(response => {
                           this.update_additional_remarks = false
                                this.$store.dispatch('getDispatch',this.viewDispatchId).then(response => {
                                let data = this.GET_DISPATCHES
                                this.dispatch                        = data.dispatch
                                this.dispatchItems                   = data.dispatch.dispatch_items
                                }).catch(error => {

                                });
                        })
                    }
                })
            },
            async renderPDF(pdfAction){
                let keys = Object.keys(this.dispatchItems)
                let total = 0
                var doc = new this.$jspdf('p', 'mm', 'letter');

                doc.text(6, 10, 'INGCOPH Traders INC').setFontSize(12).setFont(undefined, 'bold');
                doc.text(130, 15, this.user.details.is_service ? 'SERVICE DISPATCH REPORT': 'WAREHOUSE DISPATCH REPORT');
                doc.autoTable({
                    html: '#dispatch_details',
                    theme: 'plain',
                    margin: {top: 20, left: 6, right: 6},
                    columnStyles: {
                        0: {cellWidth: 20},
                        1: {cellWidth: 130},
                        2: {cellWidth: 26},
                        3: {cellWidth: 26},
                    },
                });

                this.dispatch.total_boxes = _.sumBy(this.exports.dispatch_items,'quantity')
                this.exports.keys = keys

                this.$forceUpdate()
                    keys.forEach((e,i)=>{
                        doc.setFont(undefined, 'bold');
                        doc.text(8, doc.autoTable.previous.finalY+7, e).setFontSize(12)
                        doc.setFontSize(8).setFont(undefined, 'normal')
                        doc.text(8, doc.autoTable.previous.finalY+11, `Total Num. of boxes: ${this.dispatchItems[e].total_quantity.toString()}`);
                        doc.autoTable({
                            html: `#items_details${i}`,
                            startY: doc.autoTable.previous.finalY + 14,
                            margin: {left: 6, right: 6},
                            theme: 'grid',
                            headStyles: {
                                halign:'center',
                                fillColor: '#ffffff',
                                textColor: '#000000',
                                lineWidth: .2,
                                lineColor: '#000000',
                            },
                            styles: {
                                fontSize: 10
                            },
                            columnStyles: {
                                0: {cellWidth: 38,halign:'center',  textColor: '#000000'},
                                1: {cellWidth: this.user.details.is_service ? 100 : 30, halign:'center', textColor: '#000000'},
                                2: {cellWidth: 38, halign:'center', textColor: '#000000'},
                                3: {cellWidth: 38, halign:'center', textColor: '#000000'},
                                4: {cellWidth: 38, halign:'center',  textColor: '#000000'},

                                5: {cellWidth: 20, halign:'center',  textColor: '#000000'},
                            },
                            bodyStyles: {
                                lineColor: '#000000',
                                halign:'center',
                            }
                        });

                        total += _.sumBy(this.dispatchItems[e].items,'quantity')
                    })

                    let footerY = 210;

                    if(doc.autoTable.previous.finalY > 200){
                        footerY = doc.autoTable.previous.finalY + 50;
                        // totalPls = doc.autoTable.previous.finalY + 20;
                        // totalBoxes = doc.autoTable.previous.finalY + 15;
                    }

                    if(this.user.details.is_service){
                        doc.autoTable({
                            html: '#dr_footer',
                            theme: 'grid',
                            startY: 244,
                            margin: {top: 37, left: 7, right: 6},
                            columnStyles: {
                                0: {cellWidth: 23, halign:'left', textColor: '#000000', fontSize: 8},
                                1: {cellWidth: 76, halign:'left', textColor: '#000000', fontSize: 8},
                                2: {cellWidth: 23, halign:'left', textColor: '#000000', fontSize: 8},
                                3: {cellWidth: 76, halign:'left', textColor: '#000000', fontSize: 8},
                            }
                        });
                    }else{
                        doc.autoTable({
                        html:  '#footer',
                        theme: 'grid',
                        tableWidth: 105,
                        startY: footerY,
                        margin: {left: 6, right: 6},
                        columnStyles: {
                            0: {cellWidth: 35, halign:'center', textColor: '#000000'},
                            1: {cellWidth: 35, halign:'center', textColor: '#000000'},
                            2: {cellWidth: 35, halign:'center', textColor: '#000000'},
                        },
                        bodyStyles: {
                            lineColor: '#000000',
                        }
                    });
                    }


                doc.text(160,218,"Total Boxes: "+ total);
                // setTimeout(()=>{
                //     doc.save(`Dispatch #${this.dispatch.id}.pdf`);
                // },3000)
                if(pdfAction == 'download'){
                    doc.save(`Dispatch #${this.dispatch.id}.pdf`);
                }else if(pdfAction == 'print'){
                    doc.autoPrint();
                    window.open(doc.output('bloburl'), '_blank');
                }
            },
            async getCurrentUser(){
                await this.$store.dispatch('currentUser',{
                    url:'current-user'
                }).then(response=>{
                    this.user.details = response
                    if(this.user.details.is_service == 1){
                        this.dispatchItemsHeaders = this.dispatchItemsHeaders.filter((e,i)=> i!= 1)
                    }
                })
            },
            getDocCount(item,customer,key){
                let count = 0;
                item.forEach(e=>{
                    if(!!JSON.parse(e[key])){
                        count += JSON.parse(e[key]).length
                    }
                })
                return count
            }
        },
        watch: {
            viewDispatchId: function(val){
                this.errors = []
                if(val != -1){
                    if(this.viewDispatchId != null && this.viewDispatchId > 0){
                        this.$store.dispatch('getDispatch',this.viewDispatchId).then(response => {
                            let data = response.data
                            this.dispatch                        = data.dispatch

                            this.dispatchItems = this.divideArrayByAttribute(data.dispatch_items,'company_name','object')
                            let keys = Object.keys(this.dispatchItems)
                            let object = {}

                            keys.forEach((e,i)=>{
                                this.panel.push(i)
                                let totals = {}
                                Object.assign(object,{
                                    [e]:{
                                        items:this.dispatchItems[e].filter(e=>{

                                            if(!!e.pl_nums){
                                                totals.pl_quantity = `(${e.quantity})`
                                            }

                                            if(!!e.sc_nums){
                                                totals.stock_card_quantity = `(${e.quantity})`
                                            }

                                            if(!!e.scd_nums){
                                                totals.sc_delivery_quantity = `(${e.quantity})`
                                            }

                                            if(this.user.details.is_service == 1){
                                                if(e.service == 1){
                                                    return e
                                                }
                                            }else{
                                                return e
                                            }
                                        }),
                                        totals:totals
                                    }
                                })

                                object[e].total_quantity = _.sumBy(object[e].items,'quantity')
                            })

                            this.dispatchItems = object
                            this.dispatch_dialog  = true
                            this.$forceUpdate()
                        }).catch(error => {
                            console.log(error)
                        });
                    }
                }
            },
        },
    };

</script>
<style scoped>
    .v-avatar{
        margin: 15px 50px !important;
    }
    #client_modal_card{
        min-height: 160px !important;
    }
    .v-application p {
        margin-bottom: 2px !important;
    }
</style>
