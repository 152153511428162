<template>
    <v-container>
        <v-dialog v-model="deliveryReceiptDetailsDialog" persistent max-width="98%" scrollable>
        <v-card class="pa-4">
            <v-row class="m-0">
                <v-col>
                    <v-card-title>
                        <span class="headline">Deliver Receipt Details</span>
                    </v-card-title>
                </v-col>
                <v-col cols="pull-right-10 p-2">
                    <v-btn
                        text
                        icon
                        color="gray"
                        class="float-right"
                        @click="closeDeliveryReceiptDetailsDialog()"
                    >
                    <v-icon>mdi-close-circle</v-icon>
                    </v-btn>
                </v-col>
            </v-row>

            <v-divider class="mt-0" />

            <v-container fluid style="background-color: white; overflow-y: scroll" class="ma-0 pa-0">
                <v-col>
                    <v-card-text v-if="loaded" class="text-center pa-10">
                        <v-progress-circular
                            :size="50"
                            color="amber"
                            indeterminate
                        ></v-progress-circular>
                    </v-card-text>
                    <v-card-text  v-else>
                        <v-layout row class="px-4 pb-4">
                            <v-flex lg3>
                                <div>
                                    DR#: <span>{{ drNumber }}</span>
                                </div>
                                <!-- <div>
                                    RD#: <span>{{ rrNumber }}</span>
                                </div> -->
                                <div>
                                    Customer: <span>{{ customer }}</span>
                                </div>

                                <div v-if="!!customerContactNumber">
                                    Contact Number: <span>{{ customerContactNumber }}</span>
                                </div>

                                <div v-if="!!scCustomerName">
                                    Name: <span>{{ scCustomerName }}</span>
                                </div>
                                <div v-if="!!scCustomerContactNumber">
                                    Contact Number: <span>{{ scCustomerContactNumber }}</span>
                                </div>
                                <div v-if="!!scCustomerEmail">
                                    Email: <span>{{ scCustomerEmail }}</span>
                                </div>
                                <div v-if="!!scCustomerAddress">
                                    Address: <span>{{ scCustomerAddress }}</span>
                                </div>

                                <div v-if="!!scLocationName">
                                    Name: <span>{{ scLocationName }}</span>
                                </div>
                                <div v-if="!!scLocationContactNumber">
                                    Contact Number: <span>{{ scLocationContactNumber }}</span>
                                </div>
                                <div v-if="!!scLocationAddress">
                                    Address: <span>{{ scLocationAddress }}</span>
                                </div>
                            </v-flex>
                            <v-flex lg3>
                                <div>
                                    Date: <span>{{ createdAt }}</span>
                                </div>
                                <div>
                                    Processed By: <span>{{ processedBy }}</span>
                                </div>
                            </v-flex>
                            <v-flex lg3>
                                <div>
                                    Status: <span>{{ status }}</span>
                                </div>
                                <div>
                                    Remarks: <span>{{ remarks }}</span>
                                </div>
                                <div>
                                    Number of boxes: <span>{{ number_of_boxes }}</span>
                                </div>
                            </v-flex>
                            <v-flex lg3>
                                <div>
                                    Vehicle: <span>{{ vehicleName }}</span>
                                </div>
                                <div>
                                    Plate Number: <span>{{ vehiclePlateNumber }}</span>
                                </div>
                                <div>
                                    Driver Name: <span>{{ driverName }}</span>
                                </div>
                                <div>
                                    Helper Name: <span>{{ helperName }}</span>
                                </div>
                                <div>
                                    Guard Name: <span>{{ guardName }}</span>
                                </div>
                            </v-flex>
                        </v-layout>

                        <v-card class="pa-4">
                            <v-data-table
                                :headers="headers"
                                :items="repairForms"
                            >
                                <template v-slot:item.serial="{ item }">
                                    <td class="px-0">
                                        {{ itemSerial(item) }}
                                        {{ item.sc_repair_form.sc_repair_form_item.repair_status }}
                                    </td>
                                </template>
                                <template v-slot:item.action="{ item }">
                                    <td v-if="drStatus == 'pending'" class="px-0">
                                        <span class="text-nonwrap">
                                            <v-btn text icon color="red">
                                                <v-icon class="btn-action" small @click="deleteDrRf(item)">mdi-delete</v-icon>
                                            </v-btn>
                                        </span>
                                    </td>
                                </template>
                            </v-data-table>
                        </v-card>

                        <div class="px-4 py-4">
                            <v-layout row v-if="drStatus == 'pending' || drStatus == 'defective'" class="mt-1">
                                <v-flex>
                                    <div style="text-align:center">
                                        <v-btn :disabled="false" @click="printDr()">Print Delivery Receipt</v-btn>
                                    </div>
                                </v-flex>
                                <!-- <v-flex lg6>
                                    <div style="text-align:center">
                                        <v-btn :disabled="!printedDr" @click="deliverItems()">Deliver Now</v-btn>
                                    </div>
                                </v-flex> -->
                            </v-layout>

                            <v-layout row v-if="drStatus == 'in-transit' || drStatus == 'def-in-transit'" class="mt-1">
                                <v-flex lg12>
                                    <div style="text-align:center">
                                        <v-btn @click="confirmReceivedDelivery()" class="mr-3">Confirm</v-btn>
                                        <v-btn @click="confirmedOverridePrint()">Re-print Delivery Receipt</v-btn>
                                    </div>
                                </v-flex>
                            </v-layout>
                        </div>
                    </v-card-text>
                </v-col>
            </v-container>
        </v-card>

        <!-- For Delivery Receipt Print -->
        <v-container fluid style="display:none">
            <div id="dr_logo" >
                <div style="font-size: 12px; line-height: 1.9">
                    <p>
                        <span style="font-size: 18px; font-weight: bold;">
                            WADFOW Tools Philippines Corp.
                        </span>
                        <br>2 D Arellano St. Bagong Barrio, Caloocan City
                        <br>Cell No.:
                        <br>Tel. No.:
                        <br>Service Mobile / Viber No.: +63 917 625 2286
                        <br>Spare Parts Mobile / Viber No.:
                    </p>
                </div>
            </div>
            <div id="dr_header" style="font-size:16px;font-weight:bold">
                DELIVERY RECEIPT
            </div>
             <div id="dr_number" style="font-size:12px">
                DR#: <span>{{ drNumber }}</span>
            </div>
            <div>
                <table id="customer_info">
                    <tbody>
                        <tr>
                            <td>Deliver to:</td>
                            <td>{{ printCustomerName() }}</td>
                            <td>Date:</td>
                            <td>{{ dateToday }}</td>
                        </tr>
                        <tr>
                            <td>Address:</td>
                            <td colspan="3">{{ printCustomerAddress() }}</td>
                        </tr>
                        <tr>
                            <td>Contact #:</td>
                            <td>{{ printCustomerContact() }}</td>
                            <td>Email:</td>
                            <td>{{ scCustomerEmail }}</td>
                        </tr>
                    </tbody>
                </table>
            </div>

            <div>
                <table id="dr_items">
                    <tbody>
                        <tr>
                            <td>RF#</td>
                            <td>Repair Status</td>
                            <td>Model</td>
                            <td>Name</td>
                            <td>Category</td>
                            <td>Serial No.</td>
                            <td>PL No.</td>
                        </tr>
                        <tr v-for="rf in repairForms" :key="rf.id">
                            <td>{{ rf.sc_repair_form.rf_number  || '' }}</td>
                            <td>{{ rf.sc_repair_form.sc_repair_form_item.repair_status}}</td>
                            <td>{{ rf.sc_repair_form.sc_repair_form_item.model || '' }}</td>
                            <td>{{ rf.sc_repair_form.sc_repair_form_item.product_name  || '' }}</td>
                            <td>{{ rf.sc_repair_form.sc_repair_form_item.category  || '' }}</td>
                            <td>{{ !!rf.sc_repair_form.replacement_repair_form_id ? rf.sc_repair_form.sc_repair_form_item.replacement_serial_number : rf.sc_repair_form.sc_repair_form_item.serial  || '' }}</td>
                            <td>{{ rf.sc_repair_form.packing_list_num  || '-' }}</td>
                        </tr>
                        <tr>
                            <td colspan="5">Total: {{ repairForms.length }}</td>
                        </tr>
                    </tbody>
                </table>
            </div>

            <div>
                <table id="dr_footer">
                    <tbody>
                        <tr>
                            <td>Vehicle:</td>
                            <td>{{ vehicleUse() }}</td>
                            <td>Guard:</td>
                            <td>{{ guardName }}</td>
                        </tr>
                        <tr>
                            <td>Driver Name:</td>
                            <td>{{ driverName }}</td>
                            <td>Processed By:</td>
                            <td>{{ processedBy }}</td>
                        </tr>
                        <tr>
                            <td>Helper Name:</td>
                            <td>{{ helperName }}</td>
                            <td>Received by:</td>
                            <td></td>
                        </tr>
                    </tbody>
                </table>
            </div>


        </v-container>
    </v-dialog>

    <ConfirmOverrideComponentVue
        :approve_override_dialog="override.override_dialog"
        :departments_allowed="override.override_departments_allowed"
        @closeConfirmOverride="closeConfirmOverride"
    ></ConfirmOverrideComponentVue>

    </v-container>


</template>

<script>

import swal from 'sweetalert2';
import { mapGetters } from 'vuex';
import ConfirmOverrideComponentVue from '../../../../Utils/ConfirmOverrideComponent.vue'

export default {
    data() {
        return {
            headers: [
                { text: 'RF#', value: 'sc_repair_form.rf_number' },
                { text: 'Model', value: 'sc_repair_form.sc_repair_form_item.model' },
                { text: 'Name', value: 'sc_repair_form.sc_repair_form_item.product_name' },
                { text: 'Category', value: 'sc_repair_form.sc_repair_form_item.category' },
                { text: 'Serial', value: 'serial' },
                { text: 'Status', value: 'sc_repair_form.sc_repair_form_item.repair_status' },
                { text: 'PL#', value: 'sc_repair_form.packing_list_num' },
            ],
            deliveryReceipt: null,
            repairForms: [],
            rrNumber: '',
            drNumber: '',
            customer: '',
            customerAddress: '',
            model: '',
            name: '',
            category: '',
            createdAt: '',
            deliveredAt: '',
            receivedAt: '',
            processedBy: '',
            status: '',
            dateToday: this.$dayjs().format('YYYY-MM-DD hh:mm:ss a'),
            printedDr: false,
            number_of_boxes:'',
            driverName: '',
            helperName: '',
            vehicleName: '',
            vehiclePlateNumber: '',
            scCustomerName: '',
            scCustomerContactNumber: '',
            scCustomerAddress: '',
            scCustomerEmail: '',
            customerName: '',
            customerContactNumber: '',
            scLocationName: '',
            scLocationContactNumber: '',
            scLocationAddress: '',
            guardName: '',
            loaded: true,
            showOverridePrint: false,
            overrideUserPassword: '',
            confirmOverride: false,
            approve_override_dialog: false,
            override: {
                override_dialog: false,
                errors: [],
                override_departments_allowed: ['SM', 'EX', 'IT'],
                override_authenticated: false,
            },
        }
    },
    components:{
        ConfirmOverrideComponentVue,
    },
    props: ['drStatus', 'deliveryReceiptId'],
    computed: {
        ...mapGetters([
            'USER_ACCESS',
            'GET_DELIVERY_RECEIPT',
            'GET_CONFIRM_OVERRIDE_PRINT_PL',
        ]),
        deliveryReceiptDetailsDialog() {
            return this.$store.state.service.deliveryReceiptDetailsDialog;
        }
    },
    watch: {
        deliveryReceiptId() {
            if(!!this.deliveryReceiptId) {
                this.getDeliveryReceipt();
            }
        },
        deliveryReceipt() {
            if(!!this.deliveryReceipt) {
                let dr = this.deliveryReceipt;
                this.repairForms = dr.sc_dr_repair_forms;

                this.processedBy = dr.processed_by;
                this.driverName = dr.driver_name;
                this.helperName = dr.helper_name;
                this.createdAt = this.$dayjs(dr.created_at).format('YYYY-MM-DD, hh:mm:ss a');
                this.status = dr.status;
                this.drNumber = dr.dr_number;
                this.guardName = dr.guard_name;
                this.number_of_boxes = dr.number_of_boxes;
                // this.rrNumber = dr.sc_receiving_report.rr_number;
                this.printedDr = dr.printed;
                this.remarks = dr.remarks

                if(!!dr.vehicle) {
                    this.vehicleName = dr.vehicle.name;
                    this.vehiclePlateNumber = dr.vehicle.plate_number;
                }

                if(dr.status == 'pending' || dr.status == 'in-transit' || dr.status == 'delivered') {
                    if(!!dr.customer) {
                        this.customer = dr.customer.company_name;

                        if(!!dr.customer.shipping_addresses) {
                            this.customerAddress = JSON.parse(dr.customer.shipping_addresses)[0].address;
                        }

                        let customerContact = [];

                        if(!!dr.customer.tel_1) {
                            customerContact.push(dr.customer.tel_1);
                        }if(!!dr.customer.tel_2) {
                            customerContact.push(dr.customer.tel_2);
                        }if(!!dr.customer.tel_3) {
                            customerContact.push(dr.customer.tel_3);
                        }if(!!dr.customer.mobile_1) {
                            customerContact.push(dr.customer.mobile_1);
                        }if(!!dr.customer.mobile_2) {
                            customerContact.push(dr.customer.mobile_2);
                        }if(!!dr.customer.mobile_3) {
                            customerContact.push(dr.customer.mobile_3);
                        }

                        this.customerContactNumber = customerContact.toString().replaceAll(',', ' / ');
                    }

                    if(!!dr.sc_customer) {
                        let fullName = [];

                        if(!!dr.sc_customer.first_name) {
                            fullName.push(dr.sc_customer.first_name);
                        } if(!!dr.sc_customer.middle_name) {
                            fullName.push(dr.sc_customer.middle_name);
                        } if(!!dr.sc_customer.last_name) {
                            fullName.push(dr.sc_customer.last_name);
                        }

                        this.scCustomerName = fullName.toString().replaceAll(',', ' ');
                        this.scCustomerContactNumber = dr.sc_customer.contact_number;
                        this.scCustomerAddress = dr.sc_customer.address;
                        this.scCustomerEmail = dr.sc_customer.email;
                    }
                }

                if(dr.status == 'defective' || dr.status == 'def-in-transit' || dr.status == 'def-delivered') {
                    this.scLocationName = !!dr.sc_location ? dr.sc_location.name : '';
                    this.scLocationContactNumber = !!dr.sc_location ? dr.sc_location.contact_number : '';
                    this.scLocationAddress = !!dr.sc_location ? dr.sc_location.address : '';
                }
            }
        }
    },
    methods: {
        clearFields() {
            this.deliveryReceipt = null;
            this.repairForms = [];
            this.rrNumber = '';
            this.drNumber = '';
            this.customer = '';
            this.customerAddress = '';
            this.model = '';
            this.name = '';
            this.category = '';
            this.createdAt = '';
            this.deliveredAt = '';
            this.receivedAt = '';
            this.processedBy = '';
            this.status = '';
            this.printedDr = false;
            this.driverName = '';
            this.helperName = '';
            this.vehicleName = '';
            this.vehiclePlateNumber = '';
            this.scCustomerName = '';
            this.scCustomerContactNumber = '';
            this.scCustomerAddress = '';
            this.scCustomerEmail = '';
            this.customerName = '';
            this.scCustomerName = '';
            this.scLocationName = '';
            this.scLocationContactNumber = '';
            this.scLocationAddress = '';
            this.guardName = '';
            this.number_of_boxes = 0
        },
        closeDeliveryReceiptDetailsDialog() {
            this.$emit('closeDrDetailsDialog', true);
            this.$store.commit('closeDeliveryReceiptDetailsDialog');
            this.clearFields();
            this.loaded = true;
        },
        getDeliveryReceipt() {

            this.$store.dispatch('getDeliveryReceipt',this.deliveryReceiptId).then(response=>{
                this.deliveryReceipt = this.GET_DELIVERY_RECEIPT;
                this.loaded = false;
            });
        },
        printDr() {
            let doc = new this.$jspdf('p', 'mm', 'letter');
            // doc.fromHTML($("#dr_logo").get(0), 7,-4,{'width':600});
            // doc.fromHTML($("#dr_header").get(0), 150, 6,{'width':400});
            // doc.fromHTML($("#dr_number").get(0), 150, 12,{'width':400});
            doc.setFont(undefined, 'bold').setFontSize(13).text(6,8,"WADFOW Tools Philippines Corp.")
            doc.setFont(undefined, 'normal').setFontSize(9).text(6,13,"420 Del Monte Ave, Brgy. Siena, Quezon City \nCell No.: +63 935 713 2293 / +63 917 170 0979 / +63 919 078 0016 \nTel. No.: +63 2 8727 1771 / +63 2 8535 9968 / +63 2 7091 4539 \nService Mobile / Viber No.: +63 919 078 0019 / +63 927 414 5539 \nSpare Parts Mobile / Viber No.: +63 995 095 3153")

            doc.setFont(undefined, 'normal').setFontSize(9).text(180,13,'DR#'+this.drNumber)
            doc.autoTable({
                html: '#customer_info',
                theme: 'grid',
                startY: 50,
                margin: {top: 37, left: 7, right: 6},
                columnStyles: {
                    0: {cellWidth: 23, halign:'left', textColor: '#000000', fontSize: 8},
                    1: {cellWidth: 106, halign:'left', textColor: '#000000', fontSize: 8},
                    2: {cellWidth: 23, halign:'left', textColor: '#000000', fontSize: 8},
                    3: {cellWidth: 45, halign:'left', textColor: '#000000', fontSize: 8},
                }
            });
            doc.autoTable({
                html: '#dr_items',
                startY: 58,
                margin: {top: 37, left: 7, right: 6},
                columnStyles: {
                    0: {cellWidth: 16, halign:'left', textColor: '#000000', fontSize: 8},
                    1: {cellWidth: 16, halign:'left', textColor: '#000000', fontSize: 8},
                    2: {cellWidth: 20, halign:'left', textColor: '#000000', fontSize: 8},
                    3: {cellWidth: 60, halign:'left', textColor: '#000000', fontSize: 8},
                    4: {cellWidth: 30, halign:'left', textColor: '#000000', fontSize: 8},
                    5: {cellWidth: 35, halign:'left', textColor: '#000000', fontSize: 8},
                    6: {cellWidth: 25, halign:'left', textColor: '#000000', fontSize: 8},
                }
            });
            doc.setFont(undefined, 'normal').setFontSize(8).text(6,240,"Remakrs:")
            doc.setFont(undefined, 'normal').setFontSize(8).text(140,240,"Number of boxes:")
            doc.setFont(undefined, 'normal').setFontSize(12).text(175,240,this.number_of_boxes ? this.number_of_boxes.toString() : '0')
            doc.setFont(undefined, 'normal').setFontSize(12).text(25,240,this.remarks ?? '')
            doc.autoTable({
                html: '#dr_footer',
                theme: 'grid',
                startY: 244,
                margin: {top: 37, left: 7, right: 6},
                columnStyles: {
                    0: {cellWidth: 23, halign:'left', textColor: '#000000', fontSize: 8},
                    1: {cellWidth: 76, halign:'left', textColor: '#000000', fontSize: 8},
                    2: {cellWidth: 23, halign:'left', textColor: '#000000', fontSize: 8},
                    3: {cellWidth: 76, halign:'left', textColor: '#000000', fontSize: 8},
                }
            });
            doc.autoPrint();
            window.open(doc.output('bloburl'), '_blank');
            let payload = {
                drId: this.deliveryReceipt.id
            }

            this.$store.dispatch('successDrPrint',payload);
            this.getDeliveryReceipt();

            swal.fire("", 'Delivery Receipt Print Successfully!', "success").then(ok=>{
                let payload = {
                    drId: this.deliveryReceipt.id,
                    status: this.status
                }
                this.$store.dispatch('deliverItems',payload).then(response=>{
                    if(!!response.data.msg) {
                        this.closeDeliveryReceiptDetailsDialog();
                        this.$emit('successDeliver', true);
                        swal.fire("", 'Delivery is In-Transit', "success");
                    }
                });
            });
        },
        deliverItems() {
            swal.fire({
                title: 'Are you sure you want to deliver now?',
                text: 'Make sure all needed documents are ready',
                icon: 'question',
                showConfirmButton:true,
                showCancelButton  :  true,
                reverseButtons:true,
                allowOutsideClick :  false,
                confirmButtonColor: '#397373',
                cancelButtonColor : 'grey',
                confirmButtonText:'Confirm',
                showCloseButton:true,
            }).then(result=> {
                if(result.isConfirmed) {
                    let payload = {
                        drId: this.deliveryReceipt.id,
                        status: this.status
                    }
                    this.$store.dispatch('deliverItems',payload).then(response=>{
                        if(!!response.data.msg) {
                            this.closeDeliveryReceiptDetailsDialog();
                            this.$emit('successDeliver', true);
                            swal.fire("", response.data.msg, "success");
                        }
                    });
                }
            });
        },
        confirmReceivedDelivery() {
            swal.fire({
                title: 'Confirm Received Delivery',
                text: 'Please click ok to proceed',
                icon: 'warning',
                showConfirmButton:true,
                showCancelButton  :  true,
                reverseButtons:true,
                allowOutsideClick :  false,
                confirmButtonColor: '#397373',
                cancelButtonColor : 'grey',
                confirmButtonText:'Confirm',
                showCloseButton:true,
            }).then(result=> {
                if(result.isConfirmed) {
                    let payload = {
                        drId: this.deliveryReceipt.id,
                        status: this.drStatus == 'def-in-transit' ? 'def-in-transit' : 'in-transit',
                        repairForms: this.repairForms
                    }

                    this.$store.dispatch('confirmReceivedDelivery',payload).then(response=>{
                        if(!!response.data.msg) {
                            this.closeDeliveryReceiptDetailsDialog();
                            this.$emit('successDeliver', true);
                            swal.fire("", response.data.msg, "success");
                        }
                    });
                }
            });
        },
        deleteDrRf(drRf) {
            swal.fire({
                title: 'Confirm Received Delivery',
                text: 'Please click ok to proceed',
                icon: 'warning',
                showConfirmButton:true,
                showCancelButton  :  true,
                reverseButtons:true,
                allowOutsideClick :  false,
                confirmButtonColor: '#397373',
                cancelButtonColor : 'grey',
                confirmButtonText:'Confirm',
                showCloseButton:true,
            }).then(result=> {
                if(result.isConfirmed) {
                    let payload = {
                        drRfId: drRf.id
                    }
                    this.$store.dispatch('deleteDrRf',payload).then(response=>{
                        if(!!response.data.msg) {
                            this.getDeliveryReceipt();
                            this.$emit('successDeleteDrRf', true);
                            swal.fire("", response.data.msg, "success");
                        }
                    });
                }
            });
        },
        itemSerial(item) {
            if(!!item) {
                let rfi = item.sc_repair_form.sc_repair_form_item;

                if(!!rfi.replacement_serial_number) {
                    return rfi.replacement_serial_number;
                } else {
                    return rfi.serial;
                }
            }

            return "";
        },
        printCustomerName() {
            if(this.customer == 'END USER') {
                return this.scCustomerName;
            }

            if(this.drStatus == 'defective') {
                return this.scLocationName;
            }

            return this.customer;
        },
        printCustomerAddress() {
            if(this.customer == 'END USER') {
                return this.scCustomerAddress;
            }

            if(this.drStatus == 'defective') {
                return this.scLocationAddress;
            }

            return this.customerAddress;
        },
        printCustomerContact() {
            if(this.customer == 'END USER') {
                return this.scCustomerContactNumber;
            }

            if(this.drStatus == 'defective') {
                return this.scLocationContactNumber;
            }

            return this.customerContactNumber;
        },
        vehicleUse() {
            return `${this.vehicleName} - ${this.vehiclePlateNumber}`;
        },
        confirmedOverridePrint() {
            this.override.override_dialog = true;
            if(!!this.overrideUserPassword) {
                let payload = {
                    departments_allowed: ['SR', 'IT' , 'EX'],
                    override_user_password: this.overrideUserPassword
                }
                this.$store.dispatch('confirmOverridePrintPL',payload).then((response)=> {
                    if(this.GET_CONFIRM_OVERRIDE_PRINT_PL.error) {
                        swal.fire("", this.GET_CONFIRM_OVERRIDE_PRINT_PL.error, "warning").then(()=>{this.confirmOverride = false;})
                        return false;
                    }

                    this.overrideUserPassword = "";
                });
            }
        },
        closeConfirmOverride(user_id, authenticated = false) {
            if (authenticated) {
                this.override.override_dialog = false;
                this.printDr();
            } else {
                this.override.override_dialog = false;
            }
        },
    }
}
</script>

<style>

</style>
