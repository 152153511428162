<script>
import { mapGetters } from "vuex";
import swal from "sweetalert2";
import Swal from 'sweetalert2';
export default {
    data() {
        return {
            created_date: new Date(
                Date.parse(new Date().toLocaleString("en-US", "Asia/Manila"))
            ),
            backend_promo_end: new Date(Date.parse("2023-11-30T23:59:59.00+08:00")),
            edit: false,

            moq_bundle_promo_end: new Date(
                Date.parse("2024-12-31T23:59:59.00+08:00")
            ),
            moq_bundle_items: [],

            item_with_foc_divisible_by_5: [7099,1636,893,6367,866,1413],
            item_with_foc_divisible_by_2: [3087],

            promo_300k_end: new Date(Date.parse('2024-01-25T23:59:59.00+08:00')),
            promo_300k_categories: [3, 4], // Engine Products, Generators
            promo_300k_min_net_total: 300000,
            promo_300k_discounting: {
                3: [50, 20, 15],
                4: [50, 20, 15],
            },
            promo_300k_remarks: {
                3: 'WEP300K',
                4: 'WGS300K',
            },

            promo_250k_end: new Date(Date.parse('2024-02-23T23:59:59.00+08:00')),
            promo_250k_min_net_total: 250000,
            promo_250k_remarks: 'FEB250KSP',
            promo_250k_items: [
                {"id": 1534, "model":"WDW1A20","selling_price":35000,"sp_price":12040},
                {"id": 1535, "model":"WDW1A30","selling_price":42000,"sp_price":14450},
                {"id": 21, "model":"WGW1A21","selling_price":15500,"sp_price":5330},
                {"id": 22, "model":"WGW1A31","selling_price":17000,"sp_price":5850},
                {"id": 1728, "model":"WGEAA01-5","selling_price":11000,"sp_price":3750},
                {"id": 1930, "model":"WGEAA06-5P","selling_price":29000,"sp_price":9860},
                {"id": 1931, "model":"WGEAA06D-5P","selling_price":35380,"sp_price":12030},
                {"id": 1932, "model":"WGEAA10-5P","selling_price":76610,"sp_price":26050},
                {"id": 1933, "model":"WDG1A50-5P","selling_price":97890,"sp_price":33290},
                {"id": 1934, "model":"WDG2A50-5P","selling_price":122360,"sp_price":41620},
                {"id": 1935, "model":"WGEAA09-5P","selling_price":71290,"sp_price":24240},
                {"id": 1538, "model":"WQM1A36","selling_price":62000,"sp_price":21330},
                {"id": 1537, "model":"WTA1A80","selling_price":85000,"sp_price":29240}
            ],

            with_errors: false,
            item_substitution: [],
            index:0,
            new_item_model: '',
            special_customers: ['GREAT EAST', 'JRKAWASAKI'],
            items_not_in_promo: [],

            // special_customers: [335],
            special_customers: [],
        }
    },
    methods: {
        checkItemsZeroSRP() {
            var is_allowed = true;
            if (this.orderList.length > 0) {
                this.orderList.forEach((order, index) => {
                    if (parseFloat(order.item.srp) == 0 && !order.foc) {
                        swal.fire(
                            "",
                            "Please click FOC for items without price",
                            "warning"
                        );
                        is_allowed = false;
                    }

                });;
            }
            return is_allowed;
        },
        underMoqPromo(item_id, key, og_item_qty = "") {
            let check = this.moq_bundle_items.filter((f) => {
                return f.id == item_id;
            });

            let get_bundle_items = false;

            if (check.length > 0) {
                if (og_item_qty != "" && og_item_qty % check[0].moq == 0) {
                    get_bundle_items = true;
                } else if (og_item_qty > check[0].moq){
                    get_bundle_items = true;
                } else if (this.item_with_foc_divisible_by_5.includes(item_id)) {
                    get_bundle_items = true;
                } else if (this.item_with_foc_divisible_by_2.includes(item_id)) {
                    get_bundle_items = true;
                } else {
                    get_bundle_items = false;
                }
            } else {
                get_bundle_items = false;
            }

            return get_bundle_items;
        },
        checkBundle(item_id, key, og_item_qty = "") {
            this.$store.dispatch("getBundledItems", item_id).then((response) => {
                let data = response.data;

                if (data.bundle_items != null) {
                    if (this.underMoqPromo(item_id, key, og_item_qty)) {
                        let check = this.moq_bundle_items.filter((f) => {
                            return f.id == item_id;
                        });
                        data.bundle_items.forEach((bundle_item, index) => {
                            var disc_price =
                                bundle_item.foc == 1
                                    ? 0
                                    : this.computeDiscountedPrice(
                                        bundle_item.item.selling_price.replace(/,/g, ""),
                                        bundle_item.item.item_type_text,
                                        bundle_item.item.brand_id
                                    );
                            var non_foc_disc_price = this.computeDiscountedPrice(
                                        bundle_item.item.selling_price.replace(/,/g, ""),
                                        bundle_item.item.item_type_text,
                                        bundle_item.item.brand_id
                                    )
                            var final_price = 0;
                            // if (
                            //     bundle_item.override_amount != null &&
                            //     bundle_item.override_amount > 0
                            // ) {
                            //     final_price = bundle_item.override_amount;
                            // } else {
                                final_price = bundle_item.foc == 1 ? 0 : disc_price;
                            // }
                            var total_bundle_qty =
                                this.item_with_foc_divisible_by_5.includes(item_id)
                                    ? bundle_item.quantity / 5
                                    : (this.item_with_foc_divisible_by_2.includes(item_id) ? bundle_item.quantity / 2 : bundle_item.quantity);
                            if (og_item_qty != "") {
                                total_bundle_qty =
                                    ((parseInt(og_item_qty / check[0].moq)) * bundle_item.quantity) /
                                    (this.item_with_foc_divisible_by_5.includes(item_id) ? 5 : (this.item_with_foc_divisible_by_2.includes(item_id) ? 2 : 1));
                            }

                            var total_amount = 0;
                            if (total_bundle_qty != "") {
                                total_amount = disc_price * total_bundle_qty;
                            }
                            if(triggerBundlePush){
                                this.orderList.push({
                                    quantity: total_bundle_qty,
                                    orig_quantity: bundle_item.quantity,
                                    item_id: bundle_item.item_id,
                                    foc: bundle_item.foc,
                                    manual_foc: false,
                                    disc_price: this.thousandSeprator(
                                        (
                                            Math.round(disc_price * 1000000000000) / 1000000000000
                                        ).toFixed(12)
                                    ),
                                    total_amount: this.thousandSeprator(
                                        (
                                            Math.round(total_amount * 1000000000000) / 1000000000000
                                        ).toFixed(12)
                                    ),
                                    amount: this.thousandSeprator(
                                        (
                                            Math.round(final_price * 1000000000000) / 1000000000000
                                        ).toFixed(12)
                                    ),
                                    type: bundle_item.item.item_type_text,
                                    item: {
                                        id: bundle_item.item.id,
                                        model: bundle_item.item.model,
                                        name: bundle_item.item.name,
                                        description: bundle_item.item.description,
                                        type_id: bundle_item.item.type_id,
                                        category_id: bundle_item.item.category_id,
                                        uom_id: bundle_item.item.uom_id,
                                        item_uom_text: bundle_item.item.item_uom_text,
                                        barcode: bundle_item.item.barcode,
                                        selling_price: bundle_item.item.selling_price,
                                        sp_price: bundle_item.item.sp_price,
                                        srp: bundle_item.item.selling_price,
                                        override_amount: bundle_item.override_amount,
                                        moq: bundle_item.item.moq,
                                        bundle_id: bundle_item.bundle_id,
                                    },
                                    is_bundle: true,
                                    is_editable: false,
                                    promos: [],
                                    promo_items_id: "",
                                    promo_amount: "",
                                    orig_sp_price: bundle_item.item.sp_price,
                                    orig_selling_price: bundle_item.item.selling_price,
                                    disc_price: final_price,
                                    display_disc_price: final_price,
                                    original_item_id: 0,
                                    non_foc_disc_price: non_foc_disc_price,
                                });
                                this.orderList[key].bundle_ids.push(bundle_item.item_id);
                            }
                        });
                    }
                }
            });
        },
        checkBundleForBatchAddition(item_id, key, og_item_qty = "", wait = false) {
            let callback = (response, resolve, wait) => {
                let data = response.data;

                if (data.bundle_items != null) {
                    if (this.underMoqPromo(item_id, key, og_item_qty)) {
                        data.bundle_items.forEach((bundle_item, index) => {
                            var disc_price =
                                bundle_item.foc == 1
                                    ? 0
                                    : this.computeDiscountedPrice(
                                        bundle_item.total_amount.replace(/,/g, ""),
                                        bundle_item.item.item_type_text,
                                        bundle_item.item.brand_id
                                    );
                            var final_price = 0;

                            if (
                                bundle_item.override_amount != null &&
                                bundle_item.override_amount > 0
                            ) {
                                final_price = bundle_item.override_amount;
                            } else {
                                final_price = bundle_item.foc == 1 ? 0 : disc_price;
                            }

                            var total_bundle_qty =
                                this.item_with_foc_divisible_by_5.includes(item_id)
                                    ? bundle_item.quantity / 5
                                    : (this.item_with_foc_divisible_by_2.includes(item_id) ? bundle_item.quantity / 2 : bundle_item.quantity);
                            if (og_item_qty != "") {
                                total_bundle_qty =
                                    (parseInt(og_item_qty) * bundle_item.quantity) /
                                    (this.item_with_foc_divisible_by_5.includes(item_id) ? 5 : (this.item_with_foc_divisible_by_2.includes(item_id) ? 2 : 1));
                            }

                            var total_amount = 0;
                            if (total_bundle_qty != "") {
                                total_amount = disc_price * total_bundle_qty;
                            }
                            this.orderList.push({
                                quantity: total_bundle_qty,
                                orig_quantity: bundle_item.quantity,
                                item_id: bundle_item.item_id,
                                foc: bundle_item.foc,
                                manual_foc: false,
                                disc_price: this.thousandSeprator(
                                    (
                                        Math.round(disc_price * 1000000000000) / 1000000000000
                                    ).toFixed(12)
                                ),
                                total_amount: this.thousandSeprator(
                                    (
                                        Math.round(total_amount * 1000000000000) / 1000000000000
                                    ).toFixed(12)
                                ),
                                amount: this.thousandSeprator(
                                    (
                                        Math.round(final_price * 1000000000000) / 1000000000000
                                    ).toFixed(12)
                                ),
                                type: bundle_item.item.item_type_text,
                                item: {
                                    id: bundle_item.item.id,
                                    model: bundle_item.item.model,
                                    name: bundle_item.item.name,
                                    description: bundle_item.item.description,
                                    type_id: bundle_item.item.type_id,
                                    category_id: bundle_item.item.category_id,
                                    uom_id: bundle_item.item.uom_id,
                                    item_uom_text: bundle_item.item.item_uom_text,
                                    barcode: bundle_item.item.barcode,
                                    selling_price: bundle_item.item.selling_price,
                                    sp_price: bundle_item.item.sp_price,
                                    srp: bundle_item.item.selling_price,
                                    override_amount: bundle_item.override_amount,
                                    moq: bundle_item.item.moq,
                                },
                                is_bundle: true,
                                is_editable: false,
                                promos: [],
                                promo_items_id: "",
                                promo_amount: "",
                                orig_sp_price: bundle_item.item.sp_price,
                                orig_selling_price: bundle_item.item.selling_price,
                            });

                            this.orderList[key].bundle_ids.push(bundle_item.item_id);
                        });
                    }
                }

                if (wait) {
                    resolve();
                }
            };

            let getBundledItems = new Promise((resolve) => {
                this.$store.dispatch("getBundledItems", item_id).then((response) => {
                    callback(response, resolve, wait);
                });
            });

            if (wait) {
                return getBundledItems;
            }
        },
        approveOverrideAmounts() {
            // if(this.override_username == '' || this.override_user_password == ''){
            if (this.override_user_password == "") {
                this.override_user_id = -1;
                this.approve_override_dialog = false;
            } else {
                this.$store
                    .dispatch("confirmOverrideAmounts", {
                        departments_allowed: this.departments_allowed,
                        override_username: this.override_username,
                        override_user_password: this.override_user_password,
                    })
                    .then((response) => {
                        let data = response.data;

                        // this.override_username = ''
                        this.override_user_password = "";

                        if (data.error) {
                            this.override_user_id = -1;
                            swal.fire("", data.error, "warning");
                            return false;
                        }

                        this.override_user_id = data.user_id; //get user id after entering username password
                        this.approve_override_dialog = false;
                        this.checkOrderTableHeight();
                    })
                    .catch((error) => {
                        this.override_user_id = -1;
                        if (error.response.status == 422) {
                            this.errors = error.response.data.errors;
                        }
                    });
            }
        },
        showApproveOverrideAmountsDialog() {
            var hasOverrideAmount = false;
            this.orderList.forEach((order, index) => {
                if (order.override_amount != null && order.override_amount != "") {
                    hasOverrideAmount = true;
                }
            });

            if (hasOverrideAmount) {
                this.approve_override_dialog = true;
            } else {
                this.override_user_id = "";
                this.checkOrderTableHeight();
            }
        },
        confirmOverrideAmount() {
            this.orderList[this.override_index].override_amount = 0;

            var override_amount = this.override_amount.replace(/,/g, "");

            this.orderList[this.override_index].override_amount =
                this.thousandSeprator(
                    (Math.round(override_amount * 1000000000000) / 1000000000000).toFixed(
                        12
                    )
                );
            this.computeTotalAmountPerItem(this.override_index);
            this.updateDisplayAmounts(this.override_index);
            this.override_amount = "";
            this.override_index = "";

            this.override_amount_dialog = false;
        },
        showOverrideAmountDialog(index) {
            this.override_amount = "";

            if (
                this.orderList[index].item_id != null &&
                this.orderList[index].item_id != ""
            ) {
                this.override_index = index;
                this.override_amount_dialog = true;
            } else {
                swal.fire("", "Nothing to override", "warning");
            }
        },
        removeOrderByIndex(index, item) {
            this.orderList.splice(index, 1);
            if(item) {
                this.orderList.forEach((e, i) => {
                    if(e.item.bundle_id == item) {
                        this.orderList.splice(i, 1);
                    }
                })
            }
        },
        computeDiscountedPrice(srp, type, brand_id, key = 0, under_promo = false && !this.orderList[key].is_bundle) {
            if (srp != null && type != null && brand_id != null) {
                /*if(this.customer.discounts.length > 0){
                            this.customer.discounts.forEach(discount => {
                                if(discount[type.toLowerCase()] != null){
                                    var discount_rate = parseFloat(discount[type.toLowerCase()])

                                    if(discount_rate != null && discount_rate > 0){
                                        srp = parseFloat(srp) - (parseFloat(srp) * (discount_rate / 100))
                                    }
                                }
                            });
                        }*/

                if (this.customer.discounts.length > 0) {

                    var brand_discount = this.customer.discounts.filter((brand) => {
                        return brand.brand_id == brand_id;
                    });

                    if (brand_discount.length > 0) {
                        if (this.special_customers.includes(this.customer_id) && type.toLowerCase() == 'pt') {

                        } else {
                            // if(brand_discount[0].discounts typeof )
                            if(typeof brand_discount[0].discounts == 'string'){
                                brand_discount[0].discounts = JSON.parse(brand_discount[0].discounts)
                            }

                            brand_discount[0].discounts.forEach((discount) => {
                                if (discount[type.toLowerCase()] != null) {
                                    var discount_rate = parseFloat(discount[type.toLowerCase()]);

                                    if (discount_rate != null && discount_rate > 0) {
                                        srp =
                                            parseFloat(srp) - parseFloat(srp) * (discount_rate / 100);
                                    }
                                }
                            });
                        }
                    }
                }

                if (this.special_customers.includes(this.customer_id) && ['pt', 'ht'].includes(type.toLowerCase())) {
                    srp = parseFloat(srp) - parseFloat(srp) * 0.03
                }
            }
            return srp;
        },
        checkFirstItemPromo(key, promo, index) {
            if (key == 0) {
                this.orderList[key].selected_promo = promo;
                this.orderList[key].promo_items_id = promo.id;

                if (this.orderList[key].selected_promo.cash_only == 1) {
                    this.terms = 0;
                } else {
                    this.terms = this.orig_terms;
                }
            } else {
                if (this.orderList[0].selected_promo.cash_only == promo.cash_only) {
                    this.orderList[key].selected_promo = promo;
                    this.orderList[key].promo_items_id = promo.id;
                } else {
                    if (index == promo.length - 1) {
                        //IF ALL PROMOS ARE CHECKED ALREADY
                        swal.fire(
                            "",
                            "Promo has different terms \n Please create a new Sales Order/Quotation for this",
                            "warning"
                        );
                        this.removeOrderByIndex(key);
                    }
                }
            }
        },
        calculateItemPromo(key) {
            this.orderList[key].selected_promo = "";
            var withCashPromo = false;

            //CHECK FOR PROMOS FIRST
            if (this.customer_promo != null) {
                if (this.orderList[key].promos != null) {
                    //MAKE A SHORTLIST OF PROMO
                    if (
                        this.orderList[0].selected_promo != null &&
                        this.orderList[0].selected_promo != ""
                    ) {
                        this.orderList[key].promos = this.orderList[key].promos.filter(
                            (promo) => {
                                return (
                                    promo.cash_only == this.orderList[0].selected_promo.cash_only
                                );
                            }
                        );
                    }

                    //CHECK IF CUSTOMER PROMO  IS CASH PRICE
                    if ((this.customer.is_sp != null && this.customer.is_sp == "1") || (this.arrayElementsExistsInString(this.customer.company_name, this.special_customers))) {
                        /*var item_promo = this.orderList[key].promos.filter(promo => {
                                        return promo.cash_only == '1'
                                    })*/

                        this.orderList[key].promos.forEach((promo, index) => {
                            if (promo.cash_only == "1") {
                                withCashPromo = true;
                                this.checkFirstItemPromo(key, promo, index);
                            }
                        });

                        //no cashonly promo was found
                        if (!withCashPromo) {
                            swal.fire(
                                "",
                                "Customer is sp, No Cash Only promo was found \n Please contact system administrator",
                                "warning"
                            );
                            this.removeOrderByIndex(key);
                            return false;
                        }
                    } else {
                        //CHECK OTHER ITEM PROMO IF IT MEETS QTY FROM AND QTY TO
                        var item_qty = 0;

                        if (this.orderList[key].quantity != "") {
                            item_qty = parseInt(this.orderList[key].quantity);
                        }

                        if (item_qty > 0) {
                            var lowest_qty_from = 0;

                            this.orderList[key].promos.forEach((promo, index) => {
                                //lowest_qty_from = promo.quantity_from
                                if (lowest_qty_from == 0) {
                                    lowest_qty_from = promo.quantity_from;
                                } else if (lowest_qty_from > promo.quantity_from) {
                                    lowest_qty_from = promo.quantity_from;
                                }

                                if (promo.quantity_from != null) {
                                    promo.quantity_from = parseInt(promo.quantity_from);
                                } else {
                                    promo.quantity_from = 0;
                                }

                                if (promo.quantity_from == item_qty) {
                                    this.checkFirstItemPromo(key, promo, index);

                                    if (this.orderList[key].selected_promo.cash_only == 1) {
                                        this.terms = 0;
                                    }

                                    //   item_qty -= promo.quantity_from
                                } else if (promo.quantity_from < item_qty) {
                                    if (
                                        this.orderList[key].selected_promo != null &&
                                        this.orderList[key].selected_promo != ""
                                    ) {
                                        if (
                                            this.orderList[key].selected_promo.quantity_from <
                                            promo.quantity_from
                                        ) {
                                            //GET THE HIGHEST QTY POSSIBLE PROMO
                                            this.checkFirstItemPromo(key, promo, index);
                                        }
                                    } else {
                                        this.checkFirstItemPromo(key, promo, index);
                                    }
                                } else if (item_qty < lowest_qty_from) {
                                    this.orderList[key].selected_promo = "";
                                    this.orderList[key].promo_items_id = "";
                                    this.orderList[key].excessQty = 0;
                                }
                            });

                            if (this.orderList[key].promo_items_id != "") {
                                var promo = this.orderList[key].selected_promo;
                                var excessQty = item_qty % promo.quantity_from;

                                if (excessQty > 0) {
                                    this.orderList[key].excessQty = excessQty;
                                }
                            }
                        } else {
                            this.orderList[key].selected_promo = "";
                            this.orderList[key].promo_items_id = "";
                            this.orderList[key].excessQty = 0;
                        }
                    }

                    if (
                        this.orderList[key].selected_promo != null &&
                        this.orderList[key].selected_promo != ""
                    ) {
                        var lowest_price = parseFloat(
                            this.orderList[key].selected_promo.lowest_price
                        );
                        this.orderList[key].item.srp = (
                            Math.round(lowest_price * 1000000000000) / 1000000000000
                        ).toFixed(12);

                        //COMPUTE DISC PRICE

                        this.orderList[key].disc_price = this.orderList[key].item.srp;

                        //IF HAS PROMO, COMPUTE total = net SQ/SO/PL total * (1 - promo customer "Disc %")
                        if (
                            this.orderList[key].promo_id != "" &&
                            this.customer_promo.id != ""
                        ) {

                            this.orderList[key].disc_price =
                                this.orderList[key].item.srp *
                                (1 - this.customer_promo.discount_rate / 100);
                        }

                        this.orderList[key].item.srp = (
                            Math.round(this.orderList[key].item.srp * 1000000000000) /
                            1000000000000
                        ).toFixed(12);
                        this.orderList[key].disc_price = (
                            Math.round(this.orderList[key].disc_price * 1000000000000) /
                            1000000000000
                        ).toFixed(12);
                    } else {
                        //REVERT TO ORIG SRP AND DISC PRICE
                        // if(this.customer != '' && this.customer.is_sp == 1 && this.orderList[key].type == 'PT'){
                        //     this.orderList[key].item.srp           = this.thousandSeprator((Math.round(this.orderList[key].orig_sp_price*1000000000000)/1000000000000).toFixed(12))
                        // }else{
                        this.orderList[key].item.srp = this.thousandSeprator(
                            (
                                Math.round(
                                    this.orderList[key].orig_selling_price * 1000000000000
                                ) / 1000000000000
                            ).toFixed(12)
                        );
                        // }

                        var selling_price =
                            this.customer != "" &&
                                this.customer.is_sp == 1 &&
                                this.orderList[key].type == "PT"
                                ? (
                                    Math.round(
                                        this.orderList[key].orig_sp_price * 1000000000000
                                    ) / 1000000000000
                                ).toFixed(12)
                                : this.orderList[key].item.srp.replace(/,/g, "");

                        var disc_price = this.computeDiscountedPrice(
                            selling_price,
                            this.orderList[key].type,
                            this.orderList[key].item.brand_id
                        );
                        this.orderList[key].disc_price = this.thousandSeprator(
                            (Math.round(disc_price * 1000000000000) / 1000000000000).toFixed(
                                12
                            )
                        );
                    }
                }
            }
        },
        computeTotalAmountPerItem(key) {
            var final_price = 0;

            // this.calculateItemPromo(key)

            if (
                this.orderList[key].disc_price != null &&
                this.orderList[key].disc_price != ""
            ) {
                final_price = parseFloat(
                    String(this.orderList[key].disc_price).replace(/,/g, "")
                );
            }

            // use override amount if it exists
            if (
                this.orderList[key].override_amount != null &&
                this.orderList[key].override_amount != ""
            ) {

                final_price = parseFloat(
                    this.orderList[key].override_amount.replace(/,/g, "")
                );
            }

            if (
                (this.orderList[key].foc != null || this.orderList[key].foc != 0) &&
                this.orderList[key].foc == true
            ) {
                final_price = 0;
            }

            if (this.orderList[key].foc == "1") {
                this.orderList[key].total_amount = (0).toFixed(2);
            } else {
                if (this.orderList[key].quantity != "") {
                    if (
                        this.orderList[key].item_id != null &&
                        this.orderList[key].item_id != ""
                    ) {

                        this.orderList[key].total_amount =
                            parseFloat(final_price) * parseInt(this.orderList[key].quantity);
                        this.orderList[key].total_amount = this.thousandSeprator(
                            (
                                Math.round(this.orderList[key].total_amount * 1000000000000) /
                                1000000000000
                            ).toFixed(12)
                        );
                    }
                } else {
                    this.orderList[key].total_amount = ""; //do not remove, need this to trigger change in override
                    this.orderList[key].total_amount = (0).toFixed(2);
                }
            }
            this.orderList[key].amount = final_price;
        },
        changeFoc(key) {
            this.orderList[key].override_amount = "";
            this.computeTotalAmountPerItem(key);

            if (!!this.orderList[key].foc) {
                this.showFocRemarks = true;
                this.orderListIndex = key;
            } else {

                this.showFocRemarks = false;
                this.focRemarks = "";
                this.orderList[key].manual_foc = false;
                this.orderList[key].foc = false;
                this.orderList[key].foc_remarks = "";
                if(this.orderList[key].is_bundle){
                    this.orderList[key].display_disc_price = this.orderList[key].non_foc_disc_price;
                    this.orderList[key].disc_price = this.orderList[key].non_foc_disc_price;
                }
                this.computeTotalAmountPerItem(key);
            }
        },
        changeQuantity(key) {
            // this.orderList[key].total_amount = ''
            this.computeTotalAmountPerItem(key);

            if (
                this.orderList[key].bundle_ids != null &&
                this.orderList[key].bundle_ids.length > 0
            ) {
                // backend_promo
                let backend_promo = this.orderList[key].bundle_ids.includes(
                    this.orderList[key].item_id
                );

                this.orderList[key].bundle_ids.forEach((bundle_id, index) => {
                    // backend_promo
                    if (
                        backend_promo &&
                        this.created_date <= this.backend_promo_end
                    ) {
                        this.promoPlusQty2(key);
                    } else {
                        var result = this.orderList.filter((obj, index) => {
                            return (
                                obj.item_id === bundle_id &&
                                obj.is_bundle == true &&
                                index > key
                            );
                        });
                        if (result != null && result.length > 0) {
                            let check = this.moq_bundle_items.filter((f) => {
                                return f.id == this.orderList[key].item_id;
                            });
                            result[0].quantity = 0;
                            result[0].quantity =
                                parseInt(result[0].orig_quantity) *
                                (parseInt(this.orderList[key].quantity / check[0].moq) /
                                    (this.item_with_foc_divisible_by_5.includes(this.orderList[key].item_id) ? 5 : (this.item_with_foc_divisible_by_2.includes(this.orderList[key].item_id) ? 2 : 1)));
                            var final_price = result[0].disc_price == 0 ? 0.00 : parseFloat(
                                result[0].disc_price.replace(/,/g, "")
                            );
                            result[0].total_amount = result[0].quantity * final_price;
                            result[0].total_amount = this.thousandSeprator(
                                (
                                    Math.round(result[0].total_amount * 1000000000000) /
                                    1000000000000
                                ).toFixed(12)
                            );
                            //   this.computeTotalAmountPerItem(key)
                        }
                    }
                });
            } else {
                if (
                    this.underMoqPromo(
                        this.orderList[key].item_id,
                        key,
                        this.orderList[key].quantity
                    )
                ) {
                    this.checkBundle(
                        this.orderList[key].item_id,
                        key,
                        this.orderList[key].quantity,
                        true
                    );
                }

                // var disc_price = this.computeDiscountedPrice(
                //     this.orderList[key].item.selling_price,
                //     this.orderList[key].type,
                //     this.orderList[key].item.brand_id
                // );
                // this.orderList[key].disc_price = this.thousandSeprator(
                //     (Math.round(disc_price * 1000000000000) / 1000000000000).toFixed(12)
                // );
            }
        },
        selectModel(key, model_id, is_editable = false, orig_model = null, triggerBundle = true) {
            this.alreadySubstitutedItem = false
            if (model_id != null && model_id != "") {
                this.$store
                    .dispatch("getItemEditInfoshared", {
                        promo_id: this.promo_id,
                        customer_id: this.customer_id,
                        model_id: model_id,
                    })
                    .then((response) => {
                        let data = response.data;
                        this.orderList[key].item_id = model_id;

                        if (orig_model != null) {
                            this.orderList[key].foc = orig_model.foc;
                        } else {
                            if (
                                this.orderList[key].foc == null ||
                                this.orderList[key].foc == ""
                            ) {
                                this.orderList[key].foc = data.foc;
                            }
                        }

                        if (this.orderList[key].foc != 1) {
                            //skips checking of price when entered in SO
                            if (data.category_id == 13 || data.category_id == 14) {
                            } else {
                                if (data.selling_price == 0) {
                                    this.orderList[key].foc = 0;
                                }
                                if (((data.selling_price == 0 && this.orderList[key].foc == 0) && !this.customer.is_sp ) || (this.customer.is_sp && data.sp_price == 0 && this.orderList[key].foc == 0)) {
                                    if(data.item_uom_text == "PT" && (this.promo_id == null || this.promo_id == '')){
                                        swal.fire(
                                            "",
                                            data.model +
                                            " - " +
                                            data.name +
                                            " has no price \n Please contact system administrator",
                                            "warning"
                                        );
                                        this.orderList[key].item_id = "";
                                        return false;
                                    }
                                }
                            }
                        }

                        this.orderList[key].item.active = data.active;
                        this.orderList[key].item.name = data.name;
                        this.orderList[key].item.model = data.model;

                        this.orderList[key].item.type_id = data.type_id;
                        this.orderList[key].item.category_id = data.category_id;
                        this.orderList[key].type = data.item_type_text;
                        this.orderList[key].item.item_uom_text = data.item_uom_text;
                        this.orderList[key].item.item_category_text = data.item_category_text;
                        this.orderList[key].item.item_category_code = data.item_category_code;
                        this.orderList[key].item.moq = data.moq;
                        this.orderList[key].item.brand_id = data.brand_id;
                        this.orderList[key].item.total_stocks = data.total_stocks;
                        this.orderList[key].bundle_id = data.bundle_id;
                        this.orderList[key].quantity_type = data.quantity_type;

                        if (key == 0) {
                            if (
                                this.orderList[key].item.brand_id != null &&
                                this.orderList[key].item.brand_id != ""
                            ) {
                                this.getItemTypeDiscounts(this.orderList[key].item.brand_id);
                            } else {
                                this.itemTypeDiscounts = [];
                            }
                        }

                        if (
                            this.orderList[key].is_bundle == null ||
                            this.orderList[key].is_bundle == ""
                        ) {
                            this.orderList[key].is_bundle = false;
                        }

                        this.orderList[key].bundle_ids = [];

                        if (is_editable) {
                            this.orderList[key].is_editable = true;

                            this.orderList[key].promo_items_id = "";
                            this.orderList[key].promos = data.promos;
                            this.orderList[key].promo_amount = "";
                            this.orderList[key].selected_promo = "";
                        }

                        this.orderList[key].item.sp_price = data.sp_price;
                        this.orderList[key].item.selling_price = data.selling_price;
                        this.orderList[key].orig_sp_price = data.sp_price;
                        this.orderList[key].orig_selling_price = data.selling_price;

                        // if(this.special_customers.includes(this.customer_id) && this.orderList[key].type == 'PT'){
                        //     this.orderList[key].item.srp           = this.thousandSeprator((Math.round(data.sp_price*1000000000000)/1000000000000).toFixed(12))
                        // }else{
                        this.orderList[key].item.srp = this.thousandSeprator(
                            (
                                Math.round(data.selling_price * 1000000000000) / 1000000000000
                            ).toFixed(12)
                        );
                        // }

                        var selling_price =
                            this.customer != "" &&
                                this.customer.is_sp == 1 &&
                                this.orderList[key].type == "PT"
                                ? (
                                    Math.round(data.sp_price * 1000000000000) / 1000000000000
                                ).toFixed(12)
                                : this.orderList[key].item.srp.replace(/,/g, "");

                        var disc_price = this.computeDiscountedPrice(
                            selling_price,
                            this.orderList[key].type,
                            this.orderList[key].item.brand_id
                        );
                        this.orderList[key].disc_price = this.thousandSeprator(
                            (Math.round(disc_price * 1000000000000) / 1000000000000).toFixed(
                                12
                            )
                        );

                        this.calculateItemPromo(key);
                        this.computeTotalAmountPerItem(key);
                        //this.checkOrderTableHeightAddItem()

                        if (this.orderList[key].is_bundle == false) {
                            this.checkMoq(key);
                        }

                        if (is_editable && this.orderList[key].is_bundle == false) {
                            this.checkBundle(
                                this.orderList[key].item_id,
                                key,
                                this.orderList[key].quantity,
                                triggerBundle
                            );
                        }

                        this.checkItemStock(data, key);
                    })
                    .catch((error) => { });
            } else {
                this.orderList[key].item.srp = "";
                this.orderList[key].disc_price = "";
                this.orderList[key].item.type_id = "";
                this.orderList[key].disc_price = "";
                this.orderList[key].item.sp_price = "";
                this.orderList[key].item.selling_price = "";
                this.orderList[key].orig_sp_price = "";
                this.orderList[key].orig_selling_price = "";
                this.orderList[key].item.uom_id = "";
                this.orderList[key].item.moq = "";
                this.orderList[key].total_amount = "";
                this.orderList[key].type = "";
                this.orderList[key].item.item_uom_text = "";
            }
            // $("input").blur();
        },
        async selectModelForBatchAddition(
            key,
            model_id,
            is_editable = false,
            orig_model = null
        ) {
            if (model_id != null && model_id != "") {
                return new Promise((resolve) => {
                    this.$store
                        .dispatch("getItemEditInfoshared", {
                            promo_id: this.promo_id,
                            customer_id: this.customer_id,
                            model_id: model_id,
                        })
                        .then((response) => {
                            let data = response.data;
                            this.orderList[key].item_id = model_id;

                            if (orig_model != null) {
                                this.orderList[key].foc = orig_model.foc;
                            } else {
                                if (
                                    this.orderList[key].foc == null ||
                                    this.orderList[key].foc == ""
                                ) {
                                    this.orderList[key].foc = data.foc;
                                }
                            }

                            if (this.orderList[key].foc != 1) {
                                //skips checking of price when entered in SO
                                if (data.category_id == 13 || data.category_id == 14) {
                                } else {
                                    if (data.selling_price == 0) {
                                        this.orderList[key].foc = 0;
                                    }
                                    if (((data.selling_price == 0 && this.orderList[key].foc == 0) && !this.customer.is_sp ) || (this.customer.is_sp && data.sp_price == 0 && this.orderList[key].foc == 0)) {
                                        if(data.item_uom_text == "PT" && (this.promo_id == null || this.promo_id == '')){
                                            swal.fire(
                                                "",
                                                data.model +
                                                " - " +
                                                data.name +
                                                " has no price \n Please contact system administrator",
                                                "warning"
                                            );
                                            this.orderList[key].item_id = "";
                                            return false;
                                        }
                                    }
                                }
                            }

                            this.orderList[key].item.active = data.active;
                            this.orderList[key].item.name = data.name;
                            this.orderList[key].item.model = data.model;

                            this.orderList[key].item.type_id = data.type_id;
                            this.orderList[key].item.category_id = data.category_id;
                            this.orderList[key].type = data.item_type_text;
                            this.orderList[key].item.item_uom_text = data.item_uom_text;
                            this.orderList[key].item.moq = data.moq;
                            this.orderList[key].item.brand_id = data.brand_id;

                            if (key == 0) {
                                if (
                                    this.orderList[key].item.brand_id != null &&
                                    this.orderList[key].item.brand_id != ""
                                ) {
                                    this.getItemTypeDiscounts(this.orderList[key].item.brand_id);
                                } else {
                                    this.itemTypeDiscounts = [];
                                }
                            }

                            if (
                                this.orderList[key].is_bundle == null ||
                                this.orderList[key].is_bundle == ""
                            ) {
                                this.orderList[key].is_bundle = false;
                            }

                            this.orderList[key].bundle_ids = [];

                            if (is_editable) {
                                this.orderList[key].is_editable = true;

                                this.orderList[key].promo_items_id = "";
                                this.orderList[key].promos = data.promos;
                                this.orderList[key].promo_amount = "";
                                this.orderList[key].selected_promo = "";
                            }

                            this.orderList[key].item.sp_price = data.sp_price;
                            this.orderList[key].item.selling_price = data.selling_price;
                            this.orderList[key].orig_sp_price = data.sp_price;
                            this.orderList[key].orig_selling_price = data.selling_price;

                            // if(this.customer != '' && this.customer.is_sp == 1 && this.orderList[key].type == 'PT'){
                            //     this.orderList[key].item.srp           = this.thousandSeprator((Math.round(data.sp_price*1000000000000)/1000000000000).toFixed(12))
                            // }else{
                            this.orderList[key].item.srp = this.thousandSeprator(
                                (
                                    Math.round(data.selling_price * 1000000000000) / 1000000000000
                                ).toFixed(12)
                            );
                            // }

                            var selling_price =
                                this.customer != "" &&
                                    this.customer.is_sp == 1 &&
                                    this.orderList[key].type == "PT"
                                    ? (
                                        Math.round(data.sp_price * 1000000000000) / 1000000000000
                                    ).toFixed(12)
                                    : this.orderList[key].item.srp.replace(/,/g, "");

                            var disc_price = this.computeDiscountedPrice(
                                selling_price,
                                this.orderList[key].type,
                                this.orderList[key].item.brand_id
                            );
                            this.orderList[key].disc_price = this.thousandSeprator(
                                (
                                    Math.round(disc_price * 1000000000000) / 1000000000000
                                ).toFixed(12)
                            );

                            this.calculateItemPromo(key);
                            this.computeTotalAmountPerItem(key);
                            //this.checkOrderTableHeightAddItem()

                            if (this.orderList[key].is_bundle == false) {
                                this.checkMoq(key);
                            }

                            if (is_editable && this.orderList[key].is_bundle == false) {
                                this.checkBundleForBatchAddition(
                                    this.orderList[key].item_id,
                                    key,
                                    this.orderList[key].quantity,
                                    true
                                ).then((resolved) => {
                                    resolve(resolved);
                                });
                            } else {
                                resolve(null);
                            }

                            this.checkItemStock(data, key);
                        });
                });
            } else {
                this.orderList[key].item.srp = "";
                this.orderList[key].disc_price = "";
                this.orderList[key].item.type_id = "";
                this.orderList[key].item.category_id = "";
                this.orderList[key].disc_price = "";
                this.orderList[key].item.sp_price = "";
                this.orderList[key].item.selling_price = "";
                this.orderList[key].orig_sp_price = "";
                this.orderList[key].orig_selling_price = "";
                this.orderList[key].item.uom_id = "";
                this.orderList[key].item.moq = "";
                this.orderList[key].total_amount = "";
                this.orderList[key].type = "";
                this.orderList[key].item.item_uom_text = "";
            }

            $("input").blur();
        },
        promoPlusQty(key) {
            // backend_promo
            let model_id = this.orderList[key].item_id;
            let qty = this.orderList[key].quantity;
            let free_item_qty = 0;

            let promo_items = [];
            let promo_id_super_products = 1;
            this.$store
                .dispatch("getPromoShortlists", promo_id_super_products)
                .then((response) => {
                    promo_items = response.data.item_ids;
                });

            this.$store.dispatch("getitemsmodelid", model_id).then((response) => {
                let items = response.data;
                let item_type_id = items.type_id;
                let item_type_text = items.item_type_text;
                let item_category_id = items.category_id;
                let item_small_box_qty = items.small_box_qty;

                if (
                    item_category_id == 10 ||
                    item_category_id == 11 ||
                    item_category_id == 16
                ) {
                    free_item_qty =
                        Math.floor(qty / item_small_box_qty / 12) * item_small_box_qty;
                } else if (
                    item_type_id == 1 &&
                    item_category_id != 4 &&
                    promo_items.includes(model_id) === false
                ) {
                    free_item_qty = Math.floor(qty / 24) * 2;
                }

                if (
                    free_item_qty > 0 &&
                    this.edit == false &&
                    this.orderList[key].bundle_ids.includes(model_id) === false &&
                    ((item_type_id == 1 &&
                        this.pt_24_plus_2.includes(this.customer_id) == false) ||
                        (item_type_id == 2 &&
                            this.ht_addl_5_pct_disc.includes(this.customer_id) == false))
                ) {
                    this.orderList.push({
                        quantity: free_item_qty,
                        orig_quantity: free_item_qty,
                        item_id: model_id,
                        foc: true,
                        manual_foc: false,
                        disc_price: this.thousandSeprator(
                            (Math.round(0 * 1000000000000) / 1000000000000).toFixed(12)
                        ),
                        total_amount: this.thousandSeprator(
                            (Math.round(0 * 1000000000000) / 1000000000000).toFixed(12)
                        ),
                        amount: this.thousandSeprator(
                            (Math.round(0 * 1000000000000) / 1000000000000).toFixed(12)
                        ),
                        type: item_type_text,
                        item: {
                            id: items.id,
                            model: items.model,
                            name: items.name,
                            description: items.description,
                            type_id: items.type_id,
                            category_id: items.category_id,
                            uom_id: items.uom_id,
                            item_uom_text: items.item_uom_text,
                            barcode: items.barcode,
                            selling_price: items.selling_price,
                            sp_price: items.sp_price,
                            srp: items.selling_price,
                            override_amount: null,
                            moq: items.moq,
                        },
                        is_bundle: true,
                        is_editable: false,
                        promos: [],
                        promo_items_id: "",
                        promo_amount: "",
                        orig_sp_price: items.sp_price,
                        orig_selling_price: items.selling_price,
                        backend_promo: true,
                    });
                    this.orderList[key].bundle_ids.push(model_id);
                } else {
                    this.orderList[key].bundle_ids.forEach((bundle_id, index) => {
                        var result = this.orderList.filter((obj) => {
                            return (
                                obj.item_id === bundle_id &&
                                obj.is_bundle == true &&
                                obj.backend_promo == true
                            );
                        });
                        if (result != null && result.length > 0) {
                            result[0].quantity = free_item_qty <= 0 ? 0 : free_item_qty;
                        }
                    });
                }
                this.edit = false;
            });
        },
        promoPlusQty2(key) {
            // backend_promo 12+1, 12+1
            let model_id = this.orderList[key].item_id;
            let qty = this.orderList[key].quantity;
            let free_item_qty = 0;

            let promo_items = [];
            let promo_id_power_products = 39;
            this.$store
                .dispatch("getPromoShortlists", promo_id_power_products)
                .then((response) => {
                    promo_items = response.data.item_ids;
                });

            this.$store.dispatch("getitemsmodelid", model_id, {}).then((response) => {
                let items = response.data;
                let item_type_id = items.type_id;
                let item_type_text = items.item_type_text;
                let item_category_id = items.category_id;
                let item_moq = items.moq;

                if (
                    item_type_id == 2 ||
                    (item_category_id !== 13 || item_category_id !== 14)
                ) {
                    free_item_qty = Math.floor(qty / item_moq / 12) * item_moq;
                } else if (
                    item_type_id == 1 &&
                    [18].includes(item_category_id) == false &&
                    promo_items.includes(model_id) === false
                ) {
                    free_item_qty = Math.floor(qty / 12) * 1;
                }

                if (
                    free_item_qty > 0 &&
                    this.edit == false &&
                    this.orderList[key].bundle_ids.includes(model_id) === false
                ) {
                    this.orderList.push({
                        quantity: free_item_qty,
                        orig_quantity: free_item_qty,
                        item_id: model_id,
                        foc: true,
                        manual_foc: false,
                        disc_price: this.thousandSeprator(
                            (Math.round(0 * 1000000000000) / 1000000000000).toFixed(12)
                        ),
                        total_amount: this.thousandSeprator(
                            (Math.round(0 * 1000000000000) / 1000000000000).toFixed(12)
                        ),
                        amount: this.thousandSeprator(
                            (Math.round(0 * 1000000000000) / 1000000000000).toFixed(12)
                        ),
                        type: item_type_text,
                        item: {
                            id: items.id,
                            model: items.model,
                            name: items.name,
                            description: items.description,
                            type_id: items.type_id,
                            category_id: items.category_id,
                            uom_id: items.uom_id,
                            item_uom_text: items.item_uom_text,
                            barcode: items.barcode,
                            selling_price: items.selling_price,
                            sp_price: items.sp_price,
                            srp: items.selling_price,
                            override_amount: null,
                            moq: items.moq,
                        },
                        is_bundle: true,
                        is_editable: false,
                        promos: [],
                        promo_items_id: "",
                        promo_amount: "",
                        orig_sp_price: items.sp_price,
                        orig_selling_price: items.selling_price,
                        backend_promo: true,
                    });
                    this.orderList[key].bundle_ids.push(model_id);
                } else {
                    this.orderList[key].bundle_ids.forEach((bundle_id, index) => {
                        var result = this.orderList.filter((obj) => {
                            return (
                                obj.item_id === bundle_id &&
                                obj.is_bundle == true &&
                                obj.backend_promo == true
                            );
                        });
                        if (result != null && result.length > 0) {
                            result[0].quantity = free_item_qty <= 0 ? 0 : free_item_qty;
                        }
                    });
                }
                this.edit = false;
            });
        },
        promoPlusQty3(key) {
            // gentrade_promo
            let model_id = this.orderList[key].item_id;
            let qty = this.orderList[key].quantity;
            let free_item_qty = 0;

            this.$store.dispatch("getitemsmodelid", model_id, {}).then((response) => {
                let items = response.data;
                let item_small_box_qty = items.small_box_qty;
                let item_moq = items.moq;
                let qty_in_sets = qty / item_moq / 20;
                let qty_in_sb = qty / item_small_box_qty / 20;

                if (model_id == 5057) {
                    free_item_qty = Math.floor(qty / 20);
                } else {
                    if (Math.floor(qty_in_sb) >= 1) {
                        free_item_qty = Math.floor(qty_in_sb) * item_small_box_qty;
                    } else {
                        free_item_qty = Math.floor(qty_in_sets) * item_moq;
                    }
                }

                if (
                    free_item_qty > 0 &&
                    this.edit == false &&
                    this.orderList[key].bundle_ids.includes(model_id) === false
                ) {
                    this.orderList.push({
                        quantity: free_item_qty,
                        orig_quantity: free_item_qty,
                        item_id: model_id,
                        foc: true,
                        manual_foc: false,
                        disc_price: this.thousandSeprator(
                            (Math.round(0 * 1000000000000) / 1000000000000).toFixed(12)
                        ),
                        total_amount: this.thousandSeprator(
                            (Math.round(0 * 1000000000000) / 1000000000000).toFixed(12)
                        ),
                        amount: this.thousandSeprator(
                            (Math.round(0 * 1000000000000) / 1000000000000).toFixed(12)
                        ),
                        type: items.item_type_text,
                        item: {
                            id: items.id,
                            model: items.model,
                            name: items.name,
                            description: items.description,
                            type_id: items.type_id,
                            category_id: items.category_id,
                            uom_id: items.uom_id,
                            item_uom_text: items.item_uom_text,
                            barcode: items.barcode,
                            selling_price: items.selling_price,
                            sp_price: items.sp_price,
                            srp: items.selling_price,
                            override_amount: null,
                            moq: items.moq,
                        },
                        is_bundle: true,
                        is_editable: false,
                        promos: [],
                        promo_items_id: "",
                        promo_amount: "",
                        orig_sp_price: items.sp_price,
                        orig_selling_price: items.selling_price,
                        backend_promo: true,
                    });
                    this.orderList[key].bundle_ids.push(model_id);
                } else {
                    this.orderList[key].bundle_ids.forEach((bundle_id, index) => {
                        var result = this.orderList.filter((obj) => {
                            return (
                                obj.item_id === bundle_id &&
                                obj.is_bundle == true &&
                                obj.backend_promo == true
                            );
                        });
                        if (result != null && result.length > 0) {
                            result[0].quantity = free_item_qty <= 0 ? 0 : free_item_qty;
                        }
                    });
                }
                this.edit = false;
            });
        },
        promoPlusQty4(key) {
            //hacksaw blade promo
            let model_id = this.orderList[key].item_id;
            let qty = this.orderList[key].quantity;
            let free_item_qty = 0;

            let free_item = this.hacksaw_blade_promo_items.filter((f) => {
                return f.id === model_id;
            })[0].moq;

            let moq_values = Object.keys(free_item).sort((a, b) => {
                return a - b;
            });
            let moq = 0;

            moq_values.forEach((f) => {
                moq = parseInt(f);
                if (qty >= moq && moq % qty == 0) {
                    free_item_qty = free_item[f].quantity;
                }
            });

            if (
                free_item_qty > 0 &&
                this.edit == false &&
                this.orderList[key].bundle_ids.includes(free_item[moq].id) == false
            ) {
                this.$store
                    .dispatch("getitemsmodelid", free_item[moq].id, {})
                    .then((response) => {
                        let items = response.data;
                        this.orderList.push({
                            quantity: free_item_qty,
                            orig_quantity: free_item_qty,
                            item_id: free_item[moq].id,
                            foc: true,
                            manual_foc: false,
                            disc_price: this.thousandSeprator(
                                (Math.round(0 * 1000000000000) / 1000000000000).toFixed(12)
                            ),
                            total_amount: this.thousandSeprator(
                                (Math.round(0 * 1000000000000) / 1000000000000).toFixed(12)
                            ),
                            amount: this.thousandSeprator(
                                (Math.round(0 * 1000000000000) / 1000000000000).toFixed(12)
                            ),
                            type: items.item_type_text,
                            item: {
                                id: items.id,
                                model: items.model,
                                name: items.name,
                                description: items.description,
                                type_id: items.type_id,
                                category_id: items.category_id,
                                uom_id: items.uom_id,
                                item_uom_text: items.item_uom_text,
                                barcode: items.barcode,
                                selling_price: items.selling_price,
                                sp_price: items.sp_price,
                                srp: items.selling_price,
                                override_amount: null,
                                moq: items.moq,
                            },
                            is_bundle: true,
                            is_editable: false,
                            promos: [],
                            promo_items_id: "",
                            promo_amount: "",
                            orig_sp_price: items.sp_price,
                            orig_selling_price: items.selling_price,
                            backend_promo: true,
                        });

                        this.orderList[key].bundle_ids.push(free_item[moq].id);

                        this.edit = false;
                    });
            } else {
                this.orderList[key].bundle_ids.forEach((bundle_id) => {
                    var result = this.orderList.filter((obj, index) => {
                        return (
                            obj.item_id === bundle_id &&
                            obj.is_bundle == true &&
                            obj.backend_promo == true &&
                            index > key
                        );
                    });

                    if (result != null && result.length > 0) {
                        result[0].quantity = free_item_qty;
                    }
                });
            }

            var disc_price = this.computeDiscountedPrice(
                this.orderList[key].selling_price,
                this.orderList[key].type,
                this.orderList[key].item.brand_id,
                key
            );
            this.orderList[key].disc_price = this.thousandSeprator(
                (Math.round(disc_price * 1000000000000) / 1000000000000).toFixed(12)
            );
            this.computeTotalAmountPerItem(key);
        },
        checkItemStock(data, key) {
            if (this.is_packing_list != null && this.is_packing_list == false) {
                if (this.orderList[key].item_id != "" && data.total_stocks == 0) {
                    this.checkItemSubstitutionAndSuggestion(data, key);
                }
            }
        },
        checkItemSubstitutionAndSuggestion(data, key) {
            if (data.sub_items_with_stocks.length > 0) {
                var substitutionItems = "";
                var has_substitute = false;
                var substitute_id = "";

                data.sub_items_with_stocks.forEach((item, index) => {
                    if (item.total_stocks > 0) {
                        substitutionItems += item.text + " - " + item.name + "\r\n";

                        if (has_substitute == false) {
                            substitute_id = item.value;
                            has_substitute = true;
                        }
                    }
                });

                if (has_substitute && substitute_id != "") {
                    this.orderList[key].model_id = substitute_id;
                    this.selectModel(key, substitute_id);
                }

                if (substitutionItems != "") {
                    swal.fire(
                        "",
                        "This item has old models with stocks \r\n" +
                        substitutionItems +
                        "\r\n Please coordinate with warehouse if stock transfer is needed.",
                        "warning",
                        { className: "swal-lg" }
                    );
                }
            }

            this.checkForNewerModel(this.orderList[key].item_id, key);

            if (data.suggested_items.length > 0) {
                var suggestedItems = "";

                data.suggested_items.forEach((item, index) => {
                    suggestedItems += item.text + " - " + item.name + "\r\n";
                });

                this.orderList[key].item_id = "";
                this.selectModel(key, "");

                swal.fire(
                    "",
                    "This item is out of stock \r\n Suggested Items: \r\n" +
                    suggestedItems,
                    "warning"
                );
            }
        },
        checkForNewerModel(item_id, key) {
            var newModels = "";
            this.$store.dispatch(getNewerModel, item_id).then((response) => {
                var data = response.data;

                if (data.new_models.length > 0) {
                    data.new_models.forEach((item, index) => {
                        // if(item.total_stocks > 0){
                        newModels += item.model + " - " + item.name + "\r\n";
                        // }
                    });

                    swal.fire(
                        "",
                        "This item has newer models \r\n" + newModels,
                        "warning"
                    );
                }
            });
        },
        removeDiscount() {
            if (this.customer.discounts.length > 1) {
                this.customer.discounts.pop();
            }
        },
        addDiscount() {
            if (this.customer.discounts.length < this.max_discounts_count) {
                this.customer.discounts.push({
                    pt: "",
                    ht: "",
                    sp: "",
                });
            }
        },
        removeOrderItem() {
            if (this.orderList.length > 1) {
                this.orderList.pop();
            }
        },
        addOrderItem() {
            //if(this.checkOrderTableHeightAddItem()){
            if (this.orderList.length < this.max_order_items_count) {
                this.orderList.push({
                    quantity: "",
                    item_id: "",
                    foc: "",
                    manual_foc: false,
                    disc_price: "",
                    total_amount: "",
                    amount: "",
                    type: "",
                    item: {
                        id: "",
                        model: "",
                        name: "",
                        description: "",
                        type_id: "",
                        category_id: "",
                        uom_id: "",
                        item_uom_text: "",
                        barcode: "",
                        selling_price: "",
                        sp_price: "",
                        srp: "",
                        override_amount: "",
                    },
                    selected_promo: "",
                    is_editable: true,
                    promos: [],
                    promo_items_id: "",
                    promo_amount: "",
                    limit: "",
                    backend_promo: false, // backend_promo
                    foc_remarks: "",
                    original_item_id: 0,
                });
            }
            //}
        },
        checkOrderTableHeightAddItem() {
            var vm = this;
            var limit = vm.noOfItems;
            var shipping_address_text = $("#shipping_address_text").parent().text();
            if (shipping_address_text != null && shipping_address_text != "") {
                if (shipping_address_text.length > 60) {
                    limit = vm.noOfItems;
                }
            }

            if (this.orderList.length > 0) {
                //this.tempOrderList = []
                var footer = `<tr>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td>***** NOTHING FOLLOWS *****</td>
                            </tr>`;
                var start = 0;
                var appendCount = 0;
                $("#tr_items").empty();
                $("#tr_items").append(footer);
                this.orderList.forEach((item, index) => {
                    //  vm.tempOrderList = vm.orderList.slice(start,index+1);
                    // $( "#items_details" ).load(window.location.href + " #items_details" );
                    //    vm.$set(this.tempOrderList, index, item);

                    $("#tr_items").append(
                        "<tr>" +
                        "<td>" +
                        index +
                        "</td>" +
                        "<td>" +
                        item.quantity +
                        "</td>" +
                        "<td>" +
                        item.item.item_uom_text +
                        "</td>" +
                        "<td>" +
                        item.item.model +
                        "</td>" +
                        "<td>" +
                        item.item.name +
                        "</td>" +
                        "<td>" +
                        item.disc_price +
                        "</td>" +
                        "<td>" +
                        item.total_amount +
                        "</td>" +
                        "</tr>"
                    );

                    var tableHeight = vm.computeOrderTableHeight();

                    if (tableHeight > limit) {
                        limit += limit;
                        start = index + 1;
                        //swal.fire("", "This will render more than 1 page. \n Please delete item(s) to proceed", "warning");
                        //return false;
                        $("#tr_items").empty();
                        $("#tr_items").append(footer);
                    }

                    item.limit = limit;
                });
            }

            //return true;
        },
        computeOrderTableHeight() {
            var doc = new this.$jspdf("p", "mm", "letter");
            var tableHeight = 0;

            if (this.orderList.length > 0 && $("#items_details").length) {
                //(this.orderList)
                try {
                    doc.autoTable({
                        html: "#items_details",
                        margin: { left: 7, right: 7 },
                        theme: "grid",
                        headStyles: {
                            halign: "center",
                            fillColor: "#ffffff",
                            textColor: "#000000",
                            lineWidth: 0.2,
                            lineColor: "#000000",
                        },
                        columnStyles: {
                            0: { cellWidth: 9, halign: "center", textColor: "#000000" },
                            1: { cellWidth: 13, halign: "center", textColor: "#000000" },
                            2: { cellWidth: 14, halign: "center", textColor: "#000000" },
                            3: { cellWidth: 29, halign: "center", textColor: "#000000" },
                            4: { halign: "left", textColor: "#000000" },
                            5: { cellWidth: 25, halign: "right", textColor: "#000000" },
                            6: { cellWidth: 25, halign: "right", textColor: "#000000" },
                        },
                    });

                    tableHeight = doc.autoTable.previous.finalY;
                } catch (err) { }
            }
            return tableHeight;
        },
        getItemTypeDiscounts(brand_id = null) {
            if (brand_id == null) {
                this.itemTypeDiscounts = [];
            } else {
                var brand_discount = this.customer.discounts.filter((brand) => {
                    return brand.brand_id == brand_id;
                });

                this.itemTypeDiscounts = [];

                if (brand_discount.length > 0) {
                    brand_discount = brand_discount[0];

                    if (this.type_list.length > 0) {
                        this.type_list.forEach((type) => {
                            var name = type.text;
                            var str = type.text + ": ";
                            var isEmpty = true;

                            if (brand_discount.discounts.length > 0) {
                                brand_discount.discounts.forEach((discount, index) => {
                                    if (
                                        discount[name.toLowerCase()] != null &&
                                        discount[name.toLowerCase()] != "" &&
                                        discount[name.toLowerCase()] > 0
                                    ) {
                                        if (index > 0) {
                                            str = str + " / ";
                                        }
                                        str = str + discount[name.toLowerCase()].toString();
                                        isEmpty = false;
                                    }
                                });
                                if (isEmpty) {
                                    str = "";
                                } else {
                                    if (
                                        this.customer.is_sp == 1 &&
                                        this.orderList.length > 0 &&
                                        this.orderList[0].type == "PT"
                                    ) {
                                        this.itemTypeDiscounts = [];
                                    } else {
                                        this.itemTypeDiscounts.push(str);
                                    }
                                }
                            }
                        });
                    }
                }
            }
        },
        computedDiscPrice() {
            if (this.orderList.length > 0) {
                this.orderList.forEach((order, index) => {
                    // if(this.customer.is_sp == 1 && this.orderList[index].type == 'PT'){
                    //     order.item.srp           = this.thousandSeprator((Math.round(order.item.sp_price*1000000000000)/1000000000000).toFixed(12))
                    // }else{
                    order.item.srp = this.thousandSeprator(
                        (
                            Math.round(order.item.selling_price * 1000000000000) /
                            1000000000000
                        ).toFixed(12)
                    );
                    // }

                    if (this.orderList[index].is_editable != false) {
                        var selling_price =
                            this.customer != "" &&
                                this.customer.is_sp == 1 &&
                                this.orderList[index].type == "PT"
                                ? (
                                    Math.round(order.item.sp_price * 1000000000000) /
                                    1000000000000
                                ).toFixed(12)
                                : this.orderList[index].item.srp.replace(/,/g, "");

                        var disc_price = this.computeDiscountedPrice(
                            selling_price,
                            order.type,
                            order.item.brand_id
                        );
                        order.disc_price = this.thousandSeprator(
                            (Math.round(disc_price * 1000000000000) / 1000000000000).toFixed(
                                12
                            )
                        );
                    }

                    this.computeTotalAmountPerItem(index);
                });
            }
            this.loaded = true;
        },
        checkMoq(key) {
            this.calculateItemPromo(key);
            if (
                this.orderList[key].quantity != "" &&
                this.orderList[key].item_id != "" &&
                !this.orderList[key].is_bundle
            ) {
                //"cashPrice" under CustomerSelect - exempted from MOQ restriction (qtyFrom & qtyTo)
                if (
                    this.orderList[key].selected_promo == null ||
                    this.orderList[key].selected_promo == ""
                ) {
                    if (this.promo_id != null && this.promo_id != "") {
                        if(this.orderList[key].quantity_type == 'moq') {
                            swal.fire(
                                "",
                                "The quantity for this item is not qualified for the promo",
                                "warning"
                            );
                            this.removeOrderByIndex(key);
                            //this.orderList[key].quantity = ''
                        } else {
                            if(this.orderList[key].quantity < this.orderList[key].promos.find(e => e.promo_id == this.promo_id)?.quantity_from) {
                                let quantity_from = this.orderList[key].promos.find(e => e.promo_id == this.promo_id)?.quantity_from
                                swal.fire({
                                        title: "",
                                        text:
                                            " The quantity cannot be lower than " + quantity_from,
                                        icon: 'info',
                                        buttons: true,
                                        dangerMode: true,
                                    })
                                    .then((willDelete) => {
                                        if (willDelete) {
                                            this.orderList[key].quantity = '';
                                        }
                                    });
                            } else {
                                this.computeTotalAmountPerItem(key);
                            }
                        }
                    } else {
                        let routeName = this.$route.name;
                        let overrideMOQ = routeName == 'salesquotation' ? this.override_moq.value : this.customer.override_moq
                        if (!overrideMOQ) {
                            var moq = parseInt(this.orderList[key].item.moq);

                            if (moq != null && moq > 0) {
                                if (this.orderList[key].quantity % moq > 0) {
                                    var upperQty = this.orderList[key].quantity;
                                    var lowerQty = this.orderList[key].quantity;
                                    while (upperQty % moq > 0) {
                                        upperQty++;
                                    }
                                    while (lowerQty % moq > 0) {
                                        lowerQty--;
                                    }
                                    var suggestion = "";
                                    if (lowerQty == 0) {
                                        suggestion = "\n Suggestion: " + upperQty;
                                    } else {
                                        suggestion = "\n Suggestion: " + upperQty + " or " + lowerQty;
                                    }
                                    swal.fire(
                                        "",
                                        "Quantity should be divisible by " + moq + suggestion,
                                        "warning"
                                    );
                                    this.orderList[key].quantity = "";
                                }
                            }
                        }

                        // this.promoPlusQty5(key, this.orderList[key].item_id, this.orderList[key].quantity)
                        this.computeTotalAmountPerItem(key);

                        // backend_promo:
                        if (
                            this.orderList[key].item_id != "" &&
                            this.orderList[key].quantity > 0 &&
                            this.created_date <= this.backend_promo_end
                        ) {
                            this.promoPlusQty2(key);
                        }
                    }
                } else if (
                    this.orderList[key].selected_promo != null &&
                    this.orderList[key].selected_promo != ""
                ) {
                    if(this.orderList[key].quantity_type == 'moq') {
                        if (
                            this.orderList[key].excessQty != null &&
                            this.orderList[key].excessQty > 0
                        ) {
                            var excessQty = this.orderList[key].excessQty;

                            swal
                                .fire({
                                    title: "",
                                    text:
                                        excessQty +
                                        " excess quantity. This will create a new entry, Do you want to continue?",
                                    icon: 'question',
                                    buttons: true,
                                    dangerMode: true,
                                })
                                .then((willDelete) => {
                                    this.orderList[key].quantity =
                                        this.orderList[key].quantity - excessQty;
                                    this.orderList[key].excessQty = 0;
                                    this.computeTotalAmountPerItem(key);
                                    if (willDelete) {
                                        //if(this.checkOrderTableHeightAddItem()){
                                        if (this.orderList.length < this.max_order_items_count) {
                                            var excessItemIndex = this.orderList.push({
                                                quantity: excessQty,
                                                item_id: this.orderList[key].item_id,
                                                foc: "",
                                                manual_foc: false,
                                                disc_price: "",
                                                total_amount: "",
                                                type: "",
                                                item: {
                                                    id: "",
                                                    model: "",
                                                    name: "",
                                                    description: "",
                                                    type_id: "",
                                                    category_id: "",
                                                    uom_id: "",
                                                    item_uom_text: "",
                                                    barcode: "",
                                                    selling_price: "",
                                                    sp_price: "",
                                                    srp: "",
                                                    override_amount: "",
                                                },
                                                is_editable: true,
                                                promos: [],
                                                promo_items_id: "",
                                                promo_amount: "",
                                                selected_promo: "",
                                            });

                                            this.selectModel(
                                                excessItemIndex - 1,
                                                this.orderList[key].item_id,
                                                true
                                            );
                                        }
                                        //}
                                    }
                                });
                        } else {
                            this.computeTotalAmountPerItem(key);
                        }
                    } else {
                        var moq = parseInt(this.orderList[key].item.moq);
                        if (moq != null && moq > 0) {
                            if (this.orderList[key].quantity % moq > 0) {
                                var upperQty = this.orderList[key].quantity;
                                var lowerQty = this.orderList[key].quantity;
                                while (upperQty % moq > 0) {
                                    upperQty++;
                                }
                                var suggestion = "";
                                suggestion = "\n Suggestion: " + upperQty ;
                                swal.fire(
                                    "",
                                    "Quantity should be divisible by " + moq + suggestion,
                                    "warning"
                                );
                                this.orderList[key].quantity = "";
                            }
                        }
                        this.computeTotalAmountPerItem(key);
                    }
                }
            }

            this.updateDisplayAmounts(key);
        },
        updateDisplayAmounts(key) {
            if (this.orderList[key] != null) {
                this.orderList[key].item.display_srp = this.getTwoDecimals(
                    this.orderList[key].item.srp
                );
                this.orderList[key].display_disc_price = this.getTwoDecimals(
                    this.orderList[key].disc_price
                );
                this.orderList[key].display_override_amount = this.getTwoDecimals(
                    this.orderList[key].override_amount
                );
            }

            this.orderList.push(undefined);
            this.orderList.pop();
        },
        getItemId() {
            let routeName = this.$route.name;
            let existingItemType = '';
            if (this.orderList.length > 0) {
                existingItemType = _.first(this.orderList).type
            }
            this.$store
                .dispatch("getItemId", {
                    existingItemType: existingItemType,
                    data: this.batch_addition.data,
                    override_moq: routeName == 'salesquotation' ? this.override_moq.value : this.customer.override_moq,
                })
                .then(async (response) => {
                    let item_ids = response.data.data;
                    let hasErrors = response.data.hasErrors;

                    if (this.orderList.length == 1 && this.orderList[0].item_id == "") {
                        this.orderList.pop();
                    }

                    this.orderListLength =
                        this.orderList.length > 0 ? this.orderList.length : 0;

                    for (let item of item_ids) {
                        if (item.skip == false) {
                            await new Promise(async (resolve) => {
                                this.addOrderItem();
                                this.orderListLength =
                                    this.orderList.length > 0 ? this.orderList.length : 0;
                                this.counter = this.orderListLength - 1;

                                this.orderList[this.counter].item_id = item.id;
                                this.orderList[this.counter].quantity = item.qty;
                                // this.orderList[this.counter].foc = item.foc

                                await this.selectModelForBatchAddition(
                                    this.counter,
                                    item.id,
                                    true
                                ).then((resolved) => {
                                    resolve(resolved);
                                });
                            });
                        }
                    }

                    this.batch_addition.errors = response.data.errors;
                    this.batchSubstitution.list = response.data.itemSubs;
                    if(this.batchSubstitution.list.length != 0){
                        this.batchSubstitution.dialog = true;
                    }else{
                        if (hasErrors) {
                            swal.fire(
                                "",
                                "There are errors on the copy data. Please see text in red.",
                                "warning"
                            );
                            this.with_errors = true
                        }
                        else{
                            this.with_errors = false
                        }
                    }
                });
        },
        async runBackendPromos() {
            await this.promo300k()
            await this.promo250k()
        },
        async promo300k() {
            if (this.has_promo) {
                if (this.created_date <= this.promo_300k_end) {
                    if (this.promo_300k_categories.includes(this.orderList[0].item.category_id)) {
                        var gross_total = this.orderList.reduce((a, v) => a + (v.foc == 0 ? parseInt(v.quantity) * parseFloat(v.amount) : 0), 0)
                        if (gross_total >= this.promo_300k_min_net_total) {
                            var total_amount = 0
                            for (let i in this.orderList) {
                                // var item_amount = parseFloat(this.orderList[i].item.selling_price)
                                var item_total = 0.00

                                // based on plain discounting computation
                                // for (let d in this.promo_300k_discounting[this.orderList[0].item.category_id]) {
                                //     item_amount *= 1 - (this.promo_300k_discounting[this.orderList[0].item.category_id][d] / 100)
                                // }
                                // end

                                // based on rounded up discounting
                                // for (let d in this.promo_300k_discounting[this.orderList[0].item.category_id]) {
                                //     item_amount *= 1 - (this.promo_300k_discounting[this.orderList[0].item.category_id][d] / 100)
                                // }
                                // item_amount = Math.ceil(item_amount / 5) * 5
                                // end

                                // based on SP price
                                item_amount = this.orderList[i].item.sp_price
                                // end

                                item_total = this.orderList[i].quantity * item_amount
                                total_amount += item_total

                                this.orderList[i].disc_price = item_amount
                                this.orderList[i].amount = item_amount
                                this.orderList[i].total_amount = item_total.toString()

                                this.updateDisplayAmounts(i)
                            }

                            if (this.remarks.indexOf(this.promo_300k_remarks[this.orderList[0].item.category_id]) == -1) {
                                this.remarks = ('[' + this.promo_300k_remarks[this.orderList[0].item.category_id] + '] ' + this.remarks).trim()
                            }
                        }
                    }
                }
            }
        },
        async promo250k() {
            if (this.has_promo) {
                if (this.created_date <= this.promo_250k_end) {
                    var promo_250k_item_ids = this.promo_250k_items.map(m => m.id)
                    var order_item_ids = this.orderList.map(m => m.item_id)
                    var gross_total = this.orderList.reduce((a, v) => a + (v.foc == 0 && promo_250k_item_ids.includes(v.item_id) ? parseInt(v.quantity) * parseFloat(v.amount) : 0), 0)
                    if (gross_total >= this.promo_250k_min_net_total) {
                        var total_amount = 0
                        for (let i in this.orderList) {
                            var item_total = 0
                            if (promo_250k_item_ids.includes(this.orderList[i].item_id)) {
                                item_amount = this.promo_250k_items.find(f => f.id = this.orderList[i].item_id).sp_price
                                item_total = this.orderList[i].quantity * item_amount
                                total_amount += item_total

                                this.orderList[i].disc_price = item_amount
                                this.orderList[i].amount = item_amount
                                this.orderList[i].total_amount = item_total.toString()

                                this.updateDisplayAmounts(i)
                            }
                        }

                        if (this.remarks.indexOf(this.promo_250k_remarks) == -1) {
                                this.remarks = ('[' + this.promo_250k_remarks + '] ' + this.remarks).trim()
                            }
                    }
                }
            }
        },
        getUsers() {
            this.$store.dispatch("getAllUsers").then((response) => {
                this.users_list = response.data;
            });
        },
        async getCustomers(filtered = false) {
            this.$store
                .dispatch("getCustomers", {
                    params: {
                        filtered: filtered,
                        filter_store: false,
                    },
                })
                .then((response) => {
                    let lists = response.data;
                    if (this.isSalesman && this.currentSalesmanPositionId != 1) {
                        if (this.isSalesCoordinator) {
                            let filtered_list = lists.filter(
                                (x) => x.sales_coordinator_id == this.currentSalesmanId
                            );
                            this.customers_list = filtered_list;
                        } else {
                            let filtered_list = lists.filter((x) =>
                                _.compact(this.salesmanChildrenIds).includes(x.salesman_id)
                            );
                            this.customers_list = filtered_list;
                        }
                    } else {
                        this.customers_list = lists;
                    }
                });
        },
        getSalesmanList() {
            this.$store.dispatch("getSalesmanList").then((response) => {
                if (this.isSalesman && this.currentSalesmanPositionId != 1) {
                    let lists = response;
                    if (this.isSalesCoordinator) {
                        let filtered_list = lists.filter(
                            (x) => x.value == this.currentSalesmanId
                        );
                        this.salesman_list = filtered_list;
                    } else if (_.compact(this.salesmanChildrenIds).length > 0) {
                        let filtered_list = lists.filter((x) =>
                            _.compact(this.salesmanChildrenIds).includes(x.value)
                        );
                        this.salesman_list = filtered_list;
                    }
                } else {
                    this.salesman_list = response;
                }
            });
        },
        async getItems() {
            this.$store.dispatch("getItems").then((response) => {
                this.items_list = response.data;
            });
        },
        async getItemTypes() {
            this.$store.dispatch("getItemTypes").then((response) => {
                this.type_list = response.data;
            });
        },
        getCustomerPromoInfo() {
            this.exclusive_items = []
            if (this.promo_id != "" && this.customer_id != "") {
                this.$store
                    .dispatch("getCustomerPromoInfo", {
                        promo_id: this.promo_id,
                        customer_id: this.customer_id,
                    })
                    .then((response) => {
                        var data = response.data;

                        this.customer_promo = data.customer_promo;
                        this.exclusive_items = data.exclusive_items

                        this.computedDiscPrice();
                    });
            } else {
                this.computedDiscPrice();
                this.customer_promo = "";
            }
        },
        getPromos() {
            this.$store.dispatch("promosDropdown").then((response) => {
                this.promo_list = response.data;
            });
        },
        getItemsNotInPromo() {
            this.items_not_in_promo = [];
            this.$store
                .dispatch("getItemsNotInPromo", {customer_id: this.customer_id, promo_id: this.promo_id})
                .then((response) => {
                    let data = response.data;
                    this.items_not_in_promo = data.item_ids;
                    this.loaded = true;
                })
                .catch((error) => { });
        },
        getTwoDecimals(amount) {
            var formatted_amount = "";
            if (isNaN(amount)) {
                if (amount != null && amount != "") {
                    formatted_amount = parseFloat(amount.replace(/,/g, ""));
                    formatted_amount =
                        Math.trunc(formatted_amount * Math.pow(10, 2)) / Math.pow(10, 2);
                }
            } else {
                formatted_amount =
                    Math.trunc(amount * Math.pow(10, 2)) / Math.pow(10, 2);
            }

            if (formatted_amount == "") {
                return 0.0;
            }
            return this.thousandSeprator(formatted_amount.toFixed(2));
        },
        getGenTradeCustomers() {
            this.$store.dispatch("getGenTradeCustomers", {}).then((response) => {
                this.gentrade_customers = this.GET_GENTRADE_CUSTOMERS;
            });
        },
        checkItemIfInactive(i, key){
            this.index = i;
            let item = this.filtered_items_list.find((x) => x.text == key.toUpperCase())
            this.new_item_model = key.toUpperCase();
            if(!item){
                let payload = {
                    item_model: key.toUpperCase()
                }
                this.$store.dispatch('checkItemSubstitution', payload).then((res)=>{
                    let items = res;
                    if(items.length != 0){
                        this.itemSubsDialog = true;
                        this.item_substitution = items ;
                    }else{
                        Swal.fire(
                            "No Items Found!",
                            "",
                            "warning"
                        )
                    }

                }).catch((err)=>{
                    swal.fire(
                        "Network Error",
                        "",
                        "error"
                    )
                })
            }else{
                return false
            }
        },
        getItemIdSingle(key, qty) {
            let temp_data = []
            let item = this.filtered_items_list.find((x) => x.text == key.toUpperCase())
            this.new_item_model = key.toUpperCase();
            if(!item){
                temp_data.push(
                    Object.assign({}, {
                        model: this.new_item_model,
                        id: '',
                        skip: false,
                        qty: ''
                    })
                );
                this.$store
                .dispatch("getItemId", {
                    data: temp_data,
                })
                .then(async (response) => {

                    this.batch_addition.errors = response.data.errors;
                    this.batchSubstitution.list = response.data.itemSubs;
                    if(this.batchSubstitution.list.length != 0){
                        this.batchSubstitution.dialog = true;
                    }
                });
            }
        },
        arrayElementsExistsInString(str = '', arr = []) {
            if (str && arr) {
                for (let i = 0; i < arr.length; i++) {
                    if (str.indexOf(arr[i]) > -1) {
                        return true
                    }
                }

                return false
            }
        },


        promoPlusQty5(key, item_id, quantity) {
            let model_id = item_id;
            let qty = quantity;
            let free_item_qty = 0;
            let factor = 10

            this.$store.dispatch("getitemsmodelid", model_id, {}).then((response) => {
                let items = response.data;
                let item_moq = items.moq;
                let qty_in_sets = qty / item_moq / factor;

                free_item_qty = Math.floor(qty_in_sets) * item_moq;

                if (
                    free_item_qty > 0 &&
                    this.edit == false
                ) {
                    this.orderList.push({
                        quantity: free_item_qty,
                        orig_quantity: free_item_qty,
                        item_id: model_id,
                        foc: true,
                        manual_foc: false,
                        disc_price: this.thousandSeprator(
                            (Math.round(0 * 1000000000000) / 1000000000000).toFixed(12)
                        ),
                        total_amount: this.thousandSeprator(
                            (Math.round(0 * 1000000000000) / 1000000000000).toFixed(12)
                        ),
                        amount: this.thousandSeprator(
                            (Math.round(0 * 1000000000000) / 1000000000000).toFixed(12)
                        ),
                        type: items.item_type_text,
                        item: {
                            id: items.id,
                            model: items.model,
                            name: items.name,
                            description: items.description,
                            type_id: items.type_id,
                            category_id: items.category_id,
                            uom_id: items.uom_id,
                            item_uom_text: items.item_uom_text,
                            barcode: items.barcode,
                            selling_price: items.selling_price,
                            sp_price: items.sp_price,
                            srp: items.selling_price,
                            override_amount: null,
                            moq: items.moq,
                        },
                        is_bundle: true,
                        is_editable: false,
                        promos: [],
                        promo_items_id: "",
                        promo_amount: "",
                        orig_sp_price: items.sp_price,
                        orig_selling_price: items.selling_price,
                        backend_promo: true,
                        item_free: true,
                    });
                    this.orderList[key].bundle_ids.push(model_id);
                } else {
                    this.orderList[key].bundle_ids.forEach((bundle_id, index) => {
                        var result = this.orderList.filter((obj) => {
                            return (
                                obj.item_id === bundle_id &&
                                obj.is_bundle == true &&
                                obj.backend_promo == true
                            );
                        });
                        if (result != null && result.length > 0) {
                            result[0].quantity = free_item_qty <= 0 ? 0 : free_item_qty;
                        }
                    });
                }
                this.edit = false;
            });
        }
    },
    mounted() {

    },
    computed: {
        ...mapGetters(["GET_GENTRADE_CUSTOMERS"]),
        // a computed getter
        sales_total_amount: function () {
            var total_amount = 0;
            if (this.orderList.length > 0) {
                this.orderList.forEach((orderList, index) => {
                    if (orderList.total_amount != "") {
                        if(typeof orderList.total_amount == 'string'){
                            orderList.total_amount = orderList.total_amount.replace(/,/g, "")
                        }
                        total_amount += parseFloat(
                            orderList.total_amount
                        );
                    }
                });
            }
            return this.thousandSeprator(total_amount);
        },
        filtered_items_list: function () {
            var filter_ids = [];
            if (this.promo_id != null) {
                filter_ids = this.shortlist_item_ids;
            } else {
                filter_ids = this.items_not_in_promo;
            }
            this.exclusive_items.forEach(e=>{
                filter_ids.push(e)
            })
            if (
                this.orderList.length == 1 ||
                this.orderList[0].item.type_id == null ||
                this.orderList[0].item.type_id == ""
            ) {
                if (typeof filter_ids !== "undefined" && filter_ids.length > 0) {
                    return this.items_list.filter((item) => {
                        return filter_ids.includes(item.value);
                    });
                } else {
                    return this.items_list;
                }
            } else {
                if (
                    typeof filter_ids !== "undefined" &&
                    filter_ids.length > 0 &&
                    this.action == "Add"
                ) {
                    return this.items_list.filter((item) => {
                        if (this.orderList[0].item.type_id != 2) {
                            if (this.orderList[0].item.type_id == 1) {
                                if (this.has_promo) {
                                    if (this.promo_300k_categories.includes(this.orderList[0].item.category_id)) {
                                        return item.brand_id == this.orderList[0].item.brand_id &&
                                            item.type_id == this.orderList[0].item.type_id &&
                                            item.category_id == this.orderList[0].item.category_id &&
                                            filter_ids.includes(item.value)
                                    } else {
                                        return item.brand_id == this.orderList[0].item.brand_id &&
                                            item.type_id == this.orderList[0].item.type_id &&
                                            !this.promo_300k_categories.includes(item.category_id) &&
                                            filter_ids.includes(item.value)
                                    }
                                } else {
                                    return item.brand_id == this.orderList[0].item.brand_id &&
                                        item.type_id == this.orderList[0].item.type_id &&
                                        filter_ids.includes(item.value)
                                }
                            } else {
                                return item.brand_id == this.orderList[0].item.brand_id &&
                                    item.type_id == this.orderList[0].item.type_id &&
                                    filter_ids.includes(item.value)
                            }
                        } else {
                            return item.brand_id == this.orderList[0].item.brand_id &&
                                item.type_id == this.orderList[0].item.type_id &&
                                item.category_id == this.orderList[0].item.category_id &&
                                filter_ids.includes(item.value)
                        }
                    });
                } else {
                    if (this.action == "Edit") {
                        var editListIds = this.orderList.map(function (order) {
                            return order.item_id;
                        });
                        return this.items_list.filter((item) => {
                            if (this.orderList[0].item.type_id == 1) {
                                if (this.has_promo) {
                                    if (this.promo_300k_categories.includes(this.orderList[0].item.category_id)) {
                                        return item.brand_id == this.orderList[0].item.brand_id &&
                                            item.type_id == this.orderList[0].item.type_id &&
                                            item.category_id == this.orderList[0].item.category_id &&
                                            editListIds.includes(item.value)
                                    } else {
                                        return item.brand_id == this.orderList[0].item.brand_id &&
                                            item.type_id == this.orderList[0].item.type_id &&
                                            !this.promo_300k_categories.includes(item.category_id) &&
                                            editListIds.includes(item.value)
                                    }
                                } else {
                                    return item.brand_id == this.orderList[0].item.brand_id &&
                                        item.type_id == this.orderList[0].item.type_id
                                        // && editListIds.includes(item.value)
                                }
                            } else {
                                return item.brand_id == this.orderList[0].item.brand_id &&
                                    item.type_id == this.orderList[0].item.type_id
                                    // && editListIds.includes(item.value)
                            }
                        });
                    } else {
                        return this.items_list.filter((item) => {
                            return (
                                item.brand_id == this.orderList[0].item.brand_id &&
                                item.type_id == this.orderList[0].item.type_id
                            )
                        });
                    }
                }
            }
        },
        filtered_customers_list: function (val) {
            var filter_ids = [];
            if (this.promo_id != "") {
                filter_ids = this.shortlist_customer_ids;
            }

            if (typeof filter_ids !== "undefined" && filter_ids.length > 0) {
                return this.customers_list.filter((item) => {
                    return filter_ids.includes(item.value);
                });
            } else if (filter_ids.length == 0 && this.promo_id) {
                return [];
            } else {
                return this.customers_list;
            }
        },
    },
};
</script>
