import LoginView from "@/views/credentials/LoginView.vue";
import ForgotPassword from "@/views/credentials/ForgotPassword.vue";
import SplashScreenView from "@/views/main/layouts/SplashScreen/SplashScreen.vue";
import ConfirmResetPassword from "@/views/credentials/ConfirmResetPassword.vue";
import RecoverCode from "@/views/credentials/CodeSentEmailComponent.vue";
import ResetPassword from "@/views/credentials/ResetPasswordComponent.vue";

import store from '@/store'

const routes =
  [{
    path: '/login',
    name: 'login',
    component: LoginView,
    meta: {
      requiresAuth: false,
    }
  },
  {
    path: '/',
    name: 'splashscreen',
    component: SplashScreenView,
    meta: {
      requiresAuth: false,
    },
    beforeEnter:(to,from,next)=>{
      if (window.location.search) {
        localStorage.removeItem('token')

        let urlParams = new URLSearchParams(window.location.search);
        const username = urlParams.get('username');
        const password = urlParams.get('password');
        const token = urlParams.get('token')

        if (token) {
          localStorage.setItem("token", atob(token.split('%2B').join('+').split('%2F').join('/').split('%3D').join('=')));
          window.location.href = window.location.origin + window.location.pathname + '#/home'
          store.dispatch("renderSideNav");
        } else {
          let payload = {
            username: username,
            password: atob(password.split('%2B').join('+').split('%2F').join('/').split('%3D').join('='))
          }

          store.dispatch("login", payload).then((success) => {
            localStorage.setItem("token", success.access_token);
            store.dispatch("renderSideNav");
            window.location.href = window.location.origin + window.location.pathname + '#/home'
          })
        }
      } else {
        next()
      }
    },
  },
  {
    path: '/forgotpassword',
    name: 'forgotpassword',
    component: ForgotPassword,
    meta: {
      requiresAuth: false,
    }
  },
  {
    path: '/confirmresetpassword',
    name: 'confirmresetpassword',
    component: ConfirmResetPassword,
    beforeEnter: (to, from, next) => {
          if (store.getters.FOUND_USER) {
            next();
          }
          else{
            next('/login')
          }
    },
    meta: {
      requiresAuth: false,
    }
  },
  {
    path: '/recover/code',
    name: 'recover_code',
    component: RecoverCode,
    beforeEnter: (to, from, next) => {
      let credentials = btoa('credentials_id');
      let form = {
        username: null,
        user_id: window.localStorage.getItem(credentials)
      }
      if(window.localStorage.getItem(credentials)){
        store.dispatch("forgotPassword", form).then((success) => {
          if(success){
            next();
          }
      });
      }
      else{
        next('/');
      }
    },
    meta: {
      requiresAuth: false,
    }
  },
  {
    path: '/resetpassword',
    name: 'resetpassword',
    component: ResetPassword,
    beforeEnter: (to, from, next) => {
      if (store.getters.GET_VERIFICATION_CODE) {
        next();
      }
      else{
        next('/recover/code');
      }
    },
    meta: {
      requiresAuth: false,
    }
  }];
export default routes;
