<template>
    <div class="w-100">
        <v-app id="my_vue_app">
            <v-card>
                <v-row v-if="!service" class="ma-3">
                    <v-col sm="12" md="2">
                        <v-autocomplete
                            v-model="filter.customer_id"
                            :items="customers_list"
                            label="Select Customer"
                            dense
                            outlined
                            hide-details
                            clearable
                            auto-select-first
                        ></v-autocomplete>
                    </v-col>
                    <v-col sm="12" md="2">
                        <v-autocomplete
                            v-model="filter.salesman_id"
                            :items="salesman_list"
                            label="Select Salesman"
                            dense
                            outlined
                            hide-details
                            clearable
                            auto-select-first
                        ></v-autocomplete>
                    </v-col>
                    <v-col sm="12" md="2">
                        <v-autocomplete
                            v-model="filter.user_id"
                            :items="users_list"
                            label="Select Issuer"
                            item-value="id"
                            item-text="name"
                            dense
                            outlined
                            clearable
                            small-chips
                            multiple
                            deletable-chips
                        ></v-autocomplete>
                    </v-col>
                   <v-col sm="12" md="4">
                        <v-autocomplete
                            v-model="filter.statuses"
                            :items="statusOptions"
                            item-value="id"
                            item-text="text"
                            item-color="color"
                            clearable
                            deletable-chips
                            multiple
                            dense
                            outlined
                            small-chips
                            label="Select Status"
                        >
                            <!-- @input="allConfig()" -->
                            <template v-slot:selection="{ item }">
                                <v-chip
                                x-small
                                :color="item.color"
                                :text-color="item.textColor"
                                close
                                @click:close="removeChip(item)"
                                class="my-1"
                                >
                                {{ item.text }}
                                </v-chip>
                            </template>
                        </v-autocomplete>
                    </v-col>
                    <v-col sm="12" md="1" class="d-flex ml-2">
                        <v-btn @click="searchInput()">Filter</v-btn>
                    </v-col>
                    <v-spacer></v-spacer>

                </v-row>

                <v-row class="ma-3">
                    <v-col sm="6" md="2" id="date_from">
                        <v-menu
                            v-model="menu2"
                            :close-on-content-click="false"
                            :nudge-right="40"
                            transition="scale-transition"
                            min-width="290px"
                        >
                            <template v-slot:activator="{ on }">
                                <v-text-field
                                    v-model="filter.sales_order_dates[0]"
                                    v-on="on"
                                    dense
                                    outlined
                                    hide-details
                                    readonly
                                    append-icon="mdi-calendar-clock"
                                    label="Date From"
                                ></v-text-field>
                            </template>
                            <v-date-picker v-model="filter.sales_order_dates[0]" @input="menu2 = false"></v-date-picker>
                        </v-menu>
                    </v-col>
                    <v-col sm="6" md="2" id="date_to">
                        <v-menu
                            v-model="menu3"
                            :close-on-content-click="false"
                            :nudge-right="40"
                            transition="scale-transition"
                            min-width="290px"
                        >
                            <template v-slot:activator="{ on }">
                                <v-text-field
                                    v-model="filter.sales_order_dates[1]"
                                    v-on="on"
                                    dense
                                    outlined
                                    hide-details
                                    readonly
                                    append-icon="mdi-calendar-clock"
                                    label="Date To"
                                ></v-text-field>
                            </template>
                            <v-date-picker v-model="filter.sales_order_dates[1]" @input="menu3 = false"></v-date-picker>
                        </v-menu>
                    </v-col>

                    <v-col sm="6" md="2">
                        <v-autocomplete
                            v-model="filter.type_selection"
                            :items="item_type_selection"
                            dense
                            outlined
                            hide-details
                            auto-select-first
                            label="Select Item Type"
                            clearable
                        ></v-autocomplete>
                    </v-col>
                    
                    <v-col sm="1">
                        <v-autocomplete
                            v-model="trade.base"
                            :items="trade.items"
                            label="Trade"
                            auto-select-first
                            hide-details
                            clearable
                            dense
                            outlined
                        ></v-autocomplete>
                    </v-col>
                    <v-spacer></v-spacer>
                    <v-col sm="12" md="2" class="mr-2 align-items-end">
                        <v-text-field
                            v-model="filter.search"
                            single-line
                            hide-details
                            clearable
                            label="Search SO# / SQ# / PO# / Remarks"
                            @keydown.enter="searchInput()"
                            @click:clear="filter.search='';searchInput()"
                        ></v-text-field>
                    </v-col>
                    <v-col sm="12" md="2" class="mr-3 align-items-end">
                        <v-text-field
                            v-model="filter.searchItem"
                            label="Search Item Model / Name"
                            single-line
                            hide-details
                            clearable
                            append-outer-icon="mdi-magnify"
                            @keydown.enter="searchInput()"
                            @click:append-outer="searchInput()"
                            @click:clear="filter.searchItem='';searchInput()"
                        ></v-text-field>
                    </v-col>
                </v-row>
                <v-container fluid>
                    <!-- <ReportsCardsComponentVue :totals="totals" :cards="cards"  style="width:100%;" :setClass="'ma-0 pa-0'"></ReportsCardsComponentVue> -->
                    <v-layout row>
                        <div class="col-md-12 col-lg-12">
                            <v-data-table
                                v-model="dataTableSo"
                                :headers="headers"
                                :items="salesOrders"
                                :search="filter.search"
                                :options.sync="options"
                                :server-items-length="totalSalesOrders"
                                :items-per-page="10"
                                :loading="!loaded"
                                :show-select="filter.status_id != -1"
                                :expanded.sync="expanded"
                                :footer-props="{
                                    itemsPerPageOptions: itemsPerPageOptions,
                                }"
                                loading-text="Loading... Please wait"
                                class="w-100"
                                show-expand
                                disable-sort
                                @input="getSelectedID"
                                @item-expanded="loadDetails"
                            >
                                <template v-slot:expanded-item="{ headers, item }">
                                    <td :colspan="headers.length" class="p-0">
                                        <!-- <v-simple-table dense dark>
                                                <thead class="bg-primary">
                                                    <tr>
                                                        <td class="side-borders-white" style="width: 75px"><center>PL#</center></td>
                                                        <td class="side-borders-white" style="width: 75px"><center>PL status</center></td>
                                                        <td class="side-borders-white" style="width: 100px"><center>PL amount</center></td>
                                                        <td class="side-borders-white" style="width: 75px"><center>Dispatch#</center></td>
                                                        <td class="side-borders-white" style="width: 100px"><center>Status</center></td>
                                                        <td class="side-borders-white" style="width: 100px"><center>ETA</center></td>
                                                        <td class="side-borders-white" style="width: 100px"><center>ATA</center></td>
                                                    </tr>
                                                </thead>
                                                <tbody class="bg-success" style="display:inherit">
                                                    <span>

                                                        <tr  v-for="(detail, i) in item.details" :key="i">

                                                            <td class="side-borders-white" style="width: 75px"><center>{{detail.packing_list_num}}</center></td>
                                                            <td style="width: 75px">
                                                                <span class="badge badge-saved" v-if="item.status_text=='pending'">{{ item.status_text }}</span>
                                                                <span class="badge badge-submitted" v-if="item.status_text=='submitted'">{{ item.status_text }}</span>
                                                                <span class="badge badge-purple" v-else-if="item.status_text=='confirmed'">{{ item.status_text }}</span>
                                                                <span class="badge badge-warning" v-else-if="item.status_text=='rejected'">{{ item.status_text }}</span>
                                                                <span class="badge badge-danger" v-else-if="item.status_text=='canceled'">{{ item.status_text }}</span>
                                                                <span class="badge badge-dark" v-else-if="item.status_text=='deleted'">{{ item.status_text }}</span>
                                                                <span class="badge badge-success" v-else-if="item.status_text=='completed'">{{ item.status_text }}</span>
                                                                <span class="badge badge-for-accounting" v-if="item.status_text == 'for approval'">{{ item.status_text }}</span>
                                                            </td>

                                                            <td class="side-borders-white" style="width: 100px"> <span>{{ detail.total_amount | currency }}</span></td>

                                                            <td class="side-borders-white" style="width: 75px"><center>{{detail.dispatch_id}}</center></td>
                                                            <td style="width: 100px">
                                                                <center>
                                                                    <span class="badge bg-warning" v-if="detail.dispatch_status_text == 'saved'">{{
                                                                        detail.dispatch_status_text
                                                                    }}</span>
                                                                    <span class="badge bg-info text-white" v-else-if="detail.dispatch_status_text == 'confirmed'">{{
                                                                        detail.dispatch_status_text
                                                                    }}</span>
                                                                    <span class="badge bg-success text-white" v-else-if="detail.dispatch_status_text == 'dispatched'">{{
                                                                        detail.dispatch_status_text
                                                                    }}</span>
                                                                </center>
                                                            </td>
                                                            <td class="side-borders-white" style="width: 100px"><center>{{detail.etd === '0000-00-00 00:00:00' ? 'No Data' : detail.etd | formatDate}}</center></td>
                                                            <td class="side-borders-white" style="width: 100px"><center>{{detail.atd === '0000-00-00 00:00:00' ? 'Np Data' : detail.atd | formatDate}}</center></td>

                                                        </tr>
                                                    </span>
                                                </tbody>

                                        </v-simple-table> -->
                                        <v-data-table
                                            :headers="expansion.dataTable.headers"
                                            :items="item.details"
                                            :item-class="expansionStyle"
                                            class="my-custom-data-table"
                                            style="background-color:#000000"
                                            dense
                                            hide-default-footer
                                        >
                                        <template v-slot:header="{ props }">
                                            <tr >
                                              <th v-for="header in props.headers" :key="header.value" :style="{ backgroundColor: 'black', color: 'white' }">
                                                {{ header.text }}
                                              </th>
                                            </tr>
                                          </template>
                                        <template v-slot:item="{ item }">
                                            <tr :style="{ color: 'white' }">
                                              <td>{{ item.cf_id }}</td>
                                              <td>{{ item.packing_list_num }}</td>
                                              <td>
                                                <span class="badge badge-saved" v-if="item.status_text=='pending'">{{ item.status_text }}</span>
                                                <span class="badge badge-submitted" v-if="item.status_text=='submitted'">{{ item.status_text }}</span>
                                                <span class="badge badge-purple" v-else-if="item.status_text=='confirmed'">{{ item.status_text }}</span>
                                                <span class="badge badge-warning" v-else-if="item.status_text=='rejected'">{{ item.status_text }}</span>
                                                <span class="badge badge-danger" v-else-if="item.status_text=='canceled'">{{ item.status_text }}</span>
                                                <span class="badge badge-dark" v-else-if="item.status_text=='deleted'">{{ item.status_text }}</span>
                                                <span class="badge badge-success" v-else-if="item.status_text=='completed'">{{ item.status_text }}</span>
                                                <span class="badge badge-for-accounting" v-if="item.status_text == 'for approval'">{{ item.status_text }}</span>
                                            </td>
                                              <td>&#x20b1; {{ item.total_amount | currency}}</td>
                                              <td>{{ item.dispatch_id }}</td>
                                              <td>
                                                <span class="badge bg-warning" v-if="item.dispatch_status_text == 'saved'">{{
                                                    item.dispatch_status_text
                                                }}</span>
                                                <span class="badge bg-info text-white" v-else-if="item.dispatch_status_text == 'confirmed'">{{
                                                    item.dispatch_status_text
                                                }}</span>
                                                <span class="badge bg-success text-white" v-else-if="item.dispatch_status_text == 'dispatched'">{{
                                                    item.dispatch_status_text
                                                }}</span>
                                            </td>
                                            <td>{{ item.dispatch_date === '0000-00-00 00:00:00' ? '' :item.dispatch_date | formatDate }}</td>
                                            <td>{{ item.etd === '0000-00-00 00:00:00' ? '' :item.etd | formatDate }}</td>
                                            <td>{{ item.etd === '0000-00-00 00:00:00' ? '' :item.atd | formatDate }}</td>

                                            </tr>
                                          </template>

                                            <template v-slot:[`item.etd`]="{ item }">
                                                <span style="font-size: 10px !important; " >
                                                    {{ item.etd === '0000-00-00 00:00:00' ? '' :item.etd | formatDate }}
                                                </span>
                                            </template>
                                            <template v-slot:[`item.atd`]="{ item }">
                                                <span style="font-size: 10px !important">
                                                    {{ item.atd === '0000-00-00 00:00:00' ? '' :item.etd | formatDate }}
                                                </span>
                                            </template>
                                            <template v-slot:[`item.dispatch_date`]="{ item }">
                                                <span style="font-size: 10px !important;" >
                                                    {{ item.dispatch_date === '0000-00-00 00:00:00' ? '' :item.dispatch_date | formatDate }}
                                                </span>
                                            </template>
                                            <template v-slot:[`item.total_amount`]="{ item }">
                                                <span style="">
                                                    &#x20b1; {{ item.total_amount | currency }}
                                                </span>
                                            </template>
                                            <template v-slot:[`item.status_text`]="{ item }">
                                                <span class="badge badge-saved" v-if="item.status_text=='pending'">{{ item.status_text }}</span>
                                                <span class="badge badge-submitted" v-if="item.status_text=='submitted'">{{ item.status_text }}</span>
                                                <span class="badge badge-purple" v-else-if="item.status_text=='confirmed'">{{ item.status_text }}</span>
                                                <span class="badge badge-warning" v-else-if="item.status_text=='rejected'">{{ item.status_text }}</span>
                                                <span class="badge badge-danger" v-else-if="item.status_text=='canceled'">{{ item.status_text }}</span>
                                                <span class="badge badge-dark" v-else-if="item.status_text=='deleted'">{{ item.status_text }}</span>
                                                <span class="badge badge-success" v-else-if="item.status_text=='completed'">{{ item.status_text }}</span>
                                                <span class="badge badge-for-accounting" v-if="item.status_text == 'for approval'">{{ item.status_text }}</span>
                                                <!-- <span class="badge badge-approved" v-else-if="item.status_text == 'approved'">{{ item.status_text }}</span> -->
                                            </template>
                                            <template v-slot:[`item.dispatch_status_text`]="{ item }">
                                                <span class="badge bg-warning" v-if="item.dispatch_status_text == 'saved'">{{
                                                    item.dispatch_status_text
                                                }}</span>
                                                <span class="badge bg-info text-white" v-else-if="item.dispatch_status_text == 'confirmed'">{{
                                                    item.dispatch_status_text
                                                }}</span>
                                                <span class="badge bg-success text-white" v-else-if="item.dispatch_status_text == 'dispatched'">{{
                                                    item.dispatch_status_text
                                                }}</span>
                                            </template>

                                        </v-data-table>
                                    </td>
                                </template>
                                <template v-slot:activator="{ on }">
                                    <span v-on="on" @click="menu_date_range = true" :class="{'font-weight-bold': filter.sales_order_dates.length > 0}">
                                        {{ header.text }}
                                    </span>
                                </template>

                                <template v-slot:[`item.customer_name`]="{ item }">
                                    <span style="font-size: 10px !important">
                                        {{ item.customer_name }}
                                    </span>
                                </template>

                                <template v-slot:[`item.remarks`]="{ item }">
                                    <span style="font-size: 10px !important">
                                        {{ item.remarks }}
                                    </span>
                                </template>

                                <template v-slot:[`item.total_amount`]="{ item }">
                                    <span class="text-nowrap">
                                        &#x20b1;{{ item.total_amount | currency }}
                                    </span>
                                </template>

                                <template v-slot:[`item.created_at`]="{ item }">
                                    <span class="text-nowrap">
                                        {{ item.created_at | formatDate }}
                                    </span>
                                </template>

                                <template v-slot:[`item.approve_date`]="{ item }">
                                    <span class="text-nowrap">
                                        {{ item.approve_date | formatDate }}
                                    </span>
                                </template>

                                <template v-slot:[`item.acc_approved_date`]="{ item }">
                                    <span class="text-nowrap">
                                        {{ item.acc_approved_date | formatDate }}
                                    </span>
                                </template>

                                <template v-slot:[`item.status`]="{ item }">
                                    <span class="badge badge-saved" v-if="item.status == 0">saved</span>
                                    <span class="badge badge-for-sales" v-if="item.status == 1">for sales</span>
                                    <span class="badge badge-for-accounting" v-if="item.status == 2">for accounting</span>
                                    <span class="badge badge-approved" v-else-if="item.status == 3">approved</span>
                                    <span class="badge badge-completed" v-else-if="item.status == 4">completed</span>
                                    <span class="badge badge-warning" v-else-if="item.status == 5">rejected</span>
                                    <span class="badge badge-danger" v-else-if="item.status == 6">canceled</span>
                                    <span class="badge badge-dark" v-else-if="item.status == 7">deleted</span>
                                    <span class="badge badge-purple" v-else-if="item.status == 8">converted</span>
                                    <span class="badge badge-exec-approved" v-else-if="item.status == 9">exec approved</span>
                                    <span class="badge badge-pending" v-else-if="item.status == 10">partial</span>
                                    <span class="badge badge-for-exec" v-else-if="item.status == 11">for exec approval</span>
                                    <span class="badge badge-warning" v-else-if="item.status == 12">stockcard</span>
                                    <!-- <span class="badge badge-for-credit-limit" v-else-if="item.status == 14">for credit limit</span> -->
                                    <!-- <span class="badge badge-for-credit-terms" v-else-if="item.status == 15">for credit terms</span> -->
                                    <span class="badge badge-for-scheduling" v-else-if="item.status == 16">for scheduling</span>
                                    <span class="badge badge-submitted" v-else-if="item.status == 17">waiting for stocks</span>
                                    <span class="badge badge-preparing" v-else-if="item.status == 18">preparing</span>
                                    <span class="badge badge-prepared" v-else-if="item.status == 19">prepared</span>
                                    <span class="badge badge-checking" v-else-if="item.status == 20">checking</span>
                                    <span class="badge badge-checked" v-else-if="item.status == 21">checked</span>
                                    <span class="badge badge-invoiced" v-else-if="item.status == 22">invoiced</span>
                                </template>

                                <template v-slot:[`item.movement_status_text`]="{ item }">
                                    <span class="badge badge-warning" v-if="item.movement_status_text == 'schedule'">{{ item.movement_status_text }}</span>
                                    <span class="badge badge-dark" v-if="item.movement_status_text == 'in-transit'">{{ item.movement_status_text }}</span>
                                    <span class="badge badge-saved" v-if="item.movement_status_text == 'delivered'">{{ item.movement_status_text }}</span>
                                </template>

                                <template v-slot:[`item.promo_id`]="{ item }">
                                    <span class="badge badge-primary" v-if="item.promo_id != null">Yes</span>
                                    <span class="badge badge-danger" v-else>No</span>
                                </template>

                                <template v-slot:[`item.stock_card`]="{ item }">
                                    <span class="badge badge-primary" v-if="item.stock_card">Yes</span>
                                    <span class="badge badge-danger" v-else>No</span>
                                </template>

                                <template v-slot:[`item.trade`]="{ item }">
                                    <span class="badge badge-primary" v-if="item.trade == 1">Yes</span>
                                    <span class="badge badge-danger" v-else>No</span>
                                </template>

                                <template v-slot:[`item.action`]="{ item }">
                                    <span class="text-nowrap">
                                        <v-btn text icon color="orange" v-if="userAccess.view">
                                            <v-icon class="btn-action" @click="viewSalesOrder(item, 'view')" small>mdi-eye</v-icon>
                                        </v-btn>
                                        <!-- <v-btn text icon color="blue" v-if="(userAccess.edit && [0, 10, 16, 17].includes(item.status)) || (userAccess.edit_approved && [0, 10, 16, 17].includes(item.status))">
                                            <v-icon class="btn-action" small @click="viewSalesOrder(item.id, 'edit')">mdi-square-edit-outline</v-icon>
                                        </v-btn> -->
                                        <!-- <v-btn text icon color="red" v-if="userAccess.delete>
                                            <v-icon class="btn-action" small @click="initiateOverride('delete', item.id, true)">fas fa-trash-alt</v-icon>
                                        </v-btn> -->
                                        <!-- <v-btn text icon color="red" v-if="userAccess.cancel && !service && statuses_allowed_for_cancellation.includes(item.status)">
                                            <v-icon class="btn-action" small @click="initiateOverride('cancel', item.id, true)">mdi-cancel</v-icon>
                                        </v-btn> -->
                                        <v-btn text icon color="red" v-if="userAccess.cancel && !service && statuses_allowed_for_cancellation.includes(item.status)">
                                            <v-icon class="btn-action" small @click="viewSalesOrder(item, 'cancel')">mdi-cancel</v-icon>
                                        </v-btn>
                                    </span>
                                </template>

                                <!-- <template v-slot:[`header.sales_order_date`]="{ header }">
                                    <v-menu
                                        v-model="menu_date_range"
                                        :close-on-content-click="false"
                                        transition="scale-transition"
                                        min-width="290px"
                                        >
                                        <template v-slot:activator="{ on }">
                                            <span v-on="on" @click="menu_date_range = true" :class="{ 'font-weight-bold': sales_order_dates.length > 0 }">{{ header.text }}</span>
                                        </template>
                                        <v-date-picker v-model="sales_order_dates" range absolute>
                                            <v-spacer></v-spacer>
                                            <v-btn color="primary" @click="sales_order_dates=[]; getAllSalesOrders(); menu_date_range = false">Reset</v-btn>
                                            <v-btn color="primary" @click="menu_date_range = false; getAllSalesOrders()">OK</v-btn>
                                            <v-btn color="primary" @click="menu_date_range = false">Cancel</v-btn>
                                        </v-date-picker>
                                    </v-menu>
                                </template> -->
                            </v-data-table>
                            <div v-if="convertBtn" class="table-footer-prepend d-flex pl-2 align-center" style="margin-top: -52px; height: 58px">
                                <span class="m-1" v-if="filter.status_id == 0">
                                    <v-btn :loading="btn_loader" @click="updateSOStatus(1)">Submit</v-btn>
                                </span>
                                <span class="m-1" v-if="(userAccess.approval_exec || userAccess.approval_exec2) && filter.status_id == 11">
                                    <v-btn :loading="btn_loader" @click="updateSOStatus(9)" :disabled="filter.status_id != 11">Approve (Exec)</v-btn>
                                </span>
                                <span class="m-1" v-if="userAccess.approval_sales && (filter.status_id == 1 || filter.status_id == 9)">
                                    <v-btn :loading="btn_loader" @click="updateSOStatus(2)" :disabled="!userAccess.approval_sales || (filter.status_id != 1 && filter.status_id != 9)">Approve (Sales)</v-btn>
                                </span>
                                <span class="m-1" v-else-if="userAccess.approval_stores && (filter.status_id == 1 || filter.status_id == 9)">
                                    <v-btn :loading="btn_loader" @click="updateSOStatus(2)" :disabled="!userAccess.approval_stores || (filter.status_id != 1 && filter.status_id != 9)">Approve (Stores)</v-btn>
                                </span>
                                <span class="m-1" v-if="userAccess.approval_acct && filter.status_id == 2">
                                    <v-btn :loading="btn_loader" @click="updateSOStatus(12)" :disabled="!userAccess.approval_acct || filter.status_id != 2">Approve (Accounting)</v-btn>
                                </span>
                                <span class="m-1" v-if="(filter.status_id == 3 || filter.status_id == 10) && userAccess.convert_to_wpl">
                                    <v-btn :loading="btn_loader" @click="updateSOStatus(12)">Convert to WPF</v-btn>
                                </span>
                            </div>
                        </div>
                    </v-layout>
                </v-container>
                <v-container fluid style="display: none">
                    <div id="div_logo_conversion">
                        <div style="font-size: 12px; line-height: 1.9">
                            <p>
                                <span style="font-size: 18px; font-weight: bold">WADFOW Tools Philippines Corp.</span><br />
                                420 Del Monte Ave, Brgy. Siena, Quezon City<br />
                                Contact Us Cell No: 0917-708-3850, 0933-856-200<br />
                                Tel. No.: (02)8711-6742, (02) 7717-4059<br />
                            </p>
                        </div>
                    </div>

                    <div id="div_sales_order_num_conversion" style="font-size: 12px; line-height: 1.9">
                        <div style="border-top: 1px dashed #cfcfcf; padding: 2px; padding-bottom: -4px; text-align: center; border-bottom: none;">&nbsp;</div>
                        <p>
                            <span style="font-size: 18px; font-weight: bold">Sales Order</span><br /><b>SO # {{ print.order_num }}</b>
                        </p>
                    </div>

                    <div>
                        <table id="customer_details_conversion">
                            <tbody>
                                <tr>
                                    <td>Customer:</td>
                                    <td>{{ print.customer_name | uppercase }}</td>
                                    <td>Date:</td>
                                    <td>{{ print.created_at | formatDate }}</td>
                                </tr>
                                <tr>
                                    <td>Address:</td>
                                    <td>{{ print.ship_address | uppercase }}</td>
                                    <td>Terms:</td>
                                    <td>{{ print.so_terms == 0 ? "CASH" : print.so_terms + " days"}}</td>
                                </tr>
                                <tr>
                                    <td>Remarks:</td>
                                    <td>{{ print.remarks }}</td>
                                    <td>PO#:</td>
                                    <td>{{ print.po_num }}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>

                    <div>
                        <table id="items_details_conversion">
                            <thead>
                                <tr>
                                    <th>#</th>
                                    <th>QTY</th>
                                    <th>UNITS</th>
                                    <th>MODEL</th>
                                    <th>DESCRIPTION</th>
                                    <th>UNIT PRICE</th>
                                    <th>TOTAL</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(item, index) in print.so_items" :key="index">
                                    <td>{{ index + 1 }}</td>
                                    <td>{{ item.quantity }}</td>
                                    <td>{{ item.item_uom_text }}</td>
                                    <td>{{ item.model }}</td>
                                    <td>{{ item.name }}</td>
                                    <td v-if="item.total_amount > 0">{{ item.srp | currency }}</td>
                                    <td v-else>FOC</td>
                                    <td v-if="item.total_amount > 0">{{ computePrintOutItemTotal(item.srp, item.quantity) | currency }}</td>
                                    <td v-else>FOC</td>
                                </tr>
                                <tr>
                                    <td colspan="7">***** NOTHING FOLLOWS *****</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>

                    <div>
                        <table id="items_details_without_price_conversion">
                            <thead>
                                <tr>
                                    <th>#</th>
                                    <th>QTY</th>
                                    <th>UNITS</th>
                                    <th>MODEL</th>
                                    <th>DESCRIPTION</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(item, index) in print.so_items" :key="index">
                                    <td>{{ index + 1 }}</td>
                                    <td>{{ item.quantity }}</td>
                                    <td>{{ item.item_uom_text }}</td>
                                    <td>{{ item.model }}</td>
                                    <td>{{ item.name }}</td>
                                </tr>
                                <tr>
                                    <td colspan="5">***** NOTHING FOLLOWS *****</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>

                    <div>
                        <table id="total_details_conversion">
                            <tbody>
                                <tr>
                                    <td>
                                        <p>{{ print.itemTypeDiscountsPDF[0] != null ? print.itemTypeDiscountsPDF[0] : "" }}</p>
                                    </td>
                                    <td>
                                        <p>GROSS SALES:</p>
                                    </td>
                                    <td>
                                        <p>PHP</p>
                                    </td>
                                    <td>
                                        <p>{{ print.gross_amount | currency }}</p>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <p>{{ print.itemTypeDiscountsPDF[1] != null ? print.itemTypeDiscountsPDF[1] : "" }}</p>
                                    </td>
                                    <td>
                                        <p>Less - Discount:</p>
                                    </td>
                                    <td>
                                        <p></p>
                                    </td>
                                    <td>
                                        <p>{{ print.disc_amount | currency }}</p>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <p>{{ print.itemTypeDiscountsPDF[2] != null ? print.itemTypeDiscountsPDF[2] : "" }}</p>
                                    </td>
                                    <td>
                                        <p>TOTAL SALES:</p>
                                    </td>
                                    <td>
                                        <p>PHP</p>
                                    </td>
                                    <td>
                                        <p>{{ print.total_amount | currency }}</p>
                                    </td>
                                </tr>
                                <tr>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div>
                        <table id="num_of_items_conversion">
                            <tbody>
                                <tr>
                                    <td><span>No. of Items: {{ print.no_of_items }}</span></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div>
                        <table id="footer_conversion">
                            <tbody>
                                <tr>
                                    <td>Issued By</td>
                                    <td>Checked By</td>
                                </tr>
                                <tr>
                                    <td><br /><br /><br /><br />{{ print.issuer_name }}</td>
                                    <td><br /><br /><br /><br /></td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </v-container>
            </v-card>
        </v-app>

        <!-- VIEW MODAL-->
        <sales-orders-view-component
            :viewSalesOrderId="viewSalesOrderId"
            :service="service"
            @closeView="closeView"
        ></sales-orders-view-component>

        <!-- <confirm-override-component
            :approve_override_dialog="override.dialog"
            :departments_allowed="override.departments_allowed"
            :heading="override.heading"
            @closeConfirmOverride="closeConfirmOverride"
        ></confirm-override-component> -->
    </div>
</template>

<script>
import SalesOrdersViewComponent from "./SalesOrdersViewComponent.vue";
import ConfirmOverrideComponent from "@/views/main/Utils/ConfirmOverrideComponent.vue";
import SharedFunctionsComponent from "@/views/main/Shared/SharedFunctionsComponent.vue";
// import ReportsCardsComponentVue from '@/views/main/layouts/Reports/ReportsCardsComponent.vue';
import swal from "sweetalert2";
import { mapGetters } from "vuex";

export default {
    mixins: [
        SharedFunctionsComponent
    ],
    components: {
        SalesOrdersViewComponent,
        ConfirmOverrideComponent,
        // ReportsCardsComponentVue,
    },
    props: [
        "salesOrdersActionResult",
        "status_text",
        "service",
        "print",
    ],
    data() {
        return {
            override: {
                dialog: false,
                heading: "Sales Order",
                departments_allowed: ["SM", "AC", "EX", "IT"],
                authenticated: false,
                action: "",
            },
            reason: {
                text: "",
            },
            actionResult: {
                model: "SalesOrders",
                action: "",
            },

            loaded: false,
            totalSalesOrders: 0,
            options: {},
            salesOrders: [],
            customers_list: [],
            filter:{
                customer_id: "",
                salesman_id: "",
                user_id: "",
                sales_order_dates: [
                    this.$dayjs().startOf("year").format("YYYY-MM-DD"),
                    this.$dayjs().endOf("month").format("YYYY-MM-DD"),
                ],
                search: "",
                searchItem: "",
                status_id: -1,
                statuses:[],
                type_selection:[],
            },
            salesman_list: [],
            users_list: [],
            itemsPerPageOptions: [5, 10, 20, 50, 100],
            itemsPerPage: 10,

            awaitingSearch: false,
            headers: [
                { text: "SO#", align: "left", width: "6%", value: "order_num" },
                { text: "SQ#", align: "left", width: "6%", value: "quote_num" },
                { text: "Customer", align: "left", value: "customer_name" },
                { text: "PO#", align: "left", value: "customer_po_num" },
                { text: "Remarks", align: "left", value: "remarks" },
                { text: "Salesman", align: "left", value: "salesman_name" },
                { text: "Amount", align: "right", value: "total_amount" },
                { text: "Status", align: "left", width: "5%", value: "status" },
                { text: 'Item Type', align: 'left', value: 'item_type', },
                // { text: 'Movement', align: 'left', value: 'movement_status_text', },
                { text: "Date Issued", align: "left", width: "8%", sortable: false, value: "sales_order_date" },
                // { text: "Date Approve (Sales)", align: "left", value: "approve_date" },
                { text: "Date Approve (Acct)", align: "left", value: "acc_approved_date" },
                { text: "Issuer", align: "left", value: "issuer_name" },
                { text: "Promo", align: "left", width: "3%", value: "promo_id" },
                { text: "SC", align: "left", width: "3%", value: "stock_card" },
                { text: "Trade", align: "left", width: "3%", value: "trade" },
                { text: "Actions", align: "center", width: "6%", sortable: false, value: "action" },
            ],

            viewSalesOrderId: 0,
            currentSalesOrderId: 0,


            // menu_date_range: false,

            menu2: false,
            menu3: false,

            userAccess: {
                view: false,
                edit: false,
                delete: false,
                cancel: false,
                edit_approved: false,
                approval_sales: false,
                approval_acct: false,
                approval_exec: false,
                convert_to_wpl: false,
                approval_exec2: false,
                cancel: false,
            },

            isSalesman: false,
            salesmanTitle: "",
            salesmanChildrenIds: [],
            userChildrenIds: [],
            salesmanDetails: null,
            isSalesCoordinator: false,
            currentSalesmanId: "",
            currentSalesmanPositionId: "",

            dataTableSo: [],
            convertBtn: false,
            selected_id: [],
            btn_loader: false,
            type_list: [],
            discounts: [],

            multi_customer_id: "",
            multi_total_amount: 0,
            end_function: 0,

            statuses_allowed_for_cancellation: [0, 1, 2, 3, 9, 10, 11, 12, 16, 17],
            // filter_statuses: [],
            statusIdsToCheck: [0, 11, 1, 2, 9, 3, 16, 8, 10, 4, 12, 6, 17, 18, 19, 20, 21, 22],
            statusOptions: [
                // { id: -1, text: 'All', color:'badge-all', textColor: 'white' },
                { id: 0, text: 'Saved', color: 'badge-saved', textColor: 'white' },
                { id: 11, text: 'For Exec Approval', color: 'badge-for-exec', textColor: 'white' },
                { id: 1, text: 'For Sales', color: 'badge-for-sales', textColor: 'white' },
                { id: 2, text: 'For Accounting', color: 'badge-for-accounting', textColor: 'white' },
                { id: 9, text: 'Exec Approved', color: 'badge-exec-approved', textColor: 'white' },
                { id: 3, text: 'Approved', color: 'badge-approved', textColor: 'white' },
                { id: 16, text: 'For Scheduling', color: 'badge-for-scheduling', textColor: 'black' },
                { id: 8, text: 'Converted', color: 'badge-converted', textColor: 'white' },
                { id: 10, text: 'Partial', color: 'badge-partial', textColor: 'white' },
                { id: 4, text: 'Completed', color: 'badge-completed', textColor: 'white' },
                { id: 12, text: 'Stockcard', color: '#FFAB00', textColor: 'black' },
                { id: 6, text: 'Canceled', color: '#C62828', textColor: 'white' },
                { id: 17, text: 'Waiting for stocks', color: 'badge-submitted', textColor: 'white' },
                { id: 18, text: 'Preparing', color: 'badge-preparing', textColor: 'white' },
                { id: 19, text: 'Prepared', color: 'badge-prepared', textColor: 'white' },
                { id: 20, text: 'Checking', color: 'badge-checking', textColor: 'white' },
                { id: 21, text: 'Checked', color: 'badge-checked', textColor: 'white' },
                { id: 22, text: 'Invoiced', color: 'badge-invoiced', textColor: 'white' },
            ],
            expanded:[],
            expansion:{
                dataTable:{
                    headers:[
                        { text:'CF#',value:'cf_id'},
                        { text:'PL#',value:'packing_list_num'},
                        { text:'Status',value:'status_text'},
                        { text:'PL Amount',value:'total_amount'},
                        { text:'Dispatch#',value:'dispatch_id'},
                        { text:'Dispatch Status',value:'dispatch_status_text'},
                        { text:'Dispatch Date',value:'dispatch_date'},
                        { text:'ETD',value:'etd'},
                        { text:'ATD',value:'atd'},
                    ],
                }
            },
            item_type_selection: [],
            trade: {
                items: [
                    { value: 1, text: 'Yes' },
                    { value: 0, text: 'No' },
                ],
                base: '',
            },
            // cards: [
            //     { title: 'Net Sales Total', icon: 'mdi-cart', icon_class: 'total-amount', cols: 6, sm: 6, md: 3, lg: 3, xl: 3, prefix: '&#x20b1; ', suffix: '', filter: 'currency', value: 'net_sales_total_amount' },
            //     { title: 'Gross Sales Total', icon: 'mdi-cart', icon_class: 'total-amount', cols: 6, sm: 6, md: 3, lg: 3, xl: 3, prefix: '&#x20b1; ', suffix: '', filter: 'currency', value: 'gross_sales_total_amount' },
            //     { title: 'Net Adjustment', icon: 'mdi-cart', icon_class: 'total-amount', cols: 6, sm: 6, md: 3, lg: 3, xl: 3, prefix: '&#x20b1; ', suffix: '', filter: 'currency', value: 'adjustments_total_amount' },
            //     { title: 'Total Customer', icon: 'mdi-account-multiple-outline', icon_class: 'total-customer', cols: 6, sm: 6, md: 3, lg: 3, xl: 3, prefix: '', suffix: '', filter: '', value: 'customer_count' },
            // ],
            // totals: {
            //     net_sales_total_amount: 0,
            //     gross_sales_total_amount: 0,
            //     adjustments_total_amount: 0,
            //     customer_count: 0,
            //     net_cost_total_amount: 0,
            //     cost_margin_percentage: '',
            // },
        };
    },
    methods: {
        removeChip(item) {
             const index = this.filter.statuses.findIndex(i => i.value === item.value);
                if (index > -1) {
                    this.filter.statuses.splice(index, 1);
                }
        },
        async updateSOStatus(newStatus) {
            this.btn_loader = true;
            this.getItemTypes();
            let textMsg =
                this.filter.status_id == 3 || this.filter.status_id == 10
                    ? "Are you sure you want to Convert Selected SO to WPF?"
                    : "Are you sure you want to update selected Sales Order";
            await swal.fire({
                title: "",
                text: textMsg,
                icon: "warning",
                showConfirmButton: true,
                confirmButtonText: "Confirm",
                reverseButtons: true,
                showCancelButton: true,
                allowOutsideClick: false,
            })
            .then(async (willUpdate) => {
                if (willUpdate.isConfirmed) {
                    let payload = {
                        selected_id: this.selected_id,
                        url: "getSODetails",
                    };
                    await this.$store.dispatch("salesOrdersGet", payload).then(async (response) => {
                        let data = response.data;

                        if (data != "") {
                            // for (const index in data.data){
                            //     await new Promise((resolve)=>{
                            //         if(data.data[index].discounts != ''){
                            //             this.discounts = JSON.parse(data.data[index].discounts)
                            //             if(this.discounts != null){
                            //                 this.discounts = this.discounts[0].discounts
                            //             }
                            //         }
                            //         this.print.so_items         = data.data[index].so_items
                            //         this.getItemTypeDiscountsPDF()
                            //         this.print.order_num        = data.data[index].order_num
                            //         this.print.customer_name    = data.data[index].customer_name
                            //         this.print.created_at       = data.data[index].created_at
                            //         this.print.ship_address     = data.data[index].shipping_address_text
                            //         this.print.so_terms         = data.data[index].terms
                            //         this.print.po_num           = data.data[index].customer_po_num
                            //         this.print.gross_amount     = data.data[index].gross_amount
                            //         this.print.disc_amount      = data.data[index].discount_amount
                            //         this.print.total_amount     = data.data[index].total_amount
                            //         this.print.issuer_name      = data.data[index].issuer_name
                            //         this.print.approved_by_acct = data.data[index].approved_by_acct
                            //         this.print.remarks          = data.data[index].remarks
                            //         this.print.so_items.forEach(e => {
                            //             if(e.stock_card == 1){
                            //                 this.print.no_of_items += e.sc_quantity
                            //             }else{
                            //                 this.print.no_of_items += e.quantity
                            //             }
                            //         });
                            //             if(this.print.remarks == data.data[index].remarks){
                            //                 resolve()
                            //             }
                            //     })
                            //     this.renderPDF();
                            //     this.print.no_of_items = 0
                            //     this.end_function = 0
                            // }
                            if (newStatus == 12) {
                                this.convertToWpList();
                            } else {
                                this.updateSalesOrderStatus(newStatus);
                            }
                        }
                    });

                    this.selected_id = []
                } else {
                    this.btn_loader = false;
                }
            });
        },
        updateSalesOrderStatus(newStatus) {
            var convert_to_cash = false;
            let ids = this.selected_id;
            let length = this.selected_id.length - 1;
            if (this.print.approved_by_acct && newStatus == 3) {
                swal.fire({
                    closeOnClickOutside: false,
                    title: "Customers is on hold for accounting approval. ",
                    text: "Do you want to make this a CASH Sales Order?",
                    icon: 'question',
                    buttons: {
                        no: {
                            text: "TERMS",
                            value: false,
                        },
                        yes: {
                            text: "CASH",
                            value: true,
                        },
                    },
                }).then((response) => {
                    if (response) {
                        convert_to_cash = true;
                    }
                    ids.forEach((id, index) => {
                        let payload = {
                            url: "updateSalesOrderStatus/" + id,
                            status: newStatus,
                            convert_to_cash: convert_to_cash,
                        };
                        this.$store.dispatch("salesOrdersPost", payload).then((response) => {
                            let data = response.data;
                            if (index == length) {
                                swal.fire("", data.msg, "success");
                                this.print.approved_by_acct = 0;
                                this.getAllSalesOrders();
                                this.btn_loader = false;
                            }
                        });
                    });
                });
            } else {
                ids.forEach((id, index) => {
                    let payload = {
                        status: newStatus,
                        convert_to_cash: convert_to_cash,
                        url: "updateSalesOrderStatus/" + id,
                    };
                    this.$store.dispatch("salesOrdersPost", payload).then((response) => {
                        let data = response.data;
                        if (index == length) {
                            swal.fire("", data.msg, "success");
                            this.print.approved_by_acct = 0;
                            this.getAllSalesOrders();
                            this.btn_loader = false;
                        }
                    });
                });
            }
        },
        convertToWpList() {
            let ids = this.selected_id;
            let length = this.selected_id.length - 1;

            ids.forEach((id, index) => {
                let payload = {
                    url: "convertToWpList/" + id,
                };
                this.$store.dispatch("salesOrdersPost", payload).then((response) => {
                    let data = response.data;
                    if (index == length) {
                        swal.fire("", data.msg, "success");
                        this.getAllSalesOrders();
                        this.btn_loader = false;
                    }
                });
            });
        },
        getSelectedID(val) {
            this.selected_id = val.map(function (e) {
                return e.id;
            });

            if (this.filter.status_id == -1) {
                swal.fire("Filter status first", "", "warning");
                const index = this.dataTableSo.indexOf(this.dataTableSo);
                this.dataTableSo.splice(index, 1);
                this.convertBtn = false;
                return false;
            } else {
                if (this.dataTableSo.length > 0) {
                    this.convertBtn = true;
                } else {
                    this.convertBtn = false;
                }
            }
        },
        renderPDF() {
            var doc = new this.$jspdf("p", "mm", "letter");
            this.print_no_price = false;
            doc.fromHTML($("#div_logo_conversion").get(0), 7, -4, { width: 600 });
            doc.fromHTML($("#div_sales_order_num_conversion").get(0), 162, 4, { width: 400 });

            doc.autoTable({
                html: "#customer_details_conversion",
                theme: "plain",
                margin: { top: 37, left: 6, right: 6 },
                columnStyles: {
                    0: { cellWidth: 20 },
                    1: { cellWidth: 134 },
                    2: { cellWidth: 16 },
                    3: { cellWidth: 25 },
                },
            });

            if (this.print_no_price == false) {
                doc.autoTable({
                    html: "#items_details_conversion",
                    startY: doc.autoTable.previous.finalY + 3,
                    margin: { left: 6, right: 6 },
                    theme: "grid",
                    headStyles: {
                        halign: "center",
                        fillColor: "#ffffff",
                        textColor: "#000000",
                        lineWidth: 0.2,
                        lineColor: "#000000",
                    },
                    columnStyles: {
                        0: { cellWidth: 9, halign: "center", textColor: "#000000" },
                        1: { cellWidth: 13, halign: "center", textColor: "#000000" },
                        2: { cellWidth: 14, halign: "center", textColor: "#000000" },
                        3: { cellWidth: 29, halign: "center", textColor: "#000000" },
                        4: { halign: "left", textColor: "#000000" },
                        5: { cellWidth: 25, halign: "right", textColor: "#000000" },
                        6: { cellWidth: 25, halign: "right", textColor: "#000000" },
                    },
                    bodyStyles: {
                        lineColor: "#000000",
                    },
                });
            } else {
                doc.autoTable({
                    html: "#items_details_without_price_conversion",
                    startY: doc.autoTable.previous.finalY + 3,
                    margin: { left: 6, right: 6 },
                    theme: "grid",
                    headStyles: {
                        halign: "center",
                        fillColor: "#ffffff",
                        textColor: "#000000",
                        lineWidth: 0.2,
                        lineColor: "#000000",
                    },
                    columnStyles: {
                        0: { cellWidth: 11, halign: "center", textColor: "#000000" },
                        1: { cellWidth: 17, halign: "center", textColor: "#000000" },
                        2: { cellWidth: 17, halign: "center", textColor: "#000000" },
                        3: { cellWidth: 27, halign: "center", textColor: "#000000" },
                        4: { halign: "left", textColor: "#000000" },
                    },
                    bodyStyles: {
                        lineColor: "#000000",
                    },
                });
            }

            if (this.print_no_price == false) {
                doc.autoTable({
                    html: "#total_details_conversion",
                    theme: "plain",
                    startY: 215,
                    margin: { top: 37, left: 6, right: 6 },
                    columnStyles: {
                        0: { cellWidth: 110, textColor: "#000000" },
                        1: { cellWidth: 40, halign: "right", textColor: "#000000" },
                        2: { cellWidth: 12, textColor: "#000000" },
                        3: { cellWidth: 35, halign: "right", textColor: "#000000" },
                    },
                });
            }

            doc.autoTable({
                html: "#num_of_items_conversion",
                theme: "plain",
                startY: 225,
                margin: { top: 37, left: 6, right: 6 },
                columnStyles: {
                    0: { cellWidth: 110, textColor: "#000000" },
                    1: { cellWidth: 40, halign: "right", textColor: "#000000" },
                    2: { cellWidth: 12, textColor: "#000000" },
                    3: { cellWidth: 35, halign: "right", textColor: "#000000" },
                },
            });

            var footer_y = 232;

            doc.autoTable({
                html: "#footer_conversion",
                theme: "grid",
                tableWidth: 106,
                startY: footer_y,
                margin: { left: 6, right: 6 },
                columnStyles: {
                    0: { cellWidth: 38, halign: "center", textColor: "#000000" },
                    1: { cellWidth: 38, halign: "center", textColor: "#000000" },
                },
                bodyStyles: {
                    lineColor: "#000000",
                },
            });

            let finalY = doc.lastAutoTable.finalY; // The y position on the page
            doc.setFontSize(7);
            doc.setTextColor("#000000");
            doc.text(190, finalY, "Page 1 of 1");

            doc.autoPrint();
            window.open(doc.output("bloburl"), "_blank");
        },
        getItemTypes() {
            this.$store.dispatch("getItemTypes", {}).then((response) => {
                this.type_list = response.data;
            });
        },
        getItemTypeDiscountsPDF() {
            this.print.itemTypeDiscountsPDF = [];
            if (this.type_list.length > 0) {
                this.type_list.forEach((type) => {
                    var name = type.text;
                    var str = "(" + type.text + ") LESS: ";
                    var isEmpty = true;

                    if (this.discounts.length > 0) {
                        this.discounts.forEach((discount, index) => {
                            if (
                                discount[name.toLowerCase()] != null &&
                                discount[name.toLowerCase()] != "" &&
                                discount[name.toLowerCase()] > 0
                            ) {
                                if (index > 0) {
                                    str = str + " / ";
                                }
                                str = str + discount[name.toLowerCase()].toString(); // + '%'
                                isEmpty = false;
                            }
                        });
                        if (isEmpty) {
                            str = "";
                        } else {
                            if (this.salesOrderItems[0].item.item_type_text == type.text) {
                                this.itemTypeDiscountsPDF.push(str);
                            }
                        }
                    }
                });
            }
        },
        computePrintOutItemTotal(srp, qty) {
            return Math.round(parseFloat(srp) * qty).toFixed(2);
        },
        // initiateOverride(action = null, id, dialog_status) {
        //     switch (action) {
        //         case "delete":
        //             this.override.heading = "Delete Sales Order";
        //             this.override.departments_allowed = ["SM", "AC", "EX", "IT"];
        //             break;
        //         case "cancel":
        //             this.override.heading = "Cancel Sales Order";
        //             this.override.departments_allowed = ["SM", "AC", "EX", "IT"];
        //             break;
        //     }

        //     this.override.dialog = dialog_status;
        //     this.override.action = action;
        //     this.currentSalesOrderId = id;
        // },
        // closeConfirmOverride(user_id, authenticated = false) {
        //     this.override.dialog = false;
        //     if (authenticated) {
        //         this.override.authenticated = true;
        //         switch (this.override.action) {
        //             case "delete":
        //                 this.deleteSalesOrder(this.currentSalesOrderId);
        //                 break;
        //             case "cancel":
        //                 this.cancelSalesOrder(this.currentSalesOrderId);
        //                 break;
        //         }
        //     }
        // },
        // cancelSalesOrder(id) {
        //     this.errors = [];
        //     this.status_action_text = "cancel";

        //     swal.fire({
        //         title: "",
        //         text: "Are you sure you want to " + this.status_action_text + " Sales Order?",
        //         icon: "warning",
        //         showConfirmButton: true,
        //         showCancelButton: true,
        //         reverseButtons: true,
        //         allowOutsideClick: false,
        //         confirmButtonColor: "#397373",
        //         cancelButtonColor: "grey",
        //         confirmButtonText: "Confirm",
        //         showCloseButton: true,
        //     }).then((result) => {
        //         if (result.isConfirmed) {
        //             swal.fire({
        //                 title: "Cancel Reason Remarks",
        //                 input: "text",
        //                 inputLabel: "Enter Reason",
        //                 showConfirmButton: true,
        //                 confirmButtonColor: "#397373",
        //                 confirmButtonText: "Confirm",
        //                 inputValidator: (value) => {
        //                     if (!value) {
        //                         return "Please input Reason Remarks";
        //                     }
        //                 },
        //             }).then((result) => {
        //                 if (result.isConfirmed && result.value != null) {
        //                     this.reason.text = result.value;
        //                     let payload = {
        //                         url: "cancelSalesOrder/" + id,
        //                         reason: this.reason.text,
        //                     };
        //                     this.$store.dispatch("salesOrdersPost", payload).then((response) => {
        //                         let data = response.data;

        //                         if (data.error) {
        //                             swal.fire("", data.error, "warning");
        //                             return false;
        //                         }

        //                         swal.fire({
        //                             title: "",
        //                             html: data.msg,
        //                             icon: "success",
        //                         });

        //                         this.sales_order_items_dialog = false;
        //                         this.getAllSalesOrders();
        //                     });
        //                 }
        //             });
        //         }
        //     });
        // },
        loadDetails(item) {

            if (item.value) {
                let payload = {
                    url: "pl-dispatch/" + item.item.id,
                    pending_only: true,
                    withStocks: true,
                    withoutStocks: true,
                };
                this.$store.dispatch("salesOrdersGet", payload).then((response) => {
                    let data = response.data;

                    item.item.details = data;
                    item.item.details = item.item.details.map(e=>{
                        if (e.status == 0) { //Why not use switch case? isn't this inefficient?
                            e.status_text = 'pending';
                        } else if (e.status == 1) {//Why not use switch case? isn't this inefficient?
                            e.status_text = 'confirmed';
                        } else if (e.status == 2) {
                            e.status_text = 'rejected';
                        } else if (e.status == 3) {
                            e.status_text = 'canceled';
                        } else if (e.status == 4) {
                            e.status_text = 'deleted';
                        } else if (e.status == 5) {
                            e.status_text = 'submitted';
                        } else if (e.status == 6) {
                            e.status_text = 'for approval';
                        } else if (e.status == 7) {
                            e.status_text = 'approved';
                        }

                        if (e.dispatch_status == 0) {
                            e.dispatch_status_text = 'saved';
                        } else if (e.dispatch_status == 1) {
                            e.dispatch_status_text = 'confirmed';
                        } else if (e.dispatch_status == 2) {
                            e.dispatch_status_text = 'dispatched';
                        }

                        return e
                    })

                    this.$forceUpdate();
                });
            }
        },
        async getAllSalesOrders() {
            this.salesOrders = [];
            this.loaded = false;
            let params = {
                page: this.options.page,
                itemsPerPage: this.options.itemsPerPage,
                sortBy: this.options.sortBy,
                sortDesc: this.options.sortDesc,
                search: this.filter.search,
                searchItem: this.filter.searchItem,
                status_filter: this.filter.status_id,
                statuses: this.filter.statuses,
                customer_id: this.filter.customer_id,
                salesman_id: this.filter.salesman_id,
                user_id: this.filter.user_id,
                filter_store: true,
                sales_order_dates: this.filter.sales_order_dates,
                status_text: this.status_text,
                service: this.service,
                url: "getAll",
                export: 0,
                type_selection: this.filter.type_selection,
                trade: this.trade.base,
            };
            this.$store.dispatch("salesOrdersGet", params).then((response) => {
                if (response.data.status == 200) {
                    this.salesOrders = response.data.data;
                    this.totalSalesOrders = response.data.total;
                    this.loaded = true;
                    this.dataTableSo = [];
                }
            }).catch((e) => {
                console.log(e);
                this.loaded = true;
            });
        },
        viewSalesOrder(item, action) {
            this.viewSalesOrderId = item.id;
            this.$store.commit("ACTION", action);
        },
        editSalesOrder(id) {
            this.$emit("showEditModal", id);
        },
        deleteSalesOrder(id) {
            swal.fire({
                title: "",
                text: "Are you sure you want to Delete?",
                icon: 'question',
                showConfirmButton: true,
                showCancelButton: true,
                reverseButtons: true,
                allowOutsideClick: false,
                confirmButtonColor: "#397373",
                cancelButtonColor: "grey",
                confirmButtonText: "Confirm",
                showCloseButton: true,
            }).then((result) => {
                if (result.isConfirmed) {
                    let params = {
                        url: "/salesOrders/" + id,
                    };
                    this.$store.dispatch("salesOrdersDelete", params).then((response) => {
                        if (response.data.status == "success") {
                            this.getAllSalesOrders();
                        }

                        swal.fire(response.data.msg, { icon: response.data.status });
                    })
                    .catch((error) => {
                        swal.fire("An Error Occured", error, "error");
                    });
                }
            });
        },
        filterCustomer() {
            this.getAllSalesOrders();
        },
        closeView(action = false) {
            this.viewSalesOrderId = 0;
            if (action == "convertSuccess" || action == "updateStatusSuccess") {
                this.getAllSalesOrders();
            }
        },
        searchInput() {
            this.getAllSalesOrders({
                page: this.options.page = 1,
                itemsPerPage: this.options.itemsPerPage,
                sortBy: this.options.sortBy,
                sortDesc: this.options.sortDesc,
                search: this.filter.search,
                customer_id: this.filter.customer_id,
                salesman_id: this.filter.salesman_id,
                status_filter: this.filter.status_id,
                user_id: this.filter.user_id,
                status_text: this.status_text,
                type_selection: this.filter.type_selection,
            });
        },
        getSearchFromUrl() {
            if (Object.keys(this.$route.query).length != 0) {
                this.filter.searchItem = atob(this.$route.query.search_model);
                this.filter.status_id = atob(this.$route.query.status);
                this.searchInput();
            }
        },
        getUsers() {
            // await this.$store.dispatch('getAllUsersSelect',{

            // }).then(response => {
            if (this.isSalesman && this.currentSalesmanPositionId != 1) {
                if (_.compact(this.salesmanChildrenIds).length > 0) {
                    let lists = this.GET_ALL_USER;
                    let filtered_list = lists.filter((x) => _.compact(this.userChildrenIds).includes(x.value));
                    this.users_list = filtered_list;
                }
            } else {
                this.users_list = this.GET_ALL_USERS;
            }
            // });
        },
        getCustomers() {
            // this.$store.dispatch('getCustomers',{

            // }).then(response => {
            let lists = this.GET_CUSTOMERS;
            if (this.isSalesman && this.currentSalesmanPositionId != 1) {
                if (this.isSalesCoordinator) {
                    let filtered_list = lists.filter((x) => x.sales_coordinator_id == this.currentSalesmanId);
                    this.customers_list = filtered_list;
                } else {
                    let filtered_list = lists.filter((x) => _.compact(this.salesmanChildrenIds).includes(x.salesman_id));
                    this.customers_list = filtered_list;
                }
            } else {
                this.customers_list = lists;
            }
            // });
        },
        async getSalesmanList() {
            // await this.$store.dispatch('getAllsalesmanSelection',{

            // }).then(response => {
            if (this.isSalesman && this.currentSalesmanPositionId != 1) {
                let lists = this.GET_SALESMAN_SELECTION;
                if (this.isSalesCoordinator) {
                    let filtered_list = lists.filter((x) => x.value == this.currentSalesmanId);
                    this.salesman_list = filtered_list;
                } else if (_.compact(this.salesmanChildrenIds).length > 0) {
                    let filtered_list = lists.filter((x) => _.compact(this.salesmanChildrenIds).includes(x.value));
                    this.salesman_list = filtered_list;
                }
            } else {
                this.salesman_list = this.GET_SALESMAN_SELECTION;
            }
            // });
        },
        getCurrentSalesman() {
            // this.$store.dispatch('/salesman/getCurrentSalesman').then(response=>{
            //     this.salesmanDetails = response.data;
            // });
        },
        searchFromUrl() {
            let url = window.location.search;
            url = new URLSearchParams(url);
            if (!!url) {
                this.filter.search = url.get("order_num");
                this.filter.status_id = url.get("status") ?? -1;
            }
        },
        expansionStyle(){
            return ''
        }
    },
    mounted() {
        // this.filter.search = (Uri.hasValueAndProperty('hash', 'order_num')) ? Uri.toObject('hash')['order_num'] : '';
        // this.getCurrentSalesman();
        this.searchFromUrl();
        this.getSearchFromUrl();
        this.$store.dispatch('getItemTypes').then((response)=>{
          this.item_type_selection = !this.service ? response.data.filter(e => {return e.text != 'SP'}) : response.data
        })
    },
    computed: {
        ...mapGetters([
            "GET_ALL_USERS",
            "GET_CUSTOMERS",
            "GET_SALESMAN_SELECTION",
            "GET_CURRENT_SALESMAN",
            "USER_ACCESS",
            "SALES_ORDERS",
            'GET_ITEM_TYPES',
        ]),
    },
    watch: {
        salesOrdersActionResult(val) {
            if (this.salesOrdersActionResult == "success") {
                this.getAllSalesOrders();
                this.getCustomers();
                this.getSalesmanList();
                this.getUsers();
            }
        },
        options: {
            handler() {
                this.getAllSalesOrders();
                this.getCustomers();
                this.getSalesmanList();
                this.getUsers();
            },
            deep: true,
        },
        salesmanDetails() {
            if (!!this.salesmanDetails) {
                this.isSalesman = this.salesmanDetails.isSalesman;
                this.salesmanTitle = this.salesmanDetails.salesmanPositionTitle;
                this.salesmanChildrenIds = this.salesmanDetails.salesmanChildrenIds;
                this.userChildrenIds = this.salesmanDetails.userChildrenIds;

                if (!!this.salesmanDetails.salesman) {
                    this.currentSalesmanId = this.salesmanDetails.salesman.id;
                    this.isSalesCoordinator = !!this.salesmanDetails.salesman.sales_coordinator;
                    this.currentSalesmanPositionId = this.salesmanDetails.salesman.salesman_position_id;
                }
            }
        },
        GET_ALL_USERS: {
            handler(val) {
                if (this.isSalesman && this.currentSalesmanPositionId != 1) {
                    if (_.compact(this.salesmanChildrenIds).length > 0) {
                        let lists = val;
                        let filtered_list = lists.filter((x) => _.compact(this.userChildrenIds).includes(x.value));
                        this.users_list = filtered_list;
                    }
                } else {
                    this.users_list = val;
                }
            },
        },
        GET_CUSTOMERS: {
            handler(val) {
                let lists = val;
                if (this.isSalesman && this.currentSalesmanPositionId != 1) {
                    if (this.isSalesCoordinator) {
                        let filtered_list = lists.filter((x) => x.sales_coordinator_id == this.currentSalesmanId);
                        this.customers_list = filtered_list;
                    } else {
                        let filtered_list = lists.filter((x) => _.compact(this.salesmanChildrenIds).includes(x.salesman_id));
                        this.customers_list = filtered_list;
                    }
                } else {
                    this.customers_list = lists;
                }
            },
        },
        GET_SALESMAN_SELECTION: {
            handler(val) {
                if (this.isSalesman && this.currentSalesmanPositionId != 1) {
                    let lists = val;
                    if (this.isSalesCoordinator) {
                        let filtered_list = lists.filter((x) => x.value == this.currentSalesmanId);
                        this.salesman_list = filtered_list;
                    } else if (_.compact(this.salesmanChildrenIds).length > 0) {
                        let filtered_list = lists.filter((x) => _.compact(this.salesmanChildrenIds).includes(x.value));
                        this.salesman_list = filtered_list;
                    }
                } else {
                    this.salesman_list = val;
                }
            },
        },
        USER_ACCESS: {
            handler(val) {
                if (val != "fail") {
                    val.forEach((e) => {
                        if (e.actions_code == "view") {
                            this.userAccess.view = true;
                        }
                        if (e.actions_code == "edit") {
                            this.userAccess.edit = true;
                        }
                        if (e.actions_code == "delete") {
                            this.userAccess.delete = true;
                        }
                        if (e.actions_code == "approval_sales") {
                            this.userAccess.approval_sales = true;
                        }
                        if (e.actions_code == "cancel") {
                            this.userAccess.cancel = true;
                        }
                        if (e.actions_code == "approval_acct") {
                            this.userAccess.approval_acct = true;
                        }
                        if (e.actions_code == "convert_to_wpl") {
                            this.userAccess.convert_to_wpl = true;
                        }
                        if (e.actions_code == "edit_approved") {
                            this.userAccess.edit_approved = true;
                        }
                    });
                }
            },
        },
        filter:{
            deep:true,
            handler(val){
                this.$emit('filters',val)
            }
        },
        'SALES_ORDERS.REFRESH':{
            handler(val){
                if(val){
                    this.getAllSalesOrders();
                }
                this.$store.commit('REFRESH', false)
            }
        }
    },
};
</script>
<style scoped>
/* Target the specific data table using its unique class */

</style>
