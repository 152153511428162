<template>
<div>
    <!-- <v-app id="load_bar" v-if="loaded">
        <v-text-field color="info" loading disabled></v-text-field>
    </v-app> -->
    <v-app id="view_client_modal">
        <v-dialog v-model="checker_form_items_dialog" persistent max-width="98%" scrollable>
            <v-card v-if="!['is_transmittal'].includes(tab_name)">
                <v-row class="m-0">
                    <v-card-title>
                        <span class="headline">Checker Form Details</span>
                    </v-card-title>
                    <v-col cols="pull-right-10 p-2">
                        <v-btn text icon color="gray" class="float-right" @click="clearWPListItems(); $emit('closeView');">
                            <v-icon>mdi-close-circle</v-icon>
                        </v-btn>
                    </v-col>
                </v-row>
                <v-card-text>
                    <v-row class="m-0">
                        <v-container class="d-flex justify-space-between" fluid>
                            <v-row>
                                <div class="col-sm-12 col-md-5 text-left text-dark">
                                    <div class="row ml-2">
                                        <div class="col-sm-12 col-md-8 p-0">CF#: {{ checker_form.id }}</div>
                                    </div>
                                    <div class="row ml-2">
                                        <div class="col-sm-12 col-md-8 p-0">WPF#: {{ checker_form.wp_list_id }}</div>
                                    </div>
                                    <div class="row ml-2">
                                        <div class="col-sm-12 col-md-8 p-0">SO#: {{ checker_form.sales_order_num }}</div>
                                    </div>
                                    <div class="row ml-2">
                                        <div class="col-sm-12 col-md-8 p-0">SQ#: {{ checker_form.sales_quotation_num }}</div>
                                    </div>
                                    <div class="row ml-2">
                                        <div class="col-sm-12 col-md-8 p-0">Warehouse: {{ checker_form.warehouse_name }}</div>
                                    </div>
                                    <div class="row ml-2">
                                        <div class="col-sm-12 col-md-8 p-0">SO Remarks: {{ checker_form.sales_order_remarks }}</div>
                                    </div>
                                    <div class="row ml-2">
                                        <div class="col-sm-12 col-md-8 p-0">Notes: {{ checker_form.notes }}</div>
                                    </div>
                                </div>
                                <div class="col-sm-12 col-md-5 text-left text-dark">
                                    <div class="row ml-2">
                                        <div class="col-sm-12 col-md-8 p-0">CF Remarks: {{ checker_form.remarks }}</div>
                                    </div>
                                    <div class="row ml-2">
                                        <div class="col-sm-12 col-md-8 p-0">Checker: {{ checker_form.checker_name }}</div>
                                    </div>
                                    <div class="row ml-2">
                                        <div class="col-sm-12 col-md-8 p-0">No. of Boxes: {{ checker_form.num_of_box }}</div>
                                    </div>
                                    <div class="row ml-2">
                                        <div class="col-sm-12 col-md-8 p-0">Correct Picking Reason: {{ checker_form.correct_picking_reason }}</div>
                                    </div>
                                    <div class="row ml-2">
                                        <div class="col-sm-12 col-md-8 p-0">Start Date: {{ checker_form.start_date | formatDateTime24hours }}</div>
                                    </div>
                                    <div class="row ml-2">
                                        <div class="col-sm-12 col-md-8 p-0">End Date: {{ checker_form.end_date | formatDateTime24hours }}</div>
                                    </div>
                                    <div class="row ml-2">
                                        <div class="col-sm-12 col-md-8 p-0">Duration: {{ checker_form.durations }}</div>
                                    </div>
                                    <div class="row ml-2" v-if="stock_card">
                                        <div class="col-sm-12 col-md-8 p-0">Stock Card</div>
                                    </div>
                                </div>
                                <v-col class="d-flex justify-end pr-2 pt-2">
                                    <v-btn class="mr-2" @click="showImageUploadDialog()">
                                       Attach File
                                    </v-btn>
                                    <v-flex shrink>
                                        <BaseFileViewer  :checkerFormId="checker_form.id" :delete="true" />
                                    </v-flex>
                                </v-col>
                            </v-row>
                        </v-container>
                    </v-row>

                    <v-data-table
                        :headers="selectedHeaders"
                        :items="checkerFormItems"
                        :items-per-page="500"
                        hide-default-footer
                        class="w-100"
                    >
                    </v-data-table >

                    <v-row class="m-0">
                        <v-container fluid>
                            <v-row>
                                <div class="col-sm-12 col-md-8 text-left" v-if="[0, 6].includes(checker_form.status)">
                                    <div class="card-body py-4 d-flex">
                                        <span class="m-1"><v-btn @click="print_no_price = true; renderPDF('print','_checker');" >Print Checker</v-btn></span>
                                    </div>

                                </div>
                            </v-row>
                        </v-container>
                    </v-row>
                    <div class="border-top">
                        <div class="card-body py-4 d-flex justify-content-center" v-if="checker_form.status == 0 || (checker_form.status == 1 && checker_form.packing_list_status == -1)">
                            <span class="m-1"><v-btn :loading="loading_confirm" :disabled="loading_confirm" @click="updateCheckerFormStatus(1)" v-if="checker_form.status == 0 && checker_form.printed == 1">Confirm</v-btn></span>
                            <span class="m-1"><v-btn :loading="loading_confirm" :disabled="loading_confirm" @click="convertToPackingList()" v-if="checker_form.status == 1 && checker_form.packing_list_status == -1">Convert to PL</v-btn></span>
                        </div>
                    </div>

                </v-card-text>

                <!-- CHECKER -->
                <v-container fluid style="display:none;">
                    <div id="div_logo_checker" >
                        <div style="font-size: 12px; line-height: 1.9">
                            <p><span style="font-size: 18px; font-weight: bold;">WADFOW Tools Philippines Corp.</span>
                            </p>
                        </div>
                    </div>
                    <div id="div_checker_form_num_checker" style="font-size: 12px; line-height: 1.9">
                        <div style="border-top: 1px dashed #CFCFCF; padding: 2px; padding-bottom: -4px; text-align: center; border-bottom: none;">&nbsp;</div>
                        <p>
                            <span style="font-size: 18px; font-weight: bold;">Checker Form</span>
                            <br>
                            <b>CF# {{ checker_form.id }}</b>
                            <br>
                            <b>WPF# {{ checker_form.wp_list_id }}</b>
                            <br>
                            <b>SO# {{ checker_form.sales_order_num }}</b>
                            <br>
                            <b>Terms: {{ sales_order.terms == 0 ? 'CASH' : sales_order.terms + ' days' }}</b>
                        </p>
                    </div>

                    <div>
                        <table id="customer_details_checker">
                            <tbody>
                                <tr>
                                    <td>Customer:</td>
                                    <td>{{ checker_form.customer_name | uppercase }}</td>
                                    <!-- <td>Date:</td>
                                    <td>{{ checker_form.created_at | formatDate }}</td> -->
                                </tr>
                                <tr>
                                    <td>SO Remarks:</td>
                                    <td>{{ checker_form.sales_order_remarks }}</td>
                                    <!-- <td>No of </td>
                                    <td>{{ checker_form.sales_order_remarks }}</td> -->
                                </tr>
                                <tr>
                                    <td>CF Remarks:</td>
                                    <td>{{ checker_form.remarks }}</td>
                                </tr>
                                <tr>
                                    <td>Notes:</td>
                                    <td>{{ checker_form.notes }}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>

                    <div v-for="(items, i ) in print.checkerFormItems" :key="i">
                        <table :id="'items_details_without_price_checker' + i" >
                            <thead>
                                <tr>
                                    <th>#</th>
                                    <th>PREP</th>
                                    <th>QTY</th>
                                    <th>UNITS</th>
                                    <th>MODEL</th>
                                    <th>DESCRIPTION</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(item, index ) in items" :key="index">
                                    <td>{{ index + 1 }}</td>
                                    <td>{{ item.quantity_served }}</td>
                                    <td v-if="stock_card">{{ item.sc_quantity }}</td>
                                    <td v-else>{{ item.quantity }}</td>
                                    <td>{{ item.item_uom_text }}</td>
                                    <td>{{ item.model }}</td>
                                    <td>{{ item.name }}</td>
                                </tr>
                                <tr v-if="print.checkerFormItems.length == (i + 1)">
                                    <td colspan="6">***** NOTHING FOLLOWS *****</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>

                    <div>
                        <table id="num_of_items_checker">
                            <tbody>
                                <tr>
                                    <td><span> No. of Boxes:{{ checker_form.num_of_box }}</span></td>
                                    <td><span>No. of Items: {{ this.no_of_items }}</span> </td>
                                    <td></td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div>
                        <table id="footer_checker">
                            <tbody>
                                <tr>
                                    <td>Checked By</td>
                                </tr>
                                <tr>
                                    <td><br><br><br>{{ checker_form.checker_name }}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </v-container>
                 <!-- FILE UPLOAD DIALOG -->
                <v-dialog v-model="imageUpload" persistent max-width="20%">
                    <v-card class="pa-4">
                        <v-btn
                            text
                            icon
                            color="gray"
                            class="float-right"
                            @click="closeImageUploadDialog()"
                        >
                            <v-icon>mdi-close-circle</v-icon>
                        </v-btn>
                        <div class="py-2">
                            <span>Attach Image File</span><span style="color:red">  *</span>
                            <v-divider/>
                        </div>
                        <div class="py-2 mt-2">
                            <v-layout row class="mx-0">
                                <v-flex lg2 class="mr-2">
                                    <BaseCameraCaptureVue ref="cameraCapture" @capturedImages="getImages" />
                                </v-flex>
                                <div class="mt-2">
                                    <v-chip
                                        v-if="!!images && images.length > 0"
                                        color="#f69f1a"
                                        outlined
                                        close
                                        @click:close="clearCameraCapture()"
                                    >
                                        <span class="mr-2">Images Count: {{ images.length }}</span>
                                    </v-chip>
                                </div>
                            </v-layout>
                        </div>

                        <div class="py-2 mt-3">
                            <v-layout row class="mx-0">
                                <v-flex :class="{'mr-2': true, 'lg3': !!uploadedImageFile && uploadedImageFile.attachments.length > 1, 'lg6': !!uploadedImageFile && uploadedImageFile.attachments.length == 1}">
                                    <FileUploadComponentVue ref="fileUpload" @uploadedData="uploadedData" @reset="clearImageUpload"/>
                                </v-flex>
                                <v-flex v-if="!!uploadedImageFile && uploadedImageFile.attachments.length > 0" class="ml-2" ></v-flex>
                                    <div class="mt-2">
                                        <v-chip
                                            v-if="!!uploadedImageFile && uploadedImageFile.attachments.length > 0"
                                            color="#f69f1a"
                                            outlined
                                            close
                                            @click:close="clearImageUpload()"
                                        >
                                            <span class="mr-2">Upload Count: {{ uploadedImageFile.attachments.length }}</span>
                                        </v-chip>
                                    </div>
                                </v-flex>
                            </v-layout>
                        </div>
                        <div class="py-2 mt-3">
                            <v-card-actions class="d-flex justify-content-end">
                                <v-btn  @click="storeImageUpload(checker_form.id)">Submit</v-btn>
                            </v-card-actions>
                        </div>
                    </v-card>
                </v-dialog>
            </v-card>
        </v-dialog>
        <v-dialog v-model="checker_form_items_dialog" persistent max-width="60%" v-if="['is_transmittal'].includes(tab_name)">
            <v-card>
                <v-row dense class="m-0">
                    <v-card-title>
                        <span class="headline">Transmittal Details</span>
                    </v-card-title>
                    <v-col cols="pull-right-10 p-2">
                        <v-btn text icon color="gray" class="float-right" @click="clearWPListItems(); $emit('closeView');">
                            <v-icon>mdi-close-circle</v-icon>
                        </v-btn>
                    </v-col>
                </v-row>
                    <v-card-text>
                         <v-col cols="12">
                        <span>Transmittal#: {{paddedId}}</span>
                        </v-col>
                        <v-col cols="12">
                        <span>Printed By: {{viewTransmittalItem.printed_by}}</span>
                        </v-col>
                        <v-col cols="12">
                        <span>Date Printed: {{viewTransmittalItem.date_printed | formatDateTime}}</span>
                        </v-col>
                    </v-card-text>
                     <v-row dense no-gutters class="pa-1" v-if="userAccess.includes('cf_reprint')">
                        <v-col cols="12" class="d-flex flex-row-reverse">
                            <v-tooltip bottom color="#f69f1a">
                                <template v-slot:activator="{ on, attrs }">
                                    <v-btn
                                        v-bind="attrs"
                                        v-on="on"
                                        fab
                                        text
                                        small
                                        class="mr-4"
                                        color="#f69f1a"
                                        @click="override.override_dialog = true"
                                    >
                                    <v-icon>mdi-file-pdf-box</v-icon>
                                    </v-btn>
                                </template>
                                <span>Print PDF</span>
                            </v-tooltip>
                        </v-col>
                    </v-row>
                <v-container>
                    <v-data-table
                    :headers="transmittal_headers"
                    :items="transmittal_details"
                    :items-per-page="10"
                    :loading="!loaded"
                    >
                    </v-data-table>
                </v-container>
            </v-card>
        </v-dialog>
        <ConfirmOverrideComponentVue
            :approve_override_dialog="override.override_dialog"
            :departments_allowed="override.override_departments_allowed"
            :heading="'Re-Print Transmittal Form'"
            @closeConfirmOverride="closeConfirmOverride"
        ></ConfirmOverrideComponentVue>
    </v-app>
</div>
</template>
<script>
    // import SharedFunctionsComponent from '../Shared/SharedFunctionsComponent.vue'
    import SharedFunctionsComponentVue from '@/views/main/Shared/SharedFunctionsComponent.vue';
    import swal from 'sweetalert2';
    import OperationsFunctionComponentVue from '@/views/main/Shared/OperationsFunctionComponent.vue';
    import ConfirmOverrideComponentVue from '@/views/main/Utils/ConfirmOverrideComponent.vue';
    import InventoryComponentVue from '../Inventory/InventoryComponent.vue';
    import PusherFunctionComponent from '@/views/main/Shared/PusherFunctionComponent2.vue';
    import FileUploadComponentVue from '@/views/main/Shared/FileUploadComponent.vue';
    import BaseCameraCaptureVue from '@/views/main/modules/Services/Base/BaseCameraCapture.vue';
    import BaseFileViewer from '@/views/main/modules/Operations/WarehouseReturn/BaseFileViewr.vue';
    import { mapGetters } from 'vuex';
    export default {
        mixins: [SharedFunctionsComponentVue,OperationsFunctionComponentVue,InventoryComponentVue,PusherFunctionComponent],
        components: {
            ConfirmOverrideComponentVue,
            FileUploadComponentVue,
            BaseCameraCaptureVue,
            BaseFileViewer
        },
        props: [
            'viewCheckerFormId',
            'tab_name',
            'viewTransmittalId',
            'viewTransmittalItem'
        ],
        mounted(){
            this.callPusher();
            this.getSystemPlatform();
        },
        data() {
            return {
                stock_card: false,
                checkerFormItems: [],
                transmittal_details: [],
                selectedHeaders: [],
                checkerFormItemsHeaders: [
                    { text: 'Model', align: 'left', value: 'model', width: '15%', sortable: false},
                    { text: 'Name', align: 'left', value: 'name', width: '35%', sortable: false},
                    { text: 'Type', align: 'left', value: 'item_type_text', sortable: false},
                    { text: 'UOM', align: 'left', value: 'item_uom_text', sortable: false},
                    { text: 'Qty', align: 'center', value: 'quantity', sortable: false},
                    { text: 'Qty to Ship', align: 'center', value: 'quantity_served', sortable: false},
                ],
                stockCardcheckerFormItemsHeaders: [
                    { text: 'Model', align: 'left', value: 'model', width: '15%', sortable: false},
                    { text: 'Name', align: 'left', value: 'name', width: '35%', sortable: false},
                    { text: 'Type', align: 'left', value: 'item_type_text', sortable: false},
                    { text: 'UOM', align: 'left', value: 'item_uom_text', sortable: false},
                    { text: 'Qty', align: 'center', value: 'quantity', sortable: false},
                    { text: 'Qty to Ship', align: 'center', value: 'quantity_served', sortable: false},
                    { text: 'SC Qty', align: 'center', value: 'sc_quantity', sortable: false},
                ],
                transmittal_headers: [
                    { text: 'CF#', align: 'left', value: 'module_id',sortable: false},
                    { text: 'WPF#', align: 'left', value: 'wp_list_id',sortable: false},
                    { text: 'SO#', align: 'left', value: 'order_num', sortable: false},
                    { text: 'Warehouse', align: 'left', value: 'warehouse', sortable: false},
                    { text: 'Customer', align: 'left', value: 'customer_name', sortable: false },
                    { text: 'Release Date', align: 'left', value: 'release_date_time', sortable: false},
                    { text: 'Remarks', align: 'left', value: 'release_remarks', sortable: false},
                ],
                loaded: true,
                checker_form_items_dialog: false,
                checker_form: '',
                sales_order: '',
                discounts: [],
                itemTypeDiscounts: [],
                itemTypeDiscountsPDF: [],
                type_list: [],
                errors: [],

                loading_confirm: false,
                user:{
                    warehouses:[]
                },
                paddedId: '',
                override: {
                    override_dialog: false,
                    errors: [],
                    override_departments_allowed: ['SM', 'EX', 'IT', 'SR'],
                    override_authenticated: false,
                },
                userAccess: [],
                imageUpload: false,
                images: false,
                uploadedImageFile: null,
                companyDetails:{},
                print: {
                    checkerFormItems: [],
                },
            }
        },
        methods: {
            convertToPackingList(){
                if(this.checker_form.status == 1){
                    this.errors = []
                    this.loading_confirm = true
                    swal.fire({
                        title: "",
                        text: "Are you sure you want to Convert this to PL?",
                        icon: 'question',
                        showConfirmButton:true,
                        showCancelButton  :  true,
                        reverseButtons:true,
                        allowOutsideClick :  false,
                        confirmButtonColor: '#397373',
                        cancelButtonColor : 'grey',
                        confirmButtonText:'Confirm',
                        showCloseButton:true,
                    })
                    .then((result) => {
                        if (result.isConfirmed) {

                            let payload = {
                                url: 'convertToPackingList/'+this.viewCheckerFormId
                            }
                            this.$store.dispatch('postCF',payload).then(response => {

                                window.Echo.channel('smod-channel').listen('PackingListJobMessage',(e)=>{

                                let data = e.data.find(el => el.userID == this.USERACCOUNT_LOAD.id);
                                let check_unique_id = (e.data[0].unique_key.toString().replace(/"/g,'') == this.viewCheckerFormId)
                                if(check_unique_id && data && (data.message.msg || data.message.error) ){
                                if(data.message.error){
                                    swal.fire("", data.message.error, "warning");
                                    this.loading_confirm = false
                                    return false
                                }

                                this.loading_confirm = false
                                this.resetFields()

                                swal.fire("", data.message.msg, "success");

                                this.checker_form_items_dialog = false
                                this.$emit('closeView', 'convertSuccess')
                               }


                            })
                            }).catch(error => {
                                this.loading_confirm = false
                            })
                        }
                        else{
                            this.loading_confirm = false
                        }
                    });
                }
            },
            updateCheckerFormStatus(newStatus){
                this.errors = []
                if (!this.checker_form.checker_name) {
                    swal.fire("", "Checker cannot be blank!", "warning")
                    return false
                }
                this.loading_confirm = true
                swal.fire({
                    title: "",
                    text: "Are you sure you want to update Checker Form?",
                    icon: 'question',
                    showConfirmButton:true,
                    showCancelButton  :  true,
                    reverseButtons:true,
                    allowOutsideClick :  false,
                    confirmButtonColor: '#397373',
                    cancelButtonColor : 'grey',
                    confirmButtonText:'Confirm',
                    showCloseButton:true,
                })
                .then((result) => {
                    if (result.isConfirmed) {
                        let payload = {
                            status: newStatus,
                            url:    'updateCheckerFormStatus/'+this.viewCheckerFormId
                        }
                        if (this.checker_form.checker_form_items.some(item => item.quantity_served === 0)) {
                            swal.fire({
                                title: "",
                                text: "-Are you sure you want to proceed with 0 Qty to Ship?",
                                icon: 'question',
                                showConfirmButton:true,
                                showCancelButton  :  true,
                                reverseButtons:true,
                                allowOutsideClick :  false,
                                confirmButtonColor: '#397373',
                                cancelButtonColor : 'grey',
                                cancelButtonText:'NO',
                                confirmButtonText:'YES',
                                showCloseButton:true,
                            }).then(action=>{
                                if(action.isConfirmed){
                                    this.$store.dispatch('postCF',payload).then(response => {
                                        let data = response.data

                                        if(data.error){
                                            swal.fire("", data.error, "warning");
                                            return false
                                        }


                                        this.resetFields()

                                        swal.fire("", data.msg, "success");

                                        this.checker_form_items_dialog = false
                                        this.$emit('closeView', 'updateStatusSuccess')
                                        this.loading_confirm = false
                                    }).catch(error => {
                                        this.loading_confirm = false
                                    });
                                }
                            })
                        }  else{
                            this.$store.dispatch('postCF',payload).then(response => {
                                let data = response.data

                                if(data.error){
                                    swal.fire("", data.error, "warning");
                                    return false
                                }

                                this.resetFields()

                                swal.fire("", data.msg, "success");

                                this.checker_form_items_dialog = false
                                this.$emit('closeView', 'updateStatusSuccess')
                                this.loading_confirm = false
                            }).catch(error => {
                                this.loading_confirm = false
                            });
                        }


                    }
                    else{
                        this.loading_confirm = false
                    }
                }).catch(error => {
                    this.loading_confirm = false
                });
            },
            clearWPListItems(){
                this.resetFields()
                this.checker_form_items_dialog = false
                this.transmittal_details = []
            },
            resetFields(){
                this.checkerFormItems = []
                this.checker_form = ''
                this.discounts = []
                this.itemTypeDiscounts = []
                this.itemTypeDiscountsPDF = []
                this.printed = false
                this.override_print_dialog = false
                this.stock_card = false
                this.loading_confirm = false
            },

            getItemTypes(){
                this.$store.dispatch('getItemTypes').then(response => {
                    this.type_list = response.data
                });
            },

            renderPDF(pdfAction,type){
                // if (!this.checker_form.checker_name) {
                //     swal.fire("", "Checker cannot be blank!", "warning")
                //     return false
                // }
                var doc = new this.$jspdf('p', 'mm', 'letter');
                var margin_left = 9;
                var margin_right = 9;
                let customer_details_top = 30
                let date = this.$dayjs(this.checker_form.created_at).format('MM/DD/YYYY');
                let so = this.sales_order.terms == 0 ? 'CASH' : this.sales_order.terms + ' days'
                this.print.checkerFormItems.forEach((e, i) => {
                    doc.setFont('courier', 'bold').setFontSize(13).text(6,13,`${this.companyDetails.company_name}`)
                    doc.setFont('courier', 'bold').setFontSize(14).text(162,13,"Checker Form")
                    doc.setFont('courier', 'bold').setFontSize(9).text(162,18,"CF# "+this.checker_form.id)
                    doc.setFont('courier', 'bold').setFontSize(9).text(162,23,"WPF# "+this.checker_form.wp_list_id)
                    doc.setFont('courier', 'bold').setFontSize(9).text(162,28,"SO# "+this.checker_form.sales_order_num)
                    doc.setFont('courier', 'bold').setFontSize(9).text(162,33,"Terms: "+so)
                    doc.setFont('courier', 'bold').setFontSize(9).text(162,38,"Date: "+date)

                    doc.autoTable({
                        html: '#customer_details'+type,
                        theme: 'plain',
                        margin: { top: customer_details_top, left: margin_left, right: margin_right },
                        styles: {
                            font: 'courier',
                            cellPadding: 0,
                        },
                        columnStyles: {
                            0: { cellWidth: 23 },
                            1: { cellWidth: 130 },
                            2: { cellWidth: 16 },
                            3: { cellWidth: 25 },
                        }
                    });

                    if(this.print_no_price == false){
                        doc.autoTable({
                            html: '#items_details'+type+`${i}`,
                            startY: doc.autoTable.previous.finalY + 3,
                            margin: { left: margin_left, right: margin_right },
                            theme: 'grid',
                            headStyles: {
                                halign: 'center',
                                fillColor: '#ffffff',
                                textColor: '#000000',
                                lineWidth: .2,
                                lineColor: '#000000',
                                fontStyle: 'bold',
                                fontSize: 9,
                                font: 'courier',
                            },
                            columnStyles: {
                            0: {cellWidth: 9, halign:'center',  textColor: '#000000'},
                            1: {cellWidth: 13, halign:'center', textColor: '#000000'},
                            2: {cellWidth: 14, halign:'center', textColor: '#000000'},
                            3: {cellWidth: 29, halign:'center', textColor: '#000000'},
                            4: {halign:'left', textColor: '#000000'},
                            5: {cellWidth: 25, halign:'right', textColor: '#000000'},
                            6: {cellWidth: 25, halign:'right', textColor: '#000000'}
                            },
                            bodyStyles: {
                                lineColor: '#000000',
                                font: 'courier',
                                cellPadding: 1.60,
                            }
                        });
                    }else{
                        doc.autoTable({
                            html: '#items_details_without_price'+type+`${i}`,
                            startY: doc.autoTable.previous.finalY + 3,
                            margin: { left: margin_left, right: margin_right },
                            theme: 'grid',
                            headStyles: {
                                halign:'center',
                                fillColor: '#ffffff',
                                textColor: '#000000',
                                lineWidth: .2,
                                lineColor: '#000000',
                                fontSize: 9,
                                font: 'courier',
                                fontStyle: 'bold',
                                cellPadding: 0
                            },
                            columnStyles: {
                            0: {cellWidth: 11, halign:'center', textColor: '#000000'},
                            1: {cellWidth: 17, halign:'center', textColor: '#000000'},
                            2: {cellWidth: 17, halign:'center', textColor: '#000000'},
                            3: {cellWidth: 17, halign:'center', textColor: '#000000'},
                            4: {cellWidth: 27, halign:'center', textColor: '#000000'},
                            5: {halign:'left', textColor: '#000000'},
                            },
                            bodyStyles: {
                                lineColor: '#000000',
                                font: 'courier'
                            }
                        });
                    }

                    if(this.print_no_price == false){
                        doc.autoTable({
                            html: '#total_details'+type,
                            theme: 'plain',
                            startY: 213,
                            styles:{
                                lineWidth: .2,
                                lineColor: '#000000',
                                font: 'courier'
                            },
                            margin: {top: 37, left: 107, right: margin_right},
                            columnStyles: {
                                0: { cellWidth: 48, halign: "left", textColor: "#000000" },
                                1: { cellWidth: 48, halign: "right", textColor: "#000000" },
                                2: { cellWidth: 48, halign: "right", textColor: "#000000" },
                                // 1: { cellWidth: 48, halign: "right", textColor: "#000000" },
                            }
                        });
                    }

                    // doc.autoTable({
                    //     html: '#num_of_items'+type,
                    //     theme: 'plain',
                    //     tableWidth: 116,
                    //     startY: 225,
                    //     margin: {top: 37, left: 6, right: 6},
                    //     columnStyles: {
                    //         0: {cellWidth: 38, textColor: '#000000'},
                    //         1: {cellWidth: 38, textColor: '#000000'},
                    //         2: {cellWidth: 38, textColor: '#000000'},
                    //     }
                    // });
                    let itemFinalY = doc.lastAutoTable.finalY;
                    doc.autoTable({
                        html: '#num_of_items'+type,
                        theme: 'plain',
                        tableWidth: 100,
                        startY:  e.length == 24 ? itemFinalY + 3 : 225,
                        styles:{
                            font: 'courier',
                            fontSize: 7,
                        },
                        margin: {top: 37, left: margin_left, right: margin_right},
                        columnStyles: {
                            0: {cellWidth: 42, textColor: '#000000', cellPadding: 2},
                            1: {cellWidth: 36, textColor: '#000000'},
                            2: {cellWidth: 42, textColor: '#000000'},
                        },
                        didParseCell: function (data) {
                            // Set a fixed row height
                            let fixedRowHeight = 7; // Adjust this value as needed
                            data.cell.styles.cellPadding = { top: (fixedRowHeight - data.cell.styles.fontSize) / 2, bottom: (fixedRowHeight - data.cell.styles.fontSize) / 2 };
                        }
                    });

                    // var footer_y = doc.autoTable.previous.finalY + 1
                    // if(this.print_no_price == true){
                    //     footer_y = 235
                    // }

                    let noOfItemFinaly = doc.lastAutoTable.finalY;
                    doc.autoTable({
                        html: '#footer'+type,
                        theme: 'grid',
                        tableWidth: 106,
                        startY: e.length == 24 ? noOfItemFinaly + 2 : 235,
                        styles: {
                            font: 'courier',
                            fontSize: this.checker_form.checker_name != null ? (Math.ceil(this.checker_form.checker_name.length / 20) > 1 ? 6 : 7) : 7
                        },
                        margin: { left: margin_left, right: margin_right },
                        columnStyles: {
                            0: {cellWidth: 38, halign:'center', textColor: '#000000'},
                            1: {cellWidth: 38, halign:'center', textColor: '#000000'},
                        },
                        bodyStyles: {
                            lineColor: '#000000',
                        }
                    });

                    let finalY = doc.lastAutoTable.finalY; // The y position on the page
                    doc.setFont('courier', 'bold').setFontSize(7).text(190, finalY + 7, `Page ${i + 1} of ${this.print.checkerFormItems.length}`)
                    if(this.print.checkerFormItems.length != (i + 1) ){
                        doc.addPage(1)
                    }
                })

                let payload = {
                    url: 'checkerForms/print/'+this.checker_form.id
                }
                this.$store.dispatch('postCF',payload).then(response => {
                    if (response) {
                        this.resetFields()
                        this.checker_form_items_dialog = false
                        this.$emit('closeView', 'printSuccess')
                    }
                })

                if(pdfAction == 'download'){
                    doc.save('Checker_Form ' + this.checker_form.id + '.pdf');
                    this.$emit('getAllCheckerForms')
                }else if(pdfAction == 'print'){
                    doc.autoPrint();
                    this.$emit('getAllCheckerForms')
                    window.open(doc.output('bloburl'), '_blank');
                }

            },
            printPdfTransmittal(){
                const doc = new this.$jspdf("p", "mm", "letter");
                let ingcoPhLogo = new Image();
                ingcoPhLogo.src = "/images/ingcoph-logo.png";
                doc.addImage(ingcoPhLogo, "png", 10, 12.7, 120, 20);
                doc.setFontSize(14);
                doc.line( 7, 35, 7, 10)
                doc.line( 150, 35, 150, 10)
                doc.line( 209, 35, 209, 10)
                doc.line(7, 10, 209, 10)
                doc.autoTable({
                    startY: 35,
                    theme: 'plain',
                    styles: {
                        lineWidth: 0.1,
                        lineColor: '#000000',
                        textColor: '#FF0000',
                        halign: 'center',
                        // cellWidth: 143
                    },
                    margin: {left: 7, right: 6},
                    columnStyles: { 0:{cellWidth: 143, fontStyle:'bold'}, 1: {cellWidth:59, fontStyle:'bold'}},
                    columns: [
                        { header: 'TRANSMITTAL FORM', dataKey: 'TRANSMITTAL FORM' },
                        { header: `TRANSMITTAL#${this.paddedId}`, dataKey: '' },
                    ],
                    body: [{}],
                    bodyStyles: {
                        fillColor: false,
                        textColor: '#FFFFFF',
                        lineColor: '#FFFFFF',
                    },

                })
                doc.autoTable({
                    startY: 42.5,
                    theme: 'plain',
                    styles: {
                        lineWidth: 0.1,
                        lineColor: '#000000',
                        halign: 'center',
                    },
                    bodyStyles: {
                        fillColor: false,
                        textColor: '#000000',
                    },
                    margin: {left: 7, right: 6},
                    columnStyles: { 0:{cellWidth:83}, 1: {cellWidth:15}, 2: {cellWidth:15}, 3: { cellWidth:30}, 4: {cellWidth:59, halign: 'center'}},
                    body: this.transmittal_details.reduce((row, detail)=>{
                        row.push(Object.assign({},{
                            cf:                     detail.module_id,
                            so:                     detail.order_num,
                            customer:               detail.customer_name,
                            date:                   detail.release_date_time,
                            remarks:                detail.release_remarks
                        }));
                        return row;
                    }, []),
                    columns: [
                        { header: 'CUSTOMER', dataKey: 'customer' },
                        { header: 'SO#', dataKey: 'so' },
                        { header: 'CF#', dataKey: 'cf' },
                        { header: 'RELEASE DATE & TIME', dataKey: 'date' },
                        { header: 'REMARKS', dataKey: 'remarks'},
                    ],
                })
                doc.save('CF Print (Reprinted).pdf')
            },
            closeConfirmOverride(user_id, authenticated = false) {
                if (authenticated) {
                    this.override.override_dialog = false;
                    this.printPdfTransmittal();
                    this.checker_form_items_dialog = false
                } else {
                    this.override.override_dialog = false;
                }
            },

            showImageUploadDialog() {
                this.imageUpload = true;
            },

            closeImageUploadDialog() {
                this.imageUpload = false;
            },

            getImages(images) {
                this.images = images;
            },

            uploadedData(data) {
                if(!data) {
                    this.uploadedImageFile = null;
                }

                this.uploadedImageFile = data;
            },

            clearImageUpload() {
                this.uploadedImageFile = null;
                this.$refs.fileUpload.reset();
            },

            clearCameraCapture() {
                this.$refs.cameraCapture.reset();
                this.images = null;
            },

            storeImageUpload(checkerFormId) {
                if (!!checkerFormId) {
                    const data = new FormData();
                    const config = {
                        headers: {
                            'content-type': 'multipart/form-data'
                        }
                    }
                    if(!!this.uploadedImageFile && this.uploadedImageFile.attachments.length > 0) {
                        let files = this.uploadedImageFile.attachments;

                        for(let i=0;i < _.compact(files).length;i++) {
                            data.append('files[]', files[i]);
                        }
                    }

                    if(!!this.images && this.images.length > 0) {
                        for(let i=0;i < _.compact(this.images).length;i++) {
                            data.append('images[]', _.compact(this.images)[i]);
                        }
                    }
                    data.append('checkerFormId', checkerFormId);

                    this.$store.dispatch('uploadCheckerFormFile', {params:data, config: config}).then(response=>{
                        swal.fire("", response.data.message, response.data.message.toLowerCase());
                        this.$emit('successConfirm', true);
                        this.closeImageUploadDialog();
                        this.clearCameraCapture();
                        this.clearImageUpload();
                    })
                } else {
                    console.error("Invalid Checker Form ID");
                }
            },

            fixItemInPdf(array, linesPerPage) {
                let clean_array = [];
                let temp = [];
                let linesRemaining = linesPerPage;
                let totalLines = 0;
                array.forEach((e, i) => {
                    e.index = i;
                    const nameLines = Math.ceil(e.item.name.length / 47);
                    totalLines += nameLines;
                    if (totalLines <= linesRemaining) {
                        temp.push(e);
                    } else {
                        clean_array.push(temp);
                        temp = [e];
                        totalLines = nameLines;
                    }
                    if (totalLines === linesPerPage || array.length === (i + 1)) {
                        clean_array.push(temp);
                        temp = [];
                        linesRemaining = linesPerPage;
                        totalLines = 0;
                    }
                });
                return clean_array;
            },
        },
        watch: {
            viewTransmittalId: function(val){
                this.checker_form_items_dialog = true
                this.paddedId = this.viewTransmittalItem.series
                this.loaded = false
                if(val != -1){
                    if(this.viewTransmittalId  != null && this.viewTransmittalId  > 0){
                        let payload = {
                            url: 'viewPtPrintDetailItems',
                            id: this.viewTransmittalId,
                            from: 2
                        }
                        this.$store.dispatch('partsTransferGet', payload).then((response) => {
                            this.transmittal_details = response.data
                            this.loaded = true

                            if(response.data.error){
                                swal.fire("", response.data.error, "warning");
                                return false
                            }
                        })
                    }
                }
            },
            viewCheckerFormId: function(val){
                if(val != -1){
                    if(this.viewCheckerFormId != null && this.viewCheckerFormId > 0){
                        let payload = {
                            url: 'viewCheckerForm/'+this.viewCheckerFormId
                        }
                        this.$store.dispatch('getCF',payload).then(async response => {
                            let data = response.data

                            if(data.error){
                                swal.fire("", data.error, "warning");
                                return false
                            }

                            this.checker_form            = data.checker_form
                            this.checkerFormItems        = data.checker_form.checker_form_items
                            this.stock_card              = data.stock_card
                            this.sales_order             = data.sales_order
                            this.checker_form.durations  = this.formatDateTimeDuration(this.checker_form.start_date, this.checker_form.end_date)
                            this.print.checkerFormItems  = this.fixItemInPdf(this.checkerFormItems, 24)
                            if(this.stock_card){
                                this.selectedHeaders = this.stockCardcheckerFormItemsHeaders
                            }else{
                                this.selectedHeaders = this.checkerFormItemsHeaders
                            }

                            this.getItemTypes()
                            let user_warehouses = await this.checkWarehouseUser()


                            this.$store.dispatch('inventoryGet',{
                                url:'items-stocks-per-warehouses',
                                warehouses:user_warehouses.warehouses.map(e=>e.id),
                                items:this.checkerFormItems.map(e=>e.item_id)
                            }).then(response => {
                                this.checkerFormItems.forEach(el=>{
                                    response.data.forEach(e=>{
                                        if(el.item_id == e.item_id){
                                            Object.assign(el,{[e.code]:e.quantity})
                                        }

                                        if( !this.selectedHeaders.find(ell=> ell.value == e.code)){
                                            this.selectedHeaders.push({text:e.code,value:e.code})
                                        }
                                    })
                                })

                                this.checker_form_items_dialog = true
                            })
                        }).catch(error => {
                            console.log(error)
                        });
                    }
                }
            },
            USER_ACCESS:{
                handler(val){
                    if(val != "fail"){
                        this.userAccess = val.map(e=>e.actions_code);
                    }
                }
            },
            GET_SYSTEM:{
                handler(val){
                    if(Object.keys(val).length > 0) {
                        switch (val.code) {
                            case 'ING':
                                this.getCompanyDetails(['Sales'], 'ING').then( result => {
                                    this.companyDetails = result[0];
                                });
                                break;
                            case 'WAD':
                                this.getCompanyDetails(['Sales'], 'WAD').then( result => {
                                    this.companyDetails = result[0];
                                });
                                break;
                        }
                    }
                }
            }
        },
        computed: {
            no_of_items:function(){
                if(this.checkerFormItems != null && this.checkerFormItems.length > 0){
                    var count = 0;
                    this.checkerFormItems.forEach((item)=>{
                        count += this.stock_card ? item.sc_quantity : item.quantity
                    })
                    return this.thousandSeprator(count)
                }else{
                    return 0
                }
            },
            ...mapGetters(['USERACCOUNT_LOAD', 'USER_ACCESS', 'GET_SYSTEM'])
        }

    };

</script>
<style>
    .v-avatar{
        margin: 15px 50px !important;
    }
    #client_modal_card{
        min-height: 160px !important;
    }
</style>
