<template>
    <v-dialog v-model="rfDialogWithEst" persistent max-width="98%" scrollable>
        <v-card class="pb-1">
            <v-row class="m-0">
                <v-col>
                    <v-card-title>
                        <span class="headline">{{ rfStatus == 'RET' ? 'Repair Form Details' : 'Repair Form Quotations'}}</span>
                    </v-card-title>
                </v-col>
                <v-col cols="pull-right-10 p-2">
                <v-btn
                    text
                    icon
                    color="gray"
                    class="float-right"
                    @click="closeRfDetailsDialog()"
                >
                    <v-icon>mdi-close-circle</v-icon>
                </v-btn>
                </v-col>
            </v-row>
            <v-divider class="mt-0" />
            <v-container fluid style="background-color: white; overflow-y: scroll">
                <v-col>
                    <v-card-text>
                        <div class="ma-4">
                            <v-layout>
                                <v-flex lg4>
                                    <div>
                                        RF#: <span>{{ rfNumber }}</span>
                                    </div>
                                    <div>
                                        RD#: <span>{{ rrNumber }}</span>
                                    </div>
                                    <div>
                                        Date: <span>{{ rrDate }}</span>
                                    </div>
                                    <div>
                                        Status: <span>{{ rfStatus }}</span>
                                    </div>
                                    <div v-if="itemWarrantyType == 'In-Warranty'">
                                        Purchase Date:
                                        <span>{{ itemPurchaseDate }}</span>
                                    </div>
                                    <div>
                                        Warranty Type:
                                        <span :style="checkWarrantyType()">{{ itemWarrantyType }}</span>
                                    </div>
                                    <div>
                                        Repair Status: <span>{{ itemRepairStatus }}</span>
                                    </div>
                                </v-flex>
                                <v-flex lg4>
                                    <div v-if="this.rfDetails?.status == 'FCA'">
                                        Estimation#: <span>{{ estimation_num }}</span>
                                    </div>
                                    <div v-if="this.rfDetails?.status == 'FTA'">
                                        Estimation#: <span>{{ estimation_num }}</span>
                                    </div>
                                    <div>
                                        Crate Number: <span>{{ crateNumber }}</span>
                                    </div>
                                    <div>
                                        Location Number: <span>{{ locationNumber }}</span>
                                    </div>
                                    <div v-if="!!rfItemRejectReason">
                                        Change Warranty Reason: <span>{{ rfItemRejectReason }}</span>
                                    </div>
                                </v-flex>
                            </v-layout>

                            <v-divider />
                        </div>

                        <div class="px-4 pb-4">
                            <v-layout row class="ma-4">
                            <v-flex lg12 class="mr-2">
                                <v-layout row class="pb-2 mx-0 mb-2 mt-2">
                                    <v-flex lg6>
                                        <v-tooltip bottom>
                                            <template v-slot:activator="{ on, attrs }">

                                                <v-btn @click="showCustomerInfoDialog()"
                                                    fab
                                                    text
                                                    small
                                                    :loading="loading"
                                                    class="mr-4"
                                                    color="blue"
                                                    v-bind="attrs"
                                                    v-on="on"
                                                >
                                                    <v-icon>mdi-account-question</v-icon>
                                                </v-btn>

                                            </template>
                                            <span>Customer Info</span>
                                          </v-tooltip>
                                        <v-tooltip bottom>
                                            <template v-slot:activator="{ on, attrs }">

                                            <v-btn @click="showItemInfoDialog()" class="mr-4"
                                                fab
                                                text
                                                small
                                                color="blue"
                                                v-bind="attrs"
                                                v-on="on"
                                                :loading="loading"
                                            ><v-icon>mdi-wrench</v-icon></v-btn>
                                            </template>
                                            <span>Item Info</span>
                                        </v-tooltip>
                                        <v-tooltip bottom v-if="access.includes('view_prev_quotations') && ['QUO'].includes(rfStatus) ">
                                            <template v-slot:activator="{ on, attrs }">

                                            <v-btn @click="view_previous_quotations = true"
                                                :loading="loading"
                                                class="mr-4"
                                                fab
                                                text
                                                small
                                                color="blue"
                                                v-bind="attrs"
                                                v-on="on"
                                              >
                                                <v-icon>mdi-file-document</v-icon>
                                            </v-btn>

                                            </template>
                                            <span>Previous Quotations</span>
                                        </v-tooltip>
                                        <v-tooltip bottom>
                                            <template v-slot:activator="{ on, attrs }">
                                            <v-btn @click="refreshTableList()"
                                                :loading="loading"
                                                class="mr-4"
                                                fab
                                                text
                                                small
                                                color="blue"
                                                v-bind="attrs"
                                                v-on="on"
                                              >
                                                <v-icon>mdi-sync</v-icon>
                                            </v-btn>

                                            </template>
                                            <span>Refresh Table</span>
                                        </v-tooltip>

                                        <template v-slot:loader>
                                            <span class="custom-loader">
                                              <v-icon light>mdi-cached</v-icon>
                                            </span>
                                          </template>
                                        <v-btn v-if="['FTA','WCM'].includes(rfStatus)" @click="printTechnicalReport()" :disabled="loading">Print Technical Report</v-btn>
                                    </v-flex>
                                    <v-spacer />
                                    <v-flex lg6>
                                        <v-layout row class="mx-0">
                                            <v-spacer />
                                            <v-flex class="pr-2">
                                                <div v-if="rfStatus == 'FCA'" style="text-align:end">
                                                    <!-- <v-btn @click="showEstHistoryDialog()" :disabled="loading">Conversation</v-btn> -->
                                                    <v-tooltip bottom>
                                                        <template v-slot:activator="{ on, attrs }">
                                                        <v-btn @click="showEstHistoryDialog()"
                                                            :loading="loading"
                                                            class="mr-4"
                                                            fab
                                                            text
                                                            small
                                                            color="blue"
                                                            v-bind="attrs"
                                                            v-on="on"
                                                          >
                                                            <v-icon>mdi-message-text</v-icon>
                                                        </v-btn>

                                                        </template>
                                                        <span>Conversation</span>
                                                    </v-tooltip>
                                                </div>
                                            </v-flex>

                                            <v-flex shrink>
                                                <BaseFilesViewerVue :delete="showDelete()" :rfId="rfId" :rfNumber="rfNumber" />
                                            </v-flex>
                                        </v-layout>
                                    </v-flex>
                                </v-layout>

                                <div v-if="!!partsReturn">
                                    <div>
                                        <span style="color:red">For Return Items:</span>
                                    </div>

                                    <v-card class="mt-4 mb-4 pa-4" style="background-color:lightgray">
                                        <div v-if="loading" style="text-align:center">
                                            <v-progress-circular indeterminate color="primary" size="50" />
                                        </div>
                                        <v-simple-table v-else class="dynamic_list_table">
                                            <template v-slot:default>
                                            <thead>
                                                <tr>
                                                    <th class="text-center" style="width:10%">Qty</th>
                                                    <th v-if="!!rfReEstimate" class="text-center" style="width:10%">Added Qty</th>
                                                    <th class="text-center">Item Model</th>
                                                    <th v-if="itemRepairStatus == 'For-Repair'" class="text-center">Part Num</th>
                                                    <th class="text-center">Item Name</th>
                                                    <th class="text-center">Category</th>
                                                    <th class="text-center">Warehouse</th>
                                                    <th class="text-center">Stock</th>
                                                    <th class="text-center">Price</th>
                                                    <th class="text-center">Disc. Price</th>
                                                    <th class="text-center">Total</th>
                                                    <th class="text-center">Foc</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr v-for="(item, i) in returnSpItems" :key="i">
                                                    <td style="text-align:center">
                                                        <span>{{ item.return_qty }}</span>
                                                    </td>
                                                    <td v-if="!!rfReEstimate" style="text-align:center">
                                                        <v-chip v-if="!!item.additional" color="blue">
                                                            {{ item.additional_qty }}
                                                        </v-chip>
                                                        <span v-else>{{ item.additional_qty }}</span>
                                                    </td>
                                                    <td style="text-align:center">
                                                        <span v-if="!!item.new_estimate">
                                                            <v-chip color="orange">
                                                                {{ item.model }}
                                                            </v-chip>
                                                        </span>

                                                        <span v-else-if="itemRepairStatus == 'For-Replacement' || rfStatus == 'FCA'">
                                                            {{ item.model }}
                                                        </span>

                                                        <span v-else>{{ item.model }}</span>
                                                    </td>
                                                    <td v-if="itemRepairStatus == 'For-Repair'" style="text-align:center">
                                                        <!-- <v-btn v-if="rfStatus == 'QUO'" :disabled="!!rfReEstimate ? !item.new_estimate : false" @click="searchByPartNum(item)">{{ !!item.item.part_num ? item.item.part_num : 'search model' }}</v-btn> -->
                                                        <span v-if="rfStatus == 'QUO'">{{ item.item.part_num }}</span>
                                                        <span v-else-if="rfStatus == 'FCA' || rfStatus == 'FTA'">{{ item.item.part_num }}</span>
                                                        <!-- <span>{{ item.item.part_num }}</span> -->
                                                        <!-- <span>{{ item.item.part_num }}</span> -->
                                                    </td>
                                                    <td style="text-align:center">{{ item.name }}</td>
                                                    <td style="text-align:center">{{ item.category }}</td>
                                                    <td style="text-align:center">
                                                        {{ item.warehouse_code }}
                                                    </td>
                                                    <td style="text-align:center">{{ item.stock }}</td>
                                                    <td style="text-align:center">{{ item.selling_price | currency }}</td>
                                                    <td style="text-align:center">{{ item.discounted_price | currency }}</td>
                                                    <td style="text-align:center">{{ item.total | currency }}</td>
                                                    <td style="text-align:center">
                                                        <!-- <span>{{ item.foc ? 'Yes' : 'No' }}</span> -->
                                                        <span>{{ item.foc ? 'Yes' : 'No' }}</span>
                                                    </td>
                                                </tr>
                                            </tbody>
                                            </template>
                                        </v-simple-table>
                                    </v-card>
                                </div>

                                <!-- Requested Item Details -->
                                <v-card class="pa-4">
                                    <v-layout row class="px-4">
                                        <v-flex lg4>
                                        <div class="pb-2">
                                            Requested
                                            <span v-if="itemRepairStatus == 'For-Replacement'"
                                            >Item</span
                                            >
                                            <span v-else>Spare Parts</span>

                                            <span v-if="!!rfReEstimate && !changeWarrantyType && partsReturn == 0">
                                                <v-chip color="green" text-color="white" small>
                                                    Re Estimate
                                                </v-chip>

                                                <v-chip color="red" text-color="white" small>
                                                    {{ rfReEstimateCount }}
                                                </v-chip>
                                            </span>

                                            <span v-if="rfStatus == 'WCM' && cmNumber == null">
                                                <v-chip color="orange" text-color="white" small>
                                                    Waiting For Cm Approval
                                                </v-chip>
                                            </span>
                                            <span v-else-if="rfStatus == 'WCM' && cmNumber != null">
                                                <v-chip color="green" text-color="white" small>
                                                    Approved CM
                                                </v-chip>
                                            </span>
                                        </div>
                                        <div v-if="rfStatus != 'RET'" class="pb-2">Requested Date: {{ rfEstItemsDate }}</div>
                                        <div v-if="rfStatus == 'RET'" class="pb-2">Cancelled Date: {{ rfCancelledDate }}</div>
                                        <div class="pb-2">
                                            Technician: <span>{{ assignedTechnician }}</span>
                                        </div>
                                        </v-flex>
                                        <v-flex lg4>
                                            <div v-if="rfStatus == 'FCA'" class="pb-2">{{ `SQ#: ${salesQuotationNumber}` }}</div>
                                            <div v-if="rfStatus == 'WCM' && !!cmNumber" class="pb-2">{{ `CM#: ${cmNumber}` }}</div>
                                            <div class="pb-2">{{ `Discounting: ${customerDiscount}` }}</div>
                                            <div v-if="!!sqNumber && rfStatus == 'QUO'" class="pb-2">{{ `SQ#: ${sqNumber}` }}</div>
                                        </v-flex>
                                        <v-flex lg4>
                                            <div v-if="!!rfReEstimate && itemWarrantyType != 'Dealer-Stock' && !changeWarrantyType">
                                                <v-chip color="blue" text-color="white" small>
                                                    Additional
                                                </v-chip>
                                                <v-chip color="orange" text-color="white" small>
                                                    New Request
                                                </v-chip>
                                            </div>
                                            <div v-if="rfStatus == 'WCM' && !!approvedCmBy" class="pb-2">{{ `Admin: ${approvedCmBy}` }}</div>
                                            <!-- <div v-if="rfStatus == 'WCM' && !!approvedCmDate" class="pb-2">{{ `Approved for Replacement Date: ${approvedCmDate}` }}</div> -->
                                        </v-flex>
                                    </v-layout>
                                    <div v-if="loading" style="text-align:center">
                                        <v-progress-circular indeterminate color="primary" size="50" />
                                    </div>
                                    <v-simple-table v-else class="dynamic_list_table">
                                        <template v-slot:default>
                                        <thead>
                                            <tr>
                                                <th class="text-center" style="width:10%">Qty</th>
                                                <th v-if="!!rfReEstimate" class="text-center" style="width:10%">Added Qty</th>
                                                <th class="text-center">Item Model</th>
                                                <th v-if="itemRepairStatus == 'For-Repair'" class="text-center">Part Num</th>
                                                <th v-if="!['FCA', 'FTA'].includes(rfStatus)" class="text-center">Substitute</th>
                                                <th class="text-center">Item Name</th>
                                                <th class="text-center">Category</th>
                                                <th v-if="['QUO'].includes(rfStatus)" class="text-center">SP Number</th>
                                                <th v-if="!['FCA', 'FTA', 'WCM'].includes(rfStatus)" class="text-center">Warehouse</th>
                                                <th class="text-center">Stock</th>
                                                <th class="text-center">Price</th>
                                                <th class="text-center">Disc. Price</th>
                                                <th class="text-center">Total</th>
                                                <th class="text-center">Foc</th>
                                            </tr>
                                        </thead>
                                        <tbody v-if="!['FCA', 'FTA'].includes(rfStatus)">
                                            <tr v-for="(item, i) in rfEstItems" :key="i">
                                            <td style="text-align:center">
                                                <span>{{ item.requested_qty + item.serve_qty }}</span>
                                            </td>
                                            <td v-if="!!rfReEstimate" style="text-align:center">
                                                <v-chip v-if="!!item.additional" color="blue">
                                                    {{ item.additional_qty }}
                                                </v-chip>
                                                <span v-else>{{ item.additional_qty }}</span>
                                            </td>
                                            <td style="text-align:center">
                                                <span v-if="!!item.new_estimate">
                                                    <v-chip color="orange">
                                                        {{ item.model }}
                                                    </v-chip>
                                                </span>

                                                <span v-else-if="itemRepairStatus == 'For-Replacement' || rfStatus == 'FCA'">
                                                    {{ item.model }}
                                                </span>

                                                <span v-else>{{ item.model }}</span>
                                            </td>
                                            <td v-if="!!partsReturn" style="text-align:center">
                                                <span>{{ item.item.part_num }}</span>
                                            </td>
                                            <td v-else-if="itemRepairStatus == 'For-Repair'" style="text-align:center">
                                                <v-btn
                                                    v-if="rfStatus == 'QUO'"
                                                        :disabled="checkPartNum(item)"
                                                        @click="searchByPartNum(item)"
                                                    >
                                                        {{ !!item.item.part_num ? item.item.part_num : 'search model' }}
                                                    </v-btn>
                                                <span v-else-if="rfStatus == 'FCA' || rfStatus == 'FTA'">{{ item.item.part_num }}</span>
                                                <!-- <span>{{ item.item.part_num }}</span> -->
                                                <!-- <span>{{ item.item.part_num }}</span> -->
                                            </td>
                                            <td style="text-align:center">{{ item.substitute }}</td>
                                            <td style="text-align:center">{{ item.name }}</td>
                                            <td style="text-align:center">{{ item.category }}</td>
                                            <td v-if="['QUO'].includes(rfStatus)" style="text-align:center">{{ item.sp_number }}</td>
                                            <td v-if="!['WCM'].includes(rfStatus)" style="text-align:center">
                                                {{ item.warehouse_code }}
                                            </td>
                                            <td style="text-align:center">{{ item.stock }}</td>
                                            <td style="text-align:center">{{ item.selling_price | currency }}</td>
                                            <td style="text-align:center">{{ item.discounted_price | currency }}</td>
                                            <td style="text-align:center">{{ item.total | currency }}</td>
                                            <td style="text-align:center">
                                                <v-checkbox
                                                    v-if="itemWarrantyType == 'In-Warranty' && rfStatus == 'QUO' || itemWarrantyType == 'Out-Warranty' && rfStatus == 'QUO' || itemWarrantyType == 'Dealer-Stock' && rfStatus == 'QUO'"
                                                    v-model="item.foc"
                                                    class="align-center justify-center"
                                                    :disabled="checkNewEstimate(item) || !!sqNumber"
                                                    @change="forFoc(item)"
                                                    :true-value="1"
                                                    :false-value="0"
                                                    />
                                                <span
                                                    v-else-if="rfStatus == 'FCA' || rfStatus == 'FTA' && itemWarrantyType == 'In-Warranty' || itemWarrantyType == 'Out-Warranty' || itemWarrantyType == 'Dealer-Stock' || rfStatus == 'WCM'">
                                                    {{ item.foc ? 'Yes' : 'No' }}
                                                </span>
                                            </td>
                                        </tr>
                                    </tbody>
                                    <tbody v-if="['FCA', 'FTA'].includes(rfStatus)">
                                        <tr v-for="(item, i) in quotationItems" :key="i">
                                            <td style="text-align:center">
                                                <span>{{ item.quantity }}</span>
                                            </td>
                                            <td v-if="!!rfReEstimate" style="text-align:center">
                                                <v-chip v-if="!!item.additional" color="blue">
                                                    {{ item.additional_qty }}
                                                </v-chip>
                                                <span v-else>{{ item.additional_qty }}</span>
                                            </td>
                                            <td style="text-align:center">
                                                <span v-if="!!item.new_estimate">
                                                    <v-chip color="orange">
                                                        {{ item.view_item.model }}
                                                    </v-chip>
                                                </span>

                                                <span v-else-if="itemRepairStatus == 'For-Replacement' || rfStatus == 'FCA'">
                                                    {{ item.view_item.model }}
                                                </span>

                                                <span v-else>{{ item.view_item.model }}</span>
                                            </td>
                                            <td v-if="!!partsReturn" style="text-align:center">
                                                <span>{{ item.view_item.part_num }}</span>
                                            </td>
                                            <td v-else-if="itemRepairStatus == 'For-Repair'" style="text-align:center">
                                                <span>{{ item.view_item.part_num }}</span>
                                            </td>
                                            <!-- <td style="text-align:center">{{ item.substitute }}</td> -->
                                            <td style="text-align:center">{{ item.view_item.name }}</td>
                                            <td style="text-align:center">{{ item.view_item.item_category_text }}</td>
                                            <td style="text-align:center">{{ item.stock }}</td>
                                            <td style="text-align:center">{{ item.srp | currency }}</td>
                                            <td style="text-align:center">{{ item.disc_price | currency }}</td>
                                            <td style="text-align:center">{{ item.total_amount | currency }}</td>
                                            <td style="text-align:center">
                                                <span
                                                    v-if="itemWarrantyType == 'In-Warranty' || itemWarrantyType == 'Out-Warranty' || itemWarrantyType == 'Dealer-Stock'">
                                                    {{ item.foc ? 'Yes' : 'No' }}
                                                </span>
                                            </td>
                                        </tr>
                                    </tbody>
                                </template>
                            </v-simple-table>
                        </v-card>
                                <div v-if="['FCA', 'FTA'].includes(rfStatus)" class="col-sm-12 col-md-12 pull-right text-right mt-4">
                                    <h5>Total Amount: &#x20b1;{{ grandTotalAmount | currency }}</h5>
                                </div>
                                <div v-else class="col-sm-12 col-md-12 pull-right text-right mt-4">
                                    <h5>Total Amount: &#x20b1;{{ this.estimateItemTotalPrice | currency }}</h5>
                                </div>
                            </v-flex>
                            </v-layout>
                        </div>
                    </v-card-text>
                </v-col>
            </v-container>
        <v-divider />

        <!-- Action Buttons -->
        <!-- FOR CM AND FOR REPLACEMENT APPROVAL -->
        <v-layout v-if="rfStatus == 'FTA'" row class="mb-6">
            <v-flex lg6>
                <div v-if="changeWarrantyType" style="text-align:center">
                    <v-btn :loading="approveChangeWarrantyOnFlight" @click="approveChangeWarranty()" class="mr-4">Approve Change Warranty</v-btn>
                </div>
                <div style="text-align:center">
                    <v-btn v-if="itemRepairStatus == 'For-Replacement'" :disabled="checkForReplacementStock()" :loading="approveForReplacementOnFlight" @click="showOverrideForReplacement()" class="mr-4">Approve For Replacement</v-btn>
                    <v-btn v-else-if="itemRepairStatus == 'For-Repair'" :disabled="quotationItems.length == 0" @click="forFocApproval()" class="mr-4">Approve For Repair</v-btn>
                    <v-btn v-if="itemWarrantyType == 'Dealer-Stock' && itemRepairStatus == 'For-Replacement' || itemWarrantyType == 'In-Warranty' && itemRepairStatus == 'For-Replacement'" :disabled="rfEstItems.length == 0" :loading="approveForCmOnFlight" @click="cmApproval()">Approve For CM</v-btn>
                </div>
            </v-flex>

            <v-flex lg6>
                <v-layout v-if="rfStatus == 'FTA'" row>
                    <v-flex v-if="changeWarrantyType">
                        <div style="text-align:center">
                            <v-btn :loading="rejectChangeWarrantyOnFlight" @click="rejectChangeWarranty()" color="red" class="ml-5" :disabled="loading">Reject Change Warranty</v-btn>
                        </div>
                    </v-flex>
                    <v-flex>
                        <div style="text-align:center">
                            <v-btn :disabled="quotationItems.length == 0" @click="warrantyRejectReasonDialog()" color="red" class="ml-5" >Change Warranty</v-btn>
                            <v-btn :disabled="quotationItems.length == 0" @click="showDiagnosisRejectReasonDialog()" color="red" class="ml-5" >Diagnosis Reject</v-btn>
                        </div>
                    </v-flex>
                </v-layout>
            </v-flex>
        </v-layout>

        <v-layout v-else-if="rfStatus == 'FCA'" class="mb-6" row>
            <v-flex lg6>
                <div style="text-align: center">
                    <v-btn :disabled="quotationItems.length == 0" @click="customerApproved()" class="mr-5" :loading="customerApprovedOnFlght" >Customer Approved</v-btn>
                </div>
            </v-flex>
            <v-flex lg6>
                <div style="text-align: center">
                    <!-- <v-btn
                        :disabled="rfEstItems.length == 0"
                        @click="customerDisapproved()"
                        class="ml-5"
                        style="background-color: red"
                    >
                        Customer Dis-Approvedssss
                    </v-btn> -->
                    <v-btn
                        :disabled="quotationItems.length == 0"
                        @click="updateToPin()"
                        class="ml-5"
                        style="background-color: red"
                    >
                        Customer Dis-Approved
                    </v-btn>
                    <v-btn v-if="!partsReturn" :disabled="quotationItems.length == 0" @click="warrantyRejectReasonDialog()" color="red" class="ml-5">Change Warranty</v-btn>
                    <v-btn v-if="!partsReturn" :disabled="quotationItems.length == 0" @click="showDiagnosisRejectReasonDialog()" color="red" class="ml-5" >Diagnosis Reject</v-btn>
                </div>
            </v-flex>
        </v-layout>

        <v-layout v-if="rfStatus == 'WCM'" class="mb-6" row>
            <v-flex lg12>
                <div style="text-align: center">
                    <!-- <v-btn :loading="confirmCmOnFlight" :disabled="cmId" @click="confirmApprovedCm()" class="mr-5" >Confirm CM And Create Stock Transfer</v-btn> -->
                    <!-- <v-tooltip bottom color="#f69f1a">
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn
                                @click="moveToDef()"
                                text
                                icon
                                class="mr-4"
                                color="#f69f1a"
                                v-bind="attrs"
                                v-on="on"
                                x-large
                                :disabled="cmId"
                            >
                                <v-icon>mdi-subdirectory-arrow-right</v-icon>
                            </v-btn>
                        </template>
                        <span>Move to DEF</span>
                    </v-tooltip> -->
                    <v-tooltip bottom color="#f69f1a">
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn
                                @click="createPartsTransfer()"
                                text
                                icon
                                class="mr-4"
                                color="#f69f1a"
                                v-bind="attrs"
                                v-on="on"
                                x-large
                                :loading="buttonLoading"
                                :disabled="cmId"
                            >
                                <v-icon>mdi-truck</v-icon>
                            </v-btn>
                        </template>
                        <span>Create Parts Transfer</span>
                    </v-tooltip>
                </div>
            </v-flex>
        </v-layout>

        <div v-if="rfStatus == 'QUO'">
            <v-layout v-if="!changeWarrantyType" class="mb-6 mx-8" row>
                <v-flex lg6>
                    <div style="text-align:center">
                        <v-btn :disabled="!!sqNumber" :loading="loading" @click="convertToSq()" >Generate SQ</v-btn>
                        <!-- <v-btn :disabled="showPrintSq()" :loading="loading" @click="printSq()" >Print SQ</v-btn> -->
                    </div>
                </v-flex>
                <v-flex lg6>
                    <div style="text-align:center">
                        <v-btn :disabled="!hasChanges" :loading="loading" @click="updateEstimation()" >Save</v-btn>
                        <v-btn class="mx-1" :disabled="!sqNumber" :loading="loading" @click="proceed()" >Proceed </v-btn>
                        <v-btn v-if="this.lastRfStatus == 'UAS'" :loading="loading" color="red" @click="revertRfStatus('UAS')" >Revert To UAS </v-btn>
                    </div>
                </v-flex>
            </v-layout>

            <!-- <v-layout v-else class="mb-6 mx-8" row>
                <v-flex lg12 class="pb-4">
                    <div v-show="warrantyUploader" style="text-align:center">
                        <div style="display: inline-block">
                            Upload Warranty Document: <span style="color:red">*</span>
                        </div>
                        <div style="display: inline-block" class="mr-2">
                            <FileUploadComponentVue ref="fileUpload" @uploadedData="uploadedData" @reset="clearFileUpload" />
                        </div>
                        <div style="display: inline-block">
                            Purchase Date: <span style="color:red">*</span>
                        </div>
                        <div style="display: inline-block">
                            <v-dialog
                                ref="purchaseDateDialog"
                                v-model="purchaseDateDialog"
                                :return-value.sync="purchaseDate"
                                persistent
                                width="290px"
                            >
                                <template v-slot:activator="{ on, attrs }">
                                <v-text-field
                                    v-model="purchaseDate"
                                    readonly
                                    dense outlined
                                    class="hide-text-field-details"
                                    v-bind="attrs"
                                    v-on="on"
                                    clearable
                                ></v-text-field>
                                </template>
                                <v-date-picker
                                    v-model="purchaseDate"
                                    scrollable
                                    :max="maxDate"
                                >
                                <v-spacer />
                                <v-btn
                                    text
                                    color="primary"
                                    @click="purchaseDateDialog = false"
                                >
                                    Cancel
                                </v-btn>
                                <v-btn
                                    text
                                    color="primary"
                                    @click="$refs.purchaseDateDialog.save(purchaseDate)"
                                >
                                    OK
                                </v-btn>
                                </v-date-picker>
                            </v-dialog>
                        </div>
                    </div>
                </v-flex>

                <v-flex lg12>
                    <v-layout row>
                        <v-spacer />
                        <v-flex lg2 class="mr-4">
                            <div style="text-align:end">
                                <v-autocomplete
                                    v-model="itemWarrantyTypeUpdater"
                                    :items="warrantyTypeItems()"
                                    outlined
                                    dense
                                />
                            </div>
                        </v-flex>
                        <v-flex lg6>
                            <div>
                                <v-btn v-if="itemRepairStatus == 'For-Replacement'" :loading="confirmChangeWarrantyOnFlight" color="red" @click="confirmChangeWarranty()">Re-Diagnose</v-btn>
                                <v-btn v-else :loading="confirmChangeWarrantyOnFlight" @click="confirmChangeWarranty()" :disabled="loading">Update Warranty Type</v-btn>
                            </div>
                        </v-flex>
                    </v-layout>
                </v-flex>
            </v-layout> -->
        </div>

        <!-- <v-layout v-if="rfStatus == 'RET'" class="mb-6 mx-8" row>
            <v-flex>
                <div style="text-align:center">
                    <v-btn @click="confirmCancelledQuotation()">Confirm</v-btn>
                </div>
            </v-flex>
        </v-layout> -->
        </v-card>
        <!-- Item Information Dialog -->
        <v-dialog v-model="itemInfoDialog" persistent max-width="50%">
            <v-card class="pa-4">
                <v-row class="m-0">
                    <v-col>
                        <v-card-title>
                            <span class="headline">Item Information</span>
                        </v-card-title>
                    </v-col>
                    <v-col cols="pull-right-10 p-2">
                        <v-btn
                        text
                        icon
                        color="gray"
                        class="float-right"
                        @click="closeItemInfoDialog()"
                        >
                        <v-icon>mdi-close-circle</v-icon>
                        </v-btn>
                    </v-col>
                </v-row>

                <v-divider class="mt-0" />

                <v-layout row class="mx-4">
                    <v-flex lg4>
                        <label style="color: gray">Warranty Type:</label>
                    </v-flex>
                    <v-flex lg8>
                        {{ itemWarrantyType }}
                    </v-flex>
                </v-layout>

                <v-layout row class="mx-4">
                    <v-flex lg4>
                        <label style="color: gray">Purchase Date:</label>
                    </v-flex>
                    <v-flex lg8>
                        <span>{{ itemPurchaseDate }}</span>
                    </v-flex>
                </v-layout>

                <v-layout row class="mx-4">
                    <v-flex lg4>
                        <label style="color: gray">Item Model:</label>
                    </v-flex>
                    <v-flex lg8>
                        {{ itemModel }}
                    </v-flex>
                </v-layout>

                <v-layout row class="mx-4">
                    <v-flex lg4>
                        <label style="color: gray">Item Name:</label>
                    </v-flex>
                    <v-flex lg8>
                        {{ itemProductName }}
                    </v-flex>
                </v-layout>

                <v-layout row class="mx-4">
                    <v-flex lg4>
                        <label style="color: gray">Serial No:</label>
                    </v-flex>
                    <v-flex lg8>
                        {{ itemSerial }}
                    </v-flex>
                </v-layout>

                <v-layout row class="mx-4">
                    <v-flex lg4>
                        <label style="color: gray">Accessories:</label>
                    </v-flex>
                    <v-flex lg8>
                        {{ itemAccessories }}
                    </v-flex>
                </v-layout>

                <v-layout row class="mx-4">
                    <v-flex lg4>
                        <label style="color: gray">Other Accessory:</label>
                    </v-flex>
                    <v-flex lg8>
                        {{ itemOtherAccessory }}
                    </v-flex>
                </v-layout>

                <v-layout row class="mx-4">
                    <v-flex lg4>
                        <label style="color: gray">Other Unit Condition:</label>
                    </v-flex>
                    <v-flex lg8>
                        {{ itemOtherUnitCondition }}
                    </v-flex>
                </v-layout>

                <v-layout row class="mx-4">
                    <v-flex lg4>
                        <label style="color: gray">Complaint / Symptom:</label>
                    </v-flex>
                    <v-flex lg8>
                        {{ itemDefectDescription }}
                    </v-flex>
                </v-layout>

                <v-layout row class="mx-4">
                    <v-flex lg4>
                        <label style="color: gray">Remarks:</label>
                    </v-flex>
                    <v-flex lg8>
                        {{ itemDefectRemarks }}
                    </v-flex>
                </v-layout>

                <v-layout row class="mx-4">
                    <v-flex lg4>
                        <label style="color: gray">Assigned Technician:</label>
                    </v-flex>
                    <v-flex lg8>
                        {{ assignedTechnician }}
                    </v-flex>
                </v-layout>

                <v-layout row class="mx-4">
                    <v-flex lg4>
                        <label style="color: gray">Symptom Upon Tested:</label>
                    </v-flex>
                    <v-flex lg8>
                        {{ itemSymptomUponTested }}
                    </v-flex>
                </v-layout>

                <v-layout row class="mx-4">
                    <v-flex lg4>
                        <label style="color: gray">Change Warranty Reason:</label>
                    </v-flex>
                    <v-flex lg8>
                        {{ rfItemRejectReason }}
                    </v-flex>
                </v-layout>

                <v-layout row class="mx-4">
                    <v-flex lg4>
                        <label style="color: gray">Diagnosis Reject Reason:</label>
                    </v-flex>
                    <v-flex lg8>
                        {{ rfItemDiagnosisRejectReason }}
                    </v-flex>
                </v-layout>

                <v-layout row class="mx-4">
                    <v-flex lg4>
                        <label style="color: gray">Job Done:</label>
                    </v-flex>
                    <v-flex lg8>
                        {{ itemJobDone }}
                    </v-flex>
                </v-layout>
            </v-card>
        </v-dialog>

        <!-- Customer Information Dialog -->
        <v-dialog v-model="customerInfoDialog" persistent max-width="50%">
            <v-card class="pa-4">
                <v-row class="m-0">
                <v-col>
                    <v-card-title>
                        <span class="headline">Customer Information</span>
                    </v-card-title>
                 </v-col>
                <v-col cols="pull-right-10 p-2">
                    <v-btn
                    text
                    icon
                    color="gray"
                    class="float-right"
                    @click="closeCustomerInfoDialog()"
                    >
                    <v-icon>mdi-close-circle</v-icon>
                    </v-btn>
                </v-col>
                </v-row>
                <v-divider class="mt-0" />
                <v-layout row class="mx-4">
                <v-flex lg4>
                    <label style="color: gray">Company Name:</label>
                </v-flex>
                <v-flex lg8>
                    {{ companyName }}
                </v-flex>
                </v-layout>
                <v-layout row class="mx-4">
                <v-flex lg4>
                    <label style="color: gray">Company Number:</label>
                </v-flex>
                <v-flex lg8>
                    {{ companyContactNumber }}
                </v-flex>
                </v-layout>
                <v-layout row class="mx-4">
                <v-flex lg4>
                    <label style="color: gray">Customer Number:</label>
                </v-flex>
                <v-flex lg8>
                    {{ contactNumber }}
                </v-flex>
                </v-layout>
                <v-layout row class="mx-4">
                <v-flex lg4>
                    <label style="color: gray">Customer Name:</label>
                </v-flex>
                <v-flex lg8>
                    {{ customerName }}
                </v-flex>
                </v-layout>
                <v-layout row class="mx-4">
                <v-flex lg4>
                    <label style="color: gray">Address:</label>
                </v-flex>
                <v-flex lg8>
                    {{ address }}
                </v-flex>
                </v-layout>
                <v-layout row class="mx-4">
                <v-flex lg4>
                    <label style="color: gray">Email:</label>
                </v-flex>
                <v-flex lg8>
                    {{ email }}
                </v-flex>
                </v-layout>
            </v-card>
        </v-dialog>

        <!-- Conversation Dialog -->
        <ConversationDialogVue v-if="!!rfEstimation" :rfEstId="rfEstId" @closeDialog="closeDialog" ref="conversation"/>

        <SearchByPartNumberDialogVue :est_item="selectedItemForSearch" :rfEstItems="rfEstItems" @searchResult="searchResult" @closeSearchByPartNumDialog="closeSearchByPartNumDialog" />

        <!-- Admin Approval -->
        <v-dialog v-model="showCmApproval" persistent max-width="30%" scrollable>
            <v-card>
                <v-row class="m-0">
                    <v-col>
                        <v-card-title>
                            <span class="headline">Confirm Override For CM</span>
                        </v-card-title>
                    </v-col>
                    <v-col cols="pull-right-10 p-2">
                        <v-btn text icon color="gray" class="float-right" @click="closeCmApprovalDialog(); override_username = ''; override_user_password = '';">
                            <v-icon>mdi-close-circle</v-icon>
                        </v-btn>
                    </v-col>
                </v-row>
                <v-card-text>
                    <div class="row">
                        <!-- <div class="col-sm-12 col-md-6">
                            <label for="override_username" class="float-left control-label col-form-label">Username</label>
                            <input type="text" class="form-control" id="override_username" placeholder="Username" v-model="override_username" autocomplete="new-password">
                            <span v-for="errors in errors.override_username" class="text-warning" v-bind:key="errors">{{errors}}</span>
                        </div> -->
                        <div class="col-sm-12 col-md-12">
                            <label for="override_user_password" class="float-left control-label col-form-label">Password</label>
                            <input type="password" class="form-control" id="override_user_password" placeholder="Password" v-model="adminPassword" autocomplete="new-password">
                            <!-- <span v-for="errors in errors.override_user_password" class="text-warning" v-bind:key="errors">{{errors}}</span> -->
                        </div>
                    </div>
                    <div class="border-top">
                        <div class="card-body py-4 d-flex justify-content-center">
                            <v-btn  v-on:click="confirmedCmApproval()">Confirm</v-btn>
                        </div>
                    </div>
                </v-card-text>
            </v-card>
        </v-dialog>

        <v-container fluid style="display:none;">
            <div id="div_logo" >
                <div style="font-size: 12px; line-height: 1.9">
                    <p>
                        <span style="font-size: 18px; font-weight: bold;">
                            WADFOW Tools Philippines Corp.
                        </span>
                        <br>2 D Arellano St. Bagong Barrio, Caloocan City
                        <br>Cell No.:
                        <br>Tel. No.:
                        <br>Service Mobile / Viber No.: +63 917 625 2286
                        <br>Spare Parts Mobile / Viber No.:
                    </p>
                </div>
            </div>
            <div id="div_sales_quote_num" style="font-size: 12px; line-height: 1.9">
                <div style="border-top: 1px dashed #CFCFCF; padding: 2px; padding-bottom: -4px; text-align: center; border-bottom: none;">&nbsp;</div>
                <p><span style="font-size: 18px; font-weight: bold;">Sales Quotation</span><br><b>SQ# {{ sales_quotation.quote_num }}</b></p>
            </div>

            <div>
                <table id="customer_details">
                    <tbody>
                    <tr>
                        <td>Customer:</td>
                        <td>{{ sales_quotation.customer_name | uppercase }} </td>
                        <td>Date:</td>
                        <td>{{ sales_quotation.created_at | formatDate }} </td>
                    </tr>
                    <tr>
                        <td>Address:</td>
                        <td>{{ sales_quotation.shipping_address_text | uppercase }}</td>
                        <td>Terms:</td>
                        <td>{{ sales_quotation.terms == 0 ? "CASH" : sales_quotation.terms+" days" }}</td>
                    </tr>
                    <tr>
                        <td>Remarks:</td>
                        <td>{{ sales_quotation.remarks }}</td>
                        <td></td>
                        <td></td>
                    </tr>
                    </tbody>
                </table>
            </div>

            <div>
                <table id="items_details">
                    <thead>
                        <tr>
                            <th>#</th>
                            <th>QTY</th>
                            <th>UNITS</th>
                            <th>MODEL</th>
                            <th>DESCRIPTION</th>
                            <th>UNIT PRICE</th>
                            <th>TOTAL</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(item, index ) in salesQuotationItems" :key="index">
                            <td v-if="!!item.new_estimate">{{ `(${index + 1})` }}</td>
                            <td v-else>{{ index + 1 }}</td>
                            <td v-if="!!item.additional">{{ `(${item.quantity})` }}</td>
                            <td v-else>{{ item.quantity }}</td>
                            <td>{{ item.item.item_uom_text }}</td>
                            <td>{{ item.item.model }}</td>
                            <td>{{ item.item.name }}</td>
                            <td v-if="item.total_amount>0">{{ item.display_srp | currency }}</td><td v-else>FOC</td>
                            <td v-if="item.total_amount>0">{{ computePrintOutItemTotal(item.srp, item.quantity) | currency }}</td><td v-else>FOC</td>
                        </tr>
                        <tr>
                            <td colspan="7">***** NOTHING FOLLOWS *****</td>
                        </tr>
                    </tbody>
                </table>
            </div>

            <div>
                <table id="items_details_without_price">
                    <thead>
                        <tr>
                            <th>#</th>
                            <th>QTY</th>
                            <th>UNITS</th>
                            <th>MODEL</th>
                            <th>DESCRIPTION</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(item, index ) in salesQuotationItems" :key="index">
                            <td v-if="!!item.new_estimate">{{ `(${index + 1})` }}</td>
                            <td v-else>{{ index + 1 }}</td>
                            <td v-if="!!item.additional">{{ `(${item.quantity})` }}</td>
                            <td v-else>{{ item.quantity }}</td>
                            <td>{{ item.item.item_uom_text }}</td>
                            <td>{{ item.item.model }}</td>
                            <td>{{ item.item.name }}</td>
                        </tr>
                        <tr>
                            <td colspan="5">***** NOTHING FOLLOWS *****</td>
                        </tr>
                    </tbody>
                </table>
            </div>

            <div>
                <table id="total_details">
                    <tbody>
                        <tr>
                            <td>
                                <p>{{ (this.itemTypeDiscountsPDF[0] != null)?this.itemTypeDiscountsPDF[0]: '' }}</p>
                            </td>
                            <td>
                                <p>GROSS SALES:</p>
                            </td>
                            <td>
                                <p>PHP</p>
                            </td>
                            <td>
                                <p>{{ sales_quotation.gross_amount | currency }}</p>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <p>{{ (this.itemTypeDiscountsPDF[1] != null)?this.itemTypeDiscountsPDF[1]: '' }}</p>
                            </td>
                            <td>
                                <p>Less - Discount:</p>
                            </td>
                            <td>
                                <p></p>
                            </td>
                            <td>
                                <p>{{ sales_quotation.discount_amount | currency }}</p>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <p>{{ (this.itemTypeDiscountsPDF[2] != null)?this.itemTypeDiscountsPDF[2]: '' }}</p>
                            </td>
                            <td>
                                <p>TOTAL SALES:</p>
                            </td>
                            <td>
                                <p>PHP</p>
                            </td>
                            <td>
                                <p>{{ sales_quotation.display_total_amount | currency }}</p>
                            </td>
                        </tr>
                    </tbody>
                </table>

            </div>
            <div>
                <table id="quotation_only">
                    <tbody>
                        <tr>
                            <td><span>Note: QUOTATION ONLY</span></td>
                            <td></td>
                            <td><span>No. of Items: {{ this.no_of_items }}</span></td>
                            <td></td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <!-- <div>
                <table id="num_of_items">
                    <tbody>
                        <tr>
                            <td><span>No. of Items: {{ this.no_of_items }}</span></td>
                            <td></td>
                            <td></td>
                            <td></td>
                        </tr>
                    </tbody>
                </table>
            </div> -->
            <div>
                <table id="footer">
                    <tbody>
                        <tr>
                            <td>{{ sales_quotation.issuer_name }}<br>___________</td>
                            <!-- <td><br>___________</td> -->
                        </tr>
                        <tr>
                            <td>Issued By</td>
                            <!-- <td>Checked By</td> -->
                        </tr>
                    </tbody>
                </table>
            </div>
        </v-container>

        <!-- Warranty Reject Reason Dialog -->
        <v-dialog v-model="showWarrantyReason" persistent max-width="50%">
            <v-card class="pa-4">
                <v-row class="m-0">
                    <v-col>
                        <v-card-title>
                            <span class="headline">Change Warranty Reason</span>
                        </v-card-title>
                    </v-col>
                    <v-col cols="pull-right-10 p-2">
                        <v-btn
                            text
                            icon
                            color="gray"
                            class="float-right"
                            @click="closeWarrantyReason()"
                        >
                            <v-icon>mdi-close-circle</v-icon>
                        </v-btn>
                    </v-col>
                </v-row>

                <v-divider />
                <!-- <v-card class="pa-4 mb-2"> -->

                <v-row class="m-0">
                    <v-col>
                        <div class="pb-4">
                           Choose Customer Satisfaction:
                            <v-btn
                                v-for="(emoji, index) in GET_EMOJIS"
                                :key="index"
                                @click="handleEmojiClick(emoji.id)"
                                :class="['emoji-button', { 'selected-emoji': isSelected(emoji.id), 'hovered-emoji': hoveredEmoji === emoji }]"
                                text
                                icon
                                color="white"
                                >
                                <span class="headline">{{ emoji.emoji }}</span>
                            </v-btn>
                        </div>
                    </v-col>
                </v-row>
                <!-- </v-card> -->
                    <v-textarea v-if="selectedEmoji == 1" v-model="emojiReason" dense outlined label="Reason for the emoji you choose"></v-textarea>
                    <v-textarea v-model="warrantyRejectReason" dense outlined label="Change Warranty Reason"></v-textarea>


                <v-divider />

                <div style="text-align:center">
                    <v-btn @click="rejectWarrantyType()">Submit</v-btn>
                </div>
            </v-card>
        </v-dialog>

        <!-- Diagnosis Reject Reason Dialog -->
        <v-dialog v-model="diagnosisReasonDialog" persistent max-width="40%">
            <v-card class="pa-4">
                <v-row class="m-0">
                    <v-col>
                        <v-card-title>
                            <span class="headline">Diagnosis Reject Reason</span>
                        </v-card-title>
                    </v-col>
                    <v-col cols="pull-right-10 p-2">
                        <v-btn
                            text
                            icon
                            color="gray"
                            class="float-right"
                            @click="closeDiagnosisRejectReasonDialog()"
                        >
                            <v-icon>mdi-close-circle</v-icon>
                        </v-btn>
                    </v-col>
                </v-row>

                <v-divider />

                <v-textarea v-model="diagnosisRejectReason" dense outlined></v-textarea>

                <v-divider />

                <div style="text-align:center">
                    <v-btn @click="disapprovedParts()">Submit</v-btn>
                </div>
            </v-card>
        </v-dialog>

        <!-- For Printing Details -->
        <v-container fluid style="display:none">
            <div id="div_return_sp_logo" >
                <div style="font-size: 12px; line-height: 1.9">
                    <p>
                        <span style="font-size: 18px; font-weight: bold;">
                            WADFOW Tools Philippines Corp.
                        </span>
                        <br>2 D Arellano St. Bagong Barrio, Caloocan City
                        <br>Cell No.:
                        <br>Tel. No.:
                        <br>Service Mobile / Viber No.: +63 917 625 2286
                        <br>Spare Parts Mobile / Viber No.:
                    </p>
                </div>
            </div>
            <div id="div_return_sp_num" style="font-size: 12px; line-height: 1.9">
                <div style="border-top: 1px dashed #CFCFCF; padding: 2px; padding-bottom: -4px; text-align: center; border-bottom: none;">&nbsp;</div>
                <p>
                    <span style="font-size: 18px; font-weight: bold;">Spare Parts Return Form</span>
                    <br>
                    <b>Rf#: {{ rfNumber }}</b>
                    <br>
                    <b>Issued: {{ spForReturnPrintIssued }}</b>
                </p>
            </div>

            <div>
                <table id="return_sp_item_details">
                    <tbody>
                        <tr>
                            <td>Return Qty</td>
                            <td>Item Model</td>
                            <td>Item Name</td>
                            <td>Category</td>
                            <td>Date/Signature</td>
                        </tr>
                        <tr v-for="sp in returnSpItems" :key="sp.id">
                            <td>{{ sp.return_qty }}</td>
                            <td>{{ sp.model }}</td>
                            <td>{{ sp.name }}</td>
                            <td>{{ sp.category }}</td>
                            <td></td>
                        </tr>
                        <tr>
                            <td colspan="10">Total: {{ returnSpItems.length }}</td>
                        </tr>
                    </tbody>
                </table>
                <table id="return_sp_process_details">
                    <tbody>
                        <tr>
                            <td colspan="4">Received by: </td>
                            <td colspan="4">Processed by: {{ processedReturnBy }}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </v-container>

        <ConfirmOverrideComponentVue
            :approve_override_dialog="override.override_dialog"
            :departments_allowed="override.override_departments_allowed"
            :heading="'For Replacement'"
            @closeConfirmOverride="closeConfirmOverride"
        ></ConfirmOverrideComponentVue>

        <PrintTechnicalReportPdfVue
            :printTechnicalReport="printTechnicalReport"
        >
        </PrintTechnicalReportPdfVue>

        <v-dialog v-model="view_previous_quotations" width="1000px">
            <v-card>
                <v-card-title>
                    <v-row>
                        <v-col lg="6">
                            <h4>Previous Quotations</h4>
                        </v-col>
                        <v-col lg="6" class="d-flex justify-content-end">
                            <v-btn
                            @click="view_previous_quotations = false"
                            text
                            icon
                            color="gray">
                                <v-icon >mdi-close-circle</v-icon>
                            </v-btn>
                        </v-col>
                    </v-row>

                </v-card-title>
                <v-card-text>
                    <v-row>
                        <v-col lg="12">
                            <ServiceSalesQuotationTableComponentVue :rfIds="[rfId]" >

                            </ServiceSalesQuotationTableComponentVue>
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card>
        </v-dialog>

  </v-dialog>
</template>

<script>
import _ from 'lodash';

import SparePartsListDialogComponentVue from "./SparePartsListDialogComponent.vue";
import ConversationDialogVue from "./Views/RepairFormViewComponent/ConversationDialog.vue";
import SearchByPartNumberDialogVue from "./Views/RepairFormViewComponent/SearchByPartNumberDialog.vue";
import BaseImageViewerVue from "./Base/BaseImageViewer.vue";
import BaseFilesViewerVue from "./Base/BaseFilesViewer.vue";
import FileUploadComponentVue from "../../Shared/FileUploadComponent.vue";
import SharedFunctionsComponentVue from "../../Shared/SharedFunctionsComponent.vue";
import ConfirmOverrideComponentVue from "../../Utils/ConfirmOverrideComponent.vue";
import PrintTechnicalReportPdfVue from './PrintTechnicalReportPdf.vue';
import { mapGetters } from 'vuex';
import swal from "sweetalert2";
import ServiceSalesQuotationTableComponentVue from './ServiceSalesQuotationTableComponent.vue';

export default {
    mixins: [SharedFunctionsComponentVue],
  data() {
    return {
        images: [],
        sample: '',
        customerInfoDialog: false,
        itemInfoDialog: false,
        rfStatus: "",
        rfId: "",
        rfDate: "",
        rrDate: "",
        rfNumber: "",
        rrNumber: "",
        pulloutNumber: "",
        companyName: "",
        companyContactNumber: "",
        customerName: "",
        address: "",
        contactNumber: "",
        email: "",
        itemModel: "",
        itemProductName: "",
        itemSerial: "",
        itemCategory: "",
        itemDefectDescription: "",
        itemDefectRemarks: "",
        itemAccessories: "",
        itemOtherAccessory: "",
        itemWarrantyType: "",
        itemWarrantyDocument: "",
        itemUnitCondition: "",
        itemOtherUnitCondition: "",
        itemStatus: "",
        itemPurchaseDate: "",
        deliveredBy: "",
        receivedBy: "",
        warehouse: "",
        assignedTechnician: "",
        rfDetails: null,
        rfEstimation: null,
        rfEstItems: [],
        rfEstItemHeaders: [
            { text: "Request Qty", value: "requested_qty", align: 'center', width: '10%' },
            { text: "Item Model", value: "model", align: 'center' },
            { text: "Item Name", value: "name", align: 'center' },
            { text: "Category", value: "category", align: 'center' },
            { text: "Warehouse", value: "warehouse_code", align: 'center' },
            { text: "SRP", value: "selling_price", align: 'center' },
            { text: "Disc. Price", value: "disc_price", align: 'center' },
            { text: "Total", value: "total", align: 'center' },
            { text: "FOC", value: "foc", align: 'center', width: '0' },
            { text: "Status", value: "status", align: 'center' },
            { text: "Action", value: "action", align: 'center' },
        ],
        releaseItemHeaders: [
            { text: "Available Qty", value: "requested_qty" },
            { text: "Item Model", value: "model" },
            { text: "Item Name", value: "name"},
            { text: "Category", value: "category" },
            { text: "Warehouse", value: "warehouse_code" },
            { text: "Price", value: "selling_price" },
            { text: "Status", value: "status" },
        ],
        returnSpItems: [],
        releaseItems: [],
        rfProcessedBy: "",
        rfEstItemsDate: "",
        itemRepairStatus: "",
        itemId: "",
        estimateItemTotalPrice: 0,
        loading: false,
        customerDissapprovedReason: '',
        totalAmount: 0,
        sparePartLists: [],
        completeDetails: false,
        selectedWarehouseValue: null,
        selectedItemId: '',
        selectedModelValue: null,
        readOnly: false,
        selectedItemForSearch: null,
        customerId: '',
        customerDetails: null,
        discountPrice: 0,
        customerDiscounts: [],
        customerDiscount: "",
        hasChanges: false,
        salesQuotationNumber: "",
        itemsFound: [],
        rfEstId: "",
        rfEstCancelledDate: '',
        rfItemId: '',
        rfCancelled: false,
        rfCompleted: false,
        rfCancelledDate: '',
        rfReEstimate: false,
        rfReEstimateCount: 0,
        rfApprovedCm: false,
        cmNumber: '',
        cmId: '',
        approvedCmBy: '',
        approvedCmDate: '',
        showAdminApproval: false,
        confirmApproval: false,
        adminPassword: '',
        showCmApproval: false,
        approveForReplacementOnFlight: false,
        confirmChangeWarrantyOnFlight: false,
        approveForCmOnFlight: false,
        confirmCmOnFlight: false,
        itemSymptomUponTested: "",
        itemJobDone: "",
        withConversations: false,
        changeWarrantyType: false,
        warrantyUploader: false,
        itemWarrantyTypeUpdater: '',
        rfItemRejectReason: '',
        uploadedFiles: null,
        printedSq: false,
        sqNumber: '',
        sqId: '',
        salesQuotationItems: [],
        sales_quotation: '',
        discounts: [],
        itemTypeDiscounts: [],
        itemTypeDiscountsPDF: [],
        print_no_price: false,
        printSqOnFlight: false,
        pdfAction: 'print',
        generateSqOnFlight: false,
        proceedOnFlight: false,
        purchaseDateDialog: false,
        purchaseDate: '',
        maxDate: this.$dayjs().format('YYYY-MM-DD'),
        itemSpPrice: '',
        customerSpecial: 0,
        showWarrantyReason: false,
        warrantyRejectReason: '',
        emojiReason: '',
        diagnosisReasonDialog: false,
        diagnosisRejectReason: '',
        rfItemDiagnosisRejectReason: '',
        override: {
            override_dialog: false,
            errors: [],
            override_departments_allowed: ['SR', 'EX', 'IT'],
            override_authenticated: false,
        },

        crateNumber: '',
        locationNumber: '',
        partsReturn: 0,
        customerApprovedOnFlght: false,

        selectedForReturn: [],
        processedReturnBy: '',
        spForReturnPrintIssued: this.$dayjs().format('YYYY-MM-DD, hh:mm:ss a'),
        cm_status: true,
        view_previous_quotations:false,
        access:[],
        selectedEmoji: null,
        hoveredEmoji: null,
        emoji: [],
        estimation:{},
        estimation_num: '',
        est_items: [],
        quotationItems: [],
        grandTotalAmount: 0,
        partsOrderId: null,
        buttonLoading: false,
        lastRfStatus: '',
    }
  },
  components: {
    SparePartsListDialogComponentVue,
    ConversationDialogVue,
    SearchByPartNumberDialogVue,
    BaseImageViewerVue,
    BaseFilesViewerVue,
    FileUploadComponentVue,
    ConfirmOverrideComponentVue,
    PrintTechnicalReportPdfVue,
    ServiceSalesQuotationTableComponentVue,

  },
//   async mounted() {
//     await this.getCurrentUser();
//     await this.getWarehouses();
//     await this.getEmojis();
//   },
  props: ["rf","warehousesCode"],
  watch: {
        rf() {
            if (!!this.rf) {
                this.rfId = this.rf.id;
                // this.getWarehouses();
                // this.getSparePartLists();
                this.rfConversationChecker(this.rf.id)
                this.rfDetails = this.rf;
                let rr = this.rfDetails.sc_receiving_report;
                this.rfNumber = this.rfDetails.rf_number;
                this.rrNumber = rr.rr_number;
                this.rfStatus = this.rfDetails.status;

                this.crateNumber = this.rfDetails.crate_number;
                this.locationNumber = this.rfDetails.location_number;

                this.customerId = rr.customer_id;
                this.customerDetails = rr.customer;
                this.rfReEstimate = this.rf.re_estimate;
                this.rfReEstimateCount = this.rf.re_estimate_count;

                this.partsReturn = this.rf.parts_return;

                this.getRfEstimation(this.rf.id);

                if(!!this.rf.cm_id) {
                    this.rfApprovedCm = true;
                } else {
                    this.rfApprovedCm = false;
                }

                this.cmNumber = this.rf.cm_number;

                if(!!this.rf.cm_id) {
                    this.cmNumber = this.rf.cm_number;
                }

                if(!!this.rf.approved_cm_by) {
                    this.approvedCmBy = this.rf.approved_cm_by;
                }

                if(!!this.rf.approved_cm_at) {
                    this.approvedCmDate = this.$dayjs(this.rf.approved_cm_at).format("MM-DD-YYYY, hh:mm:ss a");
                }

                if(!!this.rf.cancelled_at) {
                    this.rfCancelledDate = this.rf.cancelled_at;
                }

                if (!!rr.pullout_number) {
                this.pulloutNumber = rr.pullout_number;
                }
                this.companyName = rr.customer.company_name;
                this.rrDate = this.$dayjs(rr.created_at).format("MM-DD-YYYY, hh:mm:ss a");
                this.customerId = rr.customer_id;
                this.customerSpecial = rr.customer.special;
                this.customerDiscounts = JSON.parse(rr.customer.discounts);

                if (!!this.rfDetails.assigned_technician) {
                    this.assignedTechnician = this.rfDetails.assigned_technician;
                }

                let contact = [];
                let fullName = [];

                if (!!this.rfDetails.sc_repair_form_item) {
                    this.rfItemId = this.rfDetails.sc_repair_form_item.id;
                    this.itemId = this.rfDetails.sc_repair_form_item.item_id;
                    this.itemModel = this.rfDetails.sc_repair_form_item.model;
                    this.itemProductName =
                        this.rfDetails.sc_repair_form_item.product_name;
                    this.itemCategory = this.rfDetails.sc_repair_form_item.category;
                    this.itemDefectDescription =
                        this.rfDetails.sc_repair_form_item.defect_description;
                    this.itemDefectRemarks =
                        this.rfDetails.sc_repair_form_item.defect_remarks;
                    this.itemSerial = this.rfDetails.sc_repair_form_item.serial;
                    this.itemAccessories = this.rfDetails.sc_repair_form_item.accessories;
                    this.itemOtherAccessory =
                        this.rfDetails.sc_repair_form_item.other_accessory;
                    this.itemWarrantyType =
                        this.rfDetails.sc_repair_form_item.warranty_type;
                    this.itemWarranty = this.rfDetails.sc_repair_form_item.warranty;
                    this.itemWarrantyDocument =
                        this.rfDetails.sc_repair_form_item.warranty;
                    this.itemUnitCondition =
                        this.rfDetails.sc_repair_form_item.unit_condition;
                    this.itemOtherUnitCondition =
                        this.rfDetails.sc_repair_form_item.other_unit_condition;
                    this.itemStatus = this.rfDetails.sc_repair_form_item.status;
                    this.itemPurchaseDate = this.$dayjs(this.rfDetails.sc_repair_form_item.purchase_date).format('YYYY-MM-DD');
                    this.itemRepairStatus =
                        this.rfDetails.sc_repair_form_item.repair_status;
                    this.itemSymptomUponTested = this.rfDetails.sc_repair_form_item.symptom_upon_tested;
                    this.itemJobDone = this.rfDetails.sc_repair_form_item.job_done;
                    this.changeWarrantyType = !!this.rfDetails.sc_repair_form_item.change_warranty;
                    this.itemSpPrice = this.rfDetails.sc_repair_form_item.item.sp_price;
                    this.rfItemRejectReason = this.rfDetails.sc_repair_form_item.warranty_reject_reason;
                    this.rfItemDiagnosisRejectReason = this.rfDetails.sc_repair_form_item.diagnosis_reject_reason;
                }

                if (rr.customer_id == 275) {
                if(!!rr.sc_customer) {
                    if(!!rr.sc_customer.first_name) {
                        fullName.push(rr.sc_customer.first_name);
                    } if(!!rr.sc_customer.middle_name) {
                        fullName.push(rr.sc_customer.middle_name);
                    } if(!!rr.sc_customer.last_name) {
                        fullName.push(rr.sc_customer.last_name);
                    }

                    this.contactNumber = rr.sc_customer.contact_number;
                    this.address = rr.sc_customer.address;
                    this.email = rr.sc_customer.email;
                }
                this.customerName = fullName.toString().replaceAll(",", " ");

                } else {
                this.companyContactNumber = contact.toString().replaceAll(",", " ");
                this.address = JSON.parse(rr.customer.shipping_addresses)[0].address;
                if (!!rr.customer.mobile_1) {
                    contact.push(rr.customer.mobile_1);
                }
                if (!!rr.customer.mobile_2) {
                    contact.push(rr.customer.mobile_2);
                }
                if (!!rr.customer.mobile_3) {
                    contact.push(rr.customer.mobile_3);
                }
                if (!!rr.customer.tel_1) {
                    contact.push(rr.customer.tel_1);
                }
                if (!!rr.customer.tel_2) {
                    contact.push(rr.customer.tel_2);
                }
                if (!!rr.customer.tel_3) {
                    contact.push(rr.customer.tel_3);
                }
                // if (!!rr.customer.first_name) {
                //     fullName.push(rr.customer.first_name);
                // }
                // if (!!rr.customer.middle_name) {
                //     fullName.push(rr.customer.middle_name);
                // }
                // if (!!rr.customer.last_name) {
                //     fullName.push(rr.customer.last_name);
                // }
                // this.customerName = fullName.toString().replaceAll(",", " ");
                this.companyContactNumber = contact.toString().replaceAll(",", " / ");
                }

                if (!!this.rf.sc_parts_orders) {
                    this.partsOrderId = this.rf.sc_parts_orders[0]?.id;
                }
            }
        },
        rfEstimation() {
            if(!!this.rfEstimation) {
                this.rfEstId = this.rfEstimation.id;
                this.sqId = this.rfEstimation.sales_quotation_id;
                this.sqNumber = this.rfEstimation.sales_quotation_number;
                this.printedSq = !!this.rfEstimation.printed_sq;
                this.rfEstCancelledDate = this.rfEstimation.cancelled_at;
                this.quotationItems = this.rfEstimation.sales_quotation ? this.rfEstimation.sales_quotation.sales_quotation_items : [];
                this.grandTotalAmount = this.rfEstimation.sales_quotation?.total_amount
            }
        },
        async rfEstItems() {
            if (this.rfEstItems.length > 0) {
                // this.getAvailableSpareParts();
                await this.getDiscounts();
                await this.discountDetails();
            }
        },
        releaseItems() {
        //   if (this.releaseItems.length > 0) {
        //     let total = 0;
        //     this.releaseItems.forEach((item) => {
        //       total += parseInt(item.selling_price) * item.requested_qty;
        //     });
        //     this.estimateItemTotalPrice = total;
        //   }
        },
        applyFoc() {
            if (this.applyFoc) {
                this.showSelect = true;
            } else {
                this.showSelect = false;
            }
        },
        selectedWarehouseValue() {
            // if(!!this.selectedWarehouseValue) {
            //     let items = _.compact(this.rfEstItems);
            //     let item = items.find(x=>x.id == this.selectedItemId);
            //     console.log(this.item)
            //     let foc =  this.selectedWarehouseValue.item.foc;

            //     item.foc = foc;

            //     item.stock = this.selectedWarehouseValue.quantity;

            //     item.total = item.requested_qty * parseInt(this.selectedWarehouseValue.item.selling_price);

            //     let total = 0;
            //     if(foc) {
            //         item.total = 0;

            //         this.rfEstItems.forEach(x=>
            //             { total = total + parseInt(x.total) }
            //         )
            //     } else {
            //         item.total = item.requested_qty * parseInt(this.selectedWarehouseValue.item.selling_price);

            //         this.rfEstItems.forEach(x=>
            //             { total = total + parseInt(x.total) }
            //         )
            //     }
            //     this.estimateItemTotalPrice = total;
            // }
        },
        selectedModelValue() {
            if(!!this.selectedModelValue) {
                let items = _.compact(this.rfEstItems);
                let item = items.find(x=>x.id == this.selectedItemId);
                let foc = this.selectedModelValue.item.foc;
                item.foc = foc;

                let part_num = this.selectedModelValue.item.part_num;
                item.item.part_num = !!part_num ? part_num : "";

                item.stock = this.selectedModelValue.quantity;
                item.model = this.selectedModelValue.item.model;
                item.name = this.selectedModelValue.item.name;
                item.selling_price = this.selectedModelValue.item.selling_price;

                // this.getDiscounts();
                this.hasChanges = true;
            }
        },
        completeDetails() {
            // this.readOnly = this.completeDetails;
        },
        itemRepairStatus() {
            if(this.itemRepairStatus == 'For-Replacement') {
                if(this.itemWarrantyType == 'In-Warranty') {
                    this.readOnly = false;
                } else {
                    this.readOnly = true;
                }
            } else {
                this.completeDetails = false;
                this.readOnly = false;
            }
        },
        itemsFound() {
            // if(this.itemsFound.length >0) {
            //     this.rfEstItems.forEach();
            // }
        },
        itemWarrantyTypeUpdater() {
            if(this.itemWarrantyTypeUpdater == 'Dealer-Stock') {
                this.warrantyUploader = false;
                this.$refs.fileUpload.reset();
                this.itemWarrantyType = this.itemWarrantyTypeUpdater;
                this.rfEstItems.forEach(x=>{
                    x.foc = true;
                })
            } else if(this.itemWarrantyTypeUpdater == 'In-Warranty') {
                this.warrantyUploader = true;
                this.itemWarrantyType = this.itemWarrantyTypeUpdater;
                // this.rfEstItems.forEach(x=>{
                //     x.foc = false;
                // })
            } else if(this.itemWarrantyTypeUpdater == 'Out-Warranty') {
                this.warrantyUploader = false;
                this.$refs.fileUpload.reset();
                this.itemWarrantyType = this.itemWarrantyTypeUpdater;
                this.rfEstItems.forEach(x=>{
                    x.foc = false;
                })
            }
            // this.getDiscounts();
        },
        itemWarrantyType() {
            // if(this.itemWarrantyType == 'Dealer-Stock') {
            //     this.rfEstItems.forEach(x=>{
            //         x.foc = true;
            //     })
            // } else {
            //     this.rfEstItems.forEach(x=>{
            //         x.foc = false;
            //     })
            // }
            // this.getDiscounts();
        },
        returnSpItems() {
            if(this.returnSpItems.length > 0) {
                this.returnSpItems.forEach(x=>{
                    if(x.return_qty > 0) {
                        let returnCount = x.serve_qty - x.return_qty;

                        if(returnCount > 0) {
                            let newItem = x;
                            newItem.requested_qty = x.serve_qty - x.return_qty;;

                            this.rfEstItems.push(newItem);
                        }
                    }
                });
            }
        },
        'cm_status':{
            handler(val){
                if(val == 3){
                    this.cmId = false;
                }else{
                    this.cmId = true;
                }
            }
        },
        'USER_ACCESS':{
            handler(val){
                this.access = val.map(e=>e.actions_code)
            }
        }
  },
  async mounted() {
    await this.getCurrentUser();
    await this.getEmojis();
    this.$store.dispatch("checkAccessUser", this.$router.currentRoute.params.id);
  },
  computed: {
    ...mapGetters([
        'GET_SAME_PART_NUMBER',
        'GET_RF_ESTIMATION',
        'GET_SERVICE_GET_CURRENT_USER',
        'GET_SEARCH_REQUESTED_ITEM',
        'GET_SEARCH_REQUESTED_SPARE_PARTS',
        'GET_RF_PARTS_ORDER_FOR_RETURN',
        'GET_CHECK_RF_CONVERSATION',
        'GET_ALL_TECH_SP_ITEMS',
        'GET_SERVICE_WAREHOUSE_ITEM_STOCKS',
        'GET_SHOW_CUSTOMERS',
        'GET_VIEW_SALES_QUOTATION',
        'GET_ITEM_TYPES',
        'GET_CONFIRM_OVERRIDE_PRINT_PL',
        'USER_ACCESS',
        'GET_EMOJIS',
    ]),
    rfDialogWithEst() {
        return this.$store.state.service.rfDialogWithEst;
    },
    // Print SalesQuotation
    no_of_items:function(){
        if(this.salesQuotationItems != null && this.salesQuotationItems.length > 0){
            var count = 0;
            this.salesQuotationItems.forEach((item)=>{
                count += item.quantity
            })
            return this.thousandSeprator(count)
        }else{
            return 0
        }
    },
  },
  methods: {
        getEmojis(){
            this.$store.dispatch('getEmoji')
        },

        handleEmojiClick(selectedEmoji) {
            if (this.selectedEmoji === selectedEmoji) {
                this.selectedEmoji = null;
            } else {
                this.selectedEmoji = selectedEmoji;
            }

            this.emoji = this.GET_EMOJIS.find(e=>e.id == selectedEmoji).id
        },

        isSelected(emoji) {
            return this.selectedEmoji === emoji;
        },


        printTechnicalReport(){
            this.$store.commit('GET_PRINT_PDF_ID', this.rfId)
        },
        getLargeQtyParts(val){
            let payload = {
                partNumber: val,
            }
            this.$store.dispatch('getSamePartNumber',payload).then(response => {
                this.spLists = this.GET_SAME_PART_NUMBER;
            });
        },
        closeRfDetailsDialog() {
            this.$emit("closeRfDialogWithEst", true);
            this.$store.commit("closeRfDialogWithEst");
            this.rfEstItems = [];
            this.releaseItems = [];
            this.estimateItemTotalPrice = 0;
            this.assignedTechnician = "";
            this.rfEstItemsDate = "";
            this.rfNumber = "";
            this.rrNumber = "";
            this.rfDate = "";
            this.itemWarrantyType = "";
            this.itemRepairStatus = "";
            this.approveForCmOnFlight = false;
            this.approveForReplacementOnFlight = false;
            this.adminPassword = '';
            this.confirmCmOnFlight = false;
            this.partsReturn = 0;
            this.returnSpItems = [];
            this.customerApprovedOnFlght = false;
            this.warrantyRejectReason = '';
            this.selectedEmoji = '';
            this.emojiReason = '';
            this.quotationItems = [];
        },
        showCustomerInfoDialog() {
            this.customerInfoDialog = true;
        },
        showItemInfoDialog() {
            this.itemInfoDialog = true;
        },
        closeCustomerInfoDialog() {
            this.customerInfoDialog = false;
        },
        closeItemInfoDialog() {
            this.itemInfoDialog = false;
        },
        async getRfEstimation(id) {
            this.loading = true
            await this.$store.dispatch('getRfEstimation',id).then((response) => {
                this.estimation_num = this.GET_RF_ESTIMATION.id
                this.est_items = this.GET_RF_ESTIMATION.sc_rf_est_items.flatMap(item => item)
                this.cm_status = response.data.cm_status
                const isEmpty = Object.keys(this.GET_RF_ESTIMATION).length === 0;
                this.lastRfStatus = response.data.last_rf_status;
                // if(response.data.sc_rf_est_items.find(e=>e.id).item.auto_foc == 1 || this.itemWarrantyType == 'In-Warranty'){
                //     swal.fire('Info!','- This RF is indicated as AUTO FOC and by passes FTA','info')
                // }

                if (Array.isArray(response.data.sc_rf_est_items) && response.data.sc_rf_est_items.some(e => e.item.auto_foc === 1) && this.itemWarrantyType == 'In-Warranty') {
                    swal.fire('Info!', '- This RF is indicated as AUTO FOC and bypasses FTA', 'info');
                }

                if(!isEmpty) {
                    this.rfEstimation = this.GET_RF_ESTIMATION;

                    this.salesQuotationNumber = this.GET_RF_ESTIMATION.sales_quotation_number;
                    if(!!this.partsReturn) {
                        let estItems = [];
                        estItems = this.GET_RF_ESTIMATION.sc_rf_est_items;

                        if(estItems.length > 0) {
                            this.rfEstItems = estItems.filter(x=>{
                                return x.return == 0;
                            })

                            this.returnSpItems = estItems.filter(x=>{
                                return x.return == 1;
                            })
                        }

                        if(this.rfEstItems.length == 0) {
                            this.loading = false;
                        }

                        this.processedReturnBy = this.GET_RF_ESTIMATION.processedBy;
                    } else {

                        this.rfEstItems = this.GET_RF_ESTIMATION.sc_rf_est_items.map(e=>{
                            e.selling_price = e.item.selling_price
                            e.total = (e.requested_qty + e.serve_qty )* e.selling_price
                            return e
                        });
                    }

                    this.rfEstItemsDate = this.$dayjs(this.GET_RF_ESTIMATION.created_at).format("MM-DD-YYYY, hh:mm:ss a");
                }

                this.loading = false
            });
        },
        showSparePartsListDialog() {
            this.$store.commit("showSparePartsDialog");
        },
        getCurrentUser() {
            this.$store.dispatch('serviceGetCurrentUser').then((response) => {
                if (response) {
                    this.rfProcessedBy = this.GET_SERVICE_GET_CURRENT_USER.name;
                }
            });
        },
        checkWarrantyType() {
        if (this.itemWarrantyType == "Dealer-Stock") {
            return "background-color:green;border-radius:6px;padding:6px;color:white";
        }

        if (this.itemWarrantyType == "In-Warranty") {
            return "background-color:violet;border-radius:6px;padding:6px;color:white";
        }

        if (this.itemWarrantyType == "No-Warranty-Type") {
            return "background-color:red;border-radius:6px;padding:6px;color:white";
        }

        if (this.itemWarrantyType == "Out-Warranty") {
            return "background-color:orange;border-radius:6px;padding:6px;color:white";
        }
        return "";
        },
        getAvailableSpareParts() {
            if(this.itemRepairStatus == 'For-Replacement') {
                let payload = {
                    rfId: this.rfId,
                    orderItems: _.compact(this.rfEstItems),
                }
                this.$store.dispatch('searchRequestedItem',payload).then(response => {
                    this.itemsFound = this.GET_SEARCH_REQUESTED_ITEM;
                })
            } else {
                let payload = {
                    rfId: this.rfId,
                    orderItems: _.compact(this.rfEstItems),
                }
                this.$store.dispatch('searchRequestedSpareParts',payload).then(response => {
                    this.itemsFound = this.GET_SEARCH_REQUESTED_SPARE_PARTS;
                })
            }
        },
        refreshAutoSearch() {
            this.rfEstItems = [];
            this.releaseItems = [];
            this.estimateItemTotalPrice = 0;
        },
        estimateItemPrice(item) {
        return item.selling_price * item.requested_qty;
        },
        updateTotalEstimateAmount(item) {
        },
        forFoc(item) {
            this.getDiscounts();
            this.hasChanges = true;
        },
        customerDisapproved() {
            swal.fire({
                title: 'Customer Dissapproved?',
                icon: 'question',
                showConfirmButton:true,
                showCancelButton  :  true,
                reverseButtons:true,
                allowOutsideClick :  false,
                confirmButtonColor: '#397373',
                cancelButtonColor : 'grey',
                confirmButtonText:'Confirm',
                showCloseButton:true,
            }).then(async (result) => {
                if(result.isConfirmed) {

                    await this.$store.dispatch('getRfPartsOrderForReturn',this.rfId).then(response=>{
                        this.returnSpItems = this.GET_RF_PARTS_ORDER_FOR_RETURN.pois;

                        if(this.returnSpItems.length > 0) {
                            this.returnSpItems.forEach(x=>x.return_qty = x.serve_qty);
                        }

                        this.processedReturnBy = this.GET_RF_PARTS_ORDER_FOR_RETURN.processed_by;
                    });

                    if(this.returnSpItems.length > 0) {
                        this.printForReturnSp();
                    }
                    let payload = {
                        rfId: this.rf.id,
                        rfItemId: this.rfItemId,
                        reason: this.customerDissapprovedReason,
                    }

                    await this.$store.dispatch('rfEstimateDisapproved',payload).then(response => {
                        this.closeRfDetailsDialog()
                        swal.fire("", response.data.msg, "success");
                    });
                }
            });
        },
        updateToPin(){
            swal.fire({
                title: 'Customer Dissapproved?',
                icon: 'question',
                showConfirmButton:true,
                showCancelButton  :  true,
                reverseButtons:true,
                allowOutsideClick :  false,
                confirmButtonColor: '#397373',
                cancelButtonColor : 'grey',
                confirmButtonText:'Confirm',
                showCloseButton:true,
            }).then(async (result) => {
                if(result.isConfirmed) {
                    this.loading = true
                    this.$store.dispatch('serviceGet',{
                        url:'update-status/'+this.rfId
                    }).then(response => {
                        let res = response.data;
                        swal.fire(res.message,'', res.icon);
                        this.closeRfDetailsDialog()

                        this.loading = false
                    });
                }
            });

        },
        customerApproved() {
            this.customerApprovedOnFlght = true;
            if(!this.withConversations) {
                swal.fire('','Please Input Conversations','warning').then(confirm=>{
                    this.customerApprovedOnFlght = false;
                });
            } else {
                if(!!this.partsReturn) {
                    if(this.warehousesCode.length > 0) {
                        let desti_warehouse = this.warehousesCode.filter(x=>x.code == 'DM')[0];
                        let source_warehouse = this.warehousesCode.filter(x=>x.code == 'SC-DMS')[0];

                        swal.fire({
                            title: 'Customer Approved?',
                            icon: 'question',
                            showConfirmButton:true,
                            showCancelButton  :  true,
                            reverseButtons:true,
                            allowOutsideClick :  false,
                            confirmButtonColor: '#397373',
                            cancelButtonColor : 'grey',
                            confirmButtonText:'Confirm',
                            showCloseButton:true,
                        }).then(result=>{
                            if(result.isConfirmed) {
                                this.customerApprovedOnFlght = false;

                                let payload ={
                                    rfId: this.rf.id,
                                }

                                this.$store.dispatch('transferPartsReturn',payload).then(async resp=> {
                                    if(resp.data.error) {
                                        swal.fire('',resp.data.error,'warning').then(conf=>{
                                            this.customerApprovedOnFlght = false;
                                        });
                                    } else {
                                        let payload_create = {
                                            warehouse_id: desti_warehouse.id,
                                            initial_serving_warehouse_id: source_warehouse.id,
                                            remarks: `For Service And Repair, RF#: ${this.rf.rf_number}`,
                                            service: 1,
                                            rfId: this.rf.id,
                                            parts_module: true,
                                            parts_return: 1,
                                        }

                                        await this.$store.dispatch('requestCreate',payload_create).then(response=>{
                                            swal.fire("", `Stock Request ${response.data.stock_request_form_number}, Stock Transfer ${response.data.stock_transfer_form_number} Create Successfully!`, "success").then(confirm=>{
                                                this.closeRfDetailsDialog();
                                                this.customerApprovedOnFlght = false;

                                                this.printForReturnSp();
                                            });
                                        });
                                    }

                                });
                            } else {
                                this.customerApprovedOnFlght = false;
                            }
                        });

                    } else {
                        swal.fire('','No Warehouses','error').then(confirm=>{
                            this.customerApprovedOnFlght = false;
                        });
                    }
                } else if (this.rf.for_replacement == 1){
                   swal.fire({
                    title:'Confirm!',
                    text:'- Are you sure you want to update?',
                    icon: 'question',
                    reverseButtons:true,
                    showCancelButton:true
                   }).then(action=>{
                        if(action.isConfirmed){
                            this.$store.dispatch('servicePost',{
                            url:'approve-replacement',
                            rf_id:this.rf.id,
                        }).then(response=>{
                            swal.fire('For Replacement Approved','','success')
                        }).catch(err=>{
                            console.log(err.response.data.message)
                            swal.fire('Error!','','error')
                        })
                    }
                   })
                } else {
                    swal.fire({
                        title: 'Customer Approved?',
                        icon: 'question',
                        showConfirmButton:true,
                        showCancelButton  :  true,
                        reverseButtons:true,
                        allowOutsideClick :  false,
                        confirmButtonColor: '#397373',
                        cancelButtonColor : 'grey',
                        confirmButtonText:'Confirm',
                        showCloseButton:true,
                    }).then((result) => {
                        if (result.isConfirmed) {
                            const price = parseInt(this.rfDetails.sc_repair_form_item.item.selling_price); //placeholder
                            const repairCost = parseFloat(this.grandTotalAmount);
                            const threshold = price * 0.4;
                            const costComparison = repairCost < threshold ? 'below' : 'above';
                            let items = this.GET_RF_ESTIMATION.sc_rf_est_items
                            swal.fire({
                                title: 'Action Confirmation',
                                html: `You are about to approve this request.<br>Item Price: ₱${price}<br>Repair Cost: ₱${repairCost.toFixed(2)}<br><br>The repair cost is ${costComparison} 40% of the Item's total price.`,
                                icon: 'info',
                                showConfirmButton: true,
                                showCancelButton: true,
                                reverseButtons: true,
                                allowOutsideClick: false,
                                confirmButtonColor: '#397373',
                                cancelButtonColor: 'grey',
                                confirmButtonText: 'Yes, approve',
                                cancelButtonText: 'Cancel',
                            }).then((innerResult)=> {
                                if(innerResult.isConfirmed) {
                                    let payload = {
                                        rfId: this.rfId,
                                        spItems: items,
                                        // _.compact(this.rfEstItems),
                                        reason: this.customerDissapprovedReason,
                                        totalAmount: this.estimateItemTotalPrice,
                                        itemWarrantyType: this.itemWarrantyType,
                                        itemRepairStatus: this.itemRepairStatus,
                                        itemId: this.itemId,
                                    }
                                    this.$store.dispatch('rfEstimateApproved',payload).then(response => {
                                        this.closeRfDetailsDialog();
                                        swal.fire("", response.data.msg, "success").then(confirm=>{
                                            this.customerApprovedOnFlght = false;
                                        });
                                    });
                                } else {
                                    this.customerApprovedOnFlght = false;
                                }
                            });
                        }})
                }
                this.closeRfDetailsDialog();
                this.customerApprovedOnFlght = false;
            }
        },
        rfConversationChecker(rfId) {
            this.$store.dispatch('checkRfConversation',this.rf.id).then(response=>{
                this.withConversations = this.GET_CHECK_RF_CONVERSATION;
            })
        },
        deleteEstPart(item) {
        },
        showEstHistoryDialog() {
            this.$refs.conversation.getAllRfEstConversation()
            this.$store.commit("showConversationDialog");
        },
        getSparePartLists() {
            this.$store.dispatch('getAllTechSpItems').then(response => {
                this.sparePartLists = this.GET_ALL_TECH_SP_ITEMS;
                this.sparePartLists.forEach(x=>x.model = `${x.model} / ${x.part_num}`);
            });
        },
        selectedModel(item) {
            let payload = {
                warehouseId: item.warehouse_id,
                itemId:item.item_id,
            }
            this.$store.dispatch('serviceGetWarehouseItemStocks',payload).then(response=>{
                this.selectedModelValue = this.GET_SERVICE_WAREHOUSE_ITEM_STOCKS;
                this.selectedItemId = item.id;
            });
        },
        selectedWarehouse(item) {
            let payload = {
                warehouseId: item.warehouse_id,
                itemId:item.item_id,
            }
            this.$store.dispatch('serviceGetWarehouseItemStocks',payload).then(response=>{
                this.selectedWarehouseValue = this.GET_SERVICE_WAREHOUSE_ITEM_STOCKS;
                this.selectedItemId = item.id;
            });
        },
        changeItemQty(item) {
            let items = _.compact(this.rfEstItems);
            let origItem = items.find(x=>x.id == item.id);
            origItem.foc = false;

            origItem.total = item.requested_qty * parseInt(item.selling_price);

            let total = 0;
            this.rfEstItems.forEach(x=>
                { total = total + parseInt(x.total) }
            )
            this.estimateItemTotalPrice = total;
        },
        updateEstimation() {
            swal.fire({
                title: 'Are you sure you want to save?',
                text: 'Make sure that all information are correct',
                icon: 'question',
                showConfirmButton:true,
                showCancelButton  :  true,
                reverseButtons:true,
                allowOutsideClick :  false,
                confirmButtonColor: '#397373',
                cancelButtonColor : 'grey',
                confirmButtonText:'Confirm',
                showCloseButton:true,
            }).then(result=> {
                if(result.isConfirmed) {
                    this.updateRfEstItems();
                }
            });
        },
        updateRfEstItems() {
            this.loading = true
            let payload = {
                rfId: this.rfId,
                itemsForUpdate: this.rfEstItems,
                rfEstimation: this.rfEstimation,
                estimateTotalPrice: this.estimateItemTotalPrice,
                itemWarrantyType: this.itemWarrantyType,
                changeWarrantyType: this.changeWarrantyType
            }
            this.$store.dispatch('updateRfEstimationItems',payload).then(response => {
                if(response.data.error) {
                    swal.fire("", response.data.error, "error");
                } else {
                    // swal.fire("", response.data.msg, "success");
                    this.getRfEstimation(this.rf.id);
                    // this.closeRfDetailsDialog();
                }
                this.loading = false
            });
        },
        approveEstimation() {
            let payload = {
                rfId: this.rf.id,
            }
            this.$store.dispatch('approvedEstimation',payload).then(response=> {
                swal.fire("", response.data.msg, "success");
                this.closeRfDetailsDialog();
            });
        },
        refreshTableList(id) {
            this.rfEstItems = []
            this.getRfEstimation(id);
            this.hasChanges = false;
        },
        searchByPartNum(item) {
            this.selectedItemId = item.item_id;
            this.selectedItemForSearch = item;
            this.$store.commit('showSearchByPartNumDialog');
        },
        searchResult(result) {
            let items = _.compact(this.rfEstItems)

            let item = items.find(x=>x.item_id == this.selectedItemId);

            let foc = result.item.foc;

            if(this.itemWarrantyType == "Dealer-Stock") {
                item.foc = 1;
            } else {
                item.foc = foc;
            }

            let part_num = result.item.part_num;
            item.item.part_num = !!part_num ? part_num : "";

            item.stock = result.quantity;
            item.model = result.item.model;
            item.name = result.item.name;
            item.selling_price = result.item.selling_price;
            item.warehouse_code = result.warehouse_code;
            item.warehouse_id = result.warehouse_id;
            item.item_id = result.item_id;

            // this.getDiscounts();
            this.hasChanges = true;
        },
        saveQuotation() {
        },
        closeSearchByPartNumDialog(confirm) {
            if(confirm) {
                this.selectedItemForSearch = null;
            }
        },
        getCustomer() {
            this.$store.dispatch('showCustomers',this.customerId).then(response=>{
                this.customerDetails = this.GET_SHOW_CUSTOMERS;
            });
        },
        getDiscounts() {
            let total = 0;
            let discountedPrice = 0;
            let ingcoDiscounts = [];

            _.compact(this.rfEstItems).forEach((estitem) => {
                if(estitem.foc == 0){
                    estitem.selling_price = estitem.item.selling_price
                }
                if(this.customerDiscounts.length > 0) {
                    _.compact(this.customerDiscounts).forEach(cd=>{
                        if(cd.brand_name == "INGCO") {
                            ingcoDiscounts = cd.discounts;
                        }
                    });
                    if(ingcoDiscounts.length > 0) {
                        _.compact(ingcoDiscounts).forEach((ingcoDiscount, index)=>{
                            if(estitem.item.type_id == 1) {
                                if(ingcoDiscount.pt == 0 || ingcoDiscount.pt == null) {
                                    return;
                                }

                                if(!!this.customerSpecial) {
                                    discountedPrice = 0;
                                } else {
                                    if(index == 0) {
                                        discountedPrice = (1 - (parseInt(ingcoDiscount.pt) / 100)) * (parseInt(estitem.selling_price));
                                    } else {
                                        discountedPrice = (1 - (parseInt(ingcoDiscount.pt) / 100)) * discountedPrice;
                                    }
                                }

                            } else if(estitem.item.type_id == 2) {
                                if(ingcoDiscount.ht == 0 || ingcoDiscount.ht == null) {
                                    return;
                                }

                                if(index == 0) {
                                    discountedPrice = (1 - (parseInt(ingcoDiscount.ht) / 100)) * (parseInt(estitem.selling_price));
                                } else {
                                    discountedPrice = (1 - (parseInt(ingcoDiscount.ht) / 100)) * discountedPrice;
                                }
                            } else if(estitem.item.type_id == 3) {
                                if(ingcoDiscount.sp == 0 || ingcoDiscount.sp == null) {
                                    return;
                                }
                                // discountedPrice = (parseInt(estitem.selling_price) * estitem.requested_qty) * (parseInt(ingcoDiscount.sp) / 100);

                                if(index == 0) {
                                    discountedPrice = (1 - (parseInt(ingcoDiscount.sp) / 100)) * (parseInt(estitem.selling_price));
                                } else {
                                    discountedPrice = (1 - (parseInt(ingcoDiscount.sp) / 100)) * discountedPrice;
                                }
                            }
                        });
                    }

                    _.merge(estitem, {
                        discounted_price: discountedPrice
                    })

                    let estItemTotal = 0;
                    if(estitem.foc) {
                        estitem.total = 0;
                        estitem.discounted_price = 0;
                        estitem.selling_price = 0;
                    } else if(estitem.discounted_price > 0) {
                        estItemTotal = estitem.total = estitem.discounted_price * (estitem.requested_qty == 0 ? estitem.serve_qty : estitem.requested_qty);
                        total += estItemTotal;
                    } else {
                        estItemTotal = estitem.selling_price * (estitem.requested_qty == 0 ? estitem.serve_qty : estitem.requested_qty)
                        total += estItemTotal;
                        estitem.total = estitem.selling_price * (estitem.requested_qty == 0 ? estitem.serve_qty : estitem.requested_qty);
                    }
                }
            });
            this.estimateItemTotalPrice = total;
        },
        getPercent(percent, num) {
            return (percent / 100) * num;
        },
        checkDisc(item) {
            // console.log(item)
        },
        async convertToSq() {
            this.generateSqOnFlight = true;

            let orderList = [];

            _.compact(this.rfEstItems).forEach(estitem=> {
                let quantity = estitem.requested_qty + estitem.serve_qty
                orderList.push({
                    quantity: quantity,
                    item_id: estitem.item_id,
                    foc: estitem.foc,
                    disc_price: estitem.discounted_price,
                    total_amount: estitem.total,
                    amount: estitem.total / estitem.requested_qty,
                    type: estitem.item.item_type_text,
                    item: {
                        model: estitem.item.model,
                        name: estitem.item.name,
                        type_id: estitem.item.type_id,
                        item_uom_text: estitem.item.item_uom_text,
                        selling_price: estitem.selling_price,
                        srp: estitem.selling_price,
                        display_srp: estitem.selling_price,
                        moq: estitem.item.name,
                        name: estitem.item.name,
                    },
                    is_editable: true,
                    limit: 212,
                    display_disc_price: estitem.discounted_price,
                    display_total_amount: estitem.discounted_price,
                    orig_selling_price: estitem.selling_price,
                    sc_rf_est_item_id: estitem.id,
                    additional: estitem.additional,
                    additional_qty: estitem.additional_qty,
                    new_estimate: estitem.new_estimate,
                    original_item_id:0
                })
            })

            swal.fire({
                title: 'Are you sure you want to Generate SQ?',
                text: 'Make sure that all information are correct',
                icon: 'question',
                showConfirmButton:true,
                showCancelButton  :  true,
                reverseButtons:true,
                allowOutsideClick :  false,
                confirmButtonColor: '#397373',
                cancelButtonColor : 'grey',
                confirmButtonText:'Confirm',
                showCloseButton:true,
            }).then(async result=> {
                if(result.isConfirmed) {
                    let remarks = `RF# ${this.rf.rf_number} | ${this.itemModel} | ${this.itemSerial}`
                    if (this.customerId === 275) {
                        remarks = remarks + ` | ${this.customerName}`
                    }
                    let sq = null;
                    let payload = {
                        customer_id: this.customerDetails.id,
                        salesman_id: 5,
                        orderList: orderList,
                        shipping_address_index: 0,
                        shipping_address_text: this.customerDetails.all_shipping_addresses[0].text,
                        discounts: JSON.parse(this.customerDetails.discounts),
                        terms: this.customerDetails.terms,
                        stock_card: 0,
                        remarks: remarks,
                        rfId: this.rfId,
                        rfEstimationId: this.rfEstimation.id,
                    }

                    await this.$store.dispatch('storeSalesQuotations',payload).then(async response => {
                        sq = response.data[0]
                        if(!!response.data.error) {
                            swal.fire("Error!", response.data.error, "error");
                        } else {
                            swal.fire("Success!", '', "success");
                            await this.updateRfEstItems();
                        }
                    })

                    this.getRfEstimation(this.rf.id);

                    await this.$store.dispatch('estConvertToSoSuccess',this.rf.id).then(response=>{
                        this.estimation = response.data.estimation
                        if (Array.isArray(this.GET_RF_ESTIMATION.sc_rf_est_items) && this.GET_RF_ESTIMATION.sc_rf_est_items.some(e => e.item.auto_foc === 1) && this.itemWarrantyType == 'In-Warranty') {
                            swal.fire('Info!', '- This RF is indicated as AUTO FOC and bypasses FTA', 'info');
                        }
                    });

                    this.printSqOnFlight = true;
                    this.closeRfDetailsDialog()
                }
            });
        },
        proceed() {
            this.proceedOnFlight = true;
            swal.fire({
                title: 'Are you sure you want to Proceed?',
                text: 'Make sure that all information are correct',
                icon: 'question',
                showConfirmButton:true,
                showCancelButton  :  true,
                reverseButtons:true,
                allowOutsideClick :  false,
                confirmButtonColor: '#397373',
                cancelButtonColor : 'grey',
                confirmButtonText:'Confirm',
                showCloseButton:true,
            }).then(async result=> {
                if(result.isConfirmed) {

                    this.$store.dispatch('estConvertToSoSuccess',this.rf.id).then(confirm=>{
                        this.closeRfDetailsDialog()
                        swal.fire('','Proceed Successfully!','success').then(confirm=>{
                            this.proceedOnFlight = false;
                        });
                    });
                }
                this.proceedOnFlight = false;
            });
        },
        discountDetails() {
            if(_.compact(this.customerDiscounts).length > 0) {
                let cDiscDetails = "";
                let cDiscount = [];
                let estItem = this.rfEstItems[0];
                let discounts = _.compact(this.customerDiscounts)[0].discounts;

                discounts.forEach(d=>{
                    if(estItem.item.type_id == 1) {
                        if(d.pt == 0) {
                            return;
                        }
                        cDiscount.push(d.pt)
                    } else {
                        if(d.sp == 0) {
                            return;
                        }
                        cDiscount.push(d.sp)
                    }
                });

                if(estItem.item.type_id == 1) {
                    cDiscDetails = `PT: ${cDiscount.toString().replaceAll(',', '/').toString()}`;
                } else {
                    cDiscDetails = `SP: ${cDiscount.toString().replaceAll(',', '/').toString()}`;
                }
                this.customerDiscount = cDiscDetails;
            }
        },
        confirmCancelledQuotation() {
            swal.fire({
                title: 'Are you sure you want to confirm?',
                text: 'This Repair Form will move to RFD',
                icon: 'question',
                showConfirmButton:true,
                showCancelButton  :  true,
                reverseButtons:true,
                allowOutsideClick :  false,
                confirmButtonColor: '#397373',
                cancelButtonColor : 'grey',
                confirmButtonText:'Confirm',
                showCloseButton:true,
            }).then(result=>{
                if(result.isConfirmed) {
                    let payload = {
                        rfId: this.rf.id,
                    }
                    this.$store.dispatch('confirmCancelledQuotation',payload).then(response=>{
                        if(response.data.msg) {
                            this.closeRfDetailsDialog();
                            swal.fire("", response.data.msg, "success");
                        }
                    });
                }
            });
        },
        confirmApprovedCm() {
            // this.confirmCmOnFlight = true;

            swal.fire({
                title: 'Are you sure you want to confirm',
                text: 'Click ok to proceed',
                icon: 'warning',
                showConfirmButton:true,
                showCancelButton  :  true,
                reverseButtons:true,
                allowOutsideClick :  false,
                confirmButtonColor: '#397373',
                cancelButtonColor : 'grey',
                confirmButtonText:'Confirm',
                showCloseButton:true,
            }).then(result=>{
                if(result.isConfirmed) {

                    let payload = {
                        rfId: this.rf.id
                    }
                    this.$store.dispatch('transferItemForCm',payload).then(resp=>{
                        if(resp.data.error) {
                            swal.fire("", `${response.data.error}`, "warning").then(confirm=>{
                                this.confirmCmOnFlight = false;
                            });
                        } else {
                            if(this.warehousesCode.length > 0) {
                                let desti_warehouse = this.warehousesCode.filter(x=>x.code == 'DM-B')[0];
                                let source_warehouse = this.warehousesCode.filter(x=>x.code == 'SC-DMS')[0];
                                let payload = {
                                    warehouse_id: desti_warehouse.id,
                                    initial_serving_warehouse_id: source_warehouse.id,
                                    remarks: `For Service And Repair, RF#: ${this.rf.rf_number}, Defective Item (CM) Serial# ${this.itemSerial} `,
                                    service: 1,
                                    rfId: this.rf.id,
                                    delivery_module: true,
                                    baklas: 1,
                                    allow_butal:1
                                }
                                this.$store.dispatch('requestCreate',payload).then(response=>{
                                    swal.fire("", `Stock Request ${response.data.stock_request_form_number},
                                        Stock Transfer ${response.data.stock_transfer_form_number} Create Successfully!`, "success").then(confirm=>{
                                        this.closeRfDetailsDialog();
                                        this.confirmCmOnFlight = false;
                                    });
                                });
                            } else {
                                swal.fire('','No Warehouses','error').then(confirm=>{
                                    this.confirmCmOnFlight = false;
                                });
                            }
                        }
                    });
                } else {
                    this.confirmCmOnFlight = false;
                }

            });
        },
        showRefreshButton() {
            if(this.rfStatus == 'FTA' || this.rfStatus == 'WCM') {
                return false;
            }

            return true;
        },
        cmApproval() {
            this.showCmApproval = true;
        },
        closeCmApprovalDialog() {
            this.showCmApproval = false;
            this.adminPassword = '';
        },
        confirmedCmApproval() {
            this.approveForCmOnFlight = true;

            if(!!this.adminPassword) {
                let payload = {
                    departments_allowed: ['SR', 'IT', 'EX'],
                    override_user_password: this.adminPassword
                }
                this.$store.dispatch('confirmOverridePrintPL',payload).then((response)=> {
                    if(this.GET_CONFIRM_OVERRIDE_PRINT_PL.error) {
                        swal.fire("", this.GET_CONFIRM_OVERRIDE_PRINT_PL.error, "warning").then(()=>{
                            this.adminPassword = '';
                        })
                        return false;
                    }

                    if(!!this.GET_CONFIRM_OVERRIDE_PRINT_PL.user_id) {
                        this.showCmApproval = false;
                        swal.fire({
                            title: 'Are you sure you want to Approve for CM?',
                            text: 'Click ok to proceed',
                            icon: 'question',
                            showConfirmButton:true,
                            showCancelButton  :  true,
                            reverseButtons:true,
                            allowOutsideClick :  false,
                            confirmButtonColor: '#397373',
                            cancelButtonColor : 'grey',
                            confirmButtonText:'Confirm',
                            showCloseButton:true,
                        }).then(result=> {
                            if(result.isConfirmed) {
                                let payload = {
                                    rfId: this.rf.id,
                                    userId: this.GET_CONFIRM_OVERRIDE_PRINT_PL.user_id
                                }
                                this.$store.dispatch('approvedForCm',payload).then(response=>{
                                    if(response.data.msg) {
                                        this.$emit('successConfirm', true);
                                        this.closeRfDetailsDialog();
                                        this.approveForCmOnFlight = false;
                                        swal.fire("", response.data.msg, "success")
                                    }else{
                                        // this.$emit('successConfirm', true);
                                        // this.closeRfDetailsDialog();
                                        this.approveForCmOnFlight = false;
                                        swal.fire("", response.data.error, "error")
                                    }
                                });
                            } else {
                                this.approveForCmOnFlight = false;
                            }
                        });

                        this.adminPassword = '';
                    }
                });
            }
        },
        disapprovedParts() {
            if(!!this.diagnosisRejectReason) {
                swal.fire({
                    title: 'Are you sure you want to Reject Diagnosis?',
                    text: 'This repair form return to technician. Click ok to proceed',
                    icon: 'question',
                    showConfirmButton:true,
                    reverseButtons:true,
                    allowOutsideClick :  false,
                    confirmButtonColor: '#397373',
                    cancelButtonColor : 'grey',
                    showCancelButton:  true,
                    // confirmButtonText:'Confirm',
                    showCloseButton:true,
                }).then(result=> {
                    if(result.isConfirmed) {
                        let payload = {
                            rfId: this.rfId,
                            diagnosisRejectReason: this.diagnosisRejectReason,
                            last_rf_status: this.lastRfStatus
                        }
                        this.$store.dispatch('disapprovedParts',payload).then(response=> {
                            if(response.data.error) {
                                swal.fire('', response.data.error, 'error');
                            } else {
                                this.$emit('successConfirm', true);
                                this.closeRfDetailsDialog();
                                swal.fire("", response.data.msg, "success");
                            }
                        });
                    }
                });
            } else {
                swal.fire("", 'Please input diagnosis reason', "warning");
            }
        },
        forReplacementApproval() {
            this.approveForReplacementOnFlight = true;

            swal.fire({
                title: 'Are you sure you want to Approve For Replacement?',
                text: 'Click ok to proceed',
                icon: 'question',
                showConfirmButton:true,
                showCancelButton  :  true,
                reverseButtons:true,
                allowOutsideClick :  false,
                confirmButtonColor: '#397373',
                cancelButtonColor : 'grey',
                confirmButtonText:'Confirm',
                showCloseButton:true,
            }).then(result=> {
                if(result.isConfirmed) {
                    if(_.compact(this.warehousesCode).length > 0) {
                        let desti_warehouse = this.warehousesCode.filter(x=>x.code == 'SC-DMS')[0];
                        let source_warehouse = this.warehousesCode.filter(x=>x.code == 'DM')[0];
                        let payload = {
                            rfId: this.rfId
                        }

                        this.$store.dispatch('approvedForReplacement',payload).then(resp=>{
                            if(resp.data.error) {
                                swal.fire('', resp.data.error, 'error').then(conf=>{
                                    this.approveForReplacementOnFlight = false;
                                });
                            } else {
                                this.$emit('successConfirm', true);
                                swal.fire("", `Stock Request Create Successfully!`, "success").then(confirm=>{
                                    this.closeRfDetailsDialog();
                                    this.approveForReplacementOnFlight = false;
                                });
                            }
                        });
                    } else {
                        swal.fire('','Please Check Warehouses','warning').then(confirm=>{
                            this.approveForReplacementOnFlight = false;
                        });
                    }

                } else {
                    this.approveForReplacementOnFlight = false;
                }
            });
        },
        forFocApproval() {
            swal.fire({
                title: 'Are you sure you want to Approve FOC items?',
                text: 'Click ok to proceed',
                icon: 'question',
                showConfirmButton:true,
                showCancelButton  :  true,
                reverseButtons:true,
                allowOutsideClick :  false,
                confirmButtonColor: '#397373',
                cancelButtonColor : 'grey',
                confirmButtonText:'Confirm',
                showCloseButton:true,
            }).then(result=> {
                if(result.isConfirmed) {
                    let payload = {
                        rfId: this.rfId
                    }
                    this.$store.dispatch('serviceApprovedFoc',payload).then(response=>{
                        if(response.data.error) {
                            swal.fire('', response.data.error, 'error');
                        } else {
                            this.$emit('successConfirm', true);
                            this.closeRfDetailsDialog();
                            swal.fire("", response.data.msg, "success");
                        }
                    });
                } else {
                    this.approveForReplacementOnFlight = false;
                }
            });
        },
        checkNewEstimate(item) {
            // if(!!item) {
            //     // if(item.status == 'approved') {
            //     //     if(!!item.additional || !!item.new_estimate || this.changeWarrantyType) {
            //     //         return false;
            //     //     }

            //     //     return true;
            //     // }

            //     if(this.itemWarrantyType == 'Dealer-Stock') {
            //         return true;
            //     } else {
            //         if(!!this.sqNumber) {
            //             return true;
            //         } else {
            //             if(!!item.additional || item.status == "approved") {
            //                 return true
            //             }

            //             return false;
            //         }
            //     }
            // }

            return false;
        },
        closeDialog(confirm) {
            if(confirm) {
                this.rfConversationChecker(this.rf.id);
            }
        },
        rejectWarrantyType() {
            if (!this.emojiReason && this.selectedEmoji == 1) {
                swal.fire("", "Please input why this emoji?", "question");
                return;
            }

            if(!!this.warrantyRejectReason) {
                swal.fire({
                    title: 'Are you sure you want to Change Warranty Type?',
                    text: 'Click ok to proceed',
                    icon: 'question',
                    showConfirmButton:true,
                    showCancelButton  :  true,
                    reverseButtons:true,
                    allowOutsideClick :  false,
                    confirmButtonColor: '#397373',
                    cancelButtonColor : 'grey',
                    confirmButtonText:'Confirm',
                    showCloseButton:true,
                }).then(result=> {
                    if(result.isConfirmed) {
                        let payload = {
                            emoji_id: this.emoji,
                            rfId: this.rfId,
                            warrantyRejectReason: this.warrantyRejectReason,
                            emojiReason: this.emojiReason,
                        }
                        this.$store.dispatch('rejectWarrantyType',payload).then(response=>{
                            if(response.data.error) {
                                swal.fire('', response.data.error, 'error');
                            } else {
                                this.$emit('successConfirm', true);
                                this.closeRfDetailsDialog();
                                swal.fire("", response.data.msg, "success");
                            }
                        });
                    } else {
                        this.approveForReplacementOnFlight = false;
                    }
                });
            } else {
                swal.fire("", 'Please input warranty reject reason', "warning");
            }

        },
        confirmChangeWarranty() {
            this.confirmChangeWarrantyOnFlight = true;

            if(this.itemWarrantyType == 'In-Warranty' && this.uploadedFiles == null) {
                swal.fire('', 'Please Upload Warranty Document', 'warning').then(x=>{
                    this.confirmChangeWarrantyOnFlight = false;
                });
            } else if(!this.purchaseDate && this.itemWarrantyType == 'In-Warranty') {
                 swal.fire('', 'Please Input Purchase Date', 'warning').then(x=>{
                    this.confirmChangeWarrantyOnFlight = false;
                });
            } else {
                const data = new FormData();

                const config = {
                    headers: {
                        'content-type': 'multipart/form-data'
                    }
                }

                if(!!this.uploadedFiles && this.uploadedFiles.attachments.length > 0) {
                    let files = this.uploadedFiles.attachments;

                    for(let i=0;i < _.compact(files).length;i++) {
                        data.append('files[]', files[i]);
                    }
                }


                let collectedOrderList = [];

                _.compact(this.rfEstItems).forEach(estitem=> {
                    collectedOrderList.push({
                        quantity: estitem.requested_qty,
                        item_id: estitem.item_id,
                        foc: estitem.foc,
                        disc_price: estitem.discounted_price,
                        total_amount: estitem.total,
                        amount: estitem.total * estitem.requested_qty,
                        type: estitem.item.item_type_text,
                        item: {
                            model: estitem.item.model,
                            name: estitem.item.name,
                            type_id: estitem.item.type_id,
                            item_uom_text: estitem.item.item_uom_text,
                            selling_price: estitem.selling_price,
                            srp: estitem.selling_price,
                            display_srp: estitem.selling_price,
                            moq: estitem.item.name,
                            name: estitem.item.name,
                        },
                        is_editable: true,
                        limit: 212,
                        display_disc_price: estitem.discounted_price,
                        display_total_amount: estitem.discounted_price,
                        orig_selling_price: estitem.selling_price,
                        sc_rf_est_item_id: estitem.id,
                        additional: estitem.additional,
                        additional_qty: estitem.additional_qty,
                        new_estimate: estitem.new_estimate,
                    })
                })

                let remarks = `RF# ${this.rf.rf_number} | ${this.itemModel} | ${this.itemSerial}`
                if (this.customerId === 275) {
                    remarks = remarks + ` | ${this.customerName}`
                }

                let obj = JSON.stringify({
                    updatedWarrantyItems: this.rfEstItems.map(e=>{
                        e.total = e.selling_price * e.requested_qty
                        return e
                    }),
                    orderList: collectedOrderList,
                    discounts: JSON.parse(this.customerDetails.discounts)
                });

                data.append('rfId', this.rfId)
                data.append('newWarrantyType', this.itemWarrantyType)
                data.append('itemRepairStatus', this.itemRepairStatus)
                data.append('itemPurchaseDate', this.purchaseDate)
                data.append('changeWarrantyType', this.changeWarrantyType);

                data.append('customer_id', this.customerDetails.id)
                data.append('salesman_id', 5)
                data.append('shipping_address_index', 0)
                data.append('shipping_address_text', this.customerDetails.all_shipping_addresses[0].text)
                data.append('discounts', JSON.parse(this.customerDetails.discounts));
                data.append('terms', this.customerDetails.terms)
                data.append('stock_card', 0)
                data.append('remarks', remarks)
                data.append('rfEstimationId', this.rfEstimation.id)
                data.append('customer_id', this.customerId);

                data.append('data', obj);

                swal.fire({
                    title: 'Are you sure you want to Update Warranty Type?',
                    text: 'Click ok to proceed',
                    icon: 'question',
                    showConfirmButton:true,
                    showCancelButton  :  true,
                    reverseButtons:true,
                    allowOutsideClick :  false,
                    confirmButtonColor: '#397373',
                    cancelButtonColor : 'grey',
                    confirmButtonText:'Confirm',
                    showCloseButton:true,
                }).then(async result=> {
                    if(result.isConfirmed) {
                        await this.updateRfEstItems();
                        let payload = {
                            params: data,
                            config: config,
                        }
                        await this.$store.dispatch('confirmChangeWarranty',payload).then(response=>{
                            if(response.data.error) {
                                swal.fire('', response.data.error, 'error');
                            } else {
                                this.$emit('successConfirm', true);
                                this.closeRfDetailsDialog();
                                swal.fire("", response.data.msg, "success");
                            }
                        });
                    }

                    this.confirmChangeWarrantyOnFlight = false;
                });
            }
        },
        uploadedData(data) {
            if(!data) {
                this.uploadedFiles = null;
            }

            this.uploadedFiles = data;
        },
        clearFileUpload(confirm) {
            if(confirm) {
                this.uploadedFiles = null;
            }
            // this.$refs.fileUpload.reset();
        },
        showPrintSq() {
            if(!!this.sqNumber) {
                // if(!!this.printedSq) {
                //     return true;
                // }

                return false;
            }

            return true;
        },
        sqPrintSuccess(confirm) {
            if(confirm) {
                swal.fire('','Print Sales Quotation Success','success').then(confirm=>{
                    this.getRfEstimation(this.rf.id)
                    }
                );
            }
        },
        // Print SalesQuotation Start

        renderPDF(id){
            this.$store.dispatch('viewSalesQuotation',id).then(async response => {
                let data = this.GET_VIEW_SALES_QUOTATION

                if(data.error){
                    swal.fire("", data.error, "warning");
                    return false
                }

                this.sales_quotation = data.sales_quotation

                this.salesQuotationItems = data.sales_quotation.sales_quotation_items

                if(data.sales_quotation.discounts != ''){
                    this.discounts = JSON.parse(data.sales_quotation.discounts)
                    if(this.discounts != null){
                        this.discounts = this.discounts[0].discounts
                    }
                }

                await this.getItemTypes()
            }).catch(error => {
                if(error.response.status == 422){
                    this.errors = error.response.data.errors
                }
            });
        },
        successPrintSq() {
            this.$store.dispatch('successPrintSq',this.rfEstId).then(response=>{
                    this.$emit('sqPrintSuccess', true);
                    this.printSqOnFlight = false;
                }
            );
        },
        printNow(){
            var doc = new this.$jspdf('p', 'mm', 'letter');

            // doc.fromHTML($("#div_logo").get(0), 7,-4,{'width':600});
            // doc.fromHTML($("#div_sales_quote_num").get(0), 162,4,{'width':400});

            doc.setFont(undefined, 'bold').setFontSize(13).text(6,8,"WADFOW Tools Philippines Corp.")
            doc.setFont(undefined, 'normal').setFontSize(9).text(6,13,"420 Del Monte Ave, Brgy. Siena, Quezon City \nCell No.: +63 935 713 2293 / +63 917 170 0979 / +63 919 078 0016 \nTel. No.: +63 2 8727 1771 / +63 2 8535 9968 / +63 2 7091 4539 \nService Mobile / Viber No.: +63 919 078 0019 / +63 927 414 5539 \nSpare Parts Mobile / Viber No.: +63 995 095 3153")
            doc.setFont(undefined, 'bold').setFontSize(13).text(162,8,"Sales Quotation")
            doc.setFont(undefined, 'bold').setFontSize(9).text(162,18,"SQ#: "+this.salesQuotationNumber)
            doc.setFont(undefined, 'bold').setFontSize(9).text(162,24,"Issued: "+this.$dayjs().format('YYYY-MM-DD H:M'))

            doc.autoTable({
                html: '#customer_details',
                theme: 'plain',
                margin: {top: 50, left: 6, right: 6},
                columnStyles: {
                    0: {cellWidth: 20},
                    1: {cellWidth: 134},
                    2: {cellWidth: 16},
                    3: {cellWidth: 25},
                }
            });

            if(this.print_no_price == false){
                doc.autoTable({
                    html: '#items_details',
                    startY: doc.autoTable.previous.finalY + 3,
                    margin: {left: 6, right: 6},
                    theme: 'grid',
                    headStyles: {
                        halign:'center',
                        fillColor: '#ffffff',
                        textColor: '#000000',
                        lineWidth: .2,
                        lineColor: '#000000',
                    },
                    columnStyles: {
                        0: {cellWidth: 9, halign:'center',  textColor: '#000000'},
                        1: {cellWidth: 13, halign:'center', textColor: '#000000'},
                        2: {cellWidth: 14, halign:'center', textColor: '#000000'},
                        3: {cellWidth: 29, halign:'center', textColor: '#000000'},
                        4: {halign:'left', textColor: '#000000'},
                        5: {cellWidth: 25, halign:'right', textColor: '#000000'},
                        6: {cellWidth: 25, halign:'right', textColor: '#000000'}
                    },
                    bodyStyles: {
                        lineColor: '#000000',
                    }
                });
            }else{
                doc.autoTable({
                    html: '#items_details_without_price',
                    startY: doc.autoTable.previous.finalY + 3,
                    margin: {left: 6, right: 6},
                    theme: 'grid',
                    headStyles: {
                        halign:'center',
                        fillColor: '#ffffff',
                        textColor: '#000000',
                        lineWidth: .2,
                        lineColor: '#000000',
                    },
                    columnStyles: {
                        0: {cellWidth: 11, halign:'center', textColor: '#000000'},
                        1: {cellWidth: 17, halign:'center', textColor: '#000000'},
                        2: {cellWidth: 17, halign:'center', textColor: '#000000'},
                        3: {cellWidth: 27, halign:'center', textColor: '#000000'},
                        4: {halign:'left', textColor: '#000000'},
                    },
                    bodyStyles: {
                        lineColor: '#000000',
                    }
                });
            }

            if(this.print_no_price == false){
                doc.autoTable({
                    html: '#total_details',
                    theme: 'plain',
                    startY: 207,
                    margin: {top: 37, left: 6, right: 6},
                    columnStyles: {
                        0: {cellWidth: 110, textColor: '#000000'},
                        1: {cellWidth: 40, halign:'right', textColor: '#000000'},
                        2: {cellWidth: 12, textColor: '#000000'},
                        3: {cellWidth: 35, halign:'right', textColor: '#000000'},
                    }
                });
            }

            doc.autoTable({
                html: '#quotation_only',
                theme: 'plain',
                startY: 215,
                margin: {top: 37, left: 6, right: 6},
                columnStyles: {
                    0: {cellWidth: 45, textColor: '#000000'},
                    1: {cellWidth: 7, halign:'right', textColor: '#000000'},
                    2: {cellWidth: 40, textColor: '#000000'},
                    3: {cellWidth: 35, halign:'right', textColor: '#000000'},
                }
            });

            var footer_y = doc.autoTable.previous.finalY + 3
            if(this.print_no_price == true){
                footer_y = 242
            }else{
                footer_y = 245
            }

            doc.autoTable({
                html: '#footer',
                theme: 'plain',
                startY: footer_y,
                margin: {left: 6, right: 6},
                columnStyles: {
                    0: {cellWidth: 40, halign:'center', textColor: '#000000'},
                    1: {cellWidth: 40, halign:'center', textColor: '#000000'},
                },
                bodyStyles: {
                    lineColor: '#000000',
                }
            });

            let finalY = doc.lastAutoTable.finalY; // The y position on the page
            doc.setFontSize(7);
            doc.setTextColor('#000000');
            doc.text(190, finalY, "Page 1 of 1")

            if(this.pdfAction == 'download'){
                doc.save('SalesQuotation ' + this.sales_quotation.quote_num + '.pdf');
                this.successPrintSq();
            }else if(this.pdfAction == 'print'){
                doc.autoPrint();
                window.open(doc.output('bloburl'), '_blank');
                this.successPrintSq();
            }
        },
        getItemTypes(){

            this.$store.dispatch('getItemTypes').then(async response => {
                this.type_list = this.GET_ITEM_TYPES;
                await this.getItemTypeDiscounts();
                await this.getItemTypeDiscountsPDF();
                await this.printNow();
            });
        },
        getItemTypeDiscounts(){
            this.itemTypeDiscounts = []
            if(this.type_list.length > 0){
                this.type_list.forEach((type)=>{
                    var name = type.text
                    var str = type.text + ': '
                    var isEmpty = true;
                    this.discounts.forEach((discount, index)=>{
                        if(!discount[name.toLowerCase()]){
                            if(discount[name.toLowerCase()] != null && discount[name.toLowerCase()] != '' && discount[name.toLowerCase()] > 0){
                                if(index > 0){
                                    str = str + '/'
                                }
                                str = str + discount[name.toLowerCase()].toString()
                                isEmpty = false
                            }
                        }

                    })
                    if(isEmpty){
                        str = ''
                    }else{
                        if(this.salesQuotationItems[0].item.item_type_text == type.text){
                            this.itemTypeDiscounts.push(str)
                        }
                    }
                })
            }
        },
        getItemTypeDiscountsPDF(){
            this.itemTypeDiscountsPDF = []
            if(this.type_list.length > 0){
                this.type_list.forEach((type)=>{
                    var name = type.text
                    var str = '('+type.text+') LESS: '
                    var isEmpty = true;

                    if(this.discounts.length > 0){
                        this.discounts.forEach((discount, index)=>{
                            if(discount[name.toLowerCase()] != null && discount[name.toLowerCase()] != '' && discount[name.toLowerCase()] > 0){
                                if(index > 0){
                                    str = str + ' / '
                                }
                                str = str + discount[name.toLowerCase()].toString() + '%'
                                isEmpty = false
                            }
                        })
                        if(isEmpty){
                            str = ''
                        }else{
                            if(this.salesQuotationItems[0].item.item_type_text == type.text){
                                this.itemTypeDiscountsPDF.push(str)
                            }
                        }

                    }
                })
            }
        },
        computePrintOutItemTotal(srp,qty){
            return Math.round(parseFloat(srp) * qty).toFixed(2)
        },
        // Print SalesQuotation End
        showDelete() {
            if(this.rfStatus == 'FTA') {
                return true;
            }
            return false;
        },
        warrantyRejectReasonDialog() {
            this.showWarrantyReason = true;
        },
        closeWarrantyReason() {
            this.warrantyRejectReason = "";
            this.showWarrantyReason = false;
            this.selectedEmoji = ''
        },
        showDiagnosisRejectReasonDialog() {
            this.diagnosisReasonDialog = true;
        },
        closeDiagnosisRejectReasonDialog() {
            this.diagnosisRejectReason = "";
            this.diagnosisReasonDialog = false;
        },
        showOverrideForReplacement() {
            this.override.override_dialog = true;
        },
        closeConfirmOverride(user_id, authenticated = false) {
            if (authenticated) {
                this.override.override_dialog = false;
                this.forReplacementApproval();
            } else {
                this.override.override_dialog = false;
                // this.showWarrantyReason = true;
            }
        },
        printForReturnSp() {
            let doc = new this.$jspdf('p', 'mm', 'letter');
            // doc.fromHTML($("#div_return_sp_logo").get(0), 7,-4,{'width':600});
            // doc.fromHTML($("#div_return_sp_num").get(0), 142,-2,{'width':400});

            doc.setFont(undefined, 'bold').setFontSize(13).text(6,8,"WADFOW Tools Philippines Corp.")
            doc.setFont(undefined, 'normal').setFontSize(9).text(6,13,"420 Del Monte Ave, Brgy. Siena, Quezon City \nCell No.: +63 935 713 2293 / +63 917 170 0979 / +63 919 078 0016 \nTel. No.: +63 2 8727 1771 / +63 2 8535 9968 / +63 2 7091 4539 \nService Mobile / Viber No.: +63 919 078 0019 / +63 927 414 5539 \nSpare Parts Mobile / Viber No.: +63 995 095 3153")
            doc.setFont(undefined, 'bold').setFontSize(13).text(162,8,"Spare Parts")
            // doc.setFont(undefined, 'bold').setFontSize(9).text(162,18,"RFsss: "+this.rf.rf_number)
            doc.setFont(undefined, 'bold').setFontSize(9).text(162,13,"\nRF#:"+this.rfNumber+"\n\nPullout#: "+this.pulloutNumber+", RD#: "+this.rrNumber+"\n\nIssued: "+this.rrDate)

            doc.autoTable({
                html: '#return_sp_item_details',
                margin: {left: 7, right: 6},
                startY: 35,
                columnStyles: {
                    0: {cellWidth: 15, halign:'left', textColor: '#000000', fontSize: 8},
                    1: {cellWidth: 63, halign:'left', textColor: '#000000', fontSize: 8},
                    2: {cellWidth: 45, halign:'left', textColor: '#000000', fontSize: 8},
                    3: {cellWidth: 30, halign:'left', textColor: '#000000', fontSize: 8},
                    4: {cellWidth: 45, halign:'left', textColor: '#000000', fontSize: 8},
                }
            });
            doc.autoTable({
                html: '#return_sp_process_details',
                theme: 'grid',
                startY: 258,
                margin: {top: 37, left: 7, right: 6},
                columnStyles: {
                    0: {cellWidth: 23, halign:'left', textColor: '#000000', fontSize: 8},
                    1: {cellWidth: 23, halign:'left', textColor: '#000000', fontSize: 8},
                    2: {cellWidth: 23, halign:'left', textColor: '#000000', fontSize: 8},
                    3: {cellWidth: 23, halign:'left', textColor: '#000000', fontSize: 8},
                    4: {cellWidth: 23, halign:'left', textColor: '#000000', fontSize: 8},
                    5: {cellWidth: 23, halign:'left', textColor: '#000000', fontSize: 8},
                    6: {cellWidth: 23, halign:'left', textColor: '#000000', fontSize: 8},
                    7: {cellWidth: 23, halign:'left', textColor: '#000000', fontSize: 8}
                }
            });
            doc.autoPrint();

            window.open(doc.output('bloburl'), '_blank')
        },
        getRfPartsOrderForReturn() {
            this.$store.dispatch('getRfPartsOrderForReturn',this.rfId).then(response=>{
                this.selectedForReturn = this.GET_RF_PARTS_ORDER_FOR_RETURN.pois;
                this.processedReturnBy = this.GET_RF_PARTS_ORDER_FOR_RETURN.processed_by;
            });
        },
        checkForReplacementStock() {
            // if(_.compact(this.rfEstItems)) {
            //     let withStock = _.compact(this.rfEstItems).every(x=>{
            //         return x.warehouse_code == 'DM' && x.stock > 0;
            //     });

            //     if(withStock) {
            //         return false;
            //     }
            // }


            // return true;

            return false;
        },
        checkPartNum(item) {
            if(!!this.rfReEstimate) {
                if(!!this.sqNumber) {
                    return true;
                }

                return !item.new_estimate;
            } else {
                if(!!this.sqNumber) {
                    return true;
                }
            }

            return false;
        },
        warrantyTypeItems() {
            if(this.itemWarrantyType == 'In-Warranty') {
                return ['In-Warranty','Out-Warranty'];
            } else {
                return ['In-Warranty','Out-Warranty', 'Dealer-Stock'];
            }
        },
        approveChangeWarranty() {
            this.approveChangeWarrantyOnFlight = true;
            this.rejectChangeWarrantyOnFlight = true;

            swal.fire({
                title: 'Approve Change Warranty?',
                icon: 'question',
                dangerMode: true,
                buttons: true
            }).then(async (confirm) => {
                if(confirm.isConfirmed) {
                    this.$store.dispatch('servicePost', {
                        url: 'approveChangeWarranty/' + this.rf.id
                    }).then(response=>{
                        this.$emit('successConfirm', true);
                        this.closeRfDetailsDialog();
                        swal.fire("", response.data.msg, "success");
                        this.approveChangeWarrantyOnFlight = false;
                        this.rejectChangeWarrantyOnFlight = false;
                    }
                );
                } else {
                    this.approveChangeWarrantyOnFlight = false;
                    this.rejectChangeWarrantyOnFlight = false;
                }
            });
        },
        rejectChangeWarranty() {
            this.approveChangeWarrantyOnFlight = true;
            this.rejectChangeWarrantyOnFlight = true;

            swal.fire({
                title: 'Reject Change Warranty?',
                icon: 'question',
                dangerMode: true,
                buttons: true
            }).then(async (confirm) => {
                if(confirm.isConfirmed) {
                    this.$store.dispatch('servicePost', {
                        url: 'rejectChangeWarranty/' + this.rf.id
                    }).then(response=>{
                        this.$emit('successConfirm', true);
                        this.closeRfDetailsDialog();
                        swal.fire("", response.data.msg, "success");
                        this.approveChangeWarrantyOnFlight = false;
                        this.rejectChangeWarrantyOnFlight = false;
                    }
                );
                } else {
                    this.approveChangeWarrantyOnFlight = false;
                    this.rejectChangeWarrantyOnFlight = false;
                }
            });
        },
        moveToDef() {
            swal.fire({
                title: 'Are you sure you want to move this to DEF?',
                text: 'Click "Confirm" to proceed',
                icon: 'question',
                showConfirmButton:true,
                showCancelButton:  true,
                reverseButtons:true,
                allowOutsideClick :  false,
                confirmButtonColor: '#397373',
                cancelButtonColor : 'grey',
                confirmButtonText:'Confirm',
            }).then(async confirm => {
                if(confirm.isConfirmed) {
                    await this.$store.dispatch('servicePost', {
                        url:'moveToDef',
                        rfId: this.rfId,
                    }).then(response => {
                        swal.fire('Success!','','success')
                        this.closeRfDetailsDialog()
                    }).catch(err => {
                        swal.fire('Error!','','error')
                        console.log(err.response.data.message)
                    })
                }
            })
        },

        createPartsTransfer() {
            if (this.GET_RF_ESTIMATION.parts_transfer.length > 0)
            {
                this.buttonLoading = true;
                const pt_num = _.last(this.GET_RF_ESTIMATION.parts_transfer).series
                swal.fire({
                    title: `Whoops!!`,
                    text: `-This RF has existing parts transfer, ${'PT#: ' + pt_num}`,
                    icon: 'info',
                    allowOutsideClick :false,
                    showConfirmButton:true,
                    confirmButtonText:'OK'
                }).then( ok => {
                    if(ok.isConfirmed) {
                        this.buttonLoading = false;
                    }
                })
            }else {
                // let desti_warehouse = this.warehousesCode.filter(x=>x.code == 'DM-B')[0];
                // let source_warehouse = this.warehousesCode.filter(x=>x.code == 'DM-D')[0];

                swal.fire({
                    title: 'Are you sure you want to create parts transfer?',
                    text: 'Click "Confirm" to proceed',
                    icon: 'question',
                    showConfirmButton:true,
                    showCancelButton:  true,
                    reverseButtons:true,
                    allowOutsideClick :  false,
                    confirmButtonColor: '#397373',
                    cancelButtonColor : 'grey',
                    confirmButtonText:'Confirm',
                }).then(async confirm => {
                    if(confirm.isConfirmed) {
                        await this.$store.dispatch('servicePost', {
                            // source_warehouse_id: source_warehouse.id,
                            // destination_warehouse_id: desti_warehouse.id,
                            url:'create-parts-transfer',
                            sc_repair_form_id: this.rfId,
                            remarks:'Defective, RF#: '+ this.rfNumber,
                            item_id:this.rfEstItems.filter(e=>{
                                if(e.status != 'completed'){
                                    e.quantity = e.requested_qty
                                    return e
                                }
                            }),
                            sc_parts_order_id: this.partsOrderId,
                            total_quantity:this.sumArraybyAttribute(this.rfEstItems,'quantity'),
                        }).then(response => {
                            swal.fire('Success!',`-BKLS# ${response.data.parts_transfer_number}`,'success')
                            this.closeRfDetailsDialog()
                        }).catch(err => {
                            swal.fire('Error!','','error')
                            console.log(err.response.data.message)
                        })
                    }
                })
            }
        },

        revertRfStatus(status){
            swal.fire({
                title: 'Are you sure you want to revert RF status to UAS?',
                text: 'Click "Confirm" to proceed',
                icon: 'question',
                showConfirmButton:true,
                showCancelButton:  true,
                reverseButtons:true,
                allowOutsideClick :  false,
                confirmButtonColor: '#397373',
                cancelButtonColor : 'grey',
                confirmButtonText:'Confirm',
            }).then(async confirm => {
                if(confirm.isConfirmed) {
                    this.loading = true
                    this.$store.dispatch('servicePost', {rfId:this.rfId,status:status,url:'revert-status'}).then( res => {
                        let message = res.data.message;
                        swal.fire(message, '', message.toLowerCase());
                        this.closeRfDetailsDialog();
                        this.loading = false;
                    }).catch(err => {
                        console.log(err);
                        this.loading = false;
                    });
                }
            });
        }

     }


};
</script>

<style scoped>
</style>
